import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import invoiceImage from "../../../../../images/invoice.png";
import { Image as ReactPDFImage } from "@react-pdf/renderer";
import { tokens } from "@fluentui/react-theme";

const styles = StyleSheet.create({
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    marginRight: "auto",
  },
  reportTitle: {
    fontSize: "22px",
    color: tokens.colorBrandBackgroundPressed,
  },
  bold: {
    fontWeight: 700,
    fontStyle: "bold",
  },
  logo: {
    width: "125px",
    marginRight: "10px",
  },
});

const InvoiceLogo = ({ invoice }) => {
  return (
    <>
      <View style={styles.titleContainer}>
        <ReactPDFImage style={styles.logo} alt="" src={invoiceImage} />
      </View>
    </>
  );
};

export default InvoiceLogo;
