import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const City = (props) => {
  const { city } = props;
  const style = Style();
  const { t } = useTranslation();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t('project.city')}
          validationMessage={props.errors?.city?.message}
          id="city"
          placeholder={t('project.enter_city')}
          name="city"
          inputDefaultValue={city}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("city")}
          noRequired
        />
      </Stack>
    </>
  );
};

export default City;
