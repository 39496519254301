import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import FieldsTextarea from "../../../../components/FieldComponents/FieldsTextarea";

const SystemUserAddress = (props) => {
  const { systemUserAddress } = props;
  const style = Style();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsTextarea
          label={t("address")}
          validationMessage={props?.errors?.systemUserAddress?.message}
          placeholder={t("enter_address")}
          name="systemUserAddress"
          id="systemUserAddress"
          maxLength={255}
          noRequired
          inputClassName={style.textboxInput}
          inputDefaultValue={systemUserAddress}
          validation={props.register("systemUserAddress")}
        />
      </Stack>
    </>
  );
};

export default SystemUserAddress;
