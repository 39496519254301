import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const SystemUserState = (props) => {
  const { systemUserState } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("state")}
          validationMessage={props.errors?.systemUserState?.message}
          id="systemUserState"
          placeholder={t("enter_state")}
          name="systemUserState"
          noRequired
          inputDefaultValue={systemUserState}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("systemUserState")}
        />
      </Stack>
    </>
  );
};

export default SystemUserState;
