import { makeStyles, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  prodBtn: {
    minWidth: "51px",
  },
  headerLabel: {
    ...shorthands.padding("", "7px"),
  },
  projectContent: {
    ...shorthands.padding("20px"),
    ...shorthands.margin("30px", 0, 0, 0),
  },
  wordBreak: {
    wordWrap: "break-word",
  },
});

export default style;
