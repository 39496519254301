import ProjectList from "../pages/Project/ProjectList";
import ClientList from "../pages/Client/ClientList";
import AddClient from "../pages/Client/ClientManagement/AddClient";
import EditClient from "../pages/Client/ClientManagement/EditClient";
import AddProject from "../pages/Project/ProjectManagement/AddProject";
import ViewClient from "../pages/Client/ClientManagement/ViewClient";
import EditProject from "../pages/Project/ProjectManagement/EditProject";
import ViewProject from "../pages/Project/ProjectManagement/ViewProject";
import Theme from "../pages/Client/ClientSetting/Theme";
import ProjectLocation from "../pages/Project/Location";
import {
  WhoIsWorkingToday,
  DetailedTimesheet,
} from "../pages/TimesheetAndAttandance";
import UsersList from "../pages/User/UserList/UsersList";
import AddUsers from "../pages/User/UsersManagement/AddUser";
import EditUsers from "../pages/User/UsersManagement/EditUsers";
import QuestionsList from "../pages/Questions/QuestionsList/QuestionsList";
import ViewUser from "../pages/User/UserList/ViewUser";
import SystemEndUserList from "../pages/SystemEndUser/SystemEndUserList/SystemEndUserList";
import AddSystemEndUser from "../pages/SystemEndUser/SystemEndUserManagement/AddSystemEndUser";
import EditSystemEndUser from "../pages/SystemEndUser/SystemEndUserManagement/EditSystemEndUser";
import ViewSystemUser from "../pages/SystemEndUser/SystemEndUserList/ViewSystemUser";
import SummaryTabs from "../pages/Project/Summary/SummaryTabs";
import ProjectSettingWrapper from "../pages/ProjectSettingWrapper/ProjectSettingWrapper";
import VisitorList from "../pages/TimesheetAndAttandance/Visitor";
import Licenses from "../pages/License";
import Bundle from "../pages/License/Bundle";
import Pdf from "../pages/Billing/BillingTabs/Invoices/Pdf";
import BillingTabs from "../pages/Billing/BillingTabs/BillingTabs";
import EditClientUserProfile from "../pages/Client/ClientManagement/EditClientUserProfile";
import Stripe from "../pages/Stripe/Stripe";
import OrderSuccess from "../pages/Stripe/OrderSuccess";
import OrderFailure from "../pages/Stripe/OrderFailure";
import ItemDetails from "../pages/License/Bundle/ItemDetails";
import EditUsersProfile from "../pages/User/UsersManagement/EditUsersProfile";
import SystemEditUserImport from "../pages/SystemEndUser/SystemEndUserImport";
import ExternalSourcesForm from "../pages/ExternalSources/ExternalSourcesForm";

const Routing = [
  {
    path: "/organization-list",
    element: <ClientList />,
  },
  {
    path: "/organization-list/add",
    element: <AddClient />,
  },
  {
    path: "/organization-list/:id/edit",
    element: <EditClient />,
  },
  {
    path: "/organization-list/:id",
    element: <ViewClient />,
  },
  {
    path: "/edit-organization-profile/:id/edit",
    element: <EditClientUserProfile />,
  },
  {
    path: "/project-list",
    element: <ProjectList />,
  },
  {
    path: "/project-list/add",
    element: <AddProject />,
  },
  {
    path: "/project-list/:id/edit",
    element: <EditProject />,
  },
  {
    path: "/project-list/:id",
    element: <ViewProject />,
  },
  {
    path: "/project-list/location/:id",
    element: <ProjectLocation />,
  },
  {
    path: "/project-list/setting/:id",
    element: <ProjectSettingWrapper />,
  },
  {
    path: "/theme",
    element: <Theme />,
  },
  {
    path: "/time-and-attandance/who-is-working-today",
    element: <WhoIsWorkingToday />,
  },
  {
    path: "/time-and-attandance/detailed-timesheet",
    element: <DetailedTimesheet />,
  },
  {
    path: "/time-and-attandance/visitor",
    element: <VisitorList />,
  },
  {
    path: "/summary",
    element: <SummaryTabs />,
  },

  {
    path: "/users-list",
    element: <UsersList />,
  },
  {
    path: "/users-list/add",
    element: <AddUsers />,
  },
  {
    path: "/users-list/:id",
    element: <ViewUser />,
  },
  {
    path: "/users-list/:id/edit",
    element: <EditUsers />,
  },
  {
    path: "/user-profile/:id/edit",
    element: <EditUsersProfile />,
  },
  {
    path: "/questions-list",
    element: <QuestionsList />,
  },
  {
    path: "/system-end-user-list",
    element: <SystemEndUserList />,
  },
  {
    path: "/system-end-user-list/add",
    element: <AddSystemEndUser />,
  },
  {
    path: "/system-end-user-list/:id",
    element: <ViewSystemUser />,
  },
  {
    path: "/system-end-user-list/:id/edit",
    element: <EditSystemEndUser />,
  },
  {
    path: "/system-end-user-import",
    element: <SystemEditUserImport />,
  },

  {
    path: "/pdf",
    element: <Pdf />,
  },
  {
    path: "/billing",
    element: <BillingTabs />,
  },
  {
    path: "/licenses",
    element: <Licenses />,
  },
  {
    path: "/licenses/bundle",
    element: <Bundle />,
  },
  {
    path: "/licenses/bundle/bundleQty",
    element: <ItemDetails />,
  },
  {
    path: "/licenses/bundle/bundleQty/client-order/:id/checkout",
    element: <Stripe />,
  },
  {
    path: "/bundleQty",
    element: <ItemDetails />,
  },
  {
    path: "/client-order/:id/payment/success",
    element: <OrderSuccess />,
  },
  {
    path: "/subscription/payment/error/:id",
    element: <OrderFailure />,
  },
  {
    path: "externalSources",
    element: <ExternalSourcesForm />,
  },
];

export default Routing;
