import * as React from "react";
import { Field, mergeClasses } from "@fluentui/react-components";
import { Controller } from "react-hook-form";
import { DatePicker } from "@fluentui/react-datepicker-compat";

const FieldDatepicker = ({
  label,
  name,
  control,
  placeholderText,
  defaultValue,
  noRequired,
  validation,
  onFormatDate,
  validationMessage,
  validationMessageIcon = "",
  size = "large",
  onSelectDate,
  maxDate,
  minDate,
}) => {
  return (
    <Field
      label={label}
      validationMessage={validationMessage}
      className={mergeClasses("custom-label")}
      validationMessageIcon={validationMessageIcon}
      size={size}
      required={noRequired ? undefined : true}
    >
      <Controller
        control={control}
        name={name}
        render={({ field, onChange }) => (
          <>
            <DatePicker
              onSelectDate={onSelectDate}
              minDate={minDate}
              placeholder={placeholderText}
              maxDate={maxDate}
              value={defaultValue}
              {...validation}
              formatDate={onFormatDate}
            />
          </>
        )}
      />
    </Field>
  );
};

export default FieldDatepicker;
