import { useState } from "react";
import { useLoginUserMutation } from "../store/action/user";
import { getAccessRoles } from "../utils/getAccessRoles";

const LoggedUser = () => {
  const [viewData, setViewData] = useState();
  const [initialLoad, setInitialLoad] = useState(true);
  const [getUserApi, { isGetLoading }] = useLoginUserMutation();

  if (!localStorage.getItem("activeUser")) {
    if (initialLoad) {
      if (!isGetLoading) {
        getUserApi()
          .then((res) => {
            let person = res.data;
            setViewData(res);
            //build roles array and set and dispatch
            const roleList = getAccessRoles(person);

            localStorage.setItem(
              "canBeImportEmployee",
              person?.canBeImportEmployee
            );

            localStorage.setItem(
              "loginCompanyName",
              person?.client?.companyName
            );
            localStorage.setItem("loginClientId", person?.client?.id);

            //----------

            localStorage.setItem("activeUser", person?.fullName);
            localStorage.setItem("activeUserId", person?.id);
            localStorage.setItem("activeUserImage", person?.profileImageUrl);
            //-----------
            localStorage.setItem("activeClientId", person?.activeClient?.id);

            localStorage.setItem(
              "activeClientName",
              person?.activeClient?.companyName
            );
            //-------
            localStorage.setItem("canCreateProject", person?.canCreateProject);
            localStorage.setItem("accessRoles", roleList);
          })
          .catch((e) => {});
        setInitialLoad(false);
      }
    }
  }
  return viewData?.data;
};

export default LoggedUser;
