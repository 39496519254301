import React from "react";
import { Stack } from "@fluentui/react";
import Layout from "../../../styles/Layout.styles";
import Style from "../style";
import { mergeClasses, Spinner } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../../../components";
import { useBundleListMutation } from "../../../store/action/bundle";
import { useState } from "react";
import { useSelector } from "react-redux";
import BundleListRow from "./BundleListRow";

const BundleList = (props) => {
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();
  const [getBundleList, { isLoading }] = useBundleListMutation();
  const [isInitial, setIsInitial] = useState(true);
  const bundleList = useSelector((state) => state.bundle);
  const [displayTitle, setDisplayTitle] = useState(true);

  if (isInitial) {
    if (!isLoading) {
      getBundleList();
      setIsInitial(false);
    }
  }

  const breadcrumbData = [
    {
      title: `${t("licenses.license_text")}`,
      url: "/licenses",
    },
    {
      title: `${t("bundle.bundle_text")}`,
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack
        className={mergeClasses(
          layout.projectContent,
          "cardShadow",
          layout.borderRadiusBottom
        )}
      >
        {displayTitle && (
          <Stack className={mergeClasses(style.headerText, layout.mb_2)}>
            {t("bundle.bundleList_header_text")}
          </Stack>
        )}

        {isLoading ? (
          <Stack
            className={mergeClasses(layout.positionRelative, layout.w_100)}
          >
            <Spinner className={layout.m_auto} />
          </Stack>
        ) : (
          <>
            <Stack className={mergeClasses(layout.bundleCard, layout.mt_1)}>
              {bundleList?.data?.map((item, key) => (
                <BundleListRow
                  item={item}
                  key={key}
                  setDisplayTitle={setDisplayTitle}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default BundleList;
