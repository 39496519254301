import { Stack } from "@fluentui/react";
import { useStyle } from "../Style";
import { mergeClasses } from "@fluentui/react-components";
import FilterActions from "./FilterActions";
import TeamFilter from "./TimesheetFilter/TeamFilter";
import ProjectFilter from "./TimesheetFilter/ProjectFilter";
import { useTeamsListMutation } from "../../../store/action/teams";
import { useProjectListMutation } from "../../../store/action/project";
import React, { useEffect, useState } from "react";
import DateFilter from "./TimesheetFilter/DateFilter";

const DetailedTimesheetFilter = ({
  setAssignedProjectId,
  setAssignedTeamId,
  assignedTeamId,
  timeSheetData,
  assignedProjectId,
  setIsProjectLoader,
  setIsTeamLoader,
  setIsDateLoader,
}) => {
  const style = useStyle();
  const [options, setOptions] = useState([]);
  const [projectOption, setProjectOptions] = useState([]);
  const [filterDates, setFilterDate] = useState([null, null]);
  const [GetTeams] = useTeamsListMutation();
  const [GetProject] = useProjectListMutation();

  useEffect(() => {
    GetProject({
      pagination: false,
      isActive: "true",
    }).then((projectRes) => {
      const data = projectRes?.data?.map?.((item, label) => ({
        children: item?.title,
        value: item?.id,
      }));
      setProjectOptions(data);
      setAssignedProjectId(projectRes?.data[0]?.id);
      GetTeams({
        pagination: false,
        project: projectRes?.data[0]?.id,
        search: "",
        sortBy: "",
        sortType: "",
      }).then((res) => {
        const teamData = res?.data?.map?.((item, label) => ({
          children: item?.name,
          value: item?.id,
        }));
        setOptions(teamData);
      });
    });
  }, [GetProject, GetTeams, setAssignedProjectId]);

  return (
    <>
      <Stack className={mergeClasses(style.detailedFilter)}>
        <Stack
          className={mergeClasses(style.filterWrapper, style.tnaFilterWrapper)}
        >
          <ProjectFilter
            projectOption={projectOption}
            assignedProjectId={assignedProjectId}
            setOptions={setOptions}
            filterDates={filterDates}
            setAssignedProjectId={setAssignedProjectId}
            setIsProjectLoader={setIsProjectLoader}
            setAssignedTeamId={setAssignedTeamId}
          />
          <DateFilter
            assignedProjectId={assignedProjectId}
            assignedTeamId={assignedTeamId}
            setFilterDate={setFilterDate}
            setIsDateLoader={setIsDateLoader}
          />
          <TeamFilter
            options={options}
            setOptions={setOptions}
            filterDates={filterDates}
            setAssignedTeamId={setAssignedTeamId}
            assignedTeamId={assignedTeamId}
            assignedProjectId={assignedProjectId}
            setIsTeamLoader={setIsTeamLoader}
          />
        </Stack>
        <FilterActions timeSheetData={timeSheetData} />
      </Stack>
    </>
  );
};

export default DetailedTimesheetFilter;
