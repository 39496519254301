import * as React from "react";
import {
  Combobox,
  Option,
  makeStyles,
  mergeClasses,
  shorthands,
  typographyStyles,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
  },
  description: {
    ...typographyStyles.caption1,
  },
});

const FieldCombobox = ({
  onOptionSelect,
  selectedOptions,
  disabledOption,
  customSearch,
  matchingOptions,
  onChange,
  className,
  placeholder,
  multiselect,
  defaultSelectedOptions,
  defaultValue,
  stackCombobox,
  readOnly,
}) => {
  const styles = useStyles();

  return (
    <Stack className={mergeClasses(styles.root, stackCombobox)}>
      <Combobox
        multiselect={multiselect}
        freeform
        className={className}
        placeholder={placeholder}
        readOnly={readOnly}
        selectedOptions={selectedOptions}
        onOptionSelect={onOptionSelect}
        iconProps={{ iconName: "Search", styles: { root: { right: "8px" } } }}
        onChange={onChange}
        defaultOpen={false}
        defaultSelectedOptions={defaultSelectedOptions}
        defaultValue={defaultValue}
      >
        {!matchingOptions && customSearch && (
          <Option key="freeform" text={customSearch}></Option>
        )}
        {matchingOptions?.map((option, key) => (
          <Option
            key={key}
            className="field-option"
            value={option?.value}
            disabled={disabledOption?.includes(option?.value)}
          >
            {option?.children}
          </Option>
        ))}
      </Combobox>
    </Stack>
  );
};

export default FieldCombobox;
