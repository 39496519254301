import {
  Button,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import MenuImage from "./menuImage";
import { Stack } from "@fluentui/react";
import { ChevronDownRegular, ChevronUpRegular } from "@fluentui/react-icons";
import { NavLink, useLocation } from "react-router-dom";

export default function SidebarTopItem({
  menu,
  isOpen,
  setOpenAccordion,
  openAccordion,
}) {
  const layout = Layout();
  let crossAxis = 50;
  let mainAxis = -25;

  const location = useLocation();

  const activeTimeTabBold =
    menu?.visible &&
    menu?.children?.find((item, key) => {
      return location.pathname.includes(item?.url);
    });

  return (
    <>
      {!isOpen ? (
        menu.children && menu.visible ? (
          <Menu
            className="headerRightDropdown"
            positioning={{ position: "after", offset: { crossAxis, mainAxis } }}
          >
            <MenuTrigger disableButtonEnhancement className={layout.w_100}>
              <Button
                appearance="transparent"
                className={mergeClasses(
                  layout.sidebarLink,
                  "side-bottom-link",
                  layout.p_0,
                  layout.justifyContentStart
                )}
              >
                <Stack
                  className={mergeClasses(layout.sidebarList, "static-menu")}
                  value={menu.key}
                  key={menu.name}
                >
                  <NavLink
                    key={menu.key}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            fontWeight: "bold",
                            color: tokens.colorBrandBackground,
                            borderLeft:
                              (!(menu.children && menu.visible) &&
                                "3px solid ") ||
                              (activeTimeTabBold && "3px solid "),
                            paddingLeft: "8px",
                          }
                        : {
                            paddingLeft: "11px",
                          };
                    }}
                    to={!(menu.children && menu.visible) && menu.url}
                  >
                    <Stack
                      className={mergeClasses(
                        layout.d_flex,
                        layout.flex_row,
                        "side-bottom-link",
                        layout.w_100
                      )}
                    >
                      <Tooltip withArrow content={menu.name}>
                        <div>
                          <MenuImage img={menu} />
                        </div>
                      </Tooltip>
                    </Stack>
                  </NavLink>
                </Stack>
              </Button>
            </MenuTrigger>

            <MenuPopover className="sidebarMenuTop">
              <MenuList className="sidebarMenuList">
                {menu?.children?.map(
                  (subMenu, subKey) =>
                    subMenu?.visible && (
                      // <MenuItemRadio
                      //   className={mergeClasses(
                      //     "headerRightMenuItem",
                      //     "aria-check",
                      //     layout.cursorAuto
                      //   )}
                      //   key={subKey}
                      //   value={subMenu?.key}
                      //   name={subMenu?.name}
                      // >
                      //   <Link
                      //     key={subMenu.key}
                      //     className={mergeClasses("headerLinkResp")}
                      //     to={subMenu.url || "#"}
                      //   >
                      //     {subMenu.name}
                      //   </Link>
                      // </MenuItemRadio>

                      <NavLink
                        key={subKey}
                        style={({ isActive }) => {
                          return isActive
                            ? {
                                fontWeight: "bold",
                                color: tokens.colorBrandBackground,
                              }
                            : {};
                        }}
                        to={subMenu.url || "#"}
                      >
                        <Stack className={layout.m_0_5}> {subMenu.name}</Stack>
                      </NavLink>
                    )
                )}
              </MenuList>
            </MenuPopover>
          </Menu>
        ) : (
          <Stack
            key={menu.index}
            className={mergeClasses(
              layout.sidebarDropdown,
              "sidebar-title",
              layout.sidebarList
            )}
          >
            <NavLink
              key={menu.key}
              style={({ isActive }) => {
                return isActive
                  ? {
                      fontWeight: "bold",
                      color: tokens.colorBrandBackground,
                      borderLeft:
                        (!(menu.children && menu.visible) && "3px solid ") ||
                        (activeTimeTabBold && "3px solid "),
                      paddingLeft: "8px",
                    }
                  : {
                      paddingLeft: "11px",
                    };
              }}
              to={menu.url || "#"}
            >
              <Tooltip withArrow content={menu.name}>
                <div>
                  <MenuImage img={menu} />
                </div>
              </Tooltip>
            </NavLink>
          </Stack>
        )
      ) : (
        <>
          <Stack
            className={mergeClasses(
              layout.w_100,
              menu.children && menu.visible && openAccordion
                ? "sidebar-menu open sidebar-dropdown"
                : "sidebar-menu"
            )}
          >
            <Stack
              key={menu.index}
              className={mergeClasses(
                layout.sidebarDropdown,
                "sidebar-title",
                layout.sidebarList,
                "side-drop"
              )}
              onClick={() =>
                setOpenAccordion(
                  menu.children && menu.visible && !openAccordion
                )
              }
            >
              <NavLink
                key={menu.index}
                value={menu.key}
                style={({ isActive }) => {
                  return isActive
                    ? {
                        fontWeight: !(menu.children && menu.visible) && "bold",
                        color: tokens.colorBrandBackground,
                        borderLeft:
                          (!(menu.children && menu.visible) && "3px solid ") ||
                          (activeTimeTabBold && "3px solid "),
                        paddingLeft: "10px",
                      }
                    : {
                        paddingLeft: "13px",
                      };
                }}
                to={!(menu.children && menu.visible) && menu.url}
              >
                <MenuImage img={menu} />

                {menu.children && menu.visible ? (
                  <span className={activeTimeTabBold && layout.fontWeight}>
                    {menu?.name}
                  </span>
                ) : (
                  <>{menu?.name}</>
                )}
                {menu.children &&
                  menu.visible &&
                  (openAccordion ? (
                    <ChevronUpRegular
                      fontSize="18px"
                      className="sidebar-bottom-arrow"
                    />
                  ) : (
                    <ChevronDownRegular
                      fontSize="18px"
                      className="sidebar-bottom-arrow"
                    />
                  ))}
              </NavLink>
            </Stack>
            <Stack className="sidebar-content">
              {menu?.children?.map((child, index) => (
                <Stack key={index}>
                  {child?.visible && (
                    <NavLink
                      key={index}
                      style={({ isActive }) => {
                        return isActive
                          ? {
                              fontWeight: "bold",
                              color: tokens.colorBrandBackground,
                            }
                          : {};
                      }}
                      to={child.url || "#"}
                    >
                      <Stack className="sidebar-menu">{child.name}</Stack>
                    </NavLink>
                  )}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}
