import { createSlice } from "@reduxjs/toolkit";

const device = createSlice({
  name: "device",
  initialState: { data: [], errorMessage: "", page: 0, itemsPerPage: 10 },
  reducers: {
    setDevice(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setDevice } = device.actions;
export default device.reducer;
