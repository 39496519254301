import {
  tokens,
  shorthands,
  makeStaticStyles,
} from "@fluentui/react-components";

const commonStyle = makeStaticStyles({
  ".text-primary label": {
    color: tokens.colorBrandBackground,
  },
  body: {
    ...shorthands.margin(0),
  },
  ".custom-pagination li a": {
    cursor: "pointer",
  },
  ".custom-label label": {
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase300,
    ...shorthands.margin(0, 0, "8px", 0),
  },
  ".disablePointing label, .pointer-none": {
    pointerEvents: "none",
  },
  "::-webkit-scrollbar": {
    width: "3.5px",
    height: "3.5px",
  },
  "::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 3.5px rgba(0, 0, 0, 0.3)",
    width: "3px",
  },
  "::-webkit-scrollbar-thumb": {
    background: tokens.colorBrandBackground,
    outline: "1px solid slategrey",
    borderRadius: "15px",
  },
  "a[href]": {
    color: tokens.colorBrandBackground,
    textDecoration: "none",
  },
  ".cardShadow": {
    boxShadow:
      "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
  ".no-clear span[aria-label=clear]": {
    display: "none",
  },
  "div .mx-0": {
    marginLeft: "2px",
    marginRight: "2px",
  },
  "#password": {
    width: "100%",
  },
  ".fui-BreadcrumbDivider": {
    ...shorthands.margin(0, "10px"),
  },
  ".is-disabled": {
    opacity: "0.2",
  },
  ".headerSearch, .fui-Input, .ms-Stack .fui-Select__select, .custom-label .fui-Textarea,.ms-Stack .fui-Select, .custom-label .fui-Select, .ms-Stack .fui-Dropdown, .ms-Stack .fui-Combobox":
    {
      borderBottomColor: tokens.colorNeutralStroke1Pressed,
    },
  ".customFile": {
    ...shorthands.padding(0),
    height: "40px",
  },
  ".customFile input": {
    opacity: "0",
    height: "40px",
  },
  ".customFile .customFilePlaceholder": {
    top: "8px",
    position: "absolute",
    left: "18px",
  },
  ".customFile button": {
    height: "40px",
  },
  ".phone_label": {
    ...shorthands.margin(0, 0, "8px", 0),
  },
  ".clientContactPrefix": {
    ...shorthands.margin("29px", "10px", 0, 0),
    width: "165px",
  },
  ".phoneNumberInput": {
    width: "calc(100% - 125px)",
  },
  ".phoneNumberInput .fui-Input": {
    minWidth: "150px",
  },
  ".phoneNumberInput .fui-Label": {
    marginLeft: "-160px",
  },

  ".tbl-layout-auto": {
    tableLayout: "auto !important",
  },
  ".sidebarDetails": {
    "@media (max-width: 1280px)": {
      position: "absolute",
      ...shorthands.overflow("auto"),
      top: "10px",
      left: "10px",
      right: "10px",
      height: "100vh",
      maxWidth: "100%",
      ...shorthands.margin(0),
      zIndex: 9999,
    },
  },
  ".mb-1_5": {
    marginBottom: "17px",
  },
  ".mt-1_0": {
    marginTop: "10px",
  },
  ".disableIcons": {
    opacity: "0.4",
    pointerEvents: "none",
  },
  ".highLightRow": {
    fontWeight: "bold",
  },
  ".dialogSurface": {
    maxWidth: "457px",
  },
  ".no-reset .fui-Input__contentAfter": {
    display: "none",
  },
  ".no-reset input": {
    ...shorthands.margin(0, 0, 0, "10px"),
  },
  ".project_tags .fui-Tag": {
    maxWidth: "250px",
    pointerEvents: "none",
  },
  ".fui-Tag .fui-Tag__primaryText": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ".border-radious-4": {
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
  ".color-option": {
    ...shorthands.border("none"),
  },
  ".customTag": {
    background: "transparent",
    height: "auto",
  },
  ".customTag span": {
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  ".overflow-x-auto": {
    overflowX: "auto",
  },
  ".card-bottom-border": {
    borderBottom: "1px solid #d1d1d1",
    ...shorthands.padding("10px", 0, "10px", 0),
    ...shorthands.margin(0, "10px"),
  },
  ".card-bottom-border:last-child": {
    borderBottom: "1px solid #ffffff",
  },
  "label.fui-Label": {
    color: tokens.colorNeutralForeground1,
  },
  ".rm_pl_0": {
    paddingLeft: 0,
  },
  ".border-none": {
    ...shorthands.border(0),
  },
  '&[aria-disabled="true"].field-option [aria-hidden="true"]::before': {
    content:
      'url(\'data:image/svg+xml;utf8,<svg fill="white" class="___12fm75w_v8ls9a0 f1w7gpdv fez10in fg4l7m0" aria-hidden="true" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M9.76 3.2c.3.29.32.76.04 1.06l-4.25 4.5a.75.75 0 0 1-1.08.02L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.7 1.7L8.7 3.24a.75.75 0 0 1 1.06-.04Z" fill="white"></path></svg>\')',
    display: "block",
    position: "relative",
    width: "15px", // Set width and height according to your SVG dimensions
    height: "15px",
    backgroundColor: tokens.colorNeutralForegroundDisabled,
  },
  ".fui-MenuPopover .fui-MenuItem__content": {
    display: "flex",
    ...shorthands.gap("10px"),
  },
  ".noHover:hover": {
    background: "white",
  },
  ".customizeContent": {
    width: "100%",
  },
  ".customizeContent.modalOpen": {
    width: "calc(100% - 430px)",
  },
  ".review-timelog.modalOpen": {
    width: "calc(100% - 500px)",
  },
  ".fui-AccordionHeader .fui-AccordionHeader__button": {
    background: tokens.colorSubtleBackgroundHover,
  },

  ".tna-pending .fui-TableRow .fui-TableHeaderCell:first-child, .tna-pending .fui-TableRow .fui-TableCell:first-child":
    {
      width: "65px",
    },
  ".custom-pagination li": {
    position: "relative",
  },
  ".custom-pagination a": {
    position: "absolute",
    zIndex: "999",
    width: "100%",
    left: "0",
  },
  ".custom-pagination li:first-child a": {
    position: "relative",
  },
  ".host-list-tags .customTag": {
    pointerEvents: "none",
  },
  ".host-list-tags .customTag .fui-Tag__dismissIcon": {
    pointerEvents: "auto",
    zIndex: 9,
    cursor: "pointer",
  },
  ".openMenu .contentData": {
    maxWidth: "calc(100% - 450px)",
    ...shorthands.overflow("auto"),
  },

  ".project_tags .fui-Tag .fui-Tag__dismissIcon": {
    pointerEvents: "auto",
  },
  ".rejected": {
    color: "red",
  },
  "input[type='time']::-webkit-calendar-picker-indicator": {
    fontSize: "20px",
    color: tokens.colorBrandBackgroundPressed,
  },
  ".fui-DatePicker svg": {
    color: tokens.colorBrandBackgroundPressed,
  },
  "fui-Input__contentAfter r1572tok ___dctilx0_1vqu9nb fa420co": {
    color: "blue",
  },
  ".sidebar-close .sidebar-content .sidebar-menu": {
    padding: "10px 0px",
    margin: "0px 10px",
  },
  ".sidebar-menu.open .sidebar-content": {
    marginTop: "5px",
    fontSize: "16px",
  },
  ".dateWithIcon": {
    position: "relative",
  },
  ".dateWithIcon .icon": {
    position: "absolute",
    top: "10px",
    right: "16px",
  },
  ".clockIcon": {
    cursor: "pointer",
    pointerEvents: "all",
  },
  ".fontWeightRegular": {
    fontWeight: tokens.fontWeightRegular,
  },
  "aria-checked[true].multiSelectOption": {
    fontWeight: tokens.fontWeightRegular,
  },
  ".multiSelectOption .fui-Option__checkIcon": {
    background: "transparent",
    border: "none",
  },
  ".multiSelectOption .fui-Option__checkIcon svg": {
    color: "#242424",
  },
  ".hide-arrow .fui-AccordionHeader__expandIcon": {
    opacity: 0,
  },
  ".detailsOpen .fui-TableHeader .header-column": {
    whiteSpace: "break-spaces",
  },
  ".review-timelog.modalOpen .timeAndAttendanceWrap": {
    flexDirection: "column",
  },
  ".minwidth-combobox .fui-Combobox": {
    minWidth: "auto",
  },
  ".minwidth-combobox .fui-Combobox__input": {
    maxWidth: "100px",
  },
  "button.tab-open.sidebar-menu": {
    minWidth: "100%",
  },
  ".setting-popup .side-bottom-link": {
    marginBottom: 0,
  },
  ".bottomSubMenu .fui-MenuItem__content": {
    color: tokens.colorBrandBackground,
  },
});
export default commonStyle;
