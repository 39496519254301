export const buildMutationHelper = (builder, query, success, error) => {
  return builder.mutation({
    queryFn: async (
      args,
      { signal, dispatch, getState },
      extraOptions,
      baseQuery
    ) => {
      if (typeof query === "function") {
        let { defaultFromState, ...newArgs } = query(args);
        if (typeof defaultFromState === "function") {
          newArgs = defaultFromState(getState, newArgs);
        }

        return baseQuery(newArgs);
      }
      return baseQuery(query);
    },
    async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
      queryFulfilled
        .then((response) => success(response, dispatch, getState))
        .catch((err) => {
          error(err, dispatch, getState);
          if (
            err?.error?.data?.message?.includes("Expired JWT Token") ||
            (err?.error?.status === 401 &&
              err?.error?.data.message === "Invalid JWT Token")
          ) {
            localStorage.clear();
            window.location.assign("/login");
          }
        });
    },
  });
};
