import { Stack } from "@fluentui/react";
import {
  Button,
  Divider,
  Text,
  mergeClasses,
} from "@fluentui/react-components";
import Layout from "../../../styles/Layout.styles";
import Style from "../style";
import { CheckmarkFilled } from "@fluentui/react-icons";
import { t } from "i18next";
import { setLicense } from "../../../store/reducer/license";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const BundleListRow = ({ item, key, setDisplayTitle }) => {
  const layout = Layout();
  const style = Style();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onBuyNow = (id, data) => {
    dispatch(
      setLicense({
        purchaseBundle: true,
        buyNow: data,
      })
    );
    navigate("/licenses/bundle/bundleQty");
  };

  return (
    <>
      <Stack
        key={key}
        className={mergeClasses(
          layout.projectContent,
          "cardShadow",
          layout.p_0,
          style.section,
          layout.borderRadiusBottom
        )}
      >
        <Stack>
          <Stack
            className={mergeClasses(style.headerPart, layout.borderRadiusTop)}
          >
            <Text className={style.title}>{item?.name}</Text>
            <Text className={style.title}>
              {`${item?.currency} ${item?.monthPrice}`}
            </Text>
          </Stack>

          <Stack className={mergeClasses(style.bundlePoint)}>
            <Stack block>
              {item?.description.split("\n").map(function (item, idx) {
                return (
                  <Stack
                    key={idx}
                    className={mergeClasses(
                      layout.mb_15,
                      layout.d_flex,
                      layout.flex_row
                    )}
                  >
                    <CheckmarkFilled
                      className={mergeClasses(layout.mr_1, style.icon18)}
                    />
                    {item}
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
          <Divider className={layout.mb_0} />
          <Stack
            className={mergeClasses(
              style.bundlePoint,
              layout.borderRadiusBottom
            )}
          >
            <Button
              className={mergeClasses(
                layout.customBtn,
                layout.max250,
                layout.w_100,
                layout.m_auto
              )}
              onClick={() => onBuyNow(item?.id, item)}
              appearance="primary"
              aria-label="edit"
            >
              {t("bundle.buy_now")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
export default BundleListRow;
