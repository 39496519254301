import FieldsTextarea from "../../../../components/FieldComponents/FieldsTextarea";
import Style from "../AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const CompanyBillingAddress = (props) => {
  const { companyBillingAddress } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsTextarea
          label={t("billing_address")}
          required
          validationMessage={props?.errors?.companyBillingAddress?.message}
          placeholder={t("enter_billing_address")}
          name="companyBillingAddress"
          id="companyBillingAddress"
          maxLength={255}
          inputClassName={style.textboxInput}
          inputDefaultValue={companyBillingAddress}
          validation={props.register("companyBillingAddress", {
            required: `${t("validation_message.billing_address_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default CompanyBillingAddress;
