import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setLicense } from "../reducer/license";
import { defaultFromState } from "../../utils/defaultFromState";

export const licenseApi = createApi({
  reducerPath: "licenseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    clientLicenseList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        pagination = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/client-licenses",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            pagination: pagination,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              license: { page, itemsPerPage, pagination, client },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              pagination,
              client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setLicense({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setLicense({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(setLicense({ errorMessage: error?.data?.error }));
      }
    ),
    addClientLicense: buildMutationHelper(
      builder,
      ({ clientLicenseData }) => ({
        url: `/client-assign-license`,
        method: "POST",
        body: clientLicenseData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setLicense({
            clientLicenseData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setLicense({ errorMessage: error?.data?.violations }));
      }
    ),
    deleteClientLicense: buildMutationHelper(
      builder,
      (id) => ({
        url: `/client-assigned-license/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      ({ error }, dispatch) => {
        dispatch(setLicense({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const {
  useClientLicenseListMutation,
  useAddClientLicenseMutation,
  useDeleteClientLicenseMutation,
} = licenseApi;
