import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { defaultFromState } from "../../utils/defaultFromState";
import { setModules } from "../reducer/modules";

export const modulesApi = createApi({
  reducerPath: "modulesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    getModuleList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        pagination = undefined,
        // client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/modules",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            pagination: pagination,
            // client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              modules: { page, itemsPerPage, pagination },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              pagination,
              // client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },

      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setModules({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setModules({
              exportData,
              errorMessage: "",
            })
          );
        }
      },

      ({ error }, dispatch) => {
        dispatch(setModules({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const { useGetModuleListMutation } = modulesApi;
