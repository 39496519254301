import FieldsInput from "../../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const ContactFirstName = (props) => {
  const { contactFirstName } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("first_name")}
          validationMessage={props.errors?.contactFirstName?.message}
          id="contactFirstName"
          placeholder={t("enter_first_name")}
          name="contactFirstName"
          inputDefaultValue={contactFirstName}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("contactFirstName", {
            required: `${t("validation_message.firstname_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default ContactFirstName;
