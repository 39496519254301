import { createSlice } from "@reduxjs/toolkit";

const location = createSlice({
  name: "location",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 6,
    startPage: 6,
    project: "",
    pagination: "",
  },
  reducers: {
    setLocation(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setLocation } = location.actions;
export default location.reducer;
