import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { COMPANY_NAME, COMPANY_ADDRESS } from "../../../../../utils/constants";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
  },
  defaultAddress: {
    marginTop: 20,
    paddingBottom: 3,
  },
});
const DefaultAddress = () => (
  <View style={styles.headerContainer}>
    <Text>{COMPANY_NAME}</Text>
    <Text>{COMPANY_ADDRESS}</Text>
  </View>
);

export default DefaultAddress;
