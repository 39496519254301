import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import { NUMBER_VALIDATION_PATTERN } from "../../../../utils/constants";
import Layout from "../../../../styles/Layout.styles";
import FieldSelectPhoneCode from "../../../../components/FieldComponents/FieldSelectPhoneCode";
import { blockInvalidChar } from "../../../../utils/blockInvalidChar";

const ClientContactNumber = (props) => {
  const { clientContactNumber, clientContactPrefix } = props;
  const { t } = useTranslation();
  const style = Style();
  const layout = Layout();
  return (
    <>
      <Stack className={style.client_field}>
        <Stack className={layout.flex_row}>
          <FieldSelectPhoneCode
            name={clientContactPrefix}
            inputDefaultValue={
              clientContactPrefix ? clientContactPrefix : "+61"
            }
            className={mergeClasses(
              layout.clientContactPrefix,
              "clientContactPrefix",
              props?.errors?.clientContactNumber ? "mb-1_5" : ""
            )}
            validation={props.register("clientContactPrefix")}
          />

          <FieldsInput
            label={t("project.client_contact_number")}
            validationMessage={props.errors?.clientContactNumber?.message}
            id="clientContactNumber"
            inputType="number"
            onKeyDown={blockInvalidChar}
            placeholder={t("project.enter_contact_number")}
            name="clientContactNumber"
            inputDefaultValue={clientContactNumber}
            className="phoneNumberInput"
            inputClassName={mergeClasses(style.textboxInput)}
            validation={props.register("clientContactNumber", {
              required: `${t("validation_message.contact_number_is_required")}`,
              pattern: {
                value: NUMBER_VALIDATION_PATTERN,
                message: `${t("validation_message.number_not_match_format")}`,
              },
            })}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ClientContactNumber;
