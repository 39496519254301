// import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Stack } from "@fluentui/react";
import { useStyle } from "../Style";
import { mergeClasses } from "@fluentui/react-components";
import ProjectFilter from "./Filters/ProjectFilter";
import ProjectLocationFilter from "./Filters/ProjectLocationFilter";
import { useLocationListMutation } from "../../../store/action/location";
import { useProjectListMutation } from "../../../store/action/project";
import React, { useEffect, useState } from "react";
import ExportVisitor from "./ExportVisitor";

const VisitorFilter = (props) => {
  const {
    setAssignedProjectId,
    assignedProjectId,
    assignedLocationId,
    setIsEnableDetails,
    setAssignedLocationId,
    setIsProjectLoader,
    setIsLocationLoader,
  } = props;
  const style = useStyle();
  const [projectOption, setProjectOptions] = useState([]);
  const [projectLocationOption, setProjectLocationOptions] = useState([]);

  const [GetProject] = useProjectListMutation();
  const [getLocationListApi] = useLocationListMutation();

  useEffect(() => {
    GetProject({
      pagination: false,
      isActive: "true",
    }).then((projectRes) => {
      const data = projectRes?.data?.map?.((item, label) => ({
        children: item?.title,
        value: item?.id,
      }));
      setProjectOptions(data);
      setAssignedProjectId(projectRes?.data[0]?.id);

      getLocationListApi({
        pagination: false,
        project: data[0]?.value,
      }).then((res) => {
        const locationData = res?.data?.map?.((item, label) => ({
          children: item?.name,
          value: item?.id,
        }));
        setProjectLocationOptions(locationData);
      });
    });
  }, [
    GetProject,
    getLocationListApi,
    setAssignedProjectId,
    setProjectLocationOptions,
  ]);

  return (
    <>
      <Stack className={mergeClasses(style.detailedFilter)}>
        <Stack
          className={mergeClasses(style.filterWrapper, style.tnaFilterWrapper)}
        >
          <ProjectFilter
            projectOption={projectOption}
            setAssignedProjectId={setAssignedProjectId}
            setProjectLocationOptions={setProjectLocationOptions}
            setIsEnableDetails={setIsEnableDetails}
            setAssignedLocationId={setAssignedLocationId}
            setIsProjectLoader={setIsProjectLoader}
          />
          <ProjectLocationFilter
            assignedProjectId={assignedProjectId}
            projectLocationOption={projectLocationOption}
            setIsEnableDetails={setIsEnableDetails}
            setAssignedLocationId={setAssignedLocationId}
            assignedLocationId={assignedLocationId}
            setIsLocationLoader={setIsLocationLoader}
          />
        </Stack>
        <ExportVisitor
          assignedLocationId={assignedLocationId}
          assignedProjectId={assignedProjectId}
        />
      </Stack>
    </>
  );
};

export default VisitorFilter;
