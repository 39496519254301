import React, { useEffect, useState } from "react";
import { FieldCombobox } from "../../../../components";
import Layout from "../../../../styles/Layout.styles";
import { useVisitorListMutation } from "../../../../store/action/visitor";
import { t } from "i18next";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useDispatch } from "react-redux";
import { setVisitor } from "../../../../store/reducer/visitor";

const ProjectLocationFilter = (props) => {
  const {
    projectLocationOption,
    assignedProjectId,
    setIsEnableDetails,
    setAssignedLocationId,
    assignedLocationId,
    setIsLocationLoader,
  } = props;
  const [customSearch, setCustomSearch] = useState("" | undefined);
  const [matchingOptions, setMatchingOptions] = useState([
    ...projectLocationOption,
  ]);
  const [getVisiterListApi, { isLoading }] = useVisitorListMutation();
  const layout = Layout();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLocationLoader(isLoading);
    setMatchingOptions([...projectLocationOption]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectLocationOption, isLoading]);

  const onSelectOption = (e, data) => {
    setMatchingOptions([...projectLocationOption]);
    setIsEnableDetails(false);
    const matchingOption =
      data?.optionText && matchingOptions?.includes(data?.optionText);
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data?.optionText);
    }
    setAssignedLocationId(data?.optionValue);
    if (!isLoading) {
      dispatch(setVisitor({ page: 0 }));
      getVisiterListApi({
        projectLocationId: assignedProjectId,
        locationId: data?.optionValue,
      });
    }
  };

  const onChange = (event) => {
    const value = event?.target?.value?.trim();
    const matches = projectLocationOption?.filter((option) =>
      option?.children?.toLowerCase().includes(value?.toLowerCase())
    );
    setMatchingOptions(matches);
    if (value?.length && matches?.length < 1) {
      setCustomSearch(value);
    } else {
      setCustomSearch(undefined);
    }
  };

  if (projectLocationOption?.[0]?.children === undefined) {
    return "";
  }

  return (
    <Stack className={mergeClasses(layout.flex_column, layout.alignTop)}>
      <label className={layout.mb_1}>Location</label>
      <FieldCombobox
        stackCombobox={layout.w_100}
        multiselect={false}
        name="location"
        className={layout.w_100}
        placeholder={t("select_location")}
        defaultValue={assignedLocationId === undefined && t("select_location")}
        onOptionSelect={onSelectOption}
        onChange={onChange}
        customSearch={customSearch}
        matchingOptions={matchingOptions}
      />
    </Stack>
  );
};
export default ProjectLocationFilter;
