import { Table, TableBody } from "../../../components";
import { useStyle, staticStyle } from "../Style";
import { Stack, Text } from "@fluentui/react";
import React, { useState } from "react";
import DetailedTimesheetHeader from "./DetailedTimesheetHeader";
import timeSheetLogic from "./timeSheetLogic";
import { TableCell, TableRow } from "../../../components";
import Layout from "../../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";
import { t } from "i18next";
import LogDetails from "./LogDetails";
import detailsStyle from "./Style";

const DetailedTimesheetTable = (props) => {
  const {
    timeSheetData,
    timesheetHeader,
    isLoading,
    isProjectLoader,
    isTeamLoader,
    isDateLoader,
  } = props;

  const sheetData =
    timeSheetData && timeSheetLogic(timeSheetData, timesheetHeader);
  const style = useStyle();
  const logStyle = detailsStyle();

  const layout = Layout();
  staticStyle();
  let timesheet = sheetData?.timesheet;
  let cumulativeTotal = sheetData?.cumulativeTotal;
  const [logDetailsId, setLogDetailsId] = useState(false);
  const [isEmployeeDetails, setIsEmployeeDetails] = useState(false);

  const setLogTimeDetails = (id) => {
    setLogDetailsId(id);
    setIsEmployeeDetails(true);
  };

  return (
    <Stack className={mergeClasses(style.tableWrapper, style.timesheetWrapper)}>
      <Table
        className={
          (isLoading || isProjectLoader || isTeamLoader || isDateLoader) &&
          "tbl-layout-auto"
        }
      >
        <DetailedTimesheetHeader timesheetHeader={timesheetHeader} />
        <TableBody
          isLoading={
            isLoading || isProjectLoader || isTeamLoader || isDateLoader
          }
        >
          {timesheet &&
            Object.keys(timesheet).map((team, key) => {
              let i = timesheet[team];
              return (
                <React.Fragment key={key}>
                  <TableRow className={layout.fontWeightBold}>
                    <TableCell className={style.teamCell}>
                      <Stack
                        className={mergeClasses(
                          style.teamCelldiv,
                          layout.padding1_5x
                        )}
                        style={{ background: i.teamColorCode }}
                      >
                        {i.teamName}
                      </Stack>
                    </TableCell>
                    <TableCell>{i?.userLogCount}</TableCell>
                    {i.teamCount &&
                      Object.keys(i.teamCount).map((datewiseCount, key) => (
                        <TableCell key={key} className="min30">
                          {i.teamCount[datewiseCount]}
                        </TableCell>
                      ))}
                  </TableRow>

                  {/* display timelog of employees */}
                  {i?.userDetails &&
                    Object.keys(i?.userDetails).map((udetail, key) => {
                      let userData = i?.userDetails[udetail];
                      return (
                        <TableRow
                          key={key}
                          className={mergeClasses(
                            layout.color616161,
                            layout.fontWeightSemibold
                          )}
                        >
                          {" "}
                          <TableCell>{userData?.userName}</TableCell>
                          <TableCell>{userData?.userTotalLogged}</TableCell>
                          {userData?.loggedTime &&
                            Object.keys(userData?.loggedTime).map(
                              (timelog, key) => {
                                return (
                                  <TableCell
                                    key={key}
                                    className={mergeClasses(
                                      "min30",
                                      `${userData?.loggedStatus[timelog]}`
                                    )}
                                  >
                                    <Text
                                      className={
                                        userData?.loggedStatus[timelog] ===
                                        "rejected"
                                          ? logStyle.logClickableTextColor
                                          : logStyle.logClickableText
                                      }
                                      onClick={() =>
                                        setLogTimeDetails(
                                          userData?.timelogId[timelog]
                                        )
                                      }
                                    >
                                      {userData?.loggedTime[timelog]}
                                    </Text>
                                  </TableCell>
                                );
                              }
                            )}
                        </TableRow>
                      );
                    })}
                </React.Fragment>
              );
            })}

          {/* cumulative total column last */}
          <TableRow className={layout.fontWeightBold}>
            <TableCell
              className={mergeClasses(
                style.teamCell,
                layout.textLeft,
                layout.padding1_5x
              )}
            >
              {t("total")}
            </TableCell>
            <TableCell className={style.teamCell}>
              {cumulativeTotal?.teamTotal}
            </TableCell>
            {cumulativeTotal?.empTotal &&
              Object.keys(cumulativeTotal?.empTotal).map((key) => (
                <TableCell key={key}>
                  {Math.floor(cumulativeTotal?.empTotal[key] * 100) / 100}
                </TableCell>
              ))}
          </TableRow>
        </TableBody>
      </Table>

      {isEmployeeDetails && logDetailsId && (
        <Stack
          className={mergeClasses(
            logStyle.modalBackground,
            isEmployeeDetails && "detailsOpen"
          )}
        >
          <LogDetails
            employeeSelectedId={logDetailsId}
            setIsEmployeeDetails={setIsEmployeeDetails}
            isEmployeeDetails={isEmployeeDetails}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default DetailedTimesheetTable;
