import { Stack } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import Style from "./Style";
import { Link } from "react-router-dom";
import { ReactComponent as Eye } from "../../../images/eye.svg";
import { ReactComponent as Pencil } from "../../../images/pencil.svg";
import { ReactComponent as Setting } from "../../../images/setting.svg";
import { ReactComponent as LocationIcon } from "../../../images/location.svg";
import { useState } from "react";
import { TableCell, TableRow } from "../../../components/Table";
import {
  useDeleteProjectMutation,
  useActiveProjectMutation,
  useProjectListMutation,
} from "../../../store/action/project";
import { statusType } from "../../../utils/projectStatus";
import { format } from "date-fns";
import Layout from "../../../styles/Layout.styles";
import { setProject } from "../../../store/reducer/project";
import { useDispatch, useSelector } from "react-redux";
import SwitchPopup from "../../../components/FieldComponents/SwitchPopup";

const ProjectListRow = ({ item, role }) => {
  const [open, setOpen] = useState(false);
  const [getDeleteProject] = useDeleteProjectMutation();
  const [getProjectListApi, { isLoading }] = useProjectListMutation();
  const [projectActive] = useActiveProjectMutation();
  const dispatch = useDispatch();
  const projectList = useSelector((state) => state.project);

  const handleSwitchChange = () => {
    const projectData = {
      isActive: item?.isActive ? false : true,
    };

    item?.isActive
      ? getDeleteProject(item?.id).then((res) => {
          dispatch(
            setProject({
              page:
                projectList?.data?.length === 1 && projectList?.page > 0
                  ? projectList?.page - 1
                  : projectList?.page,
            })
          );
          if (!isLoading) {
            getProjectListApi();
            setOpen(false);
          }
        })
      : projectActive({ projectData: projectData, id: item?.id }).then(
          (res) => {
            if (!isLoading) {
              getProjectListApi();
              setOpen(false);
            }
          }
        );
  };

  const style = Style();
  const layout = Layout();

  let disableLink = item.isActive === false;

  return (
    <>
      <TableRow key={item?.project?.label}>
        <TableCell
          tabIndex={item?.key}
          className={mergeClasses(style.customTblCell, style.wordBreak)}
          role="gridcell"
        >
          {item?.projectCode}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item?.title}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item?.client?.companyName}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item.createdByName}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item?.siteContact}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item && format(new Date(item.commencementDate), "yyyy/MM/dd")}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item && format(new Date(item.completionDate), "yyyy/MM/dd")}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item && statusType(item.status)}
        </TableCell>
        {((role?.size && role?.has("project_view")) ||
          role?.has("project_teams_list") ||
          role?.has("project_location_list") ||
          role?.has("project_edit")) && (
          <TableCell
            tabIndex={item?.key}
            className={style.customTblCell}
            role="gridcell"
          >
            <Stack className={layout.tableIcons}>
              {role?.size && role?.has("project_view") && (
                <Link
                  to={`/project-list/${item.id}`}
                  className={mergeClasses(
                    layout.tableIcon,
                    layout.d_flex,
                    layout.alignCenter
                  )}
                  aria-label="view"
                >
                  <Eye height={18} width={18} />
                </Link>
              )}
              {role?.size &&
                role?.has("project_edit") &&
                item?.canBeEdit === true && (
                  <Stack
                    className={mergeClasses(
                      layout.tableIcons,
                      layout.d_flex,
                      layout.alignCenter,
                      disableLink ? "disableIcons" : ""
                    )}
                  >
                    <Link
                      to={`/project-list/${item.id}/edit`}
                      className={mergeClasses(
                        layout.tableIcon,
                        layout.d_flex,
                        layout.alignCenter
                      )}
                      aria-label="edit"
                    >
                      <Pencil height={18} width={18} />
                    </Link>
                  </Stack>
                )}
              {role?.size && role?.has("project_teams_list") && (
                <Link
                  to={`/project-list/setting/${item.id}`}
                  className={mergeClasses(
                    layout.tableIcon,
                    layout.d_flex,
                    layout.alignCenter
                  )}
                >
                  <Setting height={18} width={18} />
                </Link>
              )}
              {role?.size && role?.has("project_location_list") && (
                <Link
                  to={`/project-list/location/${item.id}`}
                  className={mergeClasses(
                    layout.tableIcon,
                    layout.d_flex,
                    layout.alignCenter
                  )}
                >
                  <LocationIcon height={18} width={18} />
                </Link>
              )}
            </Stack>
          </TableCell>
        )}
        <TableCell tabIndex={item?.key}>
          {role?.size && role?.has("project_active_inactive") ? (
            <Stack className={layout.tableIcons}>
              <SwitchPopup
                open={item?.isActive && open}
                onOpenChange={(_, data) => setOpen(data.open)}
                handleSwitchChange={() => {
                  item?.isActive === false && handleSwitchChange();
                }}
                checked={item?.isActive ? true : false}
                handleInactiveSwitch={handleSwitchChange}
                handleCancel={() => setOpen(false)}
                isLoading={isLoading}
              />
            </Stack>
          ) : item?.isActive ? (
            "Active"
          ) : (
            "Inactive"
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
export default ProjectListRow;
