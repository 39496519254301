import Layout from "../../../../styles/Layout.styles";
import { useTranslation } from "react-i18next";
import {
  mergeClasses,
  useId,
  useToastController,
  Toast,
  ToastTitle,
  Toaster,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import PopoverComponent from "../../../../components/FieldComponents/PopoverComponent";
import {
  useProjectApproveMutation,
  useProjectRejectMutation,
} from "../../../../store/action/time-and-attendance";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import ToasterComponent from "../../../../components/Toaster/ToasterComponent";
import { useGetPendingDataMutation } from "../../../../store/action/time-and-attendance/time-and-attendancePending";
import { setTimeAndAttendancePending } from "../../../../store/reducer/time-and-attendance/time-and-attendancePending";
import accessRole from "../../../../components/Common/accessRole";
const ClockLogAction = ({
  employeeSelectedId,
  assignedProjectId,
  setIsEmployeeDetailsOpen,
}) => {
  const { t } = useTranslation();
  const [projectApproveData] = useProjectApproveMutation();
  const [projectRejectData] = useProjectRejectMutation();
  const [getPendingDataList] = useGetPendingDataMutation();
  const timeAndAttendanceList = useSelector(
    (state) => state.timeAndAttendancePending
  );
  const [open, setOpen] = useState(false);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const dispatch = useDispatch();
  const layout = Layout();

  const getData = async () => {
    await getPendingDataList({
      projectId: assignedProjectId,
    });
  };

  const onSubmit = (e) => {
    if (employeeSelectedId) {
      const tnaData = {
        approveProjectTnas: [employeeSelectedId],
      };
      projectApproveData({ tnaData: tnaData }).then((res) => {
        dispatch(
          setTimeAndAttendancePending({
            page:
              timeAndAttendanceList?.data?.length === 1 &&
              timeAndAttendanceList?.page > 0
                ? timeAndAttendanceList?.page - 1
                : timeAndAttendanceList?.page,
            itemsPerPage: timeAndAttendanceList?.itemsPerPage,
          })
        );
        getData().then((res) => {
          setIsEmployeeDetailsOpen(false);
        });
      });
    }
  };

  const notify = () => {
    dispatchToast(
      <Toast>
        <ToastTitle>{t("reject")}</ToastTitle>
      </Toast>,
      { intent: t("success") }
    );
  };
  const handleSubmit = (value) => {
    notify();
    if (employeeSelectedId) {
      const tnaData = {
        rejectProjectTnas: [employeeSelectedId],
        rejectReasonMessage: value?.reason,
      };
      projectRejectData({ tnaData: tnaData }).then((res) => {
        dispatch(
          setTimeAndAttendancePending({
            page:
              timeAndAttendanceList?.data?.length === 1 &&
              timeAndAttendanceList?.page > 0
                ? timeAndAttendanceList?.page - 1
                : timeAndAttendanceList?.page,
            itemsPerPage: timeAndAttendanceList?.itemsPerPage,
          })
        );
        getData().then((res) => {
          setIsEmployeeDetailsOpen(false);
          setOpen(false);
        });
      });
    }
  };

  return (
    <Stack
      className={mergeClasses(layout.d_flex, layout.flex_row, layout.mt_2)}
    >
      {accessRole()?.has("project_tna_approve") && (
        <ToasterComponent
          appearance="primary"
          onClick={onSubmit}
          buttonLabel={t("employee.approved_log")}
          className={mergeClasses(
            layout.smallBtn,
            layout.alignCenter,
            layout.mr_1,
            "custom-primary"
          )}
          isToastMessage={true}
          successMessage={t("approve")}
        />
      )}
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      {accessRole()?.has("project_tna_reject") && (
        <PopoverComponent
          label={t("employee.reject_log")}
          className={mergeClasses(
            layout.smallBtn,
            layout.alignCenter,
            "custom-primary"
          )}
          onSubmit={handleSubmit}
          setOpen={setOpen}
          open={open}
        />
      )}
    </Stack>
  );
};

export default ClockLogAction;
