import * as React from "react";
import { Field, Select, mergeClasses } from "@fluentui/react-components";
import { useEffect } from "react";
import { useState } from "react";
import { useTimeZoneListMutation } from "../../store/action/common";
import { t } from "i18next";

const FieldTimeZoneSelect = ({
  label,
  size = "large",
  placeholder,
  name,
  inputId,
  inputDefaultValue,
  inputClassName,
  noRequired,
  validation,
  validationMessage,
  validationMessageIcon = "",
  timeZoneSelectListData,
  disabled,
}) => {
  const [timeZoneList] = useTimeZoneListMutation();
  const [timeZoneListData, setTimeZoneListData] = useState([]);

  const array = { key: "", value: t("select_timezone") };

  useEffect(() => {
    timeZoneList().then((res) => {
      var resData = Object.entries(res?.data)?.map(([id, value]) => ({
        key: id,
        value,
      }));
      const pushData = [array, ...resData];
      setTimeZoneListData(pushData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (timeZoneSelectListData?.length <= 0 || timeZoneListData?.length <= 0) {
    return "";
  }
  return (
    <Field
      label={label}
      className={mergeClasses("custom-label")}
      size={size}
      validationMessageIcon={validationMessageIcon}
      validationMessage={validationMessage}
      required={noRequired ? undefined : true}
    >
      <Select
        id={inputId}
        className={inputClassName}
        name={name}
        defaultValue={inputDefaultValue}
        placeholder={placeholder}
        {...validation}
        disabled={disabled}
      >
        {(timeZoneSelectListData
          ? timeZoneSelectListData
          : timeZoneListData
        )?.map((option, i) => (
          <option key={i} value={option?.key}>
            {option?.value}
          </option>
        ))}
      </Select>
    </Field>
  );
};

export default FieldTimeZoneSelect;
