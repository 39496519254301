import { Stack, Text } from "@fluentui/react";
import Layout from "../../../styles/Layout.styles";
import Style from "./AddClient.style";
import { Divider, mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

const ClientViewAccount = (props) => {
  const { viewData } = props;
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();

  return (
    <>
      <h2 className={mergeClasses(style.headerTitle, layout.padding1_5x)}>
        {t("company_account_person")}
      </h2>

      <Stack className={mergeClasses(style.subDetails, layout.mb_2)}>
        <Stack
          className={mergeClasses(style.fieldsection, layout.align_self_start)}
        >
          <Text className={mergeClasses(layout.detailsSplite)}>
            <Text className={style.textElement}>{t("name")}</Text>
            {`${viewData?.data?.accountPerson?.firstName}`}
          </Text>
          <Divider className={layout.padding1_5x} />
          <Text className={mergeClasses(layout.detailsSplite)}>
            <Text className={style.textElement}>{t("phone_number")}</Text>(
            {`${viewData?.data?.accountPerson?.phonePrefix}`}){" "}
            {`${viewData?.data?.accountPerson?.phoneNumber}`}
          </Text>
          <Divider className={layout.padding1_5x} />
          <Text className={mergeClasses(layout.detailsSplite)}>
            <Text className={style.textElement}>{t("department")}</Text>
            {`${viewData?.data?.accountPerson?.department}`}
          </Text>
        </Stack>

        <Stack
          className={mergeClasses(style.fieldsection, layout.align_self_start)}
        >
          <Text className={mergeClasses(layout.detailsSplite)}>
            <Text className={style.textElement}>{t("email_address")}</Text>
            {`${viewData?.data?.accountPerson?.email}`}
          </Text>
          <Divider className={layout.padding1_5x} />

          <Text className={mergeClasses(layout.detailsSplite)}>
            <Text className={style.textElement}>{t("timezone")}</Text>
            {`${viewData?.data?.accountPerson?.timezone}`}
          </Text>
          <Divider className={layout.padding1_5x} />
        </Stack>
      </Stack>
    </>
  );
};

export default ClientViewAccount;
