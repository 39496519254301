import Common from "./Common.styles";
import Variable from "./Variable";
import TextBox from "./TextBox.styles";
import Button from "./Button.styles";
import Sidebar from "./Sidebar.styles";
import staticStyle from "./CommonStaticStyle";
import { calendarStaticStyle } from "./Calendar.styles";

const Layout = () => {
  const common = Common();
  const login = Variable();
  const textBox = TextBox();
  const button = Button();
  const sidebar = Sidebar();
  const calendarStyle = calendarStaticStyle();

  staticStyle();
  const style = {
    ...common,
    ...login,
    ...textBox,
    ...button,
    ...sidebar,
    ...calendarStyle,
  };
  return style;
};

export default Layout;
