import { t } from "i18next";
import { FieldDropDown } from "../../../components";
import { Stack } from "@fluentui/react";
import Style from "../ExternalSource.style";
import { useEffect, useState } from "react";
import { useGetModuleListMutation } from "../../../store/action/modules";

const Module = (props) => {
  const {
    setValue,
    setExternalSourcesList,
    setModuleSelectedOptions,
    moduleSelectedOptions,
  } = props;
  const style = Style();
  const [getModulesApi, { isLoading }] = useGetModuleListMutation();
  const [moduleDataList, setModuleDataList] = useState([]);
  const [dataList, setDataList] = useState();

  useEffect(() => {
    getModulesApi({ pagination: false }).then((res) => {
      const listData = Object.entries(res?.data)?.map(([value, label]) => ({
        value: label?.id,
        label: label?.name,
      }));
      setModuleDataList(listData);
      res?.data?.map((item, key) => {
        const listData = Object.entries(item?.externalSources).map(
          ([value, label]) => ({
            value: label?.id,
            label: label?.name,
            authType: label?.authType,
          })
        );
        setDataList(listData);
      });
    });
  }, [getModulesApi]);

  const handleChange = (e, data) => {
    setModuleSelectedOptions(data?.selectedOptions);
    setValue("module", data?.optionText, { shouldValidate: true });
    setExternalSourcesList(dataList);
  };

  return (
    <Stack className={style.client_field}>
      <FieldDropDown
        label={t("externalSources.module")}
        validationMessage={props.errors?.module?.message}
        id="module"
        placeholder={t("enter_module")}
        name="module"
        options={moduleDataList}
        onOptionSelect={handleChange}
        selectedOptions={moduleSelectedOptions}
        inputClassName={style.textboxInput}
        validation={props.register("module", {
          required: `${t("validation_message.module_is_required")}`,
        })}
      />
    </Stack>
  );
};

export default Module;
