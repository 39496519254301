import { Stack, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useGetEmployeeDetailMutation } from "../../../store/action/time-and-attendance";
import Layout from "../../../styles/Layout.styles";
import Style from "./Style";
import {
  Divider,
  mergeClasses,
  Image,
  Button,
  Spinner,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import close from "../../../images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTimeAndAttendance } from "../../../store/reducer/time-and-attendance";
import LogTime from "./LogTime";

const LogDetails = ({ employeeSelectedId, setIsEmployeeDetails }) => {
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();

  const [GetEmployeeDetails, { isLoading }] = useGetEmployeeDetailMutation();
  const [viewDetails, setDetails] = useState();
  const [locationNameList, setLocationNameList] = useState([]);
  const dispatch = useDispatch();
  const employeeDetailsList = useSelector((state) => state.timeAndAttendance);

  useEffect(() => {
    if (employeeSelectedId) {
      if (!isLoading) {
        GetEmployeeDetails(employeeSelectedId).then((res) => {
          setDetails(res);
          const location = res?.data?.projectTnaDetails?.map((item) => ({
            timezone: res?.data?.timezone,
            locationId: item?.locationId,
            locationName: item?.locationName,
            locationShortCode: item?.locationShortCode,
            clockLog: [
              {
                clockLogId: item?.id,
                checkIn: item?.checkIn,
                checkOut: item?.checkOut,
                isDeleted: item?.isDeleted,
                logType: item?.logType,
                isEdited: item?.isEdited,
              },
            ],
          }));

          const arrayHashmap = location?.reduce((obj, item) => {
            obj[item?.locationId]
              ? obj[item?.locationId].clockLog?.push(...item?.clockLog)
              : (obj[item?.locationId] = { ...item });
            return obj;
          }, {});

          const mergedArray = Object?.values(arrayHashmap);
          setLocationNameList(mergedArray);
          dispatch(setTimeAndAttendance({ updateLogs: false }));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetEmployeeDetails, employeeSelectedId, employeeDetailsList?.updateLogs]);

  return (
    <>
      <Stack
        className={mergeClasses(
          style.employeeDetails,
          "cardShadow",
          "sidebarDetails",
          layout.mt_0
        )}
      >
        {isLoading ? (
          <Stack
            className={mergeClasses(layout.positionRelative, layout.w_100)}
          >
            <Spinner className={layout.m_auto} />
          </Stack>
        ) : (
          <>
            <h2 className={mergeClasses(style.detailTitle, layout.primary)}>
              {t("employee.clockLogs")}
              <Button
                appearance="subtle"
                onClick={() => setIsEmployeeDetails(false)}
                aria-label="close"
                icon={<Image alt="" src={close} height={20} width={20} />}
              />
            </h2>
            <Divider />
            <Text className={mergeClasses(layout.mt_1_0, layout.mb_1_0)}>
              {t("all_log_types_marked_with_have_been_edited")}
            </Text>
            {locationNameList?.map((item, key) => {
              return (
                <Stack key={key}>
                  <LogTime
                    projectTnaDetails={item}
                    viewDetails={viewDetails?.data}
                    isLoading={isLoading}
                    GetEmployeeDetails={GetEmployeeDetails}
                  />
                </Stack>
              );
            })}
          </>
        )}
      </Stack>
    </>
  );
};

export default LogDetails;
