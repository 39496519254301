import { Checkbox, Text, mergeClasses } from "@fluentui/react-components";
import Layout from "../../../../styles/Layout.styles";
import { TableCell, TableRow } from "../../../../components/Table";
import {
  dateFormatted,
  timeFormatted,
} from "../../../../utils/convert-date-time";
import { useSelector } from "react-redux";
import accessRole from "../../../../components/Common/accessRole";

const PendingRow = (props) => {
  const {
    isEmployeeDetailsOpen,
    employeeSelectedId,
    setEmployeeSelectedId,
    item,
    setProjectSelectedId,
    projectSelectedId,
    setIsEmployeeDetailsOpen,
    setIsAllCheck,
  } = props;

  const layout = Layout();
  const timeAndAttendanceList = useSelector(
    (state) => state.timeAndAttendancePending
  );

  const allCheck = (ids) => {
    if (
      timeAndAttendanceList?.data?.length !== 0 &&
      timeAndAttendanceList?.data?.length === [...new Set(ids)]?.length
    ) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  };
  const onHandleChange = (check, id) => {
    !check
      ? setProjectSelectedId((current) => current.filter((item) => item !== id))
      : setProjectSelectedId([id, ...projectSelectedId]);

    !check ? allCheck([]) : allCheck([id, ...projectSelectedId]);
  };

  const onEmployeeDetails = () => {
    setIsEmployeeDetailsOpen(true);
    setEmployeeSelectedId(item.id);
  };

  const highLightRow = isEmployeeDetailsOpen && employeeSelectedId === item?.id;

  return (
    <>
      <TableRow
        key={item?.project?.label}
        className={highLightRow ? "highLightRow" : ""}
      >
        {(accessRole()?.has("project_tna_approve") ||
          accessRole()?.has("project_tna_reject")) && (
          <TableCell>
            <Checkbox
              checked={projectSelectedId?.includes(item?.id)}
              onChange={(ev, data) => onHandleChange(data?.checked, item?.id)}
            />
          </TableCell>
        )}
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          <Text
            truncate
            block
            className={mergeClasses(
              layout.textOverFlowHidden,
              layout.cursor_pointer,
              highLightRow ? "highLightRow" : ""
            )}
            title={item?.projectCode}
            onClick={onEmployeeDetails}
          >
            {item?.employeeName}
          </Text>
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.clockCount}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.checkinDate &&
            dateFormatted(item?.checkinDate, item?.timezone)}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {timeFormatted(item?.checkinDate, item?.timezone)}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {timeFormatted(item?.checkoutDate, item?.timezone)}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.totalHours?.toFixed(2) ?? "-"}
        </TableCell>
      </TableRow>
    </>
  );
};
export default PendingRow;
