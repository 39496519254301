import React, { useEffect, useState } from "react";
import { Text, mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import {
  Pagination,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Breadcrumb,
} from "../../../components";
import { useSelector } from "react-redux";
import Layout from "../../../styles/Layout.styles";
import { useTranslation } from "react-i18next";
import { useQuestionsListMutation } from "../../../store/action/question";
import QuestionsListRow from "./QuestionsListRow";
import QuestionsFilter from "./QuestionsFilter";
import { setQuestions } from "../../../store/reducer/question";

const QuestionsList = () => {
  const layout = Layout();
  const [getQuestionsListApi, { isLoading }] = useQuestionsListMutation();
  const questionsList = useSelector((state) => state.questions);
  const [initialLoad, setInitialLoad] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getQuestionsListApi();
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isLoading, questionsList]);

  const columns = [
    { columnKey: "question", label: t("questions"), isSort: true },
    { columnKey: "type", label: t("type") },
    { columnKey: "action", label: t("action") },
  ];

  const breadcrumbData = [
    {
      title: t("question"),
    },
    {
      title: t("question_list"),
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
        <QuestionsFilter
          getQuestionsListApi={getQuestionsListApi}
          setInitialLoad={setInitialLoad}
        />
        <Stack className="contentData">
          <Stack className="overflow-y-auto">
            <Table className="tbl-layout-auto">
              <TableHeader className={layout.headerBackground}>
                <TableRow>
                  {columns.map((column, key) => (
                    <TableHeaderCell
                      key={key}
                      onSortApi={getQuestionsListApi}
                      onSet={setQuestions}
                      column={column}
                      sortBy={column?.isSort ? questionsList?.sortBy : false}
                    >
                      <Text className={layout.headerLabel}>{column.label}</Text>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody isLoading={isLoading}>
                {questionsList?.data?.map((item, key) => (
                  <QuestionsListRow key={key} item={item} />
                ))}
              </TableBody>
            </Table>
          </Stack>

          <Pagination
            totalCount={questionsList?.totalCount}
            itemsPerPage={questionsList?.itemsPerPage}
            onSetListReducer={setQuestions}
            onGetList={getQuestionsListApi}
            page={questionsList?.page}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default QuestionsList;
