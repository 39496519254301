import {
  TableRow as FluentTableRow,
  mergeClasses,
} from "@fluentui/react-components";

const TableRow = (props) => {
  const { children, className } = props;

  return (
    <FluentTableRow className={mergeClasses(className)}>
      {children}
    </FluentTableRow>
  );
};

export default TableRow;
