import { Stack, Text } from "@fluentui/react";
import { Link } from "react-router-dom";
import { ReactComponent as Pencil } from "../../../images/pencil.svg";
import { ReactComponent as Eye } from "../../../images/eye.svg";
import { useState } from "react";
import Layout from "../../../styles/Layout.styles";
import { TableCell, TableRow } from "../../../components";
import {
  useUsersListMutation,
  useGetUsersPhotoMutation,
  useDeleteUserMutation,
  useActiveUsersMutation,
} from "../../../store/action/users";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../components/FieldComponents/Avatar";
import { mergeClasses } from "@fluentui/react-components";
import { setUsers } from "../../../store/reducer/users";
import imageTimeStamp from "../../../utils/imageTimeStamp";
import SwitchPopup from "../../../components/FieldComponents/SwitchPopup";

const UsersListRow = ({ item, role }) => {
  const [open, setOpen] = useState(false);
  const [getUsersDelete] = useDeleteUserMutation();
  const [getUsersListApi, { isLoading }] = useUsersListMutation();
  const [getUsersPhotoApi] = useGetUsersPhotoMutation();
  const [activeUser] = useActiveUsersMutation();
  //eslint-disable-next-line no-unused-vars
  const [getUsersPhoto, setGetUsersPhoto] = useState();
  const usersList = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const handleSwitchChange = () => {
    const usersData = {
      isActive: item?.isActive ? false : true,
    };

    item?.isActive
      ? getUsersDelete(item?.id).then((res) => {
          dispatch(
            setUsers({
              page:
                usersList?.data?.length === 1 && usersList?.page > 0
                  ? usersList?.page - 1
                  : usersList?.page,
            })
          );
          if (!isLoading) {
            getUsersListApi();
            setOpen(false);
            getUsersPhotoApi(item?.id).then((res) => {
              setGetUsersPhoto(res?.data?.imageUrl);
            });
          }
        })
      : activeUser({ usersData: usersData, id: item?.id }).then((res) => {
          if (!isLoading) {
            getUsersListApi();
            setOpen(false);
          }
        });
  };

  const updatedRoles = { ...item?.rolesText };
  delete updatedRoles["ROLE_USER"];

  const roleList = Object.values(updatedRoles)?.map((value, index) => value);
  const layout = Layout();

  let disableLink = item.isActive === false || item.canBeEdit === false;
  let disableDeleteLink = item.canBeEdit === false;

  return (
    <>
      <TableRow key={item?.project?.label}>
        <TableCell tabIndex={item?.key} role="gridcell">
          <Stack className={mergeClasses(layout.flex_row, layout.alignCenter)}>
            <Avatar
              image={imageTimeStamp(item?.profileImageUrl)}
              name={item?.fullName}
              className={layout.mr_1}
            />
            <Text>{item?.fullName}</Text>
          </Stack>
        </TableCell>
        <TableCell tabIndex={item?.key} role="gridcell">
          {item?.email}
        </TableCell>
        <TableCell tabIndex={item?.key} role="gridcell">
          {roleList?.join(", ")}
        </TableCell>
        <TableCell tabIndex={item?.key}>
          <Stack className={layout.tableIcons}>
            {role?.has("system_user_view") && (
              <Link
                to={`/users-list/${item.id}`}
                className={mergeClasses(
                  layout.tableIcon,
                  layout.d_flex,
                  layout.alignCenter
                )}
                aria-label="view"
              >
                <Eye height={18} width={18} />
              </Link>
            )}
            {role?.has("system_user_edit") && (
              <Stack
                className={mergeClasses(disableLink ? "disableIcons" : "")}
              >
                <Link
                  to={`/users-list/${item.id}/edit`}
                  className={mergeClasses(
                    layout.tableIcon,
                    layout.d_flex,
                    layout.alignCenter
                  )}
                  aria-label="edit"
                >
                  <Pencil height={18} width={18} />
                </Link>
              </Stack>
            )}
          </Stack>
        </TableCell>
        <TableCell tabIndex={item?.key}>
          {role?.has("system_user_edit") ? (
            <Stack
              className={mergeClasses(
                layout.tableIcons,
                disableDeleteLink ? "disableIcons" : ""
              )}
            >
              <SwitchPopup
                open={item?.isActive && open}
                onOpenChange={(_, data) => setOpen(data.open)}
                handleSwitchChange={() => {
                  item?.isActive === false && handleSwitchChange();
                }}
                checked={item?.isActive ? true : false}
                handleInactiveSwitch={handleSwitchChange}
                handleCancel={() => setOpen(false)}
                isLoading={isLoading}
              />
            </Stack>
          ) : item?.isActive ? (
            "Active"
          ) : (
            "Inactive"
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
export default UsersListRow;
