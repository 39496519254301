import { createSlice } from "@reduxjs/toolkit";

const timeAndAttendancePending = createSlice({
  name: "timeAndAttendancePending",
  initialState: {
    data: [],
    errorMessage: "",
    exportData: [],
    page: 0,
    itemsPerPage: 10,
    projectId: "",
  },
  reducers: {
    setTimeAndAttendancePending(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setTimeAndAttendancePending } = timeAndAttendancePending.actions;
export default timeAndAttendancePending.reducer;
