import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import FieldTimeZoneSelect from "../../../../components/FieldComponents/FieldTimeZoneSelect";

const SystemUserTimeZone = (props) => {
  const { systemUserTimeZone, timeZoneSelectListData } = props;
  const { t } = useTranslation();
  const style = Style();

  if (typeof timeZoneSelectListData === "undefined" && systemUserTimeZone) {
    return "";
  }
  return (
    <>
      <Stack className={style.client_field}>
        <FieldTimeZoneSelect
          label={t("time_zone")}
          validationMessage={props.errors?.systemUserTimeZone?.message}
          id="systemUserTimeZone"
          placeholder={t("enter_time_zone")}
          name="systemUserTimeZone"
          noRequired
          inputDefaultValue={systemUserTimeZone}
          timeZoneSelectListData={timeZoneSelectListData}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("systemUserTimeZone")}
        />
      </Stack>
    </>
  );
};

export default SystemUserTimeZone;
