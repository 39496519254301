import React from "react";
import { mergeClasses, Checkbox } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import Layout from "../../../../../styles/Layout.styles";

const DeviceSettings = ({
  setAllowVisitor,
  allowVisitor,
  setVisitorToAddMessage,
  visitorToAddMessage,
}) => {
  const layout = Layout();
  const { t } = useTranslation();

  const checkAllowVisitor = (e) => {
    setAllowVisitor(e);
  };
  const checkVisitorToAddMessage = (e) => {
    setVisitorToAddMessage(e);
  };

  return (
    <>
      <Stack
        className={mergeClasses(layout.w_100, layout.mb_2, layout.color616161)}
      >
        <h3
          className={mergeClasses(
            layout.primary,
            layout.size18,
            layout.my2,
            layout.fontWeightSemibold
          )}
        >
          {t("device_settings")}
        </h3>

        <Checkbox
          label={t("project.allow_visitor")}
          name="AllowVisitor"
          onChange={(e) => checkAllowVisitor(e?.target?.checked)}
          checked={allowVisitor}
        />

        <Checkbox
          label={t("project.visitor_to_add_message")}
          name="AskVisitorToAddMessage"
          onChange={(e) => checkVisitorToAddMessage(e?.target?.checked)}
          checked={visitorToAddMessage}
        />
      </Stack>
    </>
  );
};

export default DeviceSettings;
