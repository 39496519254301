import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setProjectDepartment } from "../reducer/projectDepartment";

export const projectDepartmentApi = createApi({
  reducerPath: "projectDepartmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    addProjectDepartment: buildMutationHelper(
      builder,
      ({ projectDepartmentData }) => ({
        url: `/project-department`,
        method: "POST",
        body: projectDepartmentData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setProjectDepartment({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(
          setProjectDepartment({ errorMessage: error?.data?.violations })
        );
      }
    ),
  }),
});
export const { useAddProjectDepartmentMutation } = projectDepartmentApi;
