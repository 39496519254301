import { makeStyles } from "@fluentui/react-components";
import { tokens } from "@fluentui/react-theme";

const Variable = makeStyles({
  fontWeightMedium: {
    fontWeight: tokens.fontWeightMedium,
  },
  fontWeightSemibold: {
    fontWeight: tokens.fontWeightSemibold,
  },
  fontWeightBold: {
    fontWeight: tokens.fontWeightBold,
  },
  borderRadiusNone: {
    borderRadiusNone: tokens.borderRadiusNone,
  },
  borderRadiusSmall: {
    borderRadiusSmall: tokens.borderRadiusSmall,
  },
  borderRadiusMedium: {
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
  borderRadiusLarge: {
    borderRadiusLarge: tokens.borderRadiusLarge,
  },
  borderRadiusXLarge: {
    borderRadiusXLarge: tokens.borderRadiusXLarge,
  },
  borderRadiusCircular: {
    borderRadiusCircular: tokens.borderRadiusCircular,
  },
});

export default Variable;
