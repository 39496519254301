import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  clientContent: {
    ...shorthands.margin("30px", "auto", "30px", "auto"),
    ...shorthands.padding("20px"),
    ...shorthands.border("1px", "solid", "#E2E8F0"),
    display: "inline-flex",
    borderRadiusMedium: tokens.borderRadiusMedium,
    justifyContent: "space-between",
  },
  client_field: {
    ...shorthands.margin(0, 0, "20px", 0),
    "@media (min-width: 1024px)": {
      width: "calc(50% - 15px)",
    },
    "@media (max-width: 1024px)": {
      width: "100%",
    },
  },
  customBtn: {
    ...shorthands.margin(0, "10px", 0, "10px"),
    "@media (min-width: 1024px)": {
      minWidth: "150px",
    },
  },
  formfooter: {
    ...shorthands.margin("10px", "auto", 0, "auto"),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  subDetails: {
    flexDirection: "row",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  fieldsection: {
    width: "50%",
    alignSelf: "start",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  headerTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
  },
  textElement: {
    fontWeight: tokens.fontWeightSemibold,
    "@media (min-width: 1024px)": {
      display: "inline-flex",
    },
    "@media (min-width: 768px)": {
      width: "40%",
    },
    flexDirection: "column",
  },
});

export default style;
