import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  questionStyle: {
    flexDirection: "row",
    width: "100%",
  },
  ordering: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  orderBtn: {
    minWidth: "10px",
    ...shorthands.padding("2px"),
    ...shorthands.border(0),
  },
  customTags: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
  customTag: {
    ...shorthands.margin("10px"),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default style;
