import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import FieldCountrySelect from "../../../../components/FieldComponents/FieldCountrySelect";
import { t } from "i18next";

const SystemUserCountry = (props) => {
  const { systemUserCountry, setTimeZoneSelectListData, resetField } = props;
  const style = Style();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldCountrySelect
          label={t("project.country")}
          validationMessage={props.errors?.systemUserCountry?.message}
          id="systemUserCountry"
          placeholder={t("project.select_country")}
          name="systemUserCountry"
          inputClassName={mergeClasses(style.textboxInput)}
          setTimeZoneSelectListData={setTimeZoneSelectListData}
          inputDefaultValue={systemUserCountry}
          resetField={resetField}
          timeZoneName={"systemUserTimeZone"}
          noRequired
          validation={props.register("systemUserCountry")}
        />
      </Stack>
    </>
  );
};

export default SystemUserCountry;
