import { mergeClasses } from "@fluentui/react-components";
import ExportButton from "./ExportButton";
import { Breadcrumb } from "../../../components";
import { Stack } from "@fluentui/react";
import Style from "./style";
import Layout from "../../../styles/Layout.styles";
import TabListComponent from "../../../components/TabList/TabListComponent";
import { useState } from "react";
import { t } from "i18next";
import ProjectSummaryAwait from "./Await/ProjectSummaryAwait";
import ProjectSummaryLive from "./Live/ProjectSummaryLive";
import ProjectSummaryComplete from "./Complete/ProjectSummaryComplete";
import ProjectSummaryDetails from "./ProjectSummaryDetails";

const SummaryTabs = () => {
  const layout = Layout();
  const style = Style();
  const [selectedValue, setSelectedValue] = useState("awaiting");
  const [openSummaryDetails, setOpenSummaryDetails] = useState(false);
  const [projectSummaryId, setProjectSummaryId] = useState();

  const breadcrumbData = [
    {
      title: `${t("summary")}`,
    },
  ];

  const tabListOption = [
    {
      id: "awaiting",
      label: `${t("awaiting_approval")}`,
      component: (
        <ProjectSummaryAwait
          status={"awaiting_approval"}
          projectSummaryId={projectSummaryId}
          openSummaryDetails={openSummaryDetails}
          setProjectSummaryId={setProjectSummaryId}
          setOpenSummaryDetails={setOpenSummaryDetails}
        />
      ),
    },
    {
      id: "live",
      label: `${t("live")}`,
      component: (
        <ProjectSummaryLive
          status={"live"}
          projectSummaryId={projectSummaryId}
          openSummaryDetails={openSummaryDetails}
          setProjectSummaryId={setProjectSummaryId}
          setOpenSummaryDetails={setOpenSummaryDetails}
        />
      ),
    },
    {
      id: "complete",
      label: `${t("complete")}`,
      component: (
        <ProjectSummaryComplete
          status={"completed"}
          projectSummaryId={projectSummaryId}
          openSummaryDetails={openSummaryDetails}
          setProjectSummaryId={setProjectSummaryId}
          setOpenSummaryDetails={setOpenSummaryDetails}
        />
      ),
    },
  ];

  const onTabSelect = (_, data) => {
    setSelectedValue(data?.value);
    setOpenSummaryDetails(false);
  };
  return (
    <>
      <Stack className={mergeClasses(layout.flex_row, layout.flex_between)}>
        <Stack
          className={mergeClasses(
            "customizeContent",
            layout.w_100,
            openSummaryDetails && "modalOpen"
          )}
        >
          <Breadcrumb data={breadcrumbData} className={layout.mb_2_4} />
          <Stack
            className={mergeClasses(
              layout.projectContent,
              layout.flex_column,
              "cardShadow",
              layout.flex_between,
              layout.mb_2_4
            )}
          >
            <TabListComponent
              selectedValue={selectedValue}
              stackClass={style.contentTabExport}
              onTabSelect={onTabSelect}
              tabListOption={tabListOption}
              isFilter={<ExportButton selectedValue={selectedValue} />}
            />
          </Stack>
        </Stack>
        {openSummaryDetails && (
          <Stack
            className={mergeClasses(
              style.summaryBackground,
              openSummaryDetails && "detailsOpen"
            )}
          >
            <ProjectSummaryDetails
              projectSummaryId={projectSummaryId}
              setOpenSummaryDetails={setOpenSummaryDetails}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default SummaryTabs;
