import FieldsTextarea from "../../../../components/FieldComponents/FieldsTextarea";
import { useTranslation } from "react-i18next";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";

const ProjectManagementTeam = (props) => {
  const { projectManagementTeam } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsTextarea
          label={t("project.project_management_team")}
          validationMessage={props?.errors?.projectManagementTeam?.message}
          placeholder={t("project.enter_project_management_team")}
          name="projectManagementTeam"
          id="projectManagementTeam"
          inputClassName={style.textboxInput}
          inputDefaultValue={projectManagementTeam}
          noRequired
          validation={props.register("projectManagementTeam")}
        />
      </Stack>
    </>
  );
};

export default ProjectManagementTeam;
