import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { FieldsTextarea } from "../../../../components";
import Layout from "../../../../styles/Layout.styles";

const TeamsDescription = (props) => {
  const { description, validationMessage } = props;
  const style = Style();
  const layout = Layout();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={mergeClasses(layout.w_100)}>
        <FieldsTextarea
          label={t("team_description")}
          placeholder={t("enter_team_description")}
          validationMessage={
            validationMessage
              ? validationMessage
              : props.errors?.description?.message
          }
          id="description"
          fieldClassName="custom-label"
          name="description"
          inputDefaultValue={description}
          inputClassName={mergeClasses(style.textboxInput, layout.w_100)}
          validation={props.register("description", {
            required: `${t("validation_message.team_description_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default TeamsDescription;
