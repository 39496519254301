import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { Button, Image, mergeClasses } from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import Style from "./style";
import { success } from "../../images";
import { t } from "i18next";
import { useGetClientOrderMutation } from "../../store/action/bundle";
import { Breadcrumb } from "../../components";

const OrderSuccess = () => {
  const [getClientOrder, { isLoading }] = useGetClientOrderMutation();
  const { id } = useParams();
  const layout = Layout();
  const style = Style();
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState(true);
  const [getOrder, setOrder] = useState();

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getClientOrder(id).then((res) => {
          setOrder(res?.data);
        });
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isLoading]);

  const breadcrumbData = [
    {
      title: `${t("payment")}`,
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack
        className={mergeClasses(
          layout.projectContent,
          layout.w_100,
          "cardShadow"
        )}
      >
        <Stack
          className={mergeClasses(
            layout.w_50,
            "cardShadow",
            style.cardShadow,
            layout.m_auto,
            style.padding_30
          )}
        >
          <Image
            alt=""
            className={mergeClasses(layout.m_auto, style.mb_30)}
            src={success}
            height={100}
            width={100}
          />
          <h1
            className={mergeClasses(
              style.priceText,
              style.mb_30,
              layout.mt_0,
              layout.textCenter
            )}
          >
            {`${getOrder?.currency} ${getOrder?.totalAmount}`}
          </h1>
          <h2
            className={mergeClasses(
              style.paymentStatus,
              style.mb_30,
              layout.mt_0,
              layout.textCenter,
              style.textSuccess
            )}
          >
            {t("stripe.payment_successful")}
          </h2>
          <Stack
            className={mergeClasses(
              style.description,
              layout.textCenter,
              layout.mb_15
            )}
          >
            {t("stripe.transection_successful")}
          </Stack>
          <Stack
            className={mergeClasses(
              style.description,
              layout.textCenter,
              style.mb_30
            )}
          >
            {t("stripe.thank_you_for_support")}
          </Stack>
          <Button
            onClick={() => navigate("/billing")}
            appearance="primary"
            className={mergeClasses(
              "custom-primary",
              layout.smallBtn,
              layout.m_auto
            )}
          >
            {t("stripe.done")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default OrderSuccess;
