import * as React from "react";
import {
  Field,
  Radio,
  RadioGroup,
  mergeClasses,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";

const FieldRadioButton = ({
  label,
  size = "large",
  className,
  noRequired,
  validationMessage,
  validationMessageIcon = "",
  radioValue,
  name,
  onChange,
  defaultValue,
  labelClass = true,
  defaultValueKey = false,
}) => {
  return (
    <Field
      label={label}
      className={mergeClasses(
        labelClass === true && "custom-label",
        className,
        "disablePointing"
      )}
      size={size}
      validationMessageIcon={validationMessageIcon}
      validationMessage={validationMessage}
      required={noRequired ? undefined : true}
    >
      <RadioGroup
        name={name}
        layout="horizontal"
        onChange={onChange}
        key={defaultValueKey && defaultValue}
        defaultValue={defaultValue}
      >
        {radioValue?.map((option, i) => (
          <Stack key={i}>
            <Radio value={option?.value} label={option?.label} />
          </Stack>
        ))}
      </RadioGroup>
    </Field>
  );
};

export default FieldRadioButton;
