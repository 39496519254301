import { Stack } from "@fluentui/react";
import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Text,
  Toast,
  ToastTitle,
  Toaster,
  mergeClasses,
  useId,
  useToastController,
  Link,
} from "@fluentui/react-components";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Form,
  FormButton,
} from "../../components";

import { t } from "i18next";
import { useState } from "react";
import LicensesRow from "./LicensesRow";
import Layout from "../../styles/Layout.styles";
import Style from "./style";
import {
  useAddClientLicenseMutation,
  useClientLicenseListMutation,
} from "../../store/action/license";
import { useForm } from "react-hook-form";
import UserDropdown from "./UserDropdown";
import accessRole from "../../components/Common/accessRole";
import { format } from "date-fns";

const LicensesAccordion = ({ item, userDataList }) => {
  const layout = Layout();
  const style = Style();
  const [getClientLicensesList] = useClientLicenseListMutation();
  // eslint-disable-next-line no-unused-vars
  const [addClientUser, { isLoading: isAddLoading, isLoading }] =
    useAddClientLicenseMutation();
  const [selectedUserText, setSelectedUserText] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  let userId = localStorage.getItem("activeUserId");

  const columns = [
    {
      columnKey: "name",
      label: t("licenses.name"),
      isSort: true,
      visible: true,
    },
    {
      columnKey: "action",
      label: t("action"),
      visible: accessRole()?.has("license_delete") ? true : false,
    },
  ];

  const notify = (errorMessage, status) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{t(`${errorMessage}`)}</ToastTitle>
      </Toast>,
      { intent: status ? "success" : "error" }
    );
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const onSubmit = () => {
    const clientLicenseData = {
      systemUser: `/user/${selectedOptions[0]}`,
      clientLicense: `/client-license/${item?.id}`,
    };
    if (!isAddLoading) {
      addClientUser({ clientLicenseData: clientLicenseData }).then((res) => {
        getClientLicensesList();
        if (res?.error?.data?.error) {
          notify(res?.error?.data?.error);
        }
      });
    }
    setSelectedOptions([]);
    setSelectedUserText("");
  };

  const clientUserAssign = () => {
    const clientLicenseData = {
      systemUser: `/user/${userId}`,
      clientLicense: `/client-license/${item?.id}`,
    };
    if (!isAddLoading) {
      addClientUser({ clientLicenseData: clientLicenseData }).then((res) => {
        getClientLicensesList();
        if (res?.error?.data?.error) {
          notify(res?.error?.data?.error, false);
        } else {
          notify("Assign to me", true);
        }
      });
      setSelectedUserText("");
    }
  };

  const commonProps = { register, errors, setValue };

  return (
    <>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <AccordionItem value={item?.id} className={layout.mt_1}>
        <AccordionHeader
          expandIconPosition="end"
          className={
            (accessRole()?.has("license_assign") && !item?.expired) ||
            (item?.clientAssignedLicenses?.length > 0 ? "" : "hide-arrow")
          }
        >
          <Stack className={mergeClasses(style.licenseHeader)}>
            <Text truncate>
              {item?.bundle?.name} ({item?.module?.name})
            </Text>
            <Text>
              <span
                className={mergeClasses(
                  layout.mr_5,
                  item?.expired ? layout.colorEbc2f32 : layout.greenColor
                )}
              >
                {item?.expired
                  ? "Expired"
                  : `Will expire on :${format(
                      new Date(item?.expireDate),
                      "yyyy/MM/dd"
                    )}`}
              </span>
              {"    "} {t("licenses.available_licenses")} :{" "}
              <strong>{item?.unusedLicenseUser}</strong> |{" "}
              {t("licenses.assigned_user")} :{" "}
              <strong>{item?.usedLicenseUser}</strong>
            </Text>
          </Stack>
        </AccordionHeader>
        {((accessRole()?.has("license_assign") && !item?.expired) ||
          item?.clientAssignedLicenses?.length > 0) && (
          <AccordionPanel
            className={mergeClasses("cardShadow", layout.padding2, "mx-0")}
          >
            {accessRole()?.has("license_assign") && !item?.expired && (
              <Stack className={mergeClasses(layout.mb_2, layout.color616161)}>
                You can assign license by selecting users
              </Stack>
            )}
            {accessRole()?.has("license_assign") && !item?.expired && (
              <Stack>
                <Stack className={mergeClasses(layout.mb_2, style.assignLink)}>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Stack className={style.userDropdownSection}>
                      <UserDropdown
                        {...commonProps}
                        userDataList={userDataList}
                        selectedUserText={selectedUserText}
                        setSelectedUserText={setSelectedUserText}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                      />

                      <FormButton
                        appearance="primary"
                        type="submit"
                        className={mergeClasses(
                          layout.smallBtn,
                          style.licensesBtn
                        )}
                        loading={isAddLoading}
                      >
                        {t("licenses.add")}
                      </FormButton>
                    </Stack>
                  </Form>

                  <Link
                    onClick={clientUserAssign}
                    className={mergeClasses(
                      layout.lnColor,
                      layout.ml_2_0,
                      commonProps.errors?.users ? "mb-1_5" : ""
                    )}
                  >
                    {" "}
                    {t("licenses.assign_to_me")}
                  </Link>
                </Stack>
              </Stack>
            )}

            {item?.clientAssignedLicenses?.length > 0 && (
              <Stack className="overflow-y-auto">
                <Table className="tbl-layout-auto">
                  <TableHeader className={layout.headerBackground}>
                    <TableRow>
                      {columns.map(
                        (column, key) =>
                          column.visible && (
                            <TableHeaderCell
                              key={key}
                              sortBy={
                                column?.isSort ? column?.columnKey : false
                              }
                            >
                              <Text className={layout.headerLabel}>
                                {column.label}
                              </Text>
                            </TableHeaderCell>
                          )
                      )}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {item?.clientAssignedLicenses?.map((item, key) => (
                      <LicensesRow
                        key={key}
                        item={item}
                        itemExpired={item?.item?.expired}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Stack>
            )}
          </AccordionPanel>
        )}
      </AccordionItem>
    </>
  );
};
export default LicensesAccordion;
