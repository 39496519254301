import {
  makeStyles,
  makeStaticStyles,
  tokens,
} from "@fluentui/react-components";

const breadCrumbStyle = makeStyles({
  breadCrumb: {
    color: tokens.colorBrandForegroundLink,
    fontSize: tokens.fontSizeBase400,
  },
});
const useStaticStyles = makeStaticStyles({
  "nav.breadCrumb li": {
    whiteSpace: "normal",
  },

  "nav.breadCrumb li:first-child a": {
    fontSize: "24px",
    lineHeight: "24px",
    color: tokens.colorBrandBackgroundPressed,
    fontWeight: tokens.fontWeightSemibold,
  },
  "nav.breadCrumb a:hover": {
    background: "none",
  },
});

export { breadCrumbStyle, useStaticStyles };
