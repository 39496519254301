import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setLocation } from "../reducer/location";
import { defaultFromState } from "../../utils/defaultFromState";

export const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    locationList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        project = undefined,
        pagination = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/tna/project-locations",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            project: project,
            pagination: pagination,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              location: { page, itemsPerPage, pagination },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              pagination,
              client,
            };
            let stateParamsProject = {
              page,
              itemsPerPage,
              project,
              pagination,
              client,
            };
            return defaultFromState(
              project ? stateParamsProject : stateParams,
              args
            );
          },
        };
      },
      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setLocation({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setLocation({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(setLocation({ errorMessage: error?.data?.error }));
      }
    ),
    addLocation: buildMutationHelper(
      builder,
      ({ locationData }) => ({
        url: `/tna/project-location`,
        method: "POST",
        body: locationData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setLocation({
            locationData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setLocation({ errorMessage: error?.data?.violations }));
      }
    ),
    deleteLocation: buildMutationHelper(
      builder,
      (id) => ({
        url: `/tna/project-location/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      ({ error }, dispatch) => {
        dispatch(setLocation({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const {
  useLocationListMutation,
  useAddLocationMutation,
  useDeleteLocationMutation,
} = locationApi;
