export const API_HOST = process.env.REACT_APP_API_HOST;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const EMAIL_VALIDATION_PATTERN = /\S+@\S+\.\S+/;
export const NUMBER_VALIDATION_PATTERN = /^\d{5,10}$/;
// export const NUMBER_VALIDATION_13SIZE_PATTERN = /^\d{3}-\d{3}-\d{4}$/;
export const NUMBER_VALIDATION_16SIZE_PATTERN = /^[0-9+-\s]{5,16}$/;
export const BASE_COLOR = "#0C3B5E";
export const FIGMA_COLOR = "#0C3B5E";
export const PASSCODE_VALIDATION = /^\d{6}$/;
export const REMOVE_SPACE = /^\s+|\s+$|\s+(?=\s)/;
export const MAX_30_LIMIT = /^[A-Z]{1,10}$/;
export const CLIENT_SLUG_PATTERN = /^[a-zA-Z][a-zA-Z0-9-]*$/;
export const COMPANY_NAME = "Halo Analytica";
export const COMPANY_ADDRESS = "4/34 Lowe St, Queanbeyan, NSW 2620";
export const RESET_PASSWORD_PATTERN = /^[a-zA-Z0-9@$!%*#?&^_-]{6,8}$/;
