import { Stack } from "@fluentui/react";
import {
  Button,
  Divider,
  Text,
  mergeClasses,
  Tooltip,
} from "@fluentui/react-components";
import Layout from "../../../styles/Layout.styles";
import Style, { staticStyle } from "../style";
import FormButton from "../../../components/FieldComponents/FormButton";
import { t } from "i18next";
import { usePurchaseBundleMutation } from "../../../store/action/bundle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as Plus } from "../../../images/plus.svg";
import { ReactComponent as Minus } from "../../../images/minus.svg";
import { setLicense } from "../../../store/reducer/license";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "../../../components";

const ItemDetails = (props) => {
  const { item } = props;
  const licenseData = useSelector((state) => state?.license?.buyNow);
  const [defaultPrice] = useState(licenseData?.monthPrice);
  const [defaultInput, setInput] = useState(1);
  const [valid, setValid] = useState(1);
  const counter = (input) => {
    let value = parseInt(defaultInput) + parseInt(input);
    setInput(value > 0 ? value : 1);
  };
  const inputChange = (input) => {
    const amount = input;
    if (!amount || amount.match(/^\d*\.\d+$/) || amount > 10) {
      setValid(false);
    } else {
      setValid(true);
    }
    setInput(input > 0 && input);
  };

  const layout = Layout();
  const style = Style();
  staticStyle();
  const navigate = useNavigate();
  const [purchaseBundle] = usePurchaseBundleMutation();
  const dispatch = useDispatch();
  const onBuyNow = (id, data) => {
    const purchaseBundleData = {
      clientOrderBundles: [
        {
          quantity: parseInt(defaultInput),
          bundle: `bundle/${id}`,
        },
      ],
      priceUnit: data?.unit,
      currency: data?.currency,
    };

    purchaseBundle({ purchaseBundleData }).then((res) => {
      dispatch(
        setLicense({
          purchaseBundle: false,
          buyNow: [],
          clientOrder: res,
        })
      );
      navigate(
        `/licenses/bundle/bundleQty/client-order/${res?.data?.id}/checkout`
      );
    });
  };

  const onPurchaseCancel = (id, data) => {
    dispatch(
      setLicense({
        purchaseBundle: false,
        buyNow: [],
        clientOrder: [],
      })
    );

    navigate("/licenses/bundle");
  };

  const breadcrumbData = [
    {
      title: `${t("licenses.license_text")}`,
      url: "/licenses",
    },
    {
      title: `${t("bundle.bundle_text")}`,
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack
        className={mergeClasses(layout.projectContent, "cardShadow", "border4")}
      >
        <Stack
          className={mergeClasses(
            layout.projectContent,
            "cardShadow",
            layout.p_0,
            style.Itemsection,
            layout.borderRadiusBottom
          )}
        >
          <Stack className="itemDetails-section">
            <Stack
              className={mergeClasses(
                style.headerNestedPart,
                layout.borderRadiusTop,
                layout.flex_row,
                layout.flex_between,
                layout.padding1_5x
              )}
            >
              <Text className={style.Itemtitle}>{licenseData?.name}</Text>
              <Text className={mergeClasses(style.Itemtitle, style.textBlack)}>
                {`${licenseData?.currency} ${licenseData?.monthPrice}`}
              </Text>
            </Stack>
            <Stack className={layout.p_2}>
              <Stack className={mergeClasses("qty-details", layout.mb_2)}>
                <Text>{t("quantity")}</Text>
                <Stack className="counter">
                  <Button className="increment" onClick={() => counter(-1)}>
                    <Stack className="minus">
                      <Minus height={22} width={22} />
                    </Stack>
                  </Button>
                  <input
                    className="counterInput"
                    type="number"
                    min="1"
                    onChange={(e) => inputChange(e.target.value)}
                    placeholder={defaultInput}
                    value={defaultInput > 0 && defaultInput}
                  />
                  <Button className="decrement" onClick={() => counter(1)}>
                    <Stack className="plus">
                      <Plus height={22} width={22} />
                    </Stack>
                  </Button>
                </Stack>
              </Stack>

              <Divider className={layout.mb_0} />
              <Stack
                className={mergeClasses(
                  style.itemPoint,
                  layout.align_items_end,
                  layout.fontWeightBold
                )}
              >
                <Stack block className={mergeClasses(layout.flex_row)}>
                  {t("total_amount")} : {`${licenseData?.currency} `}{" "}
                  {Math.floor(defaultInput * defaultPrice * 100) / 100}
                </Stack>
              </Stack>
              <Divider className={layout.mb_2} />
              <Stack className={mergeClasses(layout.m_auto)}>
                <Stack className={style.itemButtons}>
                  <Tooltip
                    withArrow
                    content={
                      0 >= defaultInput
                        ? `${t("min_quantity")}`
                        : `${t("license_purchse")}`
                    }
                  >
                    <Button
                      className={mergeClasses(
                        layout.customBtn,
                        style.buttonWidth
                      )}
                      disabled={0 >= defaultInput || !valid ? true : false}
                      onClick={() => onBuyNow(licenseData?.id, licenseData)}
                      appearance="primary"
                      aria-label="edit"
                    >
                      {t("purchase_now")}
                    </Button>
                  </Tooltip>
                  <FormButton
                    className={mergeClasses(
                      mergeClasses(style.customBtn, style.buttonWidth)
                    )}
                    onClick={() => onPurchaseCancel(licenseData?.id, item)}
                  >
                    {t("cancel")}
                  </FormButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
export default ItemDetails;
