import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const State = (props) => {
  const { state } = props;
  const style = Style();
  const { t } = useTranslation();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t('project.state')}
          validationMessage={props.errors?.state?.message}
          id="state"
          placeholder={t('project.enter_state')}
          name="state"
          inputDefaultValue={state}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("state")}
          noRequired
        />
      </Stack>
    </>
  );
};

export default State;
