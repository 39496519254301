import { mergeClasses } from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../../components";
import { Stack } from "@fluentui/react";
import Layout from "../../styles/Layout.styles";
import { TeamsList } from "../Teams/TeamsList";
import ProjectUserList from "../ProjectUser/ProjectUserList";
import TabListComponent from "../../components/TabList/TabListComponent";
import { useParams } from "react-router-dom";
import SystemUser from "../SystemUser";
import accessRole from "../../components/Common/accessRole";

const ProjectSettingWrapper = (props) => {
  const { id } = useParams();
  const [selectedValue, setSelectedValue] = useState("teams");
  const { t } = useTranslation();
  const layout = Layout();
  const role = accessRole();

  const breadcrumbData = [
    {
      title: t("project.project"),
      url: "/project-list",
    },
    {
      title: t("setting"),
    },
  ];

  const tabListOption = [
    {
      id: "teams",
      label: `${t("teams.team")}`,
      component: <TeamsList />,
      visible: role?.has("project_teams_list") ? true : false,
    },
    {
      id: "users",
      label: `${t("system_end_users")}`,
      component: <ProjectUserList projectId={id} />,
      visible: role?.has("assign_teams_users") ? true : false,
    },
    {
      id: "systemUsers",
      label: `${t("system_users")}`,
      component: <SystemUser projectId={id} />,
      visible: role?.has("assign_project_users") ? true : false,
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
        <TabListComponent
          selectedValue={selectedValue}
          displayPermission={true}
          onTabSelect={(_, data) => setSelectedValue(data?.value)}
          tabListOption={tabListOption}
          role={role}
        />
      </Stack>
    </>
  );
};

export default ProjectSettingWrapper;
