import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  clientContent: {
    ...shorthands.margin("30px", "auto", "30px", "auto"),
    ...shorthands.padding("20px"),
    ...shorthands.border("1px", "solid", "#E2E8F0"),
    display: "inline-flex",
    borderRadiusMedium: tokens.borderRadiusMedium,
    // justifyContent: "space-between",
  },

  ExternalSourceContent: {
    "@media (min-width: 1024px)": {
      width: "calc(50% - 15px)",
      ...shorthands.border("1px", "solid", "#E2E8F0"),
      display: "inline-flex",
      ...shorthands.padding("20px"),
      borderRadiusMedium: tokens.borderRadiusMedium,
      // justifyContent: "space-between",
    },
  },

  client_field: {
    ...shorthands.margin(0, 0, "15px", 0),
    "@media (min-width: 1024px)": {
      width: "calc(100% - 15px)",
    },

    "@media (max-width: 1024px)": {
      width: "100%",
    },
  },
  customBtn: {
    ...shorthands.margin(0, "10px", 0, "10px"),
    "@media (min-width: 1024px)": {
      minWidth: "150px",
    },
  },

  ExternalSourceTitle: {
    display: "flex",
    flexDirection: "flex-between",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: tokens.fontWeightSemibold,
    // fon  tSize: "18px",
  },

  formfooter: {
    ...shorthands.margin("10px", "auto", 0, "auto"),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  subDetails: {
    flexDirection: "row",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  fieldsection: {
    width: "50%",
    alignSelf: "start",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  headerTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
  },
  textElement: {
    fontWeight: tokens.fontWeightSemibold,
    "@media (min-width: 1024px)": {
      display: "inline-flex",
    },
    "@media (min-width: 768px)": {
      width: "40%",
    },
    flexDirection: "column",
  },
  modalBackground: {
    "@media (max-width: 1440px)": {
      backdropFilter: "blur(10px)",
      position: "absolute",
      ...shorthands.overflow("auto"),
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 9999,
    },
  },

  externalSourceDetails: {
    minWidth: "500px",
    maxWidth: "500px",
    ...shorthands.padding("20px"),
    ...shorthands.margin("30px", 0, 0, "30px"),
    backgroundColor: "white",
    ...shorthands.overflow("auto"),
    "@media (max-width: 1366px)": {
      minWidth: "300px",
      position: "absolute",
      ...shorthands.overflow("auto"),
      top: "10px",
      left: "10px",
      right: "10px",
      maxWidth: "500px",
      ...shorthands.margin("20px", "auto"),
      zIndex: 9999,
    },
  },
});

export default style;
