import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
  name: "user",
  initialState: {
    data: [],
    users: [],
    errorMessage: "",
    userTheme: "",
    page: 0,
    itemsPerPage: 10,
    isDarkMode: undefined,
    reloadPage: false,
  },
  reducers: {
    setUser(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setUser } = user.actions;
export default user.reducer;
