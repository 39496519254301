export const getHeaders = ({
  customHeaders = {},
  isAuth = true,
  onlyFileUpload = false,
} = {}) => {
  const token = localStorage.getItem("token");

  if (!token || !isAuth) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...customHeaders,
    };
  } else if (token || !isAuth || onlyFileUpload) {
    return {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      ...customHeaders,
    };
  } else {
    return {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      ...customHeaders,
    };
  }
};
