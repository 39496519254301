const accessRole = () => {
  let localAccessRole = localStorage.getItem("accessRoles");
  if (localAccessRole?.length) {
    let localSplitArray = localAccessRole.split(",");
    const accessRoleSet = new Set(localSplitArray);
    return accessRoleSet;
  }
  return false;
};

export default accessRole;
