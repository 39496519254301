import * as React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuPopover,
  MenuTrigger,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  mergeClasses,
} from "@fluentui/react-components";
import { Dismiss24Regular, Checkmark24Regular } from "@fluentui/react-icons";
import { t } from "i18next";
import Layout from "../../styles/Layout.styles";
import Form from "./Form";
import { useForm } from "react-hook-form";
import FieldsInput from "./FieldsInput";
import ToasterComponent from "../Toaster/ToasterComponent";
import { Stack } from "@fluentui/react";
import accessRole from "../Common/accessRole";

const FieldMenuButton = ({
  label,
  className,
  appearance,
  onApprove,
  onSubmit,
  open,
  menuOpen,
  setMenuOpen,
  setOpen,
  isToastMessage,
  successMessage,
  rejectMessage,
}) => {
  const layout = Layout();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const onOpenMenu = () => {
    setOpen(false);
    setMenuOpen(!menuOpen);
  };

  const onOpenChange = (e, data) => {
    setMenuOpen(data.open);
  };

  return (
    <>
      <Menu open={menuOpen} onOpenChange={onOpenChange}>
        {(accessRole()?.has("project_tna_approve") ||
          accessRole()?.has("project_tna_reject")) && (
          <MenuTrigger disableButtonEnhancement>
            <MenuButton
              appearance={appearance}
              className={className}
              onClick={onOpenMenu}
            >
              {label}
            </MenuButton>
          </MenuTrigger>
        )}

        <MenuPopover>
          {accessRole()?.has("project_tna_approve") && (
            <ToasterComponent
              onClick={onApprove}
              buttonLabel={
                <>
                  <Checkmark24Regular />
                  <Stack>{t("approve")}</Stack>
                </>
              }
              className={mergeClasses(
                layout.d_flex,
                layout.gap10,
                layout.borderNone,
                "noHover"
              )}
              isToastMessage={isToastMessage}
              successMessage={successMessage}
              rejectMessage={rejectMessage}
            />
          )}

          {accessRole()?.has("project_tna_reject") && (
            <Popover
              trapFocus
              open={open}
              onOpenChange={(_, data) => setOpen(data.open)}
            >
              <PopoverTrigger disableButtonEnhancement>
                <Button
                  appearance="transparent"
                  className={mergeClasses(
                    layout.d_flex,
                    layout.gap10,
                    layout.w_100,
                    layout.justifyContentLeft,
                    layout.borderNone
                  )}
                  onClick={() => setOpen((s) => !s)}
                >
                  <Dismiss24Regular /> {t("reject")}
                </Button>
              </PopoverTrigger>
              <PopoverSurface>
                <div
                  className={mergeClasses(
                    layout.d_flex,
                    layout.flex_column,
                    layout.w_100,
                    layout.justifyContentLeft
                  )}
                >
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Stack className={layout.mb_15}>
                      <FieldsInput
                        label={t("reason")}
                        validationMessage={errors?.reason?.message}
                        fieldClassName={layout.mb_0_5}
                        placeholder={t("enter_reason")}
                        id="reason"
                        name="reason"
                        inputClassName={mergeClasses(layout.textboxInput)}
                        validation={register("reason", {
                          required: `${t(
                            "validation_message.reason_is_required"
                          )}`,
                        })}
                      />
                    </Stack>
                    <Button
                      type="submit"
                      appearance="primary"
                      className={mergeClasses(
                        "custom-primary",
                        layout.smallBtn,
                        layout.mr_1
                      )}
                    >
                      {t("submit")}
                    </Button>
                    <Button
                      className={layout.smallBtn}
                      onClick={() => {
                        setOpen(false);
                        reset({ reason: "" });
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </Form>
                </div>
              </PopoverSurface>
            </Popover>
          )}
        </MenuPopover>
      </Menu>
    </>
  );
};

export default FieldMenuButton;
