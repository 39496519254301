import FieldsInput from "../../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../AddClient.style";
import { Stack } from "@fluentui/react";
import { NUMBER_VALIDATION_16SIZE_PATTERN } from "../../../../../utils/constants";
import { useTranslation } from "react-i18next";
import Layout from "../../../../../styles/Layout.styles";
import FieldSelectPhoneCode from "../../../../../components/FieldComponents/FieldSelectPhoneCode";
import { blockInvalidCharPhone } from "../../../../../utils/blockInvalidCharPhone";

const ContactPhoneNumber = (props) => {
  const { contactPhoneNumber, contactPhonePrefix } = props;
  const { t } = useTranslation();
  const layout = Layout();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <Stack className={layout.flex_row}>
          <FieldSelectPhoneCode
            name={contactPhonePrefix}
            inputDefaultValue={contactPhonePrefix ? contactPhonePrefix : "+61"}
            className={mergeClasses(
              layout.clientContactPrefix,
              "clientContactPrefix",
              props?.errors?.contactPhoneNumber ? "mb-1_5" : ""
            )}
            validation={props.register("contactPhonePrefix")}
          />

          <FieldsInput
            label={t("phone_number")}
            validationMessage={props.errors?.contactPhoneNumber?.message}
            id="contactPhoneNumber"
            placeholder={t("enter_phone_number")}
            name="contactPhoneNumber"
            // inputType="number"
            // onKeyDown={blockInvalidCharPhone}
            className="phoneNumberInput"
            inputDefaultValue={contactPhoneNumber}
            inputClassName={mergeClasses(style.textboxInput)}
            validation={props.register("contactPhoneNumber", {
              required: `${t("validation_message.phone_number_is_required")}`,
              pattern: {
                value: NUMBER_VALIDATION_16SIZE_PATTERN,
                message: `${t("validation_message.number_not_match16_format")}`,
              },
            })}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ContactPhoneNumber;
