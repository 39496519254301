import { mergeClasses } from "@fluentui/react-components";
import { t } from "i18next";
import Layout from "../../styles/Layout.styles";
import React, { useEffect, useState } from "react";
import { useGetClientListMutation } from "../../store/action/client";
import { useForm } from "react-hook-form";
import { Dropdown, Option, Field } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { headerStyle } from "./Style";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderDropdownMenu from "./HeaderDropdownMenu";
import SideMenuRoute, { navLinkBottom } from "../Sidebar/SideMenuRoute";
import HeaderMenu from "./HeaderMenu";

const ClientListDropdown = () => {
  const {
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  const style = headerStyle();
  const navigate = useNavigate();
  const location = useLocation();

  const activeSettingBold = HeaderDropdownMenu().find((menu, key) => {
    return location.pathname.includes(menu?.url);
  });

  const bottomTabsBold = navLinkBottom().find((menu, key) => {
    return location.pathname.includes(menu?.url);
  });

  const topTabBold = SideMenuRoute().find((menu, key) => {
    return location.pathname.includes(menu?.url);
  });

  const topAccordionBold = SideMenuRoute().map((menu, key) => {
    menu?.children?.find((item, key) => {
      return location.pathname.includes(item?.url);
    });
  });
  const headerMenuBold = HeaderMenu().find((menu, key) => {
    return location.pathname.includes(menu?.url);
  });

  const handleChange = (e, data) => {
    localStorage.setItem("selectedClientName", data?.optionText);
    localStorage.setItem("selectedClientId", data?.optionValue);
    navigate(
      activeSettingBold?.url ??
        bottomTabsBold?.url ??
        topTabBold?.url ??
        topAccordionBold?.url ??
        headerMenuBold?.url
    );
    window.location.reload();
  };

  const layout = Layout();
  const [getClientListApi, { isLoading }] = useGetClientListMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentClient, setCurrentClient] = useState();

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getClientListApi({ isActive: "true", pagination: false }).then(
          (res) => {
            setCurrentClient(res?.data);
          }
        );
        setInitialLoad(false);
      }
    }
  }, [initialLoad, getClientListApi, isLoading]);

  return (
    <>
      <Stack
        className={mergeClasses(
          layout.d_flex,
          layout.flex_row,
          layout.alignCenter,
          layout.mt_1,
          layout.min_w_200,
          "clientListDrop"
        )}
      >
        <Field
          label={t("client") + ":"}
          className={mergeClasses(layout.mb_0, layout.mr_1)}
        ></Field>
        <Dropdown
          aria-labelledby={"dropdown-id"}
          placeholder={t("client")}
          name="client-list"
          defaultValue={
            localStorage?.selectedClientName
              ? localStorage?.selectedClientName
              : localStorage?.activeClientName
          }
          defaultSelectedOptions={[
            localStorage?.selectedClientId
              ? localStorage?.selectedClientId
              : localStorage?.activeClientId,
          ]}
          onOptionSelect={handleChange}
          className={style.clientDropdown}
        >
          {currentClient?.map((option) => (
            <Option
              key={option?.id}
              value={option?.id}
            >{`${option?.companyName}`}</Option>
          ))}
        </Dropdown>
      </Stack>
    </>
  );
};

export default ClientListDropdown;
