import * as React from "react";
import { Button, mergeClasses, Divider } from "@fluentui/react-components";
import Header from "./Header";
import Content from "./Content";
import Sidebar from "./Sidebar";
import LayoutStyle from "../styles/Layout.styles";
import { Stack } from "@fluentui/react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

const Layout = () => {
  const layout = LayoutStyle();

  const [isOpen, setIsOpen] = useState(true);
  const [type, setType] = useState("inline");
  const [openAccordion, setOpenAccordion] = useState(false);

  const onMediaQueryChange = useCallback(
    ({ matches }) => setType(matches ? "overlay" : "inline"),
    [setType]
  );

  useEffect(() => {
    const match = window.matchMedia("(max-width: 720px)");

    if (match.matches) {
      setType("overlay");
    }

    match.addEventListener("change", onMediaQueryChange);

    return () => match.removeEventListener("change", onMediaQueryChange);
  }, [onMediaQueryChange]);

  return (
    <div className={mergeClasses(layout.root, layout.h100vh, "layout")}>
      <Sidebar
        type={type}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setOpenAccordion={setOpenAccordion}
        openAccordion={openAccordion}
      />

      <Button
        className={mergeClasses(
          layout.sidebarBtn,
          isOpen ? "isOpen" : "isClose"
        )}
        onClick={() => setIsOpen(!isOpen)}
      />

      <div className={mergeClasses(layout.content, layout.h100vh)}>
        <Header setOpenAccordion={setOpenAccordion} />
        <Divider className={layout.mb_2} />
        <Stack
          className={mergeClasses(
            layout.mainContent,
            "contentSection",
            isOpen ? "menuOpen" : "closeMenu"
          )}
        >
          <Content />
        </Stack>
      </div>
    </div>
  );
};

export default Layout;
