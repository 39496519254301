import { roleList } from "./roles-management-array";

export const getAccessRoles = (user) => {
  const user_roles = user?.roles;

  const getPermissionsByRole = (rolesArray) => {
    let permissions = [];
    rolesArray.forEach((role) => {
      permissions = permissions.concat(roleList()[role]?.permissions || []);
    });
    return permissions;
  };

  return user_roles && getPermissionsByRole(user_roles);
};
