import { t } from "i18next";
import { Stack } from "@fluentui/react";
import Style from "../ExternalSource.style";
import {
  Button,
  Image,
  mergeClasses,
  Spinner,
  Text,
  Toast,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";
import Layout from "../../../styles/Layout.styles";
import { useAddExternalSourcesConnectMutation } from "../../../store/action/clientExternalSources";
import CustomFieldsInput from "../../../components/FieldComponents/CustomFieldsInput";
import { useState } from "react";
import copyIcon from "../../../images/copy.svg";

const ApiKey = (props) => {
  const {
    setValue,
    setOpenBusinessSelection,
    externalSourceId,
    setBusinessOptions,
    setExternalSourcesListOpen,
    editExternalSourceId,
    editApiKey,
  } = props;

  const [externalSourceConnectApi, { isLoading }] =
    useAddExternalSourcesConnectMutation();

  const [isApiKeyEnter, setIsApiKeyEnter] = useState();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const style = Style();
  const layout = Layout();

  const notify = (message, valid) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`${message}`}</ToastTitle>
      </Toast>,
      { intent: valid ? "success" : "error" }
    );
  };

  const handleConnect = (e) => {
    e?.preventDefault();

    const newExternalSourcesData = {
      externalSource: `/external-source/${externalSourceId}`,
      externalApiKey: isApiKeyEnter,
      // "ev2Kzl3RURBSytxVlg0bWRicDRialdWcDNUOFpZM085WGJtaHhxRzBKZzBNNTVIeDRoSHNtOTUrRzV3bVE1ajdsdA",
    };
    if (editExternalSourceId) {
      navigator?.clipboard?.writeText(editApiKey);
      notify(t("copy_key"), true);
    } else {
      externalSourceConnectApi({
        externalSourcesData: newExternalSourcesData,
      }).then((res) => {
        if (res?.error) {
          notify(t(res?.error?.data?.error), false);
        } else {
          setOpenBusinessSelection(true);
          setExternalSourcesListOpen(true);

          const data = res?.data?.map?.((item, label) => ({
            children: item?.name,
            value: item?.id,
            contactName: item?.contactName,
          }));
          setBusinessOptions(data);
        }
      });
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleConnect(e);
    }
    return true;
  };

  const onApiKeyChange = (e) => {
    setIsApiKeyEnter(e?.target?.value);
    setValue("apiKey", e?.target?.value, { shouldValidate: "true" });
  };

  return (
    <Stack className={style.client_field}>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <Stack
        className={mergeClasses(
          layout.flex_row,
          layout.gap_15,
          layout.alignItemEnd
        )}
      >
        <CustomFieldsInput
          label={t("externalSources.api_key")}
          validationMessage={props.errors?.apiKey?.message}
          id="apiKey"
          placeholder={t("enter_api_key")}
          name="apiKey"
          inputClassName={mergeClasses(style.textboxInput, layout.min_w_100)}
          className={mergeClasses(layout.w_100)}
          value={editExternalSourceId ? editApiKey : isApiKeyEnter}
          validation={props.register("apiKey")}
          disabled={editExternalSourceId}
          onKeyDown={handleEnter}
          onChange={onApiKeyChange}
        />
        <Stack>
          <Button
            appearance="primary"
            onClick={() => handleConnect()}
            disabled={!editExternalSourceId && !isApiKeyEnter}
            className={mergeClasses(
              layout.h_40,
              editExternalSourceId && layout.min_w_46
            )}
          >
            {isLoading ? (
              <Spinner appearance="inverted" size="extra-tiny" />
            ) : editExternalSourceId ? (
              <Image className={layout.cursor_pointer} src={copyIcon} />
            ) : (
              "Connect"
            )}
          </Button>
          {props?.errors?.apiKey?.message && (
            <Text className={layout.mb_1_8}> </Text>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ApiKey;
