import { createSlice } from "@reduxjs/toolkit";

const common = createSlice({
  name: "common",
  initialState: { data: [], errorMessage: "" },
  reducers: {
    setCommon(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setCommon } = common.actions;
export default common.reducer;
