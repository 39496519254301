import * as React from "react";
import {
  Button,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Spinner,
  Switch,
  mergeClasses,
} from "@fluentui/react-components";

import { useTranslation } from "react-i18next";
import Layout from "../../styles/Layout.styles";
import { Stack } from "@fluentui/react";

const SwitchPopup = ({
  open,
  onOpenChange,
  handleInactiveSwitch,
  handleCancel,
  handleSwitchChange,
  checked,
  isLoading,
}) => {
  const { t } = useTranslation();
  const layout = Layout();
  return (
    <Popover open={open} onOpenChange={onOpenChange} trapFocus withArrow>
      <PopoverTrigger disableButtonEnhancement>
        {isLoading ? (
          <Stack className={layout.tableIcon}>
            <Spinner
              className={mergeClasses(layout.m_auto, layout.ml_1)}
              size={"tiny"}
            />
          </Stack>
        ) : (
          <Switch
            label=""
            className={layout.tableIcon}
            onChange={handleSwitchChange}
            checked={checked}
          />
        )}
      </PopoverTrigger>

      <PopoverSurface>
        <div className={layout.mb_1}>
          {t("field_components.you_want_to_inactive")}
        </div>
        <div>
          <Button
            appearance="primary"
            onClick={handleInactiveSwitch}
            className={layout.mr_1}
          >
            {t("field_components.inActive")}
          </Button>
          <Button onClick={handleCancel}>{t("field_components.cancel")}</Button>
        </div>
      </PopoverSurface>
    </Popover>
  );
};

export default SwitchPopup;
