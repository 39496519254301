import { makeStyles } from "@fluentui/react-components";
import { tokens } from "@fluentui/react-theme";

const Button = makeStyles({
  btnCustom: {
    height: "45px",
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase300,
  },
  min150: {
    minWidth: "150px",
  },
  max150: {
    maxWidth: "150px",
  },
  max250: {
    maxWidth: "250px",
  },
});

export default Button;
