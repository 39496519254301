import { Avatar as FluentAvatar } from "@fluentui/react-components";

const Avatar = (props) => {
  const { image, name, className, badge, size } = props;
  return (
    <FluentAvatar
      image={{ src: `${image}` }}
      name={name}
      className={className}
      badge={badge}
      size={size}
      color="colorful"
    />
  );
};

export default Avatar;
