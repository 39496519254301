import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import { NUMBER_VALIDATION_PATTERN } from "../../../../utils/constants";
import Layout from "../../../../styles/Layout.styles";
import FieldSelectPhoneCode from "../../../../components/FieldComponents/FieldSelectPhoneCode";
import { blockInvalidChar } from "../../../../utils/blockInvalidChar";

const SitePhoneNumber = (props) => {
  const { sitePhoneNumber, sitePhonePrefix } = props;
  const { t } = useTranslation();
  const style = Style();
  const layout = Layout();
  return (
    <>
      <Stack className={style.client_field}>
        <Stack className={layout.flex_row}>
          <FieldSelectPhoneCode
            name={sitePhonePrefix}
            inputDefaultValue={sitePhonePrefix ? sitePhonePrefix : "+61"}
            className={mergeClasses(
              layout.clientContactPrefix,
              "clientContactPrefix",
              props?.errors?.sitePhoneNumber ? "mb-1_5" : ""
            )}
            validation={props.register("sitePhonePrefix")}
          />
          <FieldsInput
            label={t("project.site_phone_number")}
            validationMessage={props.errors?.sitePhoneNumber?.message}
            id="sitePhoneNumber"
            placeholder={t("project.enter_site_phone_number")}
            name="sitePhoneNumber"
            inputType="number"
            onKeyDown={blockInvalidChar}
            className="phoneNumberInput"
            inputDefaultValue={sitePhoneNumber}
            inputClassName={mergeClasses(style.textboxInput)}
            validation={props.register("sitePhoneNumber", {
              required: `${t(
                "validation_message.site_phone_number_is_required"
              )}`,
              pattern: {
                value: NUMBER_VALIDATION_PATTERN,
                message: `${t("validation_message.number_not_match_format")}`,
              },
            })}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default SitePhoneNumber;
