import { mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import FieldsInput from "../../../../components/FieldComponents/FieldsInput";

const ProjectManager = (props) => {
  const { projectManager } = props;
  const { t } = useTranslation();
  const style = Style();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t('project.project_manager')}
          name="projectManager"
          validationMessage={props.errors?.projectManager?.message}
          id="projectManager"
          placeholder={t('project.enter_project_manager_name')}
          inputDefaultValue={projectManager}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("projectManager", {
            required: `${t('validation_message.projectmanager_is_required')}`,
          })}
        />
      </Stack>
    </>
  );
};

export default ProjectManager;
