import {
  Button,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Toast,
  ToastTitle,
  Toaster,
  mergeClasses,
  useId,
  useToastController,
} from "@fluentui/react-components";

import {
  FieldDatepicker,
  Form,
  FormButton,
} from "../../../../components/FieldComponents";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { t } from "i18next";
import { Stack } from "@fluentui/react";
import Style from "./../pending/Style";
import Layout from "../../../../styles/Layout.styles";
import { addDays } from "date-fns";
import {
  useAddClockLogMutation,
  useEditClockLogMutation,
  useGetEmployeeDetailMutation,
} from "../../../../store/action/time-and-attendance";
import { useGetPendingDataMutation } from "../../../../store/action/time-and-attendance/time-and-attendancePending";
import { ReactComponent as Pencil } from "../../../../images/pencil.svg";
import { useDispatch } from "react-redux";
import { setTimeAndAttendance } from "../../../../store/reducer/time-and-attendance";
import timePickerStyle from "./style";
import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";
import FieldTimePicker from "../../../../components/FieldComponents/FieldTimePicker";
import { useEffect } from "react";

const ClockLogPopover = (props) => {
  const {
    isLoading,
    clockLogId,
    viewDetails,
    item,
    assignedProjectId,
    defaultDate,
    defaultCheckOut,
    defaultCheckIn,
  } = props;
  const layout = Layout();
  const [selectedDate, setSelectedDate] = useState("");
  const [open, setOpen] = useState(false);
  const style = Style();
  const today = new Date();
  timePickerStyle();
  const dispatch = useDispatch();
  const minDate = addDays(today, -7);
  const maxDate = addDays(today, 0);
  const [addClockLog, { isLoading: isAddClockLog }] = useAddClockLogMutation();
  const [editClockLog, { isLoading: isEditClockLog }] =
    useEditClockLogMutation();
  const [GetEmployeeDetails] = useGetEmployeeDetailMutation();
  const [getPendingDataList, { isLoading: isPendingLoading }] =
    useGetPendingDataMutation();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const [defaultCheckInTime, setDefaultCheckInTime] = useState(defaultCheckIn);
  const [defaultCheckOutTime, setDefaultCheckOutTime] =
    useState(defaultCheckOut);

  const {
    register,
    formState: { errors },
    control,
    setValue,
    reset,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    setValue("clockIn", defaultCheckIn);
    setValue("clockOut", defaultCheckOut);
  }, [setValue, defaultCheckIn, defaultCheckOut]);

  const onClockInTimeChange = (e) => {
    const dateObj = new Date(e);
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");

    const timeFormat = `${hours}:${minutes}`;
    setDefaultCheckInTime(timeFormat);
    setValue("clockIn", timeFormat, { shouldValidate: true });
  };

  const onClockOutTimeChange = (e, data) => {
    const dateObj = new Date(e);
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");

    const timeFormat = `${hours}:${minutes}`;

    setDefaultCheckOutTime(timeFormat);
    setValue("clockOut", timeFormat, { shouldValidate: true });
  };

  const onFormatDate = (date) => {
    return !date ? "" : format(new Date(date), "yyyy/MM/dd");
  };
  const onSelectDate = (e) => {
    setValue(
      "datePicker",
      new Date(e),
      !defaultDate && { shouldValidate: true }
    );
    setSelectedDate(e);
  };

  const notify = (isToastMessage) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{t(isToastMessage)}</ToastTitle>
      </Toast>,
      { intent: "error" }
    );
  };
  const onSubmit = (value) => {
    const { datePicker, clockIn, clockOut } = value;

    const date = defaultDate ? defaultDate : datePicker;
    const timezone = item?.timezone;

    const clockInMoment = moment.tz(
      `${date} ${clockIn}`,
      "YYYY/MM/DD HH:mm",
      timezone
    );
    const clockOutMoment = moment.tz(
      `${date} ${clockOut}`,
      "YYYY/MM/DD HH:mm",
      timezone
    );

    const formattedDateCheckIn = clockInMoment
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZ");
    const formattedDateCheckOut = clockOutMoment
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZ");

    const clockLog = clockLogId
      ? { checkIn: formattedDateCheckIn, checkOut: formattedDateCheckOut }
      : {
          projectTna: `/tna/project-tna/${viewDetails?.id}`,
          projectLocation: `/tna/project-location/${item?.locationId}`,
          projectTeam: `/project-team/${viewDetails?.teamId}`,
          checkIn: formattedDateCheckIn,
          checkOut: formattedDateCheckOut,
        };
    if (clockLogId) {
      if (!isEditClockLog) {
        editClockLog({ tnaData: clockLog, id: clockLogId }).then((res) => {
          if (res?.error?.data?.error) {
            notify(res?.error?.data?.error);
          } else {
            getPendingDataList({
              projectId: assignedProjectId,
            }).then((res) => {
              if (!isLoading) {
                GetEmployeeDetails(viewDetails?.id);
                dispatch(setTimeAndAttendance({ updateLogs: true }));
                setOpen(false);
              }
            });
          }
        });
      }
    } else {
      if (!isAddClockLog) {
        addClockLog({ tnaData: clockLog }).then((res) => {
          if (res?.error?.data?.error) {
            notify(res?.error?.data?.error);
          } else {
            getPendingDataList({
              projectId: assignedProjectId,
            }).then((res) => {
              if (!isLoading) {
                GetEmployeeDetails(viewDetails?.id);
                dispatch(setTimeAndAttendance({ updateLogs: true }));
                setOpen(false);
              }
            });
          }
        });
      }
    }
  };

  return (
    <Stack>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <Popover
        open={open}
        onOpenChange={(_, data) => setOpen(data.open)}
        trapFocus
        className={mergeClasses("custom-primary", layout.smallBtn)}
      >
        <PopoverTrigger disableButtonEnhancement>
          {clockLogId ? (
            <Pencil height={18} width={18} />
          ) : (
            <Button appearance="primary" onClick={() => setOpen(true)}>
              {t("add_popover_btn")}
            </Button>
          )}
        </PopoverTrigger>

        <PopoverSurface>
          <Form onSubmit={handleSubmit(onSubmit)} className={layout.d_flex}>
            <Stack>
              {!defaultDate && (
                <Stack
                  className={mergeClasses(style.client_field, layout.mb_1)}
                >
                  <FieldDatepicker
                    label={t("date")}
                    control={control}
                    onSelectDate={onSelectDate}
                    name="datePicker"
                    placeholderText={"Select date"}
                    defaultValue={
                      selectedDate
                        ? new Date(selectedDate)
                        : defaultDate
                        ? new Date(defaultDate)
                        : ""
                    }
                    onFormatDate={onFormatDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    validationMessage={errors?.datePicker?.message}
                    validation={register("datePicker", {
                      required: `${t("validation_message.date_is_required")}`,
                    })}
                  />
                </Stack>
              )}
              <Stack className={mergeClasses(layout.w_100, layout.mb_1)}>
                <FieldTimePicker
                  control={control}
                  label={t("clock_in")}
                  name="clockIn"
                  value={defaultCheckInTime}
                  onChange={onClockInTimeChange}
                  errorField={errors?.clockIn}
                  errorMsgClass={layout.m_0}
                  requiredMsg={t("validation_message.clockIn_is_required")}
                  className={mergeClasses("timePickerInput", "border4")}
                />
              </Stack>
              <Stack className={mergeClasses(layout.w_100, layout.mb_1)}>
                <FieldTimePicker
                  control={control}
                  label={t("clock_out")}
                  name="clockOut"
                  value={defaultCheckOutTime}
                  onChange={onClockOutTimeChange}
                  errorMsgClass={layout.m_0}
                  errorField={errors?.clockOut}
                  requiredMsg={t("validation_message.clockOut_is_required")}
                  className={mergeClasses("timePickerInput", "border4")}
                />
              </Stack>
              <Stack
                className={mergeClasses(
                  layout.flex_row,
                  style.client_field,
                  layout.m_0
                )}
              >
                <FormButton
                  type="submit"
                  appearance="primary"
                  loading={
                    isAddClockLog ||
                    isEditClockLog ||
                    isLoading ||
                    isPendingLoading
                  }
                  className={mergeClasses(
                    "custom-primary",
                    layout.smallBtn,
                    layout.mr_1
                  )}
                >
                  Save
                </FormButton>
                <FormButton
                  className={mergeClasses("custom-primary", layout.smallBtn)}
                  onClick={() => {
                    setDefaultCheckOutTime(defaultCheckOut);
                    setDefaultCheckInTime(defaultCheckIn);
                    setValue("clockIn", defaultCheckIn);
                    setValue("clockOut", defaultCheckOut);
                    !defaultDate && setValue("datePicker", setSelectedDate(""));
                    !defaultDate && reset({ datePicker: "" });
                    setOpen(false);
                  }}
                >
                  {t("cancel_popover_btn")}
                </FormButton>
              </Stack>
            </Stack>
          </Form>
        </PopoverSurface>
      </Popover>
    </Stack>
  );
};

export default ClockLogPopover;
