import {
  Table as FluentTable,
  useArrowNavigationGroup,
  mergeClasses,
} from "@fluentui/react-components";
import Style from "./Style";

const Table = (props) => {
  const { children, className } = props;
  const style = Style();
  const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });

  return (
    <FluentTable
      {...keyboardNavAttr}
      role="grid"
      className={mergeClasses(style.customTable, className)}
      sortable={true}
      sortDirection="descending"
      aria-label="Table with grid keyboard navigation"
    >
      {children}
    </FluentTable>
  );
};

export default Table;
