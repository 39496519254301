import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { buildMutationHelper } from "../../../utils/buildMutationHelper";
import { getHeaders } from "../../../utils/requestHeaders";
import { API_HOST } from "../../../utils/constants";
import { setTimeAndAttendanceApprove } from "../../reducer/time-and-attendance/time-and-attendanceApprove";
import { defaultFromState } from "../../../utils/defaultFromState";

export const timeAndAttendanceApproveApi = createApi({
  reducerPath: "timeAndAttendanceApproveApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),
  endpoints: (builder) => ({
    getApprovedData: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        projectId = undefined,
        pagination = undefined,
        sortBy = undefined,
        sortType = undefined,
        checkInDate = undefined,
        checkOutDate = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/tna/approved-project-tna",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            projectId: projectId,
            pagination: pagination,
            sortBy,
            sortType,
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              timeAndAttendanceApprove: {
                page,
                itemsPerPage,
                pagination,
                projectId,
                sortBy,
                sortType,
                checkInDate,
                checkOutDate,
                client,
              },
            } = getState();
            let stateParams = {
              page,
              itemsPerPage,
              pagination,
              projectId,
              sortBy,
              sortType,
              checkInDate,
              checkOutDate,
              client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setTimeAndAttendanceApprove({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setTimeAndAttendanceApprove({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(
          setTimeAndAttendanceApprove({ errorMessage: error?.data?.error })
        );
      }
    ),
  }),
});

export const { useGetApprovedDataMutation } = timeAndAttendanceApproveApi;
