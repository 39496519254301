import { createSlice } from "@reduxjs/toolkit";

const projectSummaryAwait = createSlice({
  name: "projectSummaryAwait",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    status: "",
    projectSummaryId: "",
    exportData: [],
  },

  reducers: {
    setProjectSummaryAwait(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setProjectSummaryAwait } = projectSummaryAwait.actions;
export default projectSummaryAwait.reducer;
