import React, { useEffect, useState } from "react";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import Layout from "../../../../styles/Layout.styles";
import CustomDialog from "../../../../components/FieldComponents/CustomDialog";
import Form from "../../../../components/FieldComponents/Form";
import FormButton from "../../../../components/FieldComponents/FormButton";
import DeviceSettings from "./DeviceForm/DeviceSettings";
import QuestionAsk from "./DeviceForm/QuestionAsk";
import HostList from "./DeviceForm/HostList";
import { useForm } from "react-hook-form";
import ShortCode from "./DeviceForm/ShortCode";
import DeviceName from "./DeviceForm/DeviceName";
import {
  useAddDeviceMutation,
  useEditDeviceMutation,
  useGetAddedDeviceMutation,
} from "../../../../store/action/device";
import { useSelector } from "react-redux";
import { useLocationListMutation } from "../../../../store/action/location";
import { trailWhiteSpace } from "../../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../../utils/errorHandler";

const AddDevice = (props) => {
  const {
    deviceId,
    isOpenDialog,
    setIsOpenDialog,
    projectLocationId,
    projectId,
  } = props;
  const layout = Layout();
  const { t } = useTranslation();
  const [allowVisitor, setAllowVisitor] = useState(false);
  const [visitorToAddMessage, setVisitorToAddMessage] = useState(false);
  const [visibleQuestions, setVisibleQuestions] = useState([]);
  const [visibleTags, setVisibleTags] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [editDeviceData, setEditDeviceData] = useState({});
  const [getLocationListApi] = useLocationListMutation();
  const locationList = useSelector((state) => state.location);
  const [addDeviceApi, { isLoading: isAddLoading }] = useAddDeviceMutation();
  const [getDeviceApi, { isLoading: isGetLoading }] =
    useGetAddedDeviceMutation();
  const [editDeviceApi, { isLoading: isEditLoading }] = useEditDeviceMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useForm();

  const commonProps = { register, errors, setValue };
  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (deviceId) {
          getDeviceApi(deviceId).then((res) => {
            setEditDeviceData(res);
            setVisibleTags(res?.data?.hostLists);
            setAllowVisitor(res?.data?.settings?.AllowVisitor);
            setVisitorToAddMessage(res?.data?.settings?.AskVisitorToAddMessage);

            const devicesQuestions =
              res?.data?.projectLocationDevices[0]?.projectLocationDevicesQuestions?.map(
                (data, key) => {
                  return { ...data?.questionBank, sort: data.sort };
                }
              );
            const sortedData = devicesQuestions.sort((a, b) => {
              return a.sort - b.sort;
            });
            setVisibleQuestions(sortedData);
          });
        }
      }
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isGetLoading]);

  if (deviceId && !editDeviceData?.data) {
    return null;
  }
  const onSubmit = (value) => {
    let formData = trailWhiteSpace(value);
    const deviceData = {
      shortCode: formData?.shortCode,
      deviceName: formData?.deviceName,
      isActive: true,
      settings: {
        AllowVisitor: allowVisitor,
        AskVisitorToAddMessage: visitorToAddMessage,
      },
      hostLists: visibleTags,
      questions: visibleQuestions?.reduce(
        (obj, item, key) => Object.assign(obj, { [item?.id]: key }),
        {}
      ),
      projectLocation: projectLocationId,
    };
    if (deviceId) {
      if (!isEditLoading) {
        editDeviceApi({ deviceData: deviceData, id: deviceId }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res?.error) {
            setIsOpenDialog(false);
            getLocationListApi({
              page: locationList?.page,
              itemsPerPage: locationList?.itemsPerPage,
              project: projectId,
            });
          }
        });
      }
    } else {
      if (!isAddLoading) {
        addDeviceApi({ deviceData: deviceData }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res?.error) {
            setIsOpenDialog(false);
            getLocationListApi({
              page: locationList?.page,
              itemsPerPage: locationList?.itemsPerPage,
              project: projectId,
            });
          }
        });
      }
    }
  };
  return (
    <>
      <CustomDialog
        dialogTitle={deviceId ? `${t("edit_device")}` : `${t("add_device")}`}
        isEdit={deviceId ? true : false}
        dialogTitleClass=""
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
        dialogContent={
          <Form onSubmit={handleSubmit(onSubmit)} className={layout.w_100}>
            <DeviceName
              {...commonProps}
              deviceName={editDeviceData?.data?.deviceName}
            />

            <ShortCode
              {...commonProps}
              shortCode={editDeviceData?.data?.shortCode}
            />

            <DeviceSettings
              {...commonProps}
              setAllowVisitor={setAllowVisitor}
              allowVisitor={allowVisitor}
              setVisitorToAddMessage={setVisitorToAddMessage}
              visitorToAddMessage={visitorToAddMessage}
            />

            <HostList
              {...commonProps}
              visibleTags={visibleTags}
              setVisibleTags={setVisibleTags}
              hostList={editDeviceData?.data?.deviceName}
            />

            <QuestionAsk
              {...commonProps}
              className={layout.mb_2}
              setVisibleQuestions={setVisibleQuestions}
              visibleQuestions={visibleQuestions}
            />

            <Stack className={mergeClasses(layout.flex_row)}>
              <FormButton
                type="submit"
                appearance="primary"
                className={mergeClasses(layout.smallBtn, "custom-primary")}
              >
                {t("save")}
              </FormButton>
              &nbsp;&nbsp;&nbsp;
              <FormButton
                appearance="secondary"
                className={mergeClasses(layout.smallBtn, "custom-primary")}
                onClick={() => setIsOpenDialog(false)}
              >
                {t("cancel")}
              </FormButton>
            </Stack>
          </Form>
        }
        dialogContentClass={layout.pr1}
        dialogClose="Close"
        dialogCloseClass=""
        dialogSubmit="Do Something"
        dialogSubmitClass="Do Something"
        appearance="primary"
      />
    </>
  );
};

export default AddDevice;
