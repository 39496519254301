import {
  tokens,
  shorthands,
  makeStaticStyles,
  makeStyles,
} from "@fluentui/react-components";

export const useStaticStyles = makeStaticStyles({
  ".custom-pagination ul": {
    display: "flex",
    flexWrap: "wrap",
  },
  ".custom-pagination li": {
    borderRadiusMedium: tokens.borderRadiusMedium,
    height: "32px",
    minWidth: "32px",
    textAlign: "center",
    lineHeight: "32px",
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    ...shorthands.margin("0", "5px"),
    listStyleType: "none",
  },
  ".custom-pagination li:first-child,li:last-child": {
    ...shorthands.border("none"),
  },
  ".custom-pagination li.selected": {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralBackground1,
  },
  ".previous, .next": {
    color: "#616161",
  },
});

export const style = makeStyles({
  paginationDropdown: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    ...shorthands.margin("auto", "auto", "auto", 0),
    "& .pagination-dropdown": {
      minWidth: "auto",
    },
  },
  textGray: {
    color: "#616161",
  },
  rowsdropdown: {
    ...shorthands.margin("auto"),
    ...shorthands.padding("0", "10px"),
    textAlign: "center",
  },
  paginationMain: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    ...shorthands.margin("22px", 0),
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  textAlign: {
    textAlign: "center",
    marginTop: "15px",
  },
});
