import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import FieldTimeZoneSelect from "../../../../components/FieldComponents/FieldTimeZoneSelect";

const UserTimeZone = (props) => {
  const { userTimeZone, timeZoneSelectListData } = props;
  const { t } = useTranslation();
  const style = Style();

  if (typeof timeZoneSelectListData === "undefined" && userTimeZone) {
    return "";
  }

  return (
    <>
      <Stack className={style.client_field}>
        <FieldTimeZoneSelect
          label={t("time_zone")}
          validationMessage={props.errors?.userTimeZone?.message}
          id="userTimeZone"
          placeholder={t("enter_time_zone")}
          name="userTimeZone"
          inputDefaultValue={userTimeZone}
          timeZoneSelectListData={timeZoneSelectListData}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("userTimeZone")}
          noRequired
        />
      </Stack>
    </>
  );
};

export default UserTimeZone;
