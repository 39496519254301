import Style from "../style";
import { TableCell, TableRow } from "../../../../components/Table";
import Layout from "../../../../styles/Layout.styles";
import { Text } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import { format } from "date-fns";

const ProjectSummaryCompleteRow = ({
  item,
  projectSummaryId,
  openSummaryDetails,
  setOpenSummaryDetails,
  setProjectSummaryId,
}) => {
  const style = Style();
  const layout = Layout();
  const onSummaryDetails = () => {
    setOpenSummaryDetails(true);
    setProjectSummaryId(item.id);
  };

  const highLightRow = openSummaryDetails && projectSummaryId === item?.id;
  return (
    <>
      <TableRow
        key={item?.project?.label}
        className={mergeClasses(highLightRow ? "highLightRow" : "")}
      >
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          <Text
            truncate
            block
            className={mergeClasses(
              layout.textOverFlowHidden,
              layout.cursor_pointer,
              highLightRow ? "highLightRow" : ""
            )}
            title={item?.projectCode}
            onClick={onSummaryDetails}
          >
            {item?.projectCode}
          </Text>
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item?.title}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item && format(new Date(item.commencementDate), "yyyy/MM/dd")}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item && format(new Date(item.completionDate), "yyyy/MM/dd")}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item?.totalMember}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item?.todayClockIn}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProjectSummaryCompleteRow;
