import { Stack } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { ReactComponent as Pencil } from "../../images/pencil.svg";
import { useState } from "react";
import { TableCell, TableRow } from "../../components/Table";

import SwitchPopup from "../../components/FieldComponents/SwitchPopup";
import Layout from "../../styles/Layout.styles";
import Style from "../Project/ProjectList/Style";
import {
  useActiveInActiveExternalSourcesMutation,
  useGetClientExternalSourcesListMutation,
} from "../../store/action/clientExternalSources";

const ClientExternalSourceRow = ({
  item,
  isEditSourcesOpen,
  setIsEditSourcesOpen,
  setEditExternalSourceId,
}) => {
  const [open, setOpen] = useState(false);
  const [ActiveInActiveSwitch] = useActiveInActiveExternalSourcesMutation();
  const [clientExternalSourceListApi, { isLoading }] =
    useGetClientExternalSourcesListMutation();

  const handleSwitchChange = () => {
    if (item.isActive && isEditSourcesOpen) {
      setIsEditSourcesOpen(false);
    }

    const externalSourcesData = {
      isActive: item?.isActive ? false : true,
    };

    ActiveInActiveSwitch({
      externalSourcesData: externalSourcesData,
      id: item?.id,
    }).then((res) => {
      if (!isLoading) {
        clientExternalSourceListApi();
        setOpen(false);
      }
    });
  };

  const style = Style();
  const layout = Layout();

  let disableLink = item.isActive === false;

  const onClientExternalSourceDetails = () => {
    setIsEditSourcesOpen(true);

    setEditExternalSourceId(item?.id);
  };

  return (
    <>
      <TableRow key={item?.project?.label}>
        <TableCell
          tabIndex={item?.key}
          className={mergeClasses(style.customTblCell, style.wordBreak)}
          role="gridcell"
        >
          {item?.externalSource?.module?.name}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item?.externalSource?.name}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          <Stack className={layout.tableIcons}>
            <Stack
              className={mergeClasses(
                layout.tableIcons,
                layout.d_flex,
                layout.alignCenter,
                disableLink ? "disableIcons" : ""
              )}
            >
              <Link
                className={mergeClasses(
                  layout.tableIcon,
                  layout.d_flex,
                  layout.alignCenter
                )}
                aria-label="edit"
                onClick={onClientExternalSourceDetails}
              >
                <Pencil height={18} width={18} />
              </Link>
            </Stack>{" "}
            <SwitchPopup
              open={item?.isActive && open}
              onOpenChange={(_, data) => setOpen(data.open)}
              handleSwitchChange={() => {
                item?.isActive === false && handleSwitchChange();
              }}
              checked={item?.isActive ? true : false}
              handleInactiveSwitch={handleSwitchChange}
              handleCancel={() => setOpen(false)}
              isLoading={isLoading}
            />
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ClientExternalSourceRow;
