import Style from "../../../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { mergeClasses } from "@fluentui/react-components";
import Layout from "../../../../../../styles/Layout.styles";
import FieldsTextarea from "../../../../../../components/FieldComponents/FieldsTextarea";

const LocationAddress = (props) => {
  const { locationAddress } = props;
  const style = Style();
  const layout = Layout;
  const { t } = useTranslation();
  return (
    <>
      <Stack className={mergeClasses(layout.textbox, layout.mb_1)}>
        <FieldsTextarea
          label={t("project.pl_address")}
          fieldClassName="custom-label"
          validationMessage={props?.errors?.locationAddress?.message}
          placeholder={t("project.pl_enter_address")}
          name="locationAddress"
          id="locationAddress"
          maxLength={255}
          inputClassName={style.textboxInput}
          inputDefaultValue={locationAddress}
          validation={props.register("locationAddress", {
            required: `${t("validation_message.address_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default LocationAddress;
