import * as React from "react";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import Style from "./Style";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { SearchBox } from "@fluentui/react-search-preview";
import Layout from "../../../styles/Layout.styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setClient } from "../../../store/reducer/client";
import FieldCombobox from "../../../components/FieldComponents/FieldCombobox";

const ClientFilter = ({ clientId, getClientListApi, setInitialLoad, role }) => {
  const style = Style();
  const layout = Layout();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clientList = useSelector((state) => state.client);

  const onChange = (data) => {
    dispatch(setClient({ page: 0, search: data }));
    getClientListApi();
    setInitialLoad(false);
  };

  const onStatusChange = (e, data) => {
    dispatch(setClient({ page: 0, isActive: data?.optionValue }));
    getClientListApi();
  };

  const options = [
    { value: "true", children: t("active") },
    { value: "false", children: t("inactive") },
    { value: "undefined", children: t("all") },
  ];

  const matchedOption = options.find(
    (option) => option.value === clientList?.isActive
  );

  return (
    <>
      <Stack className={layout.projectFilter}>
        {!clientId && (
          <SearchBox
            placeholder={t("search")}
            className={mergeClasses(
              layout.headerSearch,
              "headerSearch",
              "no-reset"
            )}
            defaultValue={clientList?.search}
            iconProps={{
              iconName: "Search",
              styles: { root: { right: "8px" } },
            }}
            onChange={(e) => onChange(e?.target?.value)}
          />
        )}
        <Stack
          className={mergeClasses(
            layout.d_flex,
            layout.flex_row,
            layout.alignCenter
          )}
        >
          {!clientId && (
            <FieldCombobox
              multiselect={false}
              name="status"
              readOnly
              stackCombobox="minwidth-combobox"
              className={layout.mr_1}
              defaultValue={matchedOption?.children}
              defaultSelectedOptions={[matchedOption?.value]}
              onOptionSelect={onStatusChange}
              matchingOptions={options}
            />
          )}
          {(role?.has("client_create") || role?.has("client_edit")) && (
            <Link
              to={
                clientId
                  ? `/organization-list/${clientId}/edit`
                  : "/organization-list/add"
              }
            >
              <Button
                appearance="primary"
                className={mergeClasses(style.prodBtn, "custom-primary")}
              >
                {clientId ? `${t("edit")}` : `${t("add")}`}
              </Button>
            </Link>
          )}{" "}
        </Stack>
      </Stack>
    </>
  );
};

export default ClientFilter;
