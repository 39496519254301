import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setProjectUser } from "../reducer/projectUser";

export const projectUserApi = createApi({
  reducerPath: "projectUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    projectUserList: buildMutationHelper(
      builder,
      (id) => ({
        url: `/assigned-project-teams-users?project=${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const data = response?.data;
        dispatch(
          setProjectUser({
            data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setProjectUser({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const { useProjectUserListMutation } = projectUserApi;
