import { Stack, Text } from "@fluentui/react";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../../components/Table";
import { useSelector } from "react-redux";
import Layout from "../../../../styles/Layout.styles";
import { useEffect } from "react";
import PendingRow from "./PendingRow";
import { Checkbox, mergeClasses } from "@fluentui/react-components";
import Pagination from "../../../../components/Pagination";
import { useGetPendingDataMutation } from "../../../../store/action/time-and-attendance/time-and-attendancePending";
import { setTimeAndAttendancePending } from "../../../../store/reducer/time-and-attendance/time-and-attendancePending";
import { t } from "i18next";
import accessRole from "../../../../components/Common/accessRole";

const TimeSheetAndAttendancePending = (props) => {
  const {
    isEmployeeDetailsOpen,
    employeeSelectedId,
    assignedProjectId,
    setEmployeeSelectedId,
    setProjectSelectedId,
    projectSelectedId,
    setIsEmployeeDetailsOpen,
    checked,
    setChecked,
  } = props;
  const layout = Layout();
  const [getPendingDataList, { isLoading }] = useGetPendingDataMutation();
  const timeAndAttendanceList = useSelector(
    (state) => state.timeAndAttendancePending
  );

  useEffect(() => {
    setChecked(false);
    setProjectSelectedId([]);
    if (assignedProjectId) {
      getData(timeAndAttendanceList?.page, timeAndAttendanceList?.itemsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timeAndAttendanceList?.page,
    timeAndAttendanceList?.itemsPerPage,
    assignedProjectId,
  ]);

  const getData = async (page, itemsPerPage) => {
    await getPendingDataList({
      page: page,
      itemsPerPage: itemsPerPage,
      projectId: assignedProjectId,
    });
  };

  const onRecordCheck = (e, data) => {
    setChecked(!!data);
    if (!data && checked) {
      setProjectSelectedId([]);
    } else {
      const allIds = timeAndAttendanceList?.data?.map((item) => {
        return item?.id;
      });
      setProjectSelectedId(allIds);
    }
  };

  const column = [
    {
      columnKey: "systemEndUser.firstName",
      label: `${t("project.employee_name")}`,
      isSort: true,
    },
    { columnKey: "clockedCount", label: `${t("clocked_count")}` },
    { columnKey: "createdAt", label: `${t("date")}`, isSort: true },
    { columnKey: "checkinDate", label: `${t("clock_in")}`, isSort: true },
    { columnKey: "checkoutDate", label: `${t("clock_out")}`, isSort: true },
    { columnKey: "totalHours", label: `${t("total_hour")}`, isSort: true },
  ];

  const selectedValueColumn = [
    {
      columnKey: "",
      label: (
        <Checkbox
          label={t("all")}
          checked={
            checked
              ? true
              : !checked
              ? false
              : [...new Set(projectSelectedId)]?.length > 0 && "mixed"
          }
          onChange={(ev, data) => onRecordCheck(ev, data?.checked)}
        />
      ),
    },
  ];

  const columns =
    accessRole()?.has("project_tna_approve") ||
    accessRole()?.has("project_tna_reject")
      ? [...selectedValueColumn, ...column]
      : column;

  return (
    <Stack className={mergeClasses("contentData", layout.mt_1)}>
      <Stack className="overflow-y-auto">
        <Table className="tbl-layout-auto">
          <TableHeader className={layout.headerBackground}>
            <TableRow>
              {columns?.map((column, key) => (
                <TableHeaderCell
                  key={key}
                  onSortApi={getData}
                  onSet={setTimeAndAttendancePending}
                  column={column}
                  sortBy={
                    column?.isSort ? timeAndAttendanceList?.sortBy : false
                  }
                >
                  <Text className={layout.headerLabel}>{column?.label}</Text>
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody isLoading={isLoading}>
            {assignedProjectId &&
              timeAndAttendanceList?.data?.map((item, key) => (
                <PendingRow
                  key={key}
                  item={item}
                  employeeSelectedId={employeeSelectedId}
                  setEmployeeSelectedId={setEmployeeSelectedId}
                  setProjectSelectedId={setProjectSelectedId}
                  projectSelectedId={projectSelectedId}
                  setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
                  setIsAllCheck={setChecked}
                  isEmployeeDetailsOpen={isEmployeeDetailsOpen}
                />
              ))}
          </TableBody>
        </Table>
      </Stack>
      <Pagination
        totalCount={assignedProjectId ? timeAndAttendanceList?.totalCount : 0}
        itemsPerPage={timeAndAttendanceList?.itemsPerPage}
        onSetListReducer={setTimeAndAttendancePending}
        onGetList={getData}
        page={timeAndAttendanceList?.page}
      />
    </Stack>
  );
};

export default TimeSheetAndAttendancePending;
