import * as React from "react";
import {
  Button,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
} from "@fluentui/react-components";
import { Image } from "@fluentui/react";
import trash from "../../images/trash.svg";
import { useTranslation } from "react-i18next";
import Layout from "../../styles/Layout.styles";

const DeletePopup = ({
  open,
  onOpenChange,
  onClick,
  handleDelete,
  handleCancel,
  className,
}) => {
  const { t } = useTranslation();
  const layout = Layout();
  return (
    <Popover open={open} onOpenChange={onOpenChange} trapFocus>
      <PopoverTrigger disableButtonEnhancement>
        <Image
          className={mergeClasses(layout.cursor_pointer, className)}
          block="true"
          alt=""
          src={trash}
          height={18}
          width={18}
          onClick={onClick}
        />
      </PopoverTrigger>

      <PopoverSurface>
        <div className={layout.mb_1}>{t("field_components.are_you_sure")}</div>
        <div>
          <Button
            appearance="primary"
            onClick={handleDelete}
            className={layout.mr_1}
          >
            {t("field_components.delete")}
          </Button>
          <Button onClick={handleCancel}>{t("field_components.cancel")}</Button>
        </div>
      </PopoverSurface>
    </Popover>
  );
};

export default DeletePopup;
