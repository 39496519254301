import { createSlice } from "@reduxjs/toolkit";

const externalSources = createSlice({
  name: "externalSources",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    pagination: "",
  },
  reducers: {
    setExternalSources(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setExternalSources } = externalSources.actions;
export default externalSources.reducer;
