import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const UserLastName = (props) => {
  const { userLastName } = props;
  const style = Style();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("last_name")}
          validationMessage={props.errors?.userLastName?.message}
          id="userLastName"
          maxLength="30"
          placeholder={t("enter_last_name")}
          name="userLastName"
          inputDefaultValue={userLastName}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("userLastName", {
            required: `${t("validation_message.last_name_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default UserLastName;
