import { Stack } from "@fluentui/react";
import { CustomDialog } from "../../../../components";
import { useTranslation } from "react-i18next";
import React from "react";
import QRCode from "react-qr-code";
import Layout from "../../../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";

const LocationQR = (props) => {
  const { t } = useTranslation();
  const { isOpenDialog, setIsOpenDialog, deviceQR } = props;
  const layout = Layout();
  return (
    <CustomDialog
      dialogTitle={t("dialog_QR")}
      dialogTitleClass={layout.mb_1}
      isOpenDialog={isOpenDialog}
      setIsOpenDialog={setIsOpenDialog}
      dialogContent={
        <Stack className={mergeClasses(layout.mt_1, layout.mb_1)}>
          <QRCode value={JSON.stringify(deviceQR)} className={layout.m_auto} />
        </Stack>
      }
      dialogContentClass=""
      dialogClose="Close"
      dialogCloseClass=""
      dialogSubmit="Do Something"
      dialogSubmitClass="Do Something"
      appearance="primary"
    />
  );
};

export default LocationQR;
