import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  projectContent: {
    ...shorthands.margin("30px", "auto", "30px", "auto"),
    ...shorthands.padding("20px", 0, 0, 0),
    display: "inline-flex",
    borderRadiusMedium: tokens.borderRadiusMedium,
    justifyContent: "space-between",
  },
  noBottomBorder: {
    ...shorthands.borderBottom(0),
  },
  borderE2E8F0: {
    ...shorthands.border("1px", "solid", "#E2E8F0"),
  },
  marginTop29: {
    marginTop: "29px",
  },
  subDetails: {
    flexDirection: "row",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  fieldsection: {
    width: "50%",
    alignSelf: "start",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  flex: {
    display: "flex",
  },
  headerTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground1Static,
    marginTop: "6px",
    marginBottom: "0",
  },
  projectCodeValue: {
    "@media (min-width: 1024px)": {
      paddingLeft: "15px",
    },
  },
  inputValue: {
    color: "#616161",
    fontSize: "14px",
    display: "inline-block",
    "@media (min-width: 991px)": {
      width: "calc(100% - 200px)",
    },
  },
  inputValueBlack: {
    color: tokens.colorNeutralForeground1Static,
    fontSize: "14px",
    fontWeight: tokens.fontWeightSemibold,
  },
  subTitle: {
    color: "#475569",
    fontSize: "14px",
  },
  projectCode: {
    color: "#475569",
    fontSize: "14px",
    fontWeight: tokens.fontWeightSemibold,
    paddingLeft: "16px",
  },
  textElement: {
    color: "#475569",
    fontSize: "14px",
    fontWeight: tokens.fontWeightSemibold,
    "@media (max-width: 768px)": {
      width: "100%",
    },
    "@media (min-width: 1024px)": {
      display: "inline-flex",
      width: "200px",
    },
    textWrap: "nowrap",
    whiteSpace: "nowrap",
  },
  d_flex: {
    "@media (min-width: 1024px)": {
      display: "flex",
    },
  },
  projectDetail: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "50%",
    ...shorthands.padding("15px"),
    ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
    ...shorthands.borderLeft("1px", "solid", tokens.colorNeutralStroke1),
  },
  detailsLeft: {
    textAlign: "center",
    marginBottom: "10px",
    fontWeight: tokens.fontWeightSemibold,
  },
  projectViewLeft: {
    width: "481px",
    "@media (max-width: 1280px)": {
      width: "100%",
    },
  },
  projectViewRight: {
    width: "calc( 100% - 481px )",
    "@media (min-width: 1280px)": {
      flexDirection: "column",
    },
    ...shorthands.borderLeft("1px", "solid", tokens.colorNeutralStroke1),
    "@media (max-width: 1280px)": {
      width: "100%",
    },
  },
  projectMainDetails: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width: 1280px)": {
      flexDirection: "column",
    },
  },
  detailsText: {
    ...shorthands.padding("15px"),
    display: "flex",
    "@media (max-width: 991px)": {
      flexDirection: "column",
    },
  },
});

export default style;
