import { createSlice } from "@reduxjs/toolkit";

const modules = createSlice({
  name: "modules",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    pagination: "",
  },
  reducers: {
    setModules(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setModules } = modules.actions;
export default modules.reducer;
