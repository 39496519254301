import {
  makeStyles,
  tokens,
  shorthands,
  makeStaticStyles,
} from "@fluentui/react-components";

const headerStyle = makeStyles({
  headerNav: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: tokens.colorNeutralBackground1,
    "@media screen and (min-width: 600px)": {
      flexDirection: "row",
    },
    "@media screen and (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
    "@media screen and (max-width: 992px)": {
      ...shorthands.padding("20px"),
    },
    "@media screen and (min-width: 1024px) and (max-width: 1439px)": {
      minHeight: "69px",
      ...shorthands.padding("18.5px", "20px", "18.5px", "30px"),
    },
    "@media screen and (min-width: 1440px)": {
      Height: "69px",
      ...shorthands.padding("18.5px", "20px", "18.5px", "30px"),
    },
  },
  headerLinks: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  customMenuReverse: {
    "@media screen and (max-width: 992px)": {
      flexDirection: "column-reverse",
    },
    "@media screen and (min-width: 640px) and (max-width: 1024px)": {
      alignItems: "flex-end",
    },
    "@media screen and (max-width: 768px)": {
      marginBottom: "15px",
    },
  },
  headerLink: {
    color: tokens.colorNeutralForeground3,
    textDecorationLine: "none",
    ":hover": {
      color: tokens.colorBrandBackground,
      textDecorationLine: "none",
    },
  },
  headerSearch: {
    flexDirection: "row-reverse",
    maxWidth: "250px",
    "@media screen and (min-width: 1024px)": {
      ...shorthands.margin("", "28px", "", ""),
      minWidth: "180px",
      maxWidth: "180px",
    },
    minWidth: "250px",
  },
  mr2_6: {
    ...shorthands.margin("", "26px", "", ""),
  },
  counter: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  notification: {
    position: "relative",
    ...shorthands.margin("-15px", "18px", "", ""),
  },
  headerGroup: {
    flexDirection: "row",
    textAlign: "center",
    "@media screen and (max-width: 767px)": {
      justifyContent: "flex-end",
      width: "100%",
      marginBottom: "15px",
    },
    "@media screen and (max-width: 991px)": {
      marginBottom: "15px",
      justifyContent: "flex-end",
    },
  },
  desktopMenu: {
    display: "none",
    "@media screen and (min-width: 1025px)": {
      display: "flex",
    },
  },
  responsiveMenu: {
    display: "flex",

    "@media screen and (min-width: 1025px)": {
      display: "none",
    },
    "@media screen and (min-width: 768px) and (max-width: 1024px)": {
      minWidth: "150px",
    },
  },
  menuDropdownBtn: {
    ...shorthands.padding(0),
    backgroundColor: "transparent",
    display: "flex",
    color: tokens.colorBrandBackgroundPressed,
    fontWeight: "normal",
    justifyContent: "left",
    minWidth: "auto",
    marginRight: "30px",
  },
  headerRightMenu: {
    marginRight: "15px",
    ...shorthands.padding("5px"),
    minWidth: "32px",
  },
  mr_0_5: {
    marginRight: "5px",
  },
  clientDropdown: {
    "@media screen and (max-width: 600px)": {
      width: "100%",
      minWidth: "auto",
      marginRight: "-15px",
    },
  },
});

const headerStaticStyle = makeStaticStyles({
  ".desktopMenu": {
    flexDirection: "row",
  },
  ".clientListDrop .fui-Dropdown": {
    minWidth: "150px",
  },
  ".headerSearchbar": {
    "@media screen and (max-width: 1599px)": {
      flexDirection: "row",
    },
    "@media screen and (max-width: 1025px)": {
      flexDirection: "column",
    },
    alignItems: "flex-start",
  },
  ".searchWithClient": {
    flexDirection: "row",
  },
  ".headerOptionResp": {
    ...shorthands.padding(0, "8px"),
  },
  ".headerLinkResp": {
    ...shorthands.padding("5px", 0),
  },
  ".desktopMenu .fui-MenuItem": {
    ...shorthands.padding(0, "25px", 0, 0),
    backgroundColor: "transparent",
    alignItems: "center",
    display: "flex",
    cursor: "auto",
  },
  ".desktopMenu .fui-MenuItem:hover": {
    backgroundColor: "transparent",
  },
  ".headerDeskTopMenu .fui-MenuItem": {
    ...shorthands.padding(0),
  },
  ".headerDeskTopMenu .fui-MenuItem__content": {
    display: "block",
    ...shorthands.gap("10px"),
  },
  ".headerDeskTopMenu .fui-MenuItem__content a": {
    ...shorthands.padding("6px"),
  },
  ".deskMenuBtn": {
    color: tokens.colorBrandBackground,
    fontWeight: "normal",
    ...shorthands.padding(0),
    minWidth: "auto",
    justifyContent: "flex-start",
    marginRight: "32px",
  },
  ".headerRightMenuItem": {
    paddingBottom: "6px",
  },
});

export { headerStyle, headerStaticStyle };
