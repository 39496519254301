import React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  Image,
  mergeClasses,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import close from "../../images/close.svg";
import Layout from "../../styles/Layout.styles";
import { Stack } from "@fluentui/react";

const CustomDialog = (props) => {
  const layout = Layout();
  const {
    isOpenDialog,
    setIsOpenDialog,
    dialogTitle,
    className,
    dialogContent,
    dialogContentClass,
  } = props;

  return (
    <>
      <Dialog
        className={mergeClasses("custom-dialog", className)}
        open={isOpenDialog}
      >
        <DialogSurface className="dialogSurface">
          <DialogBody
            className={mergeClasses(
              layout.d_flex,
              layout.flex_column,
              layout.flex_between
            )}
          >
            <Stack
              className={mergeClasses(
                layout.d_flex,
                layout.flex_row,
                layout.w_100,
                layout.flex_between
              )}
            >
              <DialogTitle
                className={layout.primary}
                action={
                  <DialogTrigger action="close">
                    <Button
                      appearance="subtle"
                      aria-label="close"
                      onClick={() => setIsOpenDialog(false)}
                      icon={<Image alt="" src={close} height={20} width={20} />}
                    />
                  </DialogTrigger>
                }
              >
                {dialogTitle}
              </DialogTitle>
            </Stack>
            <DialogContent className={mergeClasses(dialogContentClass)}>
              {dialogContent}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default CustomDialog;
