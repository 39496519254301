import React from "react";
import { Stack } from "@fluentui/react";
import { useState } from "react";
import Layout from "../../styles/Layout.styles";
import {
  mergeClasses,
  Button,
  Spinner,
  Accordion,
} from "@fluentui/react-components";
import { Breadcrumb } from "../../components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useClientLicenseListMutation } from "../../store/action/license";
import LicensesAccordion from "./LicensesAccordion";
import { useGetClientSystemUserListMutation } from "../../store/action/user";
import accessRole from "../../components/Common/accessRole";
import { style } from "../../components/Pagination/Style";

const Licenses = (props) => {
  const layout = Layout();
  const { t } = useTranslation();
  const [getClientLicensesList, { isLoading }] = useClientLicenseListMutation();
  const [getClientSystemUserList] = useGetClientSystemUserListMutation();
  const [userDataList, setUserDataList] = useState([]);
  const [isInitial, setIsInitial] = useState(true);
  const licensesList = useSelector((state) => state.license);
  const stylePage = style();

  const breadcrumbData = [
    {
      title: `${t("licenses.license_text")}`,
    },
  ];

  useEffect(() => {
    if (isInitial) {
      if (!isLoading) {
        getClientLicensesList().then((res) => {});
        setIsInitial(false);
      }
    }
    getClientSystemUserList().then((res) => {
      const listData = Object.entries(res?.data)?.map(([value, label]) => ({
        value: label?.id,
        label: label?.fullName,
      }));
      setUserDataList(listData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClientLicensesList, isLoading, isInitial]);

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.flex_between,
            layout.mb_2
          )}
        >
          <Stack></Stack>
          {accessRole()?.has("license_purchase") && (
            <Link to={"/licenses/bundle"}>
              <Button
                appearance="primary"
                className={mergeClasses(layout.smallBtn, "custom-primary")}
              >
                {t("purchase")}
              </Button>
            </Link>
          )}
        </Stack>
        {isLoading ? (
          <Stack
            className={mergeClasses(layout.positionRelative, layout.w_100)}
          >
            <Spinner className={layout.m_auto} />
          </Stack>
        ) : (
          <Accordion collapsible>
            {accessRole()?.has("license_list") &&
            licensesList?.data?.length > 0 ? (
              licensesList?.data?.map((item, key) => (
                <LicensesAccordion
                  key={key}
                  item={item}
                  userDataList={userDataList}
                />
              ))
            ) : (
              <Stack className={stylePage.textAlign}>
                {t("pagination.no_record_found")}
              </Stack>
            )}
          </Accordion>
        )}
      </Stack>
    </>
  );
};

export default Licenses;
