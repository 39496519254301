import { mergeClasses, Input } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { FieldsInput } from "../../../../components/FieldComponents/";
import Layout from "../../../../styles/Layout.styles";

const TeamsColorCode = (props) => {
  const { color, setColor } = props;
  const layout = Layout();
  const { t } = useTranslation();

  return (
    <>
      <Stack className={mergeClasses(layout.w_100, layout.mb_15)}>
        <FieldsInput
          label={t("team_colorcode")}
          validationMessage={props.errors?.teamsColorCode?.message}
          id="teamsColorCode"
          name="teamsColorCode"
          value={color}
          validation={props.register("teamsColorCode", {
            required: `${t("validation_message.team_colorcode_is_required")}`,
          })}
          contentAfter={
            <Input
              id="color-option"
              type="color"
              value={color}
              className="color-option"
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
          }
        />
      </Stack>
    </>
  );
};

export default TeamsColorCode;
