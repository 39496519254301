import { Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import {
  Divider,
  mergeClasses,
  Image,
  Button,
  Avatar,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as Email } from "../../../images/email.svg";
import { ReactComponent as Phone } from "../../../images/phone.svg";
import { Breadcrumb } from "../../../components";
import { Link } from "react-router-dom";
import {
  useGetSystemEndUserMutation,
  useGetSystemEndUserPhotoMutation,
} from "../../../store/action/systemEndUser";
import Style from "./Style";
import Layout from "../../../styles/Layout.styles";
import imageTimeStamp from "../../../utils/imageTimeStamp";
import accessRole from "../../../components/Common/accessRole";
import { ReactComponent as LocationIcon } from "../../../images/location.svg";

const ViewSystemUserFrom = (props) => {
  const { id } = props;
  const role = accessRole();
  const layout = Layout();
  const style = Style();
  const [viewData, setViewData] = useState();
  const [initialLoad, setInitialLoad] = useState(true);
  const [getSystemUserApi, { isLoading: isGetLoading }] =
    useGetSystemEndUserMutation();
  const [getSystemUserPhotoApi] = useGetSystemEndUserPhotoMutation();
  const [userPhoto, setUsersPhoto] = useState();
  const [teamListName, setTeamListName] = useState();
  const [projectListName, setProjectListName] = useState();

  const { t } = useTranslation();

  const breadcrumbData = [
    {
      title: t("system_end_user"),
      url: "/system-end-user-list",
    },
    {
      title: t("system_end_user_details"),
    },
  ];

  const updatedRoles = { ...viewData?.rolesText };
  delete updatedRoles["ROLE_USER"];

  const roleList = Object.values(updatedRoles)?.map((value, index) => value);

  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getSystemUserApi(id).then((res) => {
            const projectNames = res?.data?.projectTeams?.map((item) => {
              return item?.name;
            });
            const teamNames = res?.data?.projectTeams?.map((item) => {
              return item?.project?.title;
            });
            setProjectListName(projectNames);
            setTeamListName(teamNames);
            setViewData(res?.data);
          });
          getSystemUserPhotoApi(id).then((res) => {
            setUsersPhoto(res?.data?.imageUrl);
          });
        }
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isGetLoading]);

  if (id && !viewData) {
    return null;
  }

  //disable image caching
  let imageUrl = imageTimeStamp(userPhoto);

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack
        className={mergeClasses(
          layout.projectContent,
          layout.w_100,
          "cardShadow"
        )}
      >
        <Stack className={layout.projectFilter}>
          <Stack></Stack>
          {role?.has("system_end_user_edit") && (
            <Link
              to={
                id
                  ? `/system-end-user-list/${id}/edit`
                  : "/system-end-user-list/add"
              }
            >
              <Button
                appearance="primary"
                className={mergeClasses(layout.smallBtn, "custom-primary")}
              >
                {id ? t("userview_edit") : t("userview_add")}
              </Button>
            </Link>
          )}
        </Stack>

        <Stack className={mergeClasses(style.userMainDetails)}>
          <Stack
            className={mergeClasses(
              style.userViewLeft,
              layout.padding2,
              layout.borderRadiusMedium,
              "border4"
            )}
          >
            <Stack
              className={mergeClasses(
                layout.textCenter,
                layout.m_auto,
                layout.mb_2
              )}
            >
              {userPhoto ? (
                <Image
                  alt="image"
                  shape="circular"
                  className={layout.m_auto}
                  src={imageUrl}
                  height={96}
                  width={96}
                />
              ) : (
                <Avatar
                  className={layout.m_auto}
                  name={`${viewData?.fullName}`}
                  size={96}
                  color="colorful"
                />
              )}

              <Text className={style.userTitle}>{viewData?.fullName}</Text>
              <Text className={layout.color616161}>
                {`${roleList.join(", ")}`}
              </Text>
            </Stack>
            <Stack>
              <Text
                className={mergeClasses(
                  layout.d_flex,
                  layout.alignCenter,
                  layout.mb_15,
                  layout.color616161
                )}
              >
                <Email height={18} width={18} className={layout.mr_1_5} />
                <Text
                  className={layout.color616161}
                >{`${viewData?.email}`}</Text>
              </Text>
              {viewData?.phoneNumber && (
                <Text
                  className={mergeClasses(
                    layout.d_flex,
                    layout.alignCenter,
                    layout.color616161
                  )}
                >
                  <Phone height={18} width={18} className={layout.mr_1_5} />
                  <Text className={layout.color616161}>
                    ({`${viewData?.phonePrefix}`}) {`${viewData?.phoneNumber}`}
                  </Text>
                </Text>
              )}
            </Stack>
          </Stack>

          <Stack
            className={mergeClasses(
              style.userViewRight,
              layout.padding2,
              "border4"
            )}
          >
            <Text className={style.detailsText}>
              <Stack className={style.textElement}>
                {t("project.project")}
              </Stack>
              <Text className={style.textDetails}>
                {teamListName?.length > 0 ? teamListName?.join(", ") : "-"}
              </Text>
            </Text>
            <Divider
              className={mergeClasses(layout.maxHeight30, layout.height30)}
            />
            <Text className={style.detailsText}>
              <Stack className={style.textElement}> {t("project.team")} </Stack>
              <Text className={style.textDetails}>
                {projectListName?.length > 0
                  ? projectListName?.join(", ")
                  : "-"}
              </Text>
            </Text>
            <Divider
              className={mergeClasses(layout.maxHeight30, layout.height30)}
            />
            <Text className={mergeClasses(style.detailsText)}>
              <Stack className={style.textElement}>
                {t("project.address")}
              </Stack>
              <Text className={style.textDetails}>{`${
                viewData?.address?.length > 0 ? viewData?.address : "-"
              }`}</Text>
            </Text>

            {viewData?.clientDepartmentSystemEndUsers?.length > 0 && (
              <Stack className="contentData">
                <Divider
                  className={mergeClasses(layout.maxHeight30, layout.height30)}
                />
                <Text
                  className={mergeClasses(style.detailsText, layout.mb_1_0)}
                >
                  <Stack
                    className={mergeClasses(
                      style.textElement,
                      layout.fontSizeLarge
                    )}
                  >
                    {t("project.external_source_details")}
                  </Stack>
                </Text>{" "}
                <Stack className={mergeClasses(style.contentCard)}>
                  {viewData?.clientDepartmentSystemEndUsers?.map(
                    (item, key) => (
                      <Stack
                        key={key}
                        className={mergeClasses(
                          style.userViewRight,
                          layout.padding2,
                          layout.m_10,
                          "border4"
                        )}
                      >
                        {" "}
                        <Text
                          className={mergeClasses(
                            layout.d_flex,
                            layout.flex_between
                          )}
                        >
                          <Text className={style.textDetails}>
                            {item?.clientDepartment?.name}
                          </Text>
                          <Text
                            className={mergeClasses(
                              style.detailsText,
                              layout.whiteSpace
                            )}
                          >
                            <Stack className={style.textElement}>
                              {t("project.status")}:
                            </Stack>
                            <Text className={style.textDetails}>
                              {" "}
                              {item?.status}
                            </Text>
                          </Text>
                        </Text>
                        <Divider
                          className={mergeClasses(
                            layout.maxHeight30,
                            layout.height30
                          )}
                        />
                        <Text
                          className={mergeClasses(layout.d_flex, layout.wrap)}
                        >
                          <Text
                            className={mergeClasses(
                              layout.d_flex,
                              style.primaryColor,
                              layout.mr_1
                            )}
                          >
                            <LocationIcon width={18} />
                          </Text>
                          {item?.clientDepartment?.projectDepartments?.length >
                          0
                            ? item?.clientDepartment?.projectDepartments
                                ?.map((data) => data?.externalLocationName)
                                ?.join(", ")
                            : "-"}
                        </Text>
                      </Stack>
                    )
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewSystemUserFrom;
