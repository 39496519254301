import { Stack, Text } from "@fluentui/react";
import Layout from "../../../styles/Layout.styles";
import { TableCell, TableRow } from "../../../components";
import Avatar from "../../../components/FieldComponents/Avatar";
import { mergeClasses } from "@fluentui/react-components";
import { dateTimeFormatted } from "../../../utils/convert-date-time";

const VisiterListRow = (props) => {
  const {
    item,
    setIsEnableDetails,
    setEnableDetailsId,
    enableDetailsId,
    isEnableDetails,
  } = props;
  const layout = Layout();

  const onVisitorDetails = () => {
    setEnableDetailsId(item?.id);
    setIsEnableDetails(true);
  };

  const highLightRow = isEnableDetails && enableDetailsId === item?.id;

  return (
    <>
      <TableRow key={item?.key} className={highLightRow ? "highLightRow" : ""}>
        <TableCell tabIndex={item?.key} role="gridcell">
          <Stack className={mergeClasses(layout.flex_row, layout.alignCenter)}>
            <Avatar
              image={item?.profileImageUrl}
              name={item?.visitor_name}
              className={layout.mr_1}
            />
            <Text
              truncate
              block
              className={mergeClasses(
                layout.textOverFlowHidden,
                layout.cursor_pointer,
                highLightRow ? "highLightRow" : ""
              )}
              title={item?.projectCode}
              onClick={onVisitorDetails}
            >
              {item?.name}
            </Text>
          </Stack>
        </TableCell>
        <TableCell tabIndex={item?.key} role="gridcell">
          {item?.phoneNumber}
        </TableCell>
        <TableCell tabIndex={item?.key} role="gridcell">
          {item?.hosts?.join(", ")}
        </TableCell>

        <TableCell tabIndex={item?.key} role="gridcell">
          {dateTimeFormatted(item?.checkinDate, item?.timezone)}
        </TableCell>
      </TableRow>
    </>
  );
};
export default VisiterListRow;
