import Layout from "../../../../styles/Layout.styles";
import { TableCell, TableRow } from "../../../../components/Table";
import {
  Toast,
  ToastTitle,
  Toaster,
  mergeClasses,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { Text, Stack } from "@fluentui/react";
import {
  dateFormatted,
  timeFormatted,
} from "../../../../utils/convert-date-time";
import { DeletePopup } from "../../../../components/FieldComponents";
import { useState } from "react";
import ClockLogPopover from "./ClockLogPopover";
import {
  useDeleteClockLogMutation,
  useGetEmployeeDetailMutation,
} from "../../../../store/action/time-and-attendance";
import { useGetPendingDataMutation } from "../../../../store/action/time-and-attendance/time-and-attendancePending";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { setTimeAndAttendance } from "../../../../store/reducer/time-and-attendance";
import accessRole from "../../../../components/Common/accessRole";

const ClockLogRow = (props) => {
  const {
    isLoading,
    item,
    projectTnaDetails,
    assignedProjectId,
    viewDetails,
    selectedValue,
  } = props;
  const layout = Layout();
  const [open, setOpen] = useState(false);
  const [deleteClockLog] = useDeleteClockLogMutation();
  const [GetEmployeeDetails] = useGetEmployeeDetailMutation();
  const [getPendingDataList] = useGetPendingDataMutation();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const dispatch = useDispatch();

  const notify = (isToastMessage) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{t(isToastMessage)}</ToastTitle>
      </Toast>,
      { intent: "error" }
    );
  };
  const handleDelete = (id) => {
    deleteClockLog(id).then((res) => {
      if (res?.error?.data?.error) {
        notify(res?.error?.data?.error);
      } else {
        getPendingDataList({
          projectId: assignedProjectId,
        }).then((res) => {
          if (!isLoading) {
            GetEmployeeDetails(viewDetails?.id);
            dispatch(setTimeAndAttendance({ updateLogs: true }));
            setOpen(false);
          }
        });
      }
    });
  };
  return (
    <TableRow className={mergeClasses()}>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />

      <TableCell tabIndex={item?.key} role="gridcell">
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.alignCenter,
            layout.tableIcons,
            item?.isDeleted ? "disableIcons" : ""
          )}
        >
          <Text>
            {timeFormatted(item?.checkIn, projectTnaDetails?.timezone)}
          </Text>
        </Stack>
      </TableCell>
      <TableCell tabIndex={item?.key} role="gridcell">
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.alignCenter,
            layout.tableIcons,
            item?.isDeleted ? "disableIcons" : ""
          )}
        >
          <Text>
            {timeFormatted(item?.checkOut, projectTnaDetails?.timezone)}
          </Text>
        </Stack>
      </TableCell>
      <TableCell tabIndex={item?.key} role="gridcell">
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.alignCenter,
            layout.tableIcons,
            item?.isDeleted ? "disableIcons" : ""
          )}
        >
          <Text>
            {item?.autoCheckoutAt && item?.isAutoClockout
              ? timeFormatted(item?.autoCheckoutAt, projectTnaDetails?.timezone)
              : "-"}
          </Text>
        </Stack>
      </TableCell>
      <TableCell tabIndex={item?.key} role="gridcell">
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.alignCenter,
            layout.tableIcons,
            item?.isDeleted ? "disableIcons" : ""
          )}
        >
          <Text>
            {item?.isEdited === true && "*"}
            {item?.logType === "app"
              ? "App"
              : item?.logType === "manual"
              ? "Manual"
              : "App-Manual"}
          </Text>
        </Stack>
      </TableCell>
      {selectedValue === "review" && accessRole()?.has("can_edit_time_log") && (
        <TableCell tabIndex={item?.key}>
          <Stack
            className={mergeClasses(
              layout.tableIcons,
              item?.isDeleted ? "disableIcons" : ""
            )}
          >
            <Stack key={item?.clockLogId}>
              <ClockLogPopover
                assignedProjectId={assignedProjectId}
                viewDetails={viewDetails}
                item={projectTnaDetails}
                defaultDate={dateFormatted(
                  item?.checkIn,
                  projectTnaDetails?.timezone
                )}
                defaultCheckIn={timeFormatted(
                  item?.checkIn,
                  projectTnaDetails?.timezone
                )}
                defaultCheckOut={timeFormatted(
                  item?.checkOut,
                  projectTnaDetails?.timezone
                )}
                clockLogId={item?.clockLogId}
                isLoading={isLoading}
              />
            </Stack>

            {accessRole()?.has("can_delete_time_log") && (
              <DeletePopup
                open={open}
                onOpenChange={(_, data) => setOpen(data.open)}
                onClick={() => setOpen(true)}
                handleDelete={() => handleDelete(item?.clockLogId)}
                handleCancel={() => setOpen(false)}
              />
            )}
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ClockLogRow;
