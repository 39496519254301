import React, { useState } from "react";
import Layout from "../../../../styles/Layout.styles";
import { TableCell, TableRow } from "../../../../components/Table";
import { dateFormatted } from "../../../../utils/convert-date-time";
import { Button, mergeClasses } from "@fluentui/react-components";
import { useGetBillingMutation } from "../../../../store/action/billing";
import Pdf from "./Pdf";
import { t } from "i18next";

const BillingListRow = (props) => {
  const { item } = props;
  const layout = Layout();
  const [getBilling] = useGetBillingMutation();
  const [resultData, setResultData] = useState();
  const [isResultData, setIsResultData] = useState(false);

  const download = (id) => {
    getBilling(id).then((result) => {
      setResultData(result);
      setIsResultData(true);
    });
  };

  return (
    <>
      <TableRow key={item?.project?.label}>
        <TableCell
          tabIndex={item?.key}
          className={mergeClasses(layout.customTblCell, layout.textNowrap)}
          role="gridcell"
        >
          {item?.invoiceNumber}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.createdAt &&
            dateFormatted(
              item?.createdAt,
              item?.clientOrder?.client?.accountPerson?.timezone
            )}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {`${item?.currency} ${item?.amount.toFixed(2)}`}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {"Paid on "}
          {item?.createdAt &&
            dateFormatted(
              item?.createdAt,
              item?.clientOrder?.client?.accountPerson?.timezone
            )}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.paymentStatus}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {isResultData ? (
            <Pdf id={item?.id} data={resultData?.data} />
          ) : (
            <Button
              className={layout.textNowrap}
              onClick={() => download(item?.id)}
            >
              {t("billing_download")}
            </Button>
          )}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.clientOrder?.client?.companyBillingAddress}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.clientOrder?.client?.companyName}
        </TableCell>
      </TableRow>
    </>
  );
};
export default BillingListRow;
