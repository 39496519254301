import React, { useEffect, useState } from "react";
import { Text, mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import {
  Pagination,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Breadcrumb,
} from "../../../components";
import Layout from "../../../styles/Layout.styles";
import { t } from "i18next";
import VisitorFilter from "./VisitorFilter";
import VisitorListRow from "./VisitorListRow";
import VisitorDetails from "./VisitorDetails";
import Style from "./style";
import { useVisitorListMutation } from "../../../store/action/visitor";
import { useSelector } from "react-redux";
import { setVisitor } from "../../../store/reducer/visitor";

const VisitorList = (props) => {
  const layout = Layout();
  const style = Style();
  const [isEnableDetails, setIsEnableDetails] = useState(false);
  const [enableDetailsId, setEnableDetailsId] = useState();

  const [projectId, setProjectId] = useState();
  const [locationId, setLocationId] = useState();

  const [getVisiterListApi, { isLoading }] = useVisitorListMutation();
  const visitorList = useSelector((state) => state.visitor);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isProjectLoader, setIsProjectLoader] = useState(false);
  const [isLocationLoader, setIsLocationLoader] = useState(false);

  useEffect(() => {
    if (initialLoad) {
      if (projectId) {
        getVisiterDataListApi(visitorList?.page, visitorList?.itemsPerPage);
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialLoad,
    getVisiterListApi,
    projectId,
    locationId,
    isLoading,
    visitorList,
  ]);

  const getVisiterDataListApi = async (page, itemsPerPage) => {
    await getVisiterListApi({
      page: page,
      itemsPerPage: itemsPerPage,
      projectLocationId: projectId,
      locationId: locationId,
    });
  };

  const columns = [
    { columnKey: "name", label: t("visitor.name"), isSort: true },
    { columnKey: "phone", label: t("visitor.phone_number") },
    { columnKey: "hostName", label: t("visitor.host_name") },
    { columnKey: "checkinDate", label: t("visitor.check_in"), isSort: true },
  ];

  const breadcrumbData = [
    {
      title: t("time_and_attendance"),
    },
    {
      title: `${t("visitor.visitor_text")}`,
    },
  ];

  return (
    <>
      <Stack className={mergeClasses(layout.d_flex, layout.flex_row)}>
        <Stack className={layout.w_100}>
          <Breadcrumb data={breadcrumbData} />
          <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
            <VisitorFilter
              assignedProjectId={projectId}
              assignedLocationId={locationId}
              setAssignedProjectId={setProjectId}
              setIsEnableDetails={setIsEnableDetails}
              setAssignedLocationId={setLocationId}
              setIsProjectLoader={setIsProjectLoader}
              setIsLocationLoader={setIsLocationLoader}
            />
            <Stack className="contentData">
              <Stack className="overflow-y-auto">
                <Table className="tbl-layout-auto">
                  <TableHeader className={layout.headerBackground}>
                    <TableRow>
                      {columns.map((column, key) => (
                        <TableHeaderCell
                          key={key}
                          onSortApi={getVisiterDataListApi}
                          onSet={setVisitor}
                          column={column}
                          sortBy={column?.isSort ? visitorList?.sortBy : false}
                        >
                          <Text className={layout.headerLabel}>
                            {column.label}
                          </Text>
                        </TableHeaderCell>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody
                    isLoading={isLoading || isProjectLoader || isLocationLoader}
                  >
                    {visitorList?.data?.map((item, key) => (
                      <VisitorListRow
                        key={key}
                        item={item}
                        setIsEnableDetails={setIsEnableDetails}
                        setEnableDetailsId={setEnableDetailsId}
                        isEnableDetails={isEnableDetails}
                        enableDetailsId={enableDetailsId}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Stack>
              <Pagination
                totalCount={
                  visitorList?.totalCount
                    ? visitorList?.totalCount
                    : visitorList?.data?.length
                }
                itemsPerPage={visitorList?.itemsPerPage}
                onSetListReducer={setVisitor}
                onGetList={getVisiterDataListApi}
                page={visitorList?.page}
              />
            </Stack>
          </Stack>
        </Stack>
        {isEnableDetails && (
          <Stack className={style.summaryBackground}>
            <VisitorDetails
              enableDetailsId={enableDetailsId}
              setIsEnableDetails={setIsEnableDetails}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default VisitorList;
