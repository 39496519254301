import { useTranslation } from "react-i18next";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../../Project/ProjectManagement/AddProject.style";
import { Stack } from "@fluentui/react";
import Layout from "../../../../../styles/Layout.styles";
import { FieldsInput } from "../../../../../components";

const DeviceName = (props) => {
  const { deviceName } = props;
  const { t } = useTranslation();
  const style = Style();
  const layout = Layout();
  return (
    <>
      <Stack className={mergeClasses(layout.w_100, layout.mr_1)}>
        <Stack className={mergeClasses(layout.textbox, layout.mb_2)}>
          <FieldsInput
            label={t("project.device_name")}
            validationMessage={props.errors?.deviceName?.message}
            id="deviceName"
            placeholder={t("project.enter_device_name")}
            name="deviceName"
            inputDefaultValue={deviceName}
            inputClassName={mergeClasses(style.textboxInput)}
            validation={props.register("deviceName", {
              required: `${t("validation_message.device_name_is_required")}`,
            })}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default DeviceName;
