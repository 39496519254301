import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { useTranslation } from "react-i18next";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";

const ClientContactName = (props) => {
  const { clientContactName } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("project.client_contact_name")}
          validationMessage={props.errors?.clientContactName?.message}
          id="clientContactName"
          placeholder={t("project.enter_contact_name")}
          name="clientContactName"
          inputDefaultValue={clientContactName}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("clientContactName", {
            required: `${t(
              "validation_message.client_contact_name_is_required"
            )}`,
          })}
        />
      </Stack>
    </>
  );
};

export default ClientContactName;
