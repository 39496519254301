import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  locationContent: {
    display: "inline-flex",
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
  locationBlock: {
    ...shorthands.borderRadius("4px"),
    "@media (max-width: 767px)": {
      width: "calc(100%)",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      width: "calc(50% - 26px)",
    },
    "@media (min-width: 1025px)": {
      width: "calc(33.33% - 26px)",
    },
    ...shorthands.margin("10px"),
  },
  header: {
    ...shorthands.padding("10px", "15px"),
  },
  locationRow: {
    ...shorthands.margin(0, "-20px", 0, "-10px"),
  },
  marginx1: {
    ...shorthands.margin("10px", 0),
  },
  primaryColor: {
    color: tokens.colorBrandBackground,
  },
  contentTabExport: {
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
});

export default style;
