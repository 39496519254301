import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setTheme } from "../reducer/theme";
import { setUser } from "../reducer/user";
import { defaultFromState } from "../../utils/defaultFromState";

export const themeApi = createApi({
  reducerPath: "themeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),
  endpoints: (builder) => ({
    getThemeList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        search = undefined,
        sortBy = undefined,
        sortType = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/client_themes",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            search: search,
            sortBy,
            sortType,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              theme: { page, itemsPerPage, search, sortBy, sortType },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              search,
              sortBy,
              sortType,
              client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setTheme({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTheme({ errorMessage: error?.data?.error }));
      }
    ),
    switchOn: buildMutationHelper(
      builder,
      ({ themeData, id }) => ({
        url: `client_themes/${id}`,
        method: "PATCH",
        body: JSON.stringify(themeData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch, getState) => {
        const {
          theme: { data: newData },
        } = getState();

        const { data } = response;

        localStorage.setItem("themeColor", response?.data?.theme?.themeCode);

        dispatch(setUser({ userTheme: response?.data?.theme?.themeCode }));

        let newDataArray = [];
        newData.map((element) => {
          if (element.id === data.id) {
            newDataArray.push({ ...element, isActive: true });
          } else {
            newDataArray.push({ ...element, isActive: false });
          }
          return "";
        });
        dispatch(setTheme({ data: newDataArray, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setTheme({ errorMessage: error?.data?.error }));
      }
    ),
    addTheme: buildMutationHelper(
      builder,
      ({ themeData }) => ({
        url: `/theme`,
        method: "POST",
        body: themeData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setTheme({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTheme({ errorMessage: error?.data?.error }));
      }
    ),
    editTheme: buildMutationHelper(
      builder,
      ({ themeData, id }) => ({
        url: `/theme/${id}`,
        method: "PATCH",
        body: JSON.stringify(themeData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setTheme({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setTheme({ errorMessage: error?.data?.error }));
      }
    ),
    getTheme: buildMutationHelper(
      builder,
      (id) => ({
        url: `theme/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setTheme({
            projectData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTheme({ errorMessage: error?.data?.error }));
      }
    ),
    deleteTheme: buildMutationHelper(
      builder,
      (id) => ({
        url: `/clientTheme/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setTheme({
            projectData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTheme({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});

export const {
  useGetThemeListMutation,
  useSwitchOnMutation,
  useAddThemeMutation,
  useEditThemeMutation,
  useDeleteThemeMutation,
  useGetThemeMutation,
} = themeApi;
