import React, { useEffect, useState } from "react";
import { FieldCombobox } from "../../../../components";
import Layout from "../../../../styles/Layout.styles";
import { useTeamsListMutation } from "../../../../store/action/teams";
import { useDispatch } from "react-redux";
import { useGetProjectTimeSheetMutation } from "../../../../store/action/time-and-attendance";
import { setTimeAndAttendance } from "../../../../store/reducer/time-and-attendance";
import { t } from "i18next";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";

const TeamFilter = (props) => {
  const {
    options,
    assignedTeamId,
    setAssignedTeamId,
    assignedProjectId,
    filterDates,
    setIsTeamLoader,
  } = props;
  const [customSearch, setCustomSearch] = useState("" | undefined);
  const [matchingOptions, setMatchingOptions] = useState([...options]);
  const [getTeamList] = useTeamsListMutation();
  const [timeAndAttendanceApi, { isLoading }] =
    useGetProjectTimeSheetMutation();
  const dispatch = useDispatch();
  const layout = Layout();

  useEffect(() => {
    setIsTeamLoader(isLoading);
    setMatchingOptions([...options]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, assignedProjectId, isLoading]);

  const [startDate, endDate] = filterDates;

  const onSelectOption = (e, data) => {
    setMatchingOptions([...options]);
    const matchingOption =
      data?.optionText && matchingOptions?.includes(data?.optionText);
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data?.optionText);
    }
    setAssignedTeamId(data?.optionValue);

    if (!isLoading) {
      timeAndAttendanceApi({
        pagination: false,
        projectId: assignedProjectId,
        teamId: data?.optionValue,
        startDate: startDate,
        endDate: endDate,
      }).then(() => {
        dispatch(
          setTimeAndAttendance({
            page: 0,
            projectId: data?.optionValue,
          })
        );
        getTeamList({
          pagination: false,
          project: data?.optionValue,
        });
      });
    }
  };

  const onChange = (event) => {
    const value = event?.target?.value?.trim();
    const matches = options?.filter((option) =>
      option?.children?.toLowerCase().includes(value?.toLowerCase())
    );
    setMatchingOptions(matches);
    if (value?.length && matches?.length < 1) {
      setCustomSearch(value);
    } else {
      setCustomSearch(undefined);
    }
  };

  if (options[0]?.children === undefined) {
    return "";
  }

  return (
    <Stack className={mergeClasses(layout.flex_column, layout.alignTop)}>
      <label className={layout.mb_1}>{t("tna_team")}</label>
      <FieldCombobox
        multiselect={false}
        name="teams"
        className={layout.w_100}
        placeholder={t("select_team")}
        defaultValue={assignedTeamId === undefined && t("select_team")}
        onOptionSelect={onSelectOption}
        onChange={onChange}
        customSearch={customSearch}
        matchingOptions={matchingOptions}
      />
    </Stack>
  );
};
export default TeamFilter;
