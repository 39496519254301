import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setBundle } from "../reducer/bundle";
import { defaultFromState } from "../../utils/defaultFromState";

export const bundleApi = createApi({
  reducerPath: "bundleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    bundleList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        pagination = undefined,
      } = {}) => {
        return {
          url: "/bundles",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,

            pagination: pagination,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              bundle: { page, itemsPerPage, pagination },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              pagination,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setBundle({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setBundle({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(setBundle({ errorMessage: error?.data?.error }));
      }
    ),
    purchaseBundle: buildMutationHelper(
      builder,
      ({ purchaseBundleData }) => ({
        url: `/client-order`,
        method: "POST",
        body: purchaseBundleData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setBundle({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setBundle({ errorMessage: error?.data?.violations }));
      }
    ),
    getClientOrder: buildMutationHelper(
      builder,
      (id) => ({
        url: `/client-order/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setBundle({
            data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setBundle({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const {
  useBundleListMutation,
  usePurchaseBundleMutation,
  useGetClientOrderMutation,
} = bundleApi;
