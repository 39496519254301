import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

import FieldTimeZoneSelect from "../../../../components/FieldComponents/FieldTimeZoneSelect";

const TimeZone = (props) => {
  const { timeZone, timeZoneSelectListData } = props;
  const style = Style();
  const { t } = useTranslation();

  if (typeof timeZoneSelectListData === "undefined" && timeZone) {
    return "";
  }
  return (
    <>
      <Stack className={style.client_field}>
        <FieldTimeZoneSelect
          label={t("project.time_zone")}
          name="timeZone"
          validationMessage={props.errors?.timeZone?.message}
          id="timeZone"
          placeholder={t("project.enter_timezone")}
          inputDefaultValue={timeZone}
          timeZoneSelectListData={timeZoneSelectListData}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("timeZone", {
            required: `${t("validation_message.timezone_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default TimeZone;
