import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setVisitor } from "../reducer/visitor";
import { defaultFromState } from "../../utils/defaultFromState";

export const visitorApi = createApi({
  reducerPath: "visitorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    visitorList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        search = undefined,
        projectLocationId = undefined,
        locationId = undefined,
        pagination = undefined,
        sortBy = undefined,
        sortType = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/tna/visitors",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            search: search,
            projectLocationId: projectLocationId,
            locationId: locationId,
            pagination: pagination,
            sortBy,
            sortType,
            visitorClient: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              visitor: {
                page,
                itemsPerPage,
                search,
                pagination,
                sortBy,
                sortType,
                visitorClient,
              },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              search,
              pagination,
              sortBy,
              sortType,
              visitorClient,
            };
            let stateParamsProject = {
              page,
              itemsPerPage,
              search,
              pagination,
              projectLocationId,
              locationId,
              sortBy,
              sortType,
              visitorClient,
            };
            return defaultFromState(
              projectLocationId || locationId
                ? stateParamsProject
                : stateParams,
              args
            );
          },
        };
      },
      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setVisitor({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setVisitor({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(setVisitor({ errorMessage: error?.data?.error }));
      }
    ),
    getVisitor: buildMutationHelper(
      builder,
      (id) => ({
        url: `/tna/visitor/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setVisitor({
            visitorData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setVisitor({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const { useVisitorListMutation, useGetVisitorMutation } = visitorApi;
