/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { merge, Stack } from "@fluentui/react";
import { loadStripe } from "@stripe/stripe-js";
import { makeStyles, shorthands } from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import { Button, mergeClasses } from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { STRIPE_PUBLIC_KEY } from "../../utils/constants";
import Style from "./style";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
  },
});

const CheckoutForm = () => {
  const layout = Layout();
  const stripe = useStripe();
  const elements = useElements();
  const styles = useStyles();
  const style = Style();

  const options = {
    mode: "setup",
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const clientOrder = useSelector((state) => state?.license?.clientOrder);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    try {
      // Create the PaymentIntent and obtain clientSecret from your server endpoint
      // const res = await fetch("/create-intent", {
      //   method: "POST",
      // });

      let stripeSetupIntentClientSecret =
        clientOrder?.data?.clientOrderPaymentMetadatas?.[0]
          ?.stripeSetupIntentClientSecret;

      // const { client_secret: clientSecret } = await res.json();
      // console.log(clientOrder);
      // Confirm the payment using the PaymentIntent's client secret
      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret: stripeSetupIntentClientSecret,
        confirmParams: {
          return_url: clientOrder?.data?.returnUrl,
        },
      });

      if (error) {
        // Show error to your customer
        setErrorMessage(error.message);
        return;
      }

      // If payment confirmation succeeds, create the PaymentMethod from the card element
      const cardElement = elements.getElement(CardElement);
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
      if (paymentMethodError) {
        // Show error to your customer
        setErrorMessage(paymentMethodError.message);
        return;
      }
      // Payment is successful
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error:", error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && (
        <Stack className={mergeClasses(style.textDanger, "border4")}>
          {errorMessage}
        </Stack>
      )}
      <PaymentElement stripe={stripePromise} />
      <button
        disabled={!stripe}
        className={mergeClasses("border-none", layout.mt_2, layout.p_0)}
      >
        <Button appearance="primary">Submit</Button>
      </button>
      {/* Show error message to your customers */}
    </form>
  );
};

export default CheckoutForm;
