import CustomFieldsInput from "../../../../../components/FieldComponents/CustomFieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const AccountLastName = (props) => {
  const { onChange, value } = props;
  const style = Style();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={style.client_field}>
        <CustomFieldsInput
          label={t("last_name")}
          validationMessage={props.errors?.accountLastName?.message}
          id="accountLastName"
          placeholder={t("enter_last_name")}
          name="accountLastName"
          value={value}
          onChange={onChange}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("accountLastName", {
            required: `${t("validation_message.lastname_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default AccountLastName;
