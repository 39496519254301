import * as React from "react";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import Style from "./Style";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchBox } from "@fluentui/react-search-preview";
import Layout from "../../../styles/Layout.styles";
import { setProject } from "../../../store/reducer/project";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import StatusChangeComponent from "./StatusChangeComponent";

const ProjectFilter = ({
  projectId,
  getProjectListApi,
  setInitialLoad,
  role,
}) => {
  const style = Style();
  const layout = Layout();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectList = useSelector((state) => state.project);
  const onChange = (data) => {
    dispatch(setProject({ page: 0, search: data }));
    getProjectListApi();
    setInitialLoad(false);
  };

  return (
    <>
      <Stack className={mergeClasses(layout.projectFilter)}>
        {!projectId && (
          <SearchBox
            placeholder={t("search")}
            className={mergeClasses(
              layout.headerSearch,
              "headerSearch",
              "no-reset"
            )}
            defaultValue={projectList?.search}
            iconProps={{
              iconName: "Search",
              styles: { root: { right: "8px" } },
            }}
            onChange={(e) => onChange(e?.target?.value)}
          />
        )}
        <Stack
          className={mergeClasses(
            layout.d_flex,
            layout.flex_row,
            layout.alignCenter
          )}
        >
          {!projectId && (
            <StatusChangeComponent getProjectListApi={getProjectListApi} />
          )}
          {role?.size &&
          role?.has("project_create") &&
          localStorage.getItem("canCreateProject") === "true" ? (
            <Link
              to={
                projectId
                  ? `/project-list/${projectId}/edit`
                  : "/project-list/add"
              }
            >
              <Button
                appearance="primary"
                className={mergeClasses(style.prodBtn, "custom-primary")}
              >
                {projectId ? t("project.edit") : t("project.add")}
              </Button>
            </Link>
          ) : (
            ""
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectFilter;
