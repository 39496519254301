import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { FormButton, Breadcrumb, Form } from "../../../components";
import Style from "./AddProject.style";
import {
  useEditProjectMutation,
  useGetProjectMutation,
  useAddProjectMutation,
} from "../../../store/action/project";
import Layout from "../../../styles/Layout.styles";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Title from "./Form/Title";
import ProjectCode from "./Form/ProjectCode";
import Address from "./Form/Address";
import Builder from "./Form/Builder";
import ClientContactName from "./Form/ClientContactName";
import ClientContactNumber from "./Form/ClientContactNumber";
import ContractValue from "./Form/ContractValue";
import SitePhoneNumber from "./Form/SitePhoneNumber";
import ProjectManagementTeam from "./Form/ProjectManagementTeam";
import DesignTeam from "./Form/DesignTeam";
import staticStyle from "./Style";
import SiteContact from "./Form/SiteContact";
import CommencementDate from "./Form/CommencementDate";
import CompletionDate from "./Form/CompletionDate";
import Branch from "./Form/Branch";
import Description from "./Form/Description";
import ProjectManager from "./Form/ProjectManager";
import Country from "./Form/Country";
import State from "./Form/State";
import TimeZone from "./Form/TimeZone";
import Tags from "./Form/Tags";
import City from "./Form/City";
import { trailWhiteSpace } from "../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../utils/errorHandler";
import WorkingDuration from "./Form/WorkingDuration";
import { setProject } from "../../../store/reducer/project";
import { useDispatch } from "react-redux";

const ProjectForm = (props) => {
  const { id } = props;
  const [initialLoad, setInitialLoad] = useState(true);
  const [addProjectApi, { isLoading: isAddLoading }] = useAddProjectMutation();
  const [getProjectApi, { isLoading: isGetLoading }] = useGetProjectMutation();
  const [editProjectApi, { isLoading: isEditLoading }] =
    useEditProjectMutation();
  const [editData, setEditData] = useState({});
  const [validationMsg, setValidationMsg] = useState(false);
  const [timeZoneSelectListData, setTimeZoneSelectListData] = useState();
  const [inputValue, setInput] = useState(1);
  const [visibleTags, setVisibleTags] = useState([]);
  const { t } = useTranslation();
  const style = Style();
  const navigate = useNavigate();
  const layout = Layout();
  const dispatch = useDispatch();
  staticStyle();

  const breadcrumbData = [
    {
      title: `${t("project.project")}`,
      url: "/project-list",
    },
    {
      title: id ? `${t("edit_project")}` : `${t("add_project")}`,
    },
  ];

  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getProjectApi(props?.id).then((res) => {
            setEditData(res);
            setVisibleTags(res?.data?.tags);
          });
        }
      }
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isGetLoading]);

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    setError,
    resetField,
  } = useForm();

  const onSubmit = (projectInfo) => {
    if (inputValue === 0) {
      setValidationMsg(true);
    }
    let formData = trailWhiteSpace(projectInfo);
    const newProjectData = {
      projectCode: formData?.projectCode,
      title: formData?.title,
      builder: formData?.builder,
      designTeam: formData?.designTeam,
      siteContact: formData?.siteContact,
      sitePhoneNumber: formData?.sitePhoneNumber,
      sitePhonePrefix: formData?.sitePhonePrefix,
      clientContactName: formData?.clientContactName,
      clientContactNumber: formData?.clientContactNumber,
      clientContactPrefix: formData?.clientContactPrefix,
      commencementDate: formData?.commencementDate,
      completionDate: formData?.completionDate,
      contractValue: parseInt(
        formData?.contractValue ? formData?.contractValue : 0
      ),
      branch: formData?.branch,
      projectManagementTeam: formData?.projectManagementTeam,
      address: formData?.address,
      client: formData?.client,
      tags: visibleTags,
      timezone: formData?.timeZone,
      description: formData?.description,
      country: formData?.country,
      state: formData?.state,
      city: formData?.city,
      projectManager: formData?.projectManager,
      numberOfHours: parseInt(inputValue),
    };

    if (id) {
      if (!isEditLoading && inputValue !== 0) {
        editProjectApi({ projectData: newProjectData, id: id }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res.error) {
            navigate("/project-list");
          }
        });
      }
    } else {
      if (!isAddLoading && inputValue !== 0) {
        addProjectApi({ projectData: newProjectData }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res.error) {
            navigate("/project-list");
            dispatch(setProject({ isActive: "true", status: undefined }));
          }
        });
      }
    }
  };
  if (id && !editData.data) {
    return null;
  }
  const commonProps = { register, errors, setValue, resetField };

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack>
        <Form onSubmit={handleSubmit(onSubmit)} className={layout.d_flex}>
          <Stack
            className={mergeClasses(
              style.clientContent,
              "clientContent",
              layout.borderRadiusMedium
            )}
          >
            <ProjectCode
              {...commonProps}
              projectCode={editData?.data?.projectCode}
            />
            <Title {...commonProps} title={editData?.data?.title} />
            <Builder {...commonProps} builder={editData?.data?.builder} />
            <DesignTeam
              {...commonProps}
              designTeam={editData?.data?.designTeam}
            />
            <SiteContact
              {...commonProps}
              siteContact={editData?.data?.siteContact}
            />
            <SitePhoneNumber
              {...commonProps}
              sitePhoneNumber={editData?.data?.sitePhoneNumber}
              sitePhonePrefix={editData?.data?.sitePhonePrefix}
            />

            <ClientContactName
              {...commonProps}
              clientContactName={editData?.data?.clientContactName}
            />
            <ContractValue
              {...commonProps}
              contractValue={editData?.data?.contractValue}
            />
            <ClientContactNumber
              {...commonProps}
              clientContactNumber={editData?.data?.clientContactNumber}
              clientContactPrefix={editData?.data?.clientContactPrefix}
            />

            <CompletionDate
              control={control}
              {...commonProps}
              completionDate={editData?.data?.completionDate}
            />
            <CommencementDate
              control={control}
              {...commonProps}
              commencementDate={editData?.data?.commencementDate}
            />
            <ProjectManager
              {...commonProps}
              projectManager={editData?.data?.projectManager}
            />
            <Branch {...commonProps} branch={editData?.data?.branch} />
            <Country
              {...commonProps}
              country={editData?.data?.country}
              setTimeZoneSelectListData={setTimeZoneSelectListData}
            />

            <Address {...commonProps} address={editData?.data?.address} />
            <City {...commonProps} city={editData?.data?.city} />

            <State {...commonProps} state={editData?.data?.state} />

            <ProjectManagementTeam
              {...commonProps}
              projectManagementTeam={editData?.data?.projectManagementTeam}
            />
            <TimeZone
              {...commonProps}
              timeZone={editData?.data?.timezone}
              timeZoneSelectListData={timeZoneSelectListData}
            />

            <Description
              {...commonProps}
              description={editData?.data?.description}
            />
            <Tags
              {...commonProps}
              visibleTags={visibleTags}
              setVisibleTags={setVisibleTags}
            />
            <WorkingDuration
              {...commonProps}
              inputValue={inputValue}
              setInput={setInput}
              editWorkingDuration={editData?.data?.numberOfHours}
              validationMsg={validationMsg}
              setValidationMsg={setValidationMsg}
            />
            <Stack className={style.formfooter}>
              <FormButton
                type="submit"
                appearance="primary"
                loading={isEditLoading || isAddLoading}
                className={mergeClasses(style.customBtn, "custom-primary")}
              >
                {t("project.save")}
              </FormButton>
              <Link
                to="/project-list"
                appearance="primary"
                className={mergeClasses(style.customBtn)}
              >
                <FormButton className={layout.min150}>
                  {t("project.cancel")}
                </FormButton>
              </Link>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </>
  );
};

export default ProjectForm;
