import React, { useState } from "react";
import {
  Toast,
  ToastTitle,
  Toaster,
  mergeClasses,
  useId,
  useToastController,
} from "@fluentui/react-components";
import Layout from "../../../../styles/Layout.styles";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { setTimeAndAttendance } from "../../../../store/reducer/time-and-attendance";
import "react-datepicker/dist/react-datepicker.css";
import { generateResult } from "../../../../utils/timesheet";
import { useGetProjectTimeSheetMutation } from "../../../../store/action/time-and-attendance";
import { format } from "date-fns";
import { t } from "i18next";
import { ReactComponent as DateIcon } from "../../../../images/dateIcon.svg";
import { Stack } from "@fluentui/react";
import { useStyle, staticStyle } from "../../Style";
import { dateFormatted } from "../../../../utils/convert-date-time";
import { useEffect } from "react";

const DateFilter = (props) => {
  const { assignedProjectId, assignedTeamId, setFilterDate, setIsDateLoader } =
    props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();
  const [timeAndAttendanceApi, { isLoading }] =
    useGetProjectTimeSheetMutation();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const layout = Layout();
  const currentDate = new Date();
  const style = useStyle();
  staticStyle();

  useEffect(() => {
    setIsDateLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const notify = () => {
    dispatchToast(
      <Toast>
        <ToastTitle>{t("datepicker_max_92_warning")}</ToastTitle>
      </Toast>,
      { intent: "error" }
    );
  };

  const setHeaderRange = (dates) => {
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      const diffInDays = Math.ceil(
        (endDate - startDate) / (1000 * 60 * 60 * 24)
      );
      if (diffInDays <= 91) {
        const timesheetHeader = generateResult(startDate, endDate, true);
        dispatch(setTimeAndAttendance({ headerData: timesheetHeader }));

        startDate.setHours(currentDate.getHours());
        startDate.setMinutes(currentDate.getMinutes());
        startDate.setSeconds(currentDate.getSeconds());

        endDate.setHours(currentDate.getHours());
        endDate.setMinutes(currentDate.getMinutes());
        endDate.setSeconds(currentDate.getSeconds());

        let start = dateFormatted(startDate, "UTC");
        let end = dateFormatted(endDate, "UTC");

        if (!isLoading) {
          timeAndAttendanceApi({
            pagination: false,
            projectId: assignedProjectId,
            teamId: assignedTeamId,
            startDate: start,
            endDate: end,
          });
          setFilterDate([start, end]);
        }
      } else {
        notify();
      }
    } else {
      const timesheetHeader = generateResult(new Date(), new Date());
      dispatch(setTimeAndAttendance({ headerData: timesheetHeader }));

      let start = startDate && format(startDate, "yyyy-MM-dd");
      let end = endDate && format(endDate, "yyyy-MM-dd");
      timeAndAttendanceApi({
        pagination: false,
        projectId: assignedProjectId,
        teamId: assignedTeamId,
        startDate: startDate && format(startDate, "yyyy-MM-dd"),
        endDate: endDate && format(endDate, "yyyy-MM-dd"),
      }).then(() => {
        dispatch(
          setTimeAndAttendance({
            page: 0,
            projectId: assignedProjectId,
          })
        );
      });
      setFilterDate([start, end]);
    }
  };

  return (
    <Stack className={mergeClasses(layout.flex_column, layout.alignTop)}>
      <label className={layout.mb_1}>{t("tna_date")}</label>
      <React.Fragment>
        <Toaster position="top" timeout={3000} toasterId={toasterId} />

        <DatePicker
          showIcon
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          className={style.timesheetDatepicker}
          placeholderText={t("select_dates")}
          onChange={(update) => {
            setDateRange(update);
            setHeaderRange(update);
          }}
          icon={<DateIcon height={20} width={20} />}
          isClearable={true}
        />
      </React.Fragment>
    </Stack>
  );
};
export default DateFilter;
