import { format } from "date-fns";
import {
  zonedTimeToUtc,
  formatInTimeZone,
  getTimezoneOffset,
} from "date-fns-tz";

export const dateFormatWithTime = (date) => {
  if (date === undefined || date === null) return null;

  const utcDate = zonedTimeToUtc(date, "Asia/Dhaka");

  let formattedDate = format(utcDate, "hh:mm a");

  return formattedDate;
};

export const timeFormatted = (date, timeZone) => {
  if (date === undefined || date === null) return null;
  const formattedDate = formatInTimeZone(date, timeZone, "HH:mm");
  return formattedDate;
};

export const dateFormatted = (date, timeZone) => {
  if (date === undefined || date === null) return null;
  const formattedDate = formatInTimeZone(date, timeZone, "yyyy/MM/dd");
  return formattedDate;
};

export const dateFormattedDash = (date, timeZone) => {
  if (date === undefined || date === null) return null;
  const formattedDate = formatInTimeZone(date, timeZone, "yyyy-MM-dd");
  return formattedDate;
};

export const dateTimeFormatted = (date, timeZone) => {
  if (date === undefined || date === null) return null;
  const formattedDate = formatInTimeZone(date, timeZone, "yyyy/MM/dd HH:mm");
  return formattedDate;
};

export const getTimezoneDate = (date, timeZone) => {
  if (date === undefined || date === null) return null;
  const formattedDate = formatInTimeZone(date, timeZone, "dd");
  return formattedDate;
};

export const dateObjFormatted = (date, timeZone) => {
  if (date === undefined || date === null) return null;
  const formattedDate = formatInTimeZone(date, timeZone, "yyyy-MM-dd'T'HH:mm");
  return formattedDate;
};

export const getTimezoneOffsetData = (timeZone) => {
  const currentDate = new Date();
  const offsetInMinutes = getTimezoneOffset(timeZone, currentDate);
  const hours = offsetInMinutes / 3600000;
  return ` (UTC ${offsetInMinutes >= 0 ? "+" : ""}${hours} hours) `;
};

export const dateTimeZone = (date) => {
  if (date === undefined || date === null) return null;

  const utcDate = formatInTimeZone(date, "Asia/Dhaka", "hh:mm a");

  return utcDate;
};
