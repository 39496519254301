import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import InvoiceLogo from "./InvoiceSections/InvoiceLogo";
import InvoiceNo from "./InvoiceSections/InvoiceNo";
import BillTo from "./InvoiceSections/BillTo";
import DefaultAddress from "./InvoiceSections/DefaultAddress";
import InvoiceItemsTable from "./InvoiceSections/InvoiceItemsTable";
import Layout from "../../../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";
import { t } from "i18next";
import { dateFormatted } from "../../../../utils/convert-date-time";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
  body: {
    color: "black",
  },
});

// React component to render PDF
const PDFDocument = ({ data }) => {
  return (
    <Document style={styles.body}>
      <Page size="A4" style={styles.page}>
        <InvoiceLogo invoice={data} />
        <InvoiceNo
          invoice={data}
          date={
            data?.createdAt &&
            dateFormatted(
              data?.createdAt,
              data?.clientOrder?.client?.accountPerson?.timezone
            )
          }
        />
        <DefaultAddress />
        <BillTo invoice={data} />
        <InvoiceItemsTable invoice={data} />
      </Page>
    </Document>
  );
};

const Pdf = ({ id, data }) => {
  const layout = Layout();
  return (
    <div>
      <PDFDownloadLink
        document={<PDFDocument data={data} />}
        className={mergeClasses(layout.d_flex, layout.justifyContent)}
        fileName="invoice.pdf"
      >
        {({ loading }) => (loading ? t("loading_document") : t("download_pdf"))}
      </PDFDownloadLink>
    </div>
  );
};

export default Pdf;
