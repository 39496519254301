import { React, useState } from "react";
import { mergeClasses, Button } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import Style from "./Style";
import { SearchBox } from "@fluentui/react-search-preview";
import { useTranslation } from "react-i18next";
import Layout from "../../../../styles/Layout.styles";
import ThemeManagementWrapper from "./ThemeManagement/ThemeManagementWrapper";
import { setTheme } from "../../../../store/reducer/theme";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const ThemeFilter = (props) => {
  const { getThemeApi, setInitialLoad, role } = props;
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const themeList = useSelector((state) => state?.theme);

  const onChange = (data) => {
    dispatch(setTheme({ page: 0, search: data }));
    getThemeApi();
    setInitialLoad(false);
  };

  return (
    <>
      <Stack
        className={mergeClasses(
          layout.projectFilter,
          style.themeFilter,
          "no-clear"
        )}
      >
        <Stack>
          <SearchBox
            placeholder={t("search")}
            className={mergeClasses(
              layout.headerSearch,
              style.themeSearch,
              "headerSearch"
            )}
            defaultValue={themeList?.search}
            onChange={(e) => onChange(e.target.value)}
            iconProps={{
              iconName: "Search",
              styles: { root: { right: "8px" } },
            }}
          />
        </Stack>
        {isOpenDialog && (
          <ThemeManagementWrapper
            isOpenDialog={isOpenDialog}
            setIsOpenDialog={setIsOpenDialog}
          />
        )}
        {role?.has("client_theme_create") && (
          <Button
            className={mergeClasses(
              layout.prodBtn,
              "custom-primary",
              layout.max150
            )}
            appearance="primary"
            onClick={() => setIsOpenDialog(true)}
          >
            {t("add_theme")}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default ThemeFilter;
