import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from "./components/Main";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Forgot from "./pages/Forgot";
import PrivateRoutes from "./PrivateRoutes";
import { FluentProvider } from "@fluentui/react-components";
import "./index.css";
import { BASE_COLOR } from "./utils/constants";
import "./i18n";
import { useSelector } from "react-redux";
import { themeGenerator } from "./utils/themeGenerator";
import { tokenExpiration } from "./utils/tokenExpiration";

export default function App() {
  const userData = useSelector((state) => state.user);

  const [activeTheme, setActiveTheme] = useState(
    localStorage.getItem("themeColor")
      ? localStorage.getItem("themeColor")
      : BASE_COLOR
  );

  const [lightDarkToggle, setLightDarkToggle] = useState(
    localStorage.getItem("lightDarkToggle") === "true" ? true : false
  );

  useEffect(() => {
    if (userData?.isDarkMode !== undefined) {
      setLightDarkToggle(userData?.isDarkMode);
    }
  }, [userData?.isDarkMode]);

  const { darkTheme, lightTheme } = themeGenerator(activeTheme);

  //remove old token data
  tokenExpiration();

  return (
    <FluentProvider theme={lightDarkToggle ? darkTheme : lightTheme}>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route element={<PrivateRoutes />}>
              <Route
                path="/*"
                element={
                  <Main
                    setActiveTheme={setActiveTheme}
                    setLightDarkToggle={setLightDarkToggle}
                  />
                }
              />
            </Route>
            <Route
              path="/login"
              element={<Login setActiveTheme={setActiveTheme} />}
            />
            <Route path="/reset/:token" element={<Reset />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="" element={<Navigate to="/project-list" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </FluentProvider>
  );
}
