import * as React from "react";
import { Field, Select, mergeClasses } from "@fluentui/react-components";
import { useGetPhonePrefixMutation } from "../../store/action/common";
import { useState } from "react";
import { useEffect } from "react";

const FieldSelectPhoneCode = ({
  label,
  size = "large",
  placeholder,
  className,
  name,
  inputId,
  inputDefaultValue,
  options,
  inputClassName,
  noRequired,
  validation,
  validationMessage,
  onClick,
  validationMessageIcon = "",
  disabled,
}) => {
  const [getPhonePrefix] = useGetPhonePrefixMutation();
  const [phonePrefixList, setPhonePrefixList] = useState([]);

  useEffect(() => {
    getPhonePrefix().then((res) => {
      const listData = Object.entries(res?.data)?.map(([id, value]) => ({
        key: id,
        value: value,
      }));
      setPhonePrefixList(listData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (phonePrefixList.length <= 0) {
    return "";
  }

  return (
    <Field
      label={label}
      className={mergeClasses("custom-label", className)}
      size={size}
      validationMessageIcon={validationMessageIcon}
      validationMessage={validationMessage}
      required={noRequired ? undefined : true}
    >
      <Select
        id={inputId}
        className={mergeClasses(inputClassName)}
        name={name}
        defaultValue={inputDefaultValue}
        placeholder={placeholder}
        onClick={onClick}
        disabled={disabled}
        {...validation}
      >
        {phonePrefixList?.map((option, i) => (
          <option key={i} value={option?.value}>
            {option?.key} ({option?.value})
          </option>
        ))}
      </Select>
    </Field>
  );
};

export default FieldSelectPhoneCode;
