export const roleList = () => {
  const roles = {
    ROLE_SUPER_ADMIN: {
      permissions: [
        "client_create",
        "client_list",
        "client_edit",
        "client_view",
        // "client_theme_create",
        // "client_theme_list",
        // "client_theme_edit",
        // "client_theme_view",
        "project_summary_view",
        "system_user_create",
        "system_user_list",
        "system_user_edit",
        "system_user_view",
        "project_list",
        "project_view",
        "bundle_list",
        "bundle_view",
        "license_list",
        "billing_list",
        "assigned_license_list",
        "dashboard_view",
        "reporting_view",
        "assign_organization",
        "system_end_user_list",
        "system_end_user_view",
        "project_tna_list",
        "project_tna_view",
        "visitor_view",
        "visitor_list",
        "view_client_dropdown",
      ],
    },
    ROLE_STANDARD_ADMIN: {
      permissions: [
        "client_create",
        "client_list",
        "client_edit",
        "client_view",
        "project_summary_view",
        "dashboard_view",
        "reporting_view",
        "system_user_list",
        // "client_theme_create",
        // "client_theme_list",
        // "client_theme_edit",
        // "client_theme_view",
        "system_user_view",
        "project_list",
        "project_view",
        "bundle_list",
        "bundle_view",
        "license_list",
        "billing_list",
        "assigned_license_list",
        "assign_organization",
        "view_client_dropdown",
      ],
    },
    ROLE_CLIENT_ADMIN: {
      permissions: [
        "client_edit",
        "client_view",
        "client_theme_create",
        "project_summary_view",
        "client_theme_list",
        "client_theme_edit",
        "client_theme_view",
        "question_bank_create",
        "question_bank_list",
        "question_bank_edit",
        "question_bank_view",
        "system_user_create",
        "system_user_list",
        "system_user_edit",
        "system_user_view",
        "system_end_user_view",
        "project_list",
        "project_view",
        "project_teams_view",
        "project_location_view",
        "device_view",
        "bundle_list",
        "bundle_view",
        "license_list",
        "billing_list",
        "license_purchase",
        "license_assign",
        "license_delete",
        "assigned_license_list",
        "dashboard_view",
        "reporting_view",
        "edit_profile",
        "edit_organization_profile",
        "external_source",
      ],
    },
    ROLE_MODULE_ADMIN: {
      permissions: [
        // "system_user_create",
        // "system_user_list",
        // "system_user_edit",
        "system_user_view",
        "system_end_user_create",
        "system_end_user_list",
        "system_end_user_edit",
        "system_end_user_view",
        "system_end_user_import",
        "project_list",
        "project_view",
        "project_summary_view",
        "assign_teams_users",
        "assign_project_users",
        "project_teams_create",
        "project_teams_list",
        "project_teams_edit",
        "project_teams_view",
        "project_location_create",
        "project_location_list",
        "project_location_edit",
        "project_location_view",
        "device_create",
        "device_list",
        "device_edit",
        "device_view",
        "dashboard_view",
        "reporting_view",
        "edit_profile",
        "end_user_license_count",
        // "project_tna_list",
        // "project_tna_view",
        // "visitor_view",
        // "visitor_list",
      ],
    },
    ROLE_PROJECT_ADMIN: {
      permissions: [
        // "system_user_create",
        //"system_user_list",
        //"system_user_edit",
        //"system_user_view",
        "system_end_user_view",
        // "project_create",
        // "project_edit",
        "project_list",
        "project_active_inactive",
        "project_status_update",
        "project_view",
        "project_summary_view",
        //"assign_teams_users",
        "assign_project_users",
        "project_teams_create",
        "project_teams_list",
        "project_teams_edit",
        "project_teams_view",
        "project_location_create",
        "project_location_list",
        "project_location_edit",
        "project_location_view",
        "device_create",
        "device_list",
        "device_edit",
        "device_view",
        "dashboard_view",
        "reporting_view",
        "edit_profile",
        // "project_tna_list",
        // "project_tna_view",
        // "visitor_view",
        // "visitor_list",
      ],
    },
    ROLE_TNA_LICENSED_USER: {
      permissions: [
        "system_user_view",
        "system_end_user_view",
        "project_create",
        "project_edit",
        "project_list",
        "project_view",
        // "project_teams_list",
        "project_teams_view",
        "project_location_view",
        "device_view",
        "project_tna_list",
        "project_tna_view",
        "project_tna_approve",
        "project_tna_reject",
        "visitor_view",
        "visitor_list",
        "dashboard_view",
        "reporting_view",
        "edit_profile",
        "can_add_time_log",
        "can_edit_time_log",
        "can_delete_time_log",
      ],
    },
    ROLE_END_USER: {
      permissions: [
        "system_end_user_edit",
        "system_end_user_view",
        "project_location_view",
        "device_view",
      ],
    },
    ROLE_STANDARD_USER: {
      permissions: ["edit_profile"],
    },
  };
  return roles;
};
