import {
  tokens,
  shorthands,
  makeStaticStyles,
} from "@fluentui/react-components";

const calendarStaticStyle = makeStaticStyles({
  ".react-datepicker__close-icon::after": {
    backgroundColor: tokens.colorBrandBackgroundPressed,
    color: tokens.colorStrokeFocus1,
  },

  ".react-datepicker-popper .react-datepicker__day.react-datepicker__day--in-selecting-range":
    {
      backgroundColor: tokens.colorBrandBackgroundPressed,
      color: tokens.colorStrokeFocus1,
    },
  ".react-datepicker-popper .react-datepicker__day--in-range": {
    backgroundColor: tokens.colorBrandBackgroundPressed,
    color: tokens.colorStrokeFocus1,
  },
  ".react-datepicker-wrapper input": {
    ...shorthands.padding("7.5px"),
    width: "250px",
    ...shorthands.border(0),
  },

  ".react-datepicker__input-container": {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    borderRadius: tokens.borderRadiusMedium,
  },

  ".react-datepicker__input-container .react-datepicker__calendar-icon": {
    width: "18px",
    height: "18px",
    ...shorthands.padding("0.4rem"),
    right: "5px",
    left: "auto",
  },
  ".react-datepicker-wrapper .react-datepicker__close-icon": {
    right: "30px",
    left: "auto",
  },
  ".fui-CalendarDayGrid__hoverStyle .fui-CalendarDayGrid__dayButton span": {
    color: tokens.colorNeutralForegroundInvertedHover,
  },
  ".fui-CalendarDayGrid__daySelected .fui-CalendarDayGrid__dayButton span": {
    color: tokens.colorNeutralForegroundInvertedHover,
  },
});
export { calendarStaticStyle };
