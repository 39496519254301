import {
  Combobox,
  Divider,
  mergeClasses,
  Option,
  Text,
} from "@fluentui/react-components";
import { t } from "i18next";
import Layout from "../../../styles/Layout.styles";
import { Stack } from "@fluentui/react";
import { useState, useEffect } from "react";
import Styles from "../../ProjectUser/style";
import Style from "../ExternalSource.style";

const Businesses = (props) => {
  const {
    businessOptions,
    setExternalDepartmentArray,
    editExternalSourceId,
    setSelectedOptionId,
    selectedOptionId,
    errorHandle,
    setErrorHandle,
  } = props;

  const layout = Layout();
  const styles = Styles();
  const style = Style();
  const [value, setValue] = useState("");
  const [customSearch, setCustomSearch] = useState("" | undefined);
  const [matchingOptions, setMatchingOptions] = useState(businessOptions);
  const [selectedBusinessOptionId, setSelectedBusinessOptionId] = useState([]);

  useEffect(() => {
    setMatchingOptions([...businessOptions]);
  }, [businessOptions]);

  const onSelectOption = (e, data) => {
    setSelectedBusinessOptionId(data?.selectedOptions);

    editExternalSourceId && setSelectedOptionId(data?.selectedOptions);

    const chosenArray = businessOptions?.filter((item) =>
      [...new Set(data?.selectedOptions)]?.includes(item?.value)
    );
    const selectedOptions = chosenArray?.map((item) => ({
      id: item.value,
      name: item.children,
      contactName: item.contactName,
    }));

    // setChosenArrayOptions(chosenArray);

    setExternalDepartmentArray(selectedOptions);

    const matchingOption =
      data?.optionText && matchingOptions?.includes(data?.optionText);
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data?.optionText);
    }
    !editExternalSourceId && setErrorHandle(data?.selectedOptions);
  };

  const chosenArray = businessOptions?.filter((item) =>
    [
      ...new Set(
        editExternalSourceId
          ? selectedOptionId?.map((i) => Number(i))
          : selectedBusinessOptionId
      ),
    ]?.includes(item?.value)
  );

  const onChange = (event) => {
    const value = event?.target?.value?.trim();
    const matches = businessOptions?.filter((option) =>
      option?.children?.toLowerCase().includes(value?.toLowerCase())
    );
    setMatchingOptions(matches);
    if (value?.length && matches?.length < 1) {
      setCustomSearch(value);
    } else {
      setCustomSearch(undefined);
    }
  };

  const onInput = (ev) => {
    setValue(ev.target.value);
  };

  return (
    <>
      <Stack className={style.client_field}>
        <Text className={mergeClasses(layout.mb_1)}>
          Businesses <a className={layout.durationText}>*</a>
        </Text>
        <Stack>
          <Stack className={mergeClasses(styles.root)}>
            <Combobox
              multiselect={true}
              placeholder={t("search")}
              className={mergeClasses(layout.w_100)}
              onOptionSelect={onSelectOption}
              selectedOptions={[
                ...new Set(
                  editExternalSourceId
                    ? selectedOptionId?.map((i) => Number(i))
                    : selectedBusinessOptionId
                ),
              ]}
              onChange={onChange}
              matchingOptions={matchingOptions}
              value={value}
              onInput={onInput}
            >
              {!matchingOptions && customSearch && (
                <Option key="freeform" text={customSearch}></Option>
              )}
              {matchingOptions?.map((option, key) => (
                <Option
                  key={key}
                  className="field-option"
                  value={option?.value}
                >
                  {option?.children}
                </Option>
              ))}
            </Combobox>
          </Stack>
        </Stack>
        <Text
          className={mergeClasses(
            layout.fontWeightBold,
            layout.color616161,
            layout.mt_1_0,
            layout.mb_1_0
          )}
        >
          {chosenArray?.length > 0
            ? t("externalSources.selected_business")
            : ""}
        </Text>
        <Stack className={mergeClasses(Layout.mx_over)}>
          {chosenArray?.map((item, key) => (
            <Stack key={key}>
              <Divider />
              <Stack
                key={key}
                className={mergeClasses(layout.mt_1_0, layout.mb_1_0)}
              >
                {item.children}
              </Stack>
            </Stack>
          ))}
        </Stack>

        {editExternalSourceId
          ? chosenArray?.length === 0
          : errorHandle?.length === 0 && (
              <Text
                className={mergeClasses(layout.mb_0_5, layout.colorEbc2f32)}
              >
                {t("please_select_business")}
              </Text>
            )}
      </Stack>
    </>
  );
};
export default Businesses;
