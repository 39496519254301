import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import { blockInvalidChar } from "../../../../utils/blockInvalidChar";

const ContractValue = (props) => {
  const { contractValue } = props;
  const { t } = useTranslation();
  const style = Style();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("project.contract_value")}
          inputType="number"
          onKeyDown={blockInvalidChar}
          validationMessage={props.errors?.contractValue?.message}
          id="contractValue"
          placeholder={t("project.enter_contract_value")}
          name="contractValue"
          inputDefaultValue={contractValue}
          inputClassName={mergeClasses(style.textboxInput)}
          noRequired
          validation={props.register("contractValue", {
            min: {
              value: 0,
              message: `${t("validation_message.positive_value")}`,
            },
          })}
        />
      </Stack>
    </>
  );
};

export default ContractValue;
