import React from "react";
import ViewUserForm from "./ViewUserForm";
import { useParams } from "react-router-dom";

const ViewUser = () => {
  const { id } = useParams();
  return <ViewUserForm id={id} />;
};

export default ViewUser;
