import * as React from "react";
import { Tab, TabList, mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import Layout from "../../styles/Layout.styles";

const TabListComponent = ({
  selectedValue,
  tabListOption,
  isFilter = null,
  displayPermission = false,
  stackClass,
  tabClass,
  tabListClass,
  tabListOptionClass,
  onTabSelect,
  role,
}) => {
  const layout = Layout();
  return (
    <>
      <Stack
        className={mergeClasses(
          layout.flex_row,
          layout.flex_between,
          stackClass
        )}
      >
        <TabList
          selectedValue={selectedValue}
          className={mergeClasses(layout.mb_2, tabListClass)}
          onTabSelect={onTabSelect}
        >
          {tabListOption?.map((item, key) => {
            return displayPermission ? (
              item?.visible && (
                <Tab
                  key={key}
                  id={item?.id}
                  value={item?.id}
                  className={mergeClasses(
                    layout.pt_0,
                    `rm_pl_${key}`,
                    tabClass
                  )}
                >
                  {item?.label}
                </Tab>
              )
            ) : (
              <Tab
                key={key}
                id={item?.id}
                value={item?.id}
                className={mergeClasses(layout.pt_0, `rm_pl_${key}`, tabClass)}
              >
                {item?.label}
              </Tab>
            );
          })}
        </TabList>
        {isFilter && isFilter}
      </Stack>

      {tabListOption?.map((item, key) => {
        return (
          <Stack key={key} className={mergeClasses(key, tabListOptionClass)}>
            {selectedValue === item?.id && item?.component}
          </Stack>
        );
      })}
    </>
  );
};

export default TabListComponent;
