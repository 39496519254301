import { Stack } from "@fluentui/react";
import Layout from "../../styles/Layout.styles";
import { Text, mergeClasses } from "@fluentui/react-components";
import Style from "../../styles/Unauthentication.styles";
import { useTranslation } from "react-i18next";

const UnAuthWrapper = ({ children }) => {
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();

  return (
    <>
      <Stack className={style.loginPage}>
        <Stack className={mergeClasses(style.loginMain, style.loginLeftImage)}>
          <Stack className={layout.m_auto}>
            <Stack className={mergeClasses(style.loginLeft, "loginLeft")}>
              <Stack className={style.loginLeftTitle}>
                {t("halo")}
                <Text
                  className={mergeClasses(
                    style.loginLeftTitle,
                    style.loginLeftSec
                  )}
                >
                  {t("analytica")}
                </Text>
              </Stack>
              <Text className={style.loginLeftSubTitle}>
                {t("un_auth_wrapper.un_auth_dummy_text")}
              </Text>
            </Stack>
          </Stack>
        </Stack>
        {children}
      </Stack>
    </>
  );
};

export default UnAuthWrapper;
