import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#e0e0e0";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#e0e0e0",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "50%",
    textAlign: "left",
    borderRightColor: borderColor,
    paddingLeft: "8px",
    borderRightWidth: 1,
    paddingRight: 8,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  rate: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  amount: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableRow = ({ invoice }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.description}>
        {invoice?.clientOrder?.clientOrderBundles?.[0]?.bundle?.name}
      </Text>
      <Text style={styles.qty}>
        {invoice?.clientOrder?.clientOrderBundles?.[0]?.quantity}
      </Text>
      <Text style={styles.rate}>{invoice?.paymentStatus}</Text>
      <Text style={styles.amount}>
        {`${invoice?.currency} ${invoice?.amount.toFixed(2)}`}
      </Text>
    </View>
  );
};

export default InvoiceTableRow;
