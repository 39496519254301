import { useTranslation } from "react-i18next";
import { Stack } from "@fluentui/react";
import {
  Combobox,
  Divider,
  Option,
  Text,
  makeStyles,
  mergeClasses,
  tokens,
  shorthands,
  typographyStyles,
} from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import Style from "./style";
import { useEffect, useState } from "react";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
  },
  description: {
    ...typographyStyles.caption1,
  },
});
const ProjectUserRow = ({
  item,
  projectTeamsUsers,
  setProjectTeamsUsers,
  options,
}) => {
  const { t } = useTranslation();
  const layout = Layout();
  const style = Style();
  const styles = useStyles();
  const [customSearch, setCustomSearch] = useState("" | undefined);
  // eslint-disable-next-line no-unused-vars
  const [randomSearch, setRandomSearch] = useState("");
  const [matchingOptions, setMatchingOptions] = useState([...options]);
  const [value, setValue] = useState("");

  useEffect(() => {
    setMatchingOptions([...options]);
  }, [options]);

  const colorBoxStyles = {
    backgroundColor: item?.colorCode,
    color: "white",
    fontSize: tokens.fontSizeBase300,
  };

  const onSelectOption = (e, data) => {
    const matchingOption =
      data?.optionText && matchingOptions?.includes(data?.optionText);
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data?.optionText);
    }

    const newProjectTeamsUsers = [];
    projectTeamsUsers?.forEach((i) => {
      if (`${i?.teamId}` === `${item?.id}`) {
        newProjectTeamsUsers.push({
          teamId: i?.teamId,
          userIds: data?.selectedOptions,
        });
      } else {
        newProjectTeamsUsers?.push(i);
      }
    });
    setProjectTeamsUsers(newProjectTeamsUsers);
  };

  const selectedOptionsArray = projectTeamsUsers?.filter(
    (i) => `${i.teamId}` === `${item.id}`
  );

  if (selectedOptionsArray[0]?.userIds === undefined) {
    return "";
  }

  const chosenArray = options.filter((item) =>
    selectedOptionsArray[0]?.userIds.includes(item.value)
  );

  const disabledOption = projectTeamsUsers
    ?.filter((i) => `${i.teamId}` !== `${item.id}`)
    ?.flatMap((item) => {
      return item?.userIds;
    });

  const onChange = (event) => {
    const value = event?.target?.value?.trim();
    const matches = options?.filter((option) =>
      option?.children?.toLowerCase().includes(value?.toLowerCase())
    );
    setMatchingOptions(matches);
    if (value?.length && matches?.length < 1) {
      setCustomSearch(value);
    } else {
      setCustomSearch(undefined);
    }
  };

  const onInput = (ev) => {
    setValue(ev.target.value);
  };
  return (
    <>
      <Stack
        className={mergeClasses(
          style.card,
          "border4",
          layout.justifyContentTop,
          layout.mt_2
        )}
      >
        <Stack
          className={mergeClasses(
            style.cardTitle,
            "borderTop4",
            layout.fontWeightSemibold
          )}
          styles={{ root: colorBoxStyles }}
        >
          {item?.name}
        </Stack>
        <Stack className={style.cardBody}>
          <Text
            className={mergeClasses(
              layout.fontWeightBold,
              layout.color616161,
              layout.mb_1
            )}
          >
            {t("systemuser_username")}
          </Text>
          <Stack>
            <Stack className={mergeClasses(styles.root)}>
              <Combobox
                multiselect={true}
                placeholder={t("search")}
                className={mergeClasses(layout.w_100, layout.min_w_46)}
                onOptionSelect={onSelectOption}
                selectedOptions={selectedOptionsArray[0]?.userIds}
                onChange={onChange}
                matchingOptions={matchingOptions}
                value={value}
                onInput={onInput}
              >
                {!matchingOptions && customSearch && (
                  <Option key="freeform" text={customSearch}></Option>
                )}
                {matchingOptions?.map((option, key) => (
                  <Option
                    key={key}
                    className="field-option"
                    value={option?.value}
                    disabled={disabledOption?.includes(option?.value)}
                  >
                    {option?.children}
                  </Option>
                ))}
              </Combobox>
            </Stack>
          </Stack>

          <Text
            className={mergeClasses(
              layout.fontWeightBold,
              layout.color616161,
              layout.mt_1_0,
              layout.mb_1_0
            )}
          >
            {chosenArray?.length > 0 ? t("selected_users") : ""}
          </Text>
          <Stack className={mergeClasses(layout.mx_over)}>
            {chosenArray.map((item, key) => (
              <Stack key={key}>
                <Divider />
                <Stack
                  key={key}
                  className={mergeClasses(layout.mt_1_0, layout.mb_1_0)}
                >
                  {item.children}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectUserRow;
