import * as React from "react";
import {
  Combobox,
  makeStyles,
  mergeClasses,
  Option,
  OptionGroup,
  shorthands,
  typographyStyles,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { setProject } from "../../../store/reducer/project";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../styles/Layout.styles";
import { t } from "i18next";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
  },
  description: {
    ...typographyStyles.caption1,
  },
});

const StatusChangeComponent = ({ getProjectListApi }) => {
  const projectList = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const layout = Layout();
  const styles = useStyles();

  const options = [
    { value: "true", text: t("active") },
    { value: "false", text: t("inactive") },
    { value: "undefined", text: t("all") },
  ];

  const status = [
    { value: "awaiting_approval", text: t("awaiting_approval") },
    { value: "live", text: t("approved") },
    { value: "completed", text: t("completed") },
  ];

  const CustomOption = (props) => {
    return <Option {...props} className="multiSelectOption" />;
  };

  const CustomOptionGroup = (props) => {
    const { label, options } = props;

    return (
      <OptionGroup multiselect="false" label={label} {...options}>
        {options?.map((option, i) => (
          <CustomOption key={i} value={option?.value}>
            {option?.text}{" "}
          </CustomOption>
        ))}
      </OptionGroup>
    );
  };

  const handleChange = (e, data) => {
    if (options.map((item) => item?.value).includes(data?.optionValue)) {
      data.selectedOptions = data.selectedOptions.filter(
        (val) =>
          !options
            .map((item) => item?.value)
            .filter((el) => el !== data?.optionValue)
            .includes(val)
      );

      if (data?.selectedOptions?.includes(data?.optionValue)) {
        dispatch(setProject({ isActive: data?.optionValue }));
        getProjectListApi();
      } else {
        dispatch(setProject({ isActive: undefined }));
        getProjectListApi();
      }
    }
    if (status.map((item) => item?.value).includes(data?.optionValue)) {
      data.selectedOptions = data.selectedOptions.filter(
        (val) =>
          !status
            .map((item) => item?.value)
            .filter((el) => el !== data?.optionValue)
            .includes(val)
      );
      if (data?.selectedOptions?.includes(data?.optionValue)) {
        dispatch(setProject({ status: data?.optionValue }));
        getProjectListApi();
      } else {
        dispatch(setProject({ status: undefined }));
        getProjectListApi();
      }
    }
  };

  const matchedOption = options.find(
    (option) => option.value === projectList?.isActive
  );

  const matchedStatusOption = status.find(
    (option) => option.value === projectList?.status
  );

  const selectedOptionsText = [
    matchedOption?.text,
    matchedStatusOption?.text,
  ].filter((e) => e);

  const selectedOptionsValue = [
    matchedOption?.value,
    matchedStatusOption?.value,
  ].filter((e) => e);

  return (
    <Stack className={mergeClasses(styles.root, "minwidth-combobox")}>
      <Combobox
        multiselect="true"
        onOptionSelect={handleChange}
        readOnly
        defaultValue="Active"
        className={mergeClasses(layout.mr_1, "statusChangeComp")}
        defaultSelectedOptions={selectedOptionsValue}
        selectedOptions={selectedOptionsValue}
        value={selectedOptionsText}
        placeholder={t("select_status")}
      >
        <CustomOptionGroup options={options} />

        <CustomOptionGroup label={t("project.status")} options={status} />
      </Combobox>
    </Stack>
  );
};

export default StatusChangeComponent;
