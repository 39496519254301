import { useTranslation } from "react-i18next";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import { FieldsTextarea } from "../../../../components";

const Description = (props) => {
  const { description } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsTextarea
          label={t("project.description")}
          validationMessage={props?.errors?.description?.message}
          placeholder={t("project.enter_description")}
          name="description"
          id="description"
          inputClassName={style.textboxInput}
          inputDefaultValue={description}
          validation={props.register("description", {
            required: `${t("validation_message.description_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default Description;
