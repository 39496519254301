import React from "react";
import { useParams } from "react-router-dom";
import EditClientProfileWrapper from "./EditClientProfileWrapper";

const EditClientUserProfile = () => {
  const { id } = useParams();
  return <EditClientProfileWrapper id={id} />;
};

export default EditClientUserProfile;
