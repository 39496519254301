import React from "react";
import { Tooltip, mergeClasses, tokens } from "@fluentui/react-components";
import sideMenuRoute from "./SideMenuRoute";
import { navLinkBottom } from "./SideMenuRoute";
import Layout from "../../styles/Layout.styles";
import MenuImage from "./menuImage";
import { Stack } from "@fluentui/react";
import staticStyle from "./staticStyle";
import { NavLink } from "react-router-dom";
import accessRole from "../../components/Common/accessRole";
import SidebarTopItem from "./SidebarTopItem";
import BottomSettingDropdown from "../Header/BottomSettingDropdown";

const Menu = ({ isOpen, setOpenAccordion, openAccordion }) => {
  staticStyle();
  const layout = Layout();

  return (
    <>
      <Stack
        className={
          accessRole()?.has("client_list")
            ? layout.headerNav
            : layout.headerNavTwo
        }
      >
        {sideMenuRoute().map(
          (menu, index) =>
            menu?.visible && (
              <SidebarTopItem
                key={index}
                menu={menu}
                isOpen={isOpen}
                setOpenAccordion={setOpenAccordion}
                openAccordion={openAccordion}
              />
            )
        )}

        <Stack
          className={mergeClasses(layout.sidebarUl, layout.sidebarBottomMenu)}
        >
          {navLinkBottom().map((menu, key) => {
            return (
              menu?.visible &&
              (menu?.isDropdown ? (
                <Stack
                  key={key}
                  className={mergeClasses(
                    layout.sidebarDropdown,
                    "sidebar-title",
                    layout.sidebarList
                  )}
                  onClick={() => setOpenAccordion(false)}
                >
                  <BottomSettingDropdown
                    sidebarOpen={isOpen}
                    sideBarTabName={menu}
                  />
                </Stack>
              ) : (
                <Stack
                  key={menu.key}
                  className={mergeClasses(
                    layout.sidebarDropdown,
                    "sidebar-title",
                    layout.sidebarList
                  )}
                  onClick={() => setOpenAccordion(false)}
                >
                  <NavLink
                    key={menu.key}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            fontWeight: "bold",
                            color: tokens.colorBrandBackground,
                            borderLeft: "3px solid ",
                            paddingLeft: isOpen ? "10px" : "8px",
                          }
                        : {
                            paddingLeft: isOpen ? "13px" : "11px",
                          };
                    }}
                    to={menu.url}
                  >
                    {!isOpen ? (
                      <Tooltip withArrow content={menu.name}>
                        <div>
                          <MenuImage img={menu} />
                        </div>
                      </Tooltip>
                    ) : (
                      <MenuImage img={menu} />
                    )}

                    {isOpen && menu.name}
                  </NavLink>
                </Stack>
              ))
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};

export default Menu;
