import * as React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import {
  makeStyles,
  shorthands,
  Card,
  CardHeader,
} from "@fluentui/react-components";
import { STRIPE_PUBLIC_KEY } from "../../utils/constants";
import { useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { t } from "i18next";
import Breadcrumb from "../../components/Breadcrumb";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    marginTop: "20px",
    width: "720px",
    maxWidth: "100%",
  },
});

const breadcrumbData = [
  {
    title: t("stripe_payment"),
  },
];

export default function App() {
  const clientOrder = useSelector((state) => state?.license?.clientOrder?.data);
  const styles = useStyles();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const options = {
    mode: "setup",
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <>
      {clientOrder ? (
        <Elements stripe={stripePromise} options={options}>
          <Breadcrumb data={breadcrumbData} />
          <Card className={styles.card}>
            <CardHeader description={<h3>CHECKOUT</h3>} />
            <CheckoutForm />
          </Card>
        </Elements>
      ) : (
        <Navigate to="/project-list" />
      )}
    </>
  );
}
