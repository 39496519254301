import * as React from "react";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { SearchBox } from "@fluentui/react-search-preview";
import { Button } from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../../../styles/Layout.styles";
import { useDispatch } from "react-redux";
import { setUsers } from "../../../store/reducer/users";
import { useSelector } from "react-redux";
import FieldCombobox from "../../../components/FieldComponents/FieldCombobox";

const UsersFilter = ({ usersId, getUsersListApi, setInitialLoad, role }) => {
  const layout = Layout();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const usersList = useSelector((state) => state.users);

  const onChange = (data) => {
    dispatch(setUsers({ page: 0, search: data }));
    getUsersListApi();
    setInitialLoad(false);
  };

  const onStatusChange = (e, data) => {
    dispatch(setUsers({ page: 0, isActive: data?.optionValue }));
    getUsersListApi();
  };

  const options = [
    { value: "true", children: t("active") },
    { value: "false", children: t("inactive") },
    { value: "undefined", children: t("all") },
  ];

  const matchedOption = options.find(
    (option) => option.value === usersList?.isActive
  );
  return (
    <>
      <Stack className={layout.projectFilter}>
        <SearchBox
          placeholder={t("search")}
          className={mergeClasses(
            layout.headerSearch,
            "headerSearch",
            "no-reset"
          )}
          defaultValue={usersList?.search}
          iconProps={{ iconName: "Search", styles: { root: { right: "8px" } } }}
          onChange={(e) => onChange(e?.target?.value)}
        />
        <Stack
          className={mergeClasses(
            layout.d_flex,
            layout.flex_row,
            layout.alignCenter
          )}
        >
          <FieldCombobox
            multiselect={false}
            name="status"
            readOnly
            stackCombobox="minwidth-combobox"
            className={layout.mr_1}
            defaultValue={matchedOption?.children}
            defaultSelectedOptions={[matchedOption?.value]}
            onOptionSelect={onStatusChange}
            matchingOptions={options}
          />
          {(role?.has("system_user_create") ||
            role?.has("system_user_edit")) && (
            <Link
              to={usersId ? `/users-list/${usersId}/edit` : "/users-list/add"}
            >
              <Button
                appearance="primary"
                className={mergeClasses(layout.smallBtn, "custom-primary")}
              >
                {usersId ? t("users.edit") : t("users.add")}
              </Button>
            </Link>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default UsersFilter;
