import * as React from "react";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { SearchBox } from "@fluentui/react-search-preview";
import Layout from "../../../styles/Layout.styles";
import { useState } from "react";
import QuestionsWrapper from "../QuestionsManagement/QuestionsWrapper";
import { setQuestions } from "../../../store/reducer/question";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const QuestionsFilter = ({ getQuestionsListApi, setInitialLoad }) => {
  const layout = Layout();
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const questionsList = useSelector((state) => state.questions);

  const onChange = (data) => {
    dispatch(setQuestions({ page: 0, question: data }));
    getQuestionsListApi();
    setInitialLoad(false);
  };
  return (
    <>
      <Stack className={layout.projectFilter}>
        <SearchBox
          placeholder={t("search")}
          className={mergeClasses(
            layout.headerSearch,
            "headerSearch",
            "no-reset"
          )}
          defaultValue={questionsList?.question}
          iconProps={{ iconName: "Search", styles: { root: { right: "8px" } } }}
          onChange={(e) => onChange(e?.target?.value)}
        />
        {isOpenDialog && (
          <QuestionsWrapper
            isOpenDialog={isOpenDialog}
            setIsOpenDialog={setIsOpenDialog}
          />
        )}

        <Button
          appearance="primary"
          className={mergeClasses("custom-primary", layout.smallBtn)}
          onClick={() => setIsOpenDialog(true)}
        >
          {t("teams.add")}
        </Button>
      </Stack>
    </>
  );
};

export default QuestionsFilter;
