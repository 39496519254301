import { Spinner, mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../styles/Layout.styles";
import Style from "./style";
import { useGetProjectMutation } from "../../store/action/project";
import {
  useAssignUsersProjectMutation,
  useGetProjectClientSystemUserMutation,
} from "../../store/action/systemEndUser";
import SystemUserTab from "./SystemUserTab";
import ToasterComponent from "../../components/Toaster/ToasterComponent";

const SystemUser = (props) => {
  const { projectId } = props;
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();
  const [GetProjectApi, { isProjectLoading }] = useGetProjectMutation();
  const [projectName, setProjectName] = useState(true);
  const [projectInitial, setProjectInitial] = useState(true);
  const [GetProjectClientSystemUser, { isLoading }] =
    useGetProjectClientSystemUserMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [options, setOptions] = useState([]);
  const [assignUsersProject, { isLoading: isUserLoading }] =
    useAssignUsersProjectMutation();
  const [projectUsers, setProjectUsers] = useState({});
  const [selectedOptionProjectId, setSelectedOptionProjectId] = useState([]);
  const [isToastMessage, setIsToastMessage] = useState(true);

  useEffect(() => {
    if (projectInitial) {
      if (!isProjectLoading) {
        GetProjectApi(projectId).then((res) => {
          setProjectName(res?.data);
          setProjectInitial(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectLoading]);

  useEffect(() => {
    if (initialLoad) {
      GetProjectClientSystemUser({ projectId: projectId }).then((res) => {
        const data = res?.data?.map?.((item, label) => ({
          children: item?.fullName,
          value: item?.id,
        }));
        setOptions(data);

        res?.data?.map?.((item) => {
          return item?.projects?.find?.((obj) => {
            return (
              obj?.id === projectId && selectedOptionProjectId?.push(item?.id)
            );
          });
        });
        setProjectUsers({
          projectId: projectId,
          userIds: [...new Set(selectedOptionProjectId)],
        });
      });

      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isLoading]);

  const onHandleSystemUser = () => {
    setIsToastMessage(true);
    if (!isUserLoading) {
      const projectAssignData = {
        projectUsers,
      };
      assignUsersProject({ projectAssignData: projectAssignData });
    }
  };

  return (
    <>
      <Stack className={mergeClasses(layout.w_100)}>
        <Stack
          className={mergeClasses(
            style.projectCard,
            "borderTop4",
            layout.m_auto,
            layout.fontWeightSemibold,
            layout.bgF8FAFC,
            layout.padding2
          )}
        >
          {projectName?.title}
        </Stack>
        <Stack className={mergeClasses(style.card, "border4", layout.m_auto)}>
          <Stack className={style.cardBody}>
            <label
              className={mergeClasses(
                layout.fontWeightBold,
                layout.d_flex,
                layout.mb_1,
                layout.color616161
              )}
            >
              {t("systemuser_username")}
            </label>
            {isLoading ? (
              <Stack
                className={mergeClasses(layout.positionRelative, layout.w_100)}
              >
                <Spinner className={layout.m_auto} />
              </Stack>
            ) : (
              <Stack className={mergeClasses(layout.w_100, layout.mb_1)}>
                {options?.length > 0 ? (
                  <SystemUserTab
                    options={options}
                    projectId={projectId}
                    selectedOptionProjectId={selectedOptionProjectId}
                    setSelectedOptionProjectId={setSelectedOptionProjectId}
                    setProjectUsers={setProjectUsers}
                  />
                ) : (
                  <Stack className={style.textCenter}>No user found</Stack>
                )}
              </Stack>
            )}
            <Stack>
              <ToasterComponent
                type="submit"
                appearance="primary"
                className={mergeClasses(
                  layout.customBtn,
                  layout.maxWidth32,
                  layout.m_auto,
                  "custom-primary"
                )}
                loading={isUserLoading}
                onClick={onHandleSystemUser}
                buttonLabel={t("assign")}
                isToastMessage={isToastMessage}
                successMessage={
                  [...new Set(selectedOptionProjectId)]?.length === 0
                    ? t("system_users_not_assign")
                    : t("system_users_assign")
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SystemUser;
