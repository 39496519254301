import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { Button, mergeClasses, Text } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { PASSCODE_VALIDATION } from "../../../../utils/constants";
import { useState } from "react";
import Layout from "../../../../styles/Layout.styles";

const UserPasscodeGeneration = (props) => {
  const { userPassCode, isEditDisable, setValue } = props;
  const style = Style();
  const { t } = useTranslation();
  const layout = Layout();
  const [rendomPassWord, setRendomPassWord] = useState("");

  const rendomPasswordGenerate = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters?.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    setRendomPassWord(result);
    setValue("userPassCode", result, { shouldValidate: true });
    return result;
  };

  return (
    <>
      <Stack className={style.client_field}>
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.gap_15,
            layout.alignItemEnd
          )}
        >
          <FieldsInput
            label={t("passcode")}
            validationMessage={props.errors?.userPassCode?.message}
            id="userPassCode"
            value={rendomPassWord ? "******" : ""}
            placeholder={isEditDisable ? "******" : t("generate_passcode")}
            name="userPassCode"
            className={mergeClasses(layout.w_100, "pointer-none")}
            disabled={isEditDisable}
            inputDefaultValue={userPassCode}
            inputClassName={mergeClasses(
              style.textboxInput,
              layout.min_w_100,
              "pointer-none"
            )}
            validation={
              !isEditDisable
                ? props.register("userPassCode", {
                    required: `${t("validation_message.passcode_is_required")}`,
                    pattern: {
                      value: PASSCODE_VALIDATION,
                      message: `${t(
                        "validation_message.passcode_value_enter_six_digit"
                      )}`,
                    },
                  })
                : ""
            }
          />
          {!isEditDisable && (
            <Stack>
              <Button
                onClick={() => rendomPasswordGenerate(6)}
                appearance="primary"
                className={layout.h_40}
                disabled={isEditDisable}
              >
                {t("generate_btn")}
              </Button>

              {props?.errors?.userPassCode?.message && (
                <Text className={layout.mb_1_8}> </Text>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default UserPasscodeGeneration;
