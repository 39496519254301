import React from "react";
import { Stack } from "@fluentui/react";
import { useState } from "react";
import FieldTagInput from "../../../../../components/FieldComponents/FieldTagInput";
import { useTranslation } from "react-i18next";
import Layout from "../../../../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";

const HostList = (props) => {
  const { setVisibleTags, visibleTags, setValue } = props;
  const { t } = useTranslation();
  const layout = Layout();

  const removeItem = (_e, { value }) => {
    setVisibleTags([...visibleTags].filter((tag) => tag?.name !== value));
    setTagError(false);
    setValue("hostLists", visibleTags?.length !== 1 && visibleTags, {
      shouldValidate: true,
    });
  };

  const [tagInputValue, setTagInputValue] = useState("");
  const [tagError, setTagError] = useState();

  const handleTagsValue = (e) => {
    e.preventDefault();
    const insertAt = 0;

    const hostListArray = [
      ...visibleTags.slice(0, insertAt),
      { name: tagInputValue?.trim() },
      ...visibleTags.slice(insertAt),
    ];

    if (
      visibleTags.find((tag) => tag?.name === tagInputValue?.trim()) &&
      tagInputValue?.trim()?.length > 0
    ) {
      setTagError(t("validation_message.this_value_is_already_used"));
      setTagInputValue("");
    } else if (tagInputValue?.trim()?.length === 0) {
      setTagError("Input value is blank");
      setTagInputValue("");
    } else {
      setVisibleTags(hostListArray);
      setTagInputValue("");
      setTagError(false);
    }

    setValue("hostLists", hostListArray?.length !== 0 && hostListArray, {
      shouldValidate: true,
    });
  };

  const onHostList = (e) => {
    setTagInputValue(e?.target?.value);
    setTagError(false);
  };

  return (
    <Stack className="host-list-tags">
      <FieldTagInput
        label={t("project.host_list")}
        name="hostLists"
        id="hostLists"
        btnText="Add"
        value={tagInputValue}
        placeholder={t("project.employee_name")}
        validationMessage={props.errors?.hostLists?.message}
        onChange={onHostList}
        onClick={handleTagsValue}
        visibleTags={visibleTags}
        onDismiss={removeItem}
        customDesign={true}
        className={mergeClasses(layout.mb_2)}
        tagError={tagError}
        validation={props.register("hostLists", {
          required: `${t("validation_message.host_list_is_required")}`,
        })}
      />
    </Stack>
  );
};

export default HostList;
