import React from "react";
import ViewProjectForm from "./ViewProjectForm";
import { useParams } from "react-router-dom";

const ViewProject = () => {
  const { id } = useParams();
  return <ViewProjectForm id={id}/>;
};

export default ViewProject;
