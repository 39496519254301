import {
  Button,
  Divider,
  Text,
  mergeClasses,
  Image,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { Breadcrumb } from "../../../components";
import Layout from "../../../styles/Layout.styles";
import { t } from "i18next";
import Style from "./style";
import CsvUpload from "../../../components/FieldComponents/CsvUpload";
import { successBg } from "../../../images";

const SystemEndUserImport = () => {
  const layout = Layout();
  const style = Style();

  const breadcrumbData = [
    {
      title: `${t("system_end_import")}`,
    },
  ];

  return (
    <Stack className={mergeClasses(layout.d_flex, layout.flex_row)}>
      <Stack className={layout.w_100}>
        <Breadcrumb data={breadcrumbData} />
        <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
          <Stack className={mergeClasses(style.subCard, "cardShadow")}>
            <Stack
              className={mergeClasses(
                layout.flex_row,
                layout.alignCenter,
                layout.justifyCenter
              )}
            >
              <Stack className={mergeClasses(style.pointDesign, "border4")}>
                1
              </Stack>
              <h1 className={style.mainTitle}>Download Sample CSV Template</h1>
            </Stack>
            <Divider />
            <Text
              className={mergeClasses(style.contentText, layout.color616161)}
            >
              Download sample template by clicking on the button below.
              <br /> You can add the data according to the template file.
            </Text>
            <Button
              appearance="primary"
              className={mergeClasses(
                layout.btnCustom,
                "custom-primary",
                layout.h_32,
                layout.m_auto
              )}
            >
              Download CSV Template
            </Button>
            <Divider />
            <Stack
              className={mergeClasses(layout.flex_row, layout.alignCenter)}
            >
              <Stack className={mergeClasses(style.pointDesign, "border4")}>
                2
              </Stack>
              <h1 className={style.mainTitle}>Upload CSV</h1>
            </Stack>
            <Divider />
            <Text
              className={mergeClasses(style.contentText, layout.color616161)}
            >
              Upload the CSV file by clicking on the button below.
            </Text>

            <CsvUpload
              name={"fileUpload"}
              accept="image/png, image/jpeg, image/jpg,image/gif"
              label={t("photo_label")}
              placeholder={t("choose_file")}
              className={style.fileWidth}
            />
            <Text
              className={mergeClasses(
                style.contentText,
                layout.color616161,
                layout.d_flex,
                layout.alignCenter
              )}
            >
              <Image
                alt=""
                className={mergeClasses(style.mr_0_5)}
                src={successBg}
                height={14}
                width={14}
              />
              End-users-import-data-file.csv
            </Text>
            <Text
              className={mergeClasses(style.contentText, layout.fontWeightBold)}
            >
              We'll handle your upload in the following way:
            </Text>
            <Text
              className={mergeClasses(style.contentText, layout.color616161)}
            >
              Any rows containing new email addresses will be added.
              <br /> Any rows containing existing email addresses will be
              updated.
              <br />
              Any rows with errors will not be added. <br /> All the details
              related to import will be sent to your email address.
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SystemEndUserImport;
