import { useProjectSummaryLiveMutation } from "../../../store/action/projectSummary/projectSummaryLive";
import ExportFile from "../../../utils/exportFile";
import { useProjectSummaryAwaitMutation } from "../../../store/action/projectSummary/projectSummaryAwait";
import { useProjectSummaryCompleteMutation } from "../../../store/action/projectSummary/projectSummaryComplete";
import { format } from "date-fns";
import ExportButtonComponent from "../../../components/FieldComponents/ExportButton";
import { useSelector } from "react-redux";

const ExportButton = ({ selectedValue }) => {
  const [projectSummaryExportAwait] = useProjectSummaryAwaitMutation();
  const [projectSummaryExportLive] = useProjectSummaryLiveMutation();
  const [projectSummaryExportComplete] = useProjectSummaryCompleteMutation();
  const projectSummaryAwait = useSelector((state) => state.projectSummaryAwait);
  const projectSummaryLive = useSelector((state) => state.projectSummaryLive);
  const projectSummaryComplete = useSelector(
    (state) => state.projectSummaryComplete
  );
  const onExport = async (e) => {
    if (selectedValue === "awaiting") {
      const res = await projectSummaryExportAwait({
        status: "awaiting_approval",
        pagination: false,
      });
      if (res?.data?.length > 0) {
        const exportData = res?.data?.map?.((item, label) => ({
          projectCode: item?.projectCode,
          projectName: item?.title,
          commencementDate: format(
            new Date(item.commencementDate),
            "yyyy/MM/dd"
          ),
          completionDate: format(new Date(item.completionDate), "yyyy/MM/dd"),
          totalTeamMember: item?.totalMember,
          todayClockIn: item?.todayClockIn,
          address: item?.address,
        }));
        return ExportFile(exportData);
      }
    } else if (selectedValue === "live") {
      const res = await projectSummaryExportLive({
        status: "live",
        pagination: false,
      });
      if (res?.data?.length > 0) {
        const exportData = res?.data?.map?.((item, label) => ({
          projectCode: item?.projectCode,
          projectName: item?.title,
          commencementDate: format(
            new Date(item.commencementDate),
            "yyyy/MM/dd"
          ),
          completionDate: format(new Date(item.completionDate), "yyyy/MM/dd"),
          totalTeamMember: item?.totalMember,
          todayClockIn: item?.todayClockIn,
          address: item?.address,
        }));
        return ExportFile(exportData);
      }
    } else {
      const res = await projectSummaryExportComplete({
        status: "complete",
        pagination: false,
      });
      if (res?.data?.length > 0) {
        const exportData = res?.data?.map?.((item, label) => ({
          projectCode: item?.projectCode,
          projectName: item?.title,
          commencementDate: format(
            new Date(item.commencementDate),
            "yyyy/MM/dd"
          ),
          completionDate: format(new Date(item.completionDate), "yyyy/MM/dd"),
          totalTeamMember: item?.totalMember,
          todayClockIn: item?.todayClockIn,
          address: item?.address,
        }));
        return ExportFile(exportData);
      }
    }
  };
  return (
    <>
      <ExportButtonComponent
        exportData={onExport}
        isExportButtonDisable={
          selectedValue === "awaiting"
            ? projectSummaryAwait?.totalCount === 0
            : selectedValue === "live"
            ? projectSummaryLive?.totalCount === 0
            : projectSummaryComplete?.totalCount === 0
        }
      />
    </>
  );
};

export default ExportButton;
