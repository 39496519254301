import { Stack } from "@fluentui/react";
import { Button, Image, mergeClasses } from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import Style from "./style";
import { failure } from "../../images";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Breadcrumb } from "../../components";

const OrderFailure = () => {
  const layout = Layout();
  const navigate = useNavigate();
  const style = Style();
  const breadcrumbData = [
    {
      title: `${t("payment")}`,
    },
  ];
  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack
        className={mergeClasses(
          layout.projectContent,
          layout.w_100,
          "cardShadow"
        )}
      >
        <Stack
          className={mergeClasses(
            layout.w_50,
            "cardShadow",
            style.cardShadow,
            layout.m_auto,
            style.padding_30
          )}
        >
          <Image
            alt=""
            className={mergeClasses(layout.m_auto, style.mb_30)}
            src={failure}
            height={100}
            width={100}
          />
          <h2
            className={mergeClasses(
              style.paymentStatus,
              style.mb_30,
              layout.mt_0,
              layout.textCenter,
              style.textFailure
            )}
          >
            {t("stripe.payment_failed")}
          </h2>
          <Stack
            className={mergeClasses(
              style.description,
              layout.textCenter,
              layout.mb_15
            )}
          >
            {t("stripe.error_details_one")}
          </Stack>
          <Stack
            className={mergeClasses(
              style.description,
              layout.textCenter,
              style.mb_30
            )}
          >
            {t("stripe.error_details_two")}
          </Stack>
          <Button
            onClick={() => navigate("/licenses")}
            appearance="primary"
            className={mergeClasses(
              "custom-primary",
              layout.smallBtn,
              layout.m_auto
            )}
          >
            {t("stripe.try_again")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default OrderFailure;
