import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setClient } from "../reducer/client";
import { defaultFromState, isActiveReload } from "../../utils/defaultFromState";

export const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),
  endpoints: (builder) => ({
    addClient: buildMutationHelper(
      builder,
      ({ clientData }) => ({
        url: `/client`,
        method: "POST",
        body: clientData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setClient({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setClient({ errorMessage: error?.data?.violations }));
      }
    ),
    uploadAddLogoClient: buildMutationHelper(
      builder,
      ({ formData, id }) => ({
        url: `/client-logo/${id}`,
        method: "POST",
        body: formData,
        headers: getHeaders({
          onlyFileUpload: true,
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setClient({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setClient({ errorMessage: error?.data?.error }));
      }
    ),
    editClient: buildMutationHelper(
      builder,
      ({ clientData, id }) => ({
        url: `/client/${id}`,
        method: "PATCH",
        body: JSON.stringify(clientData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch, getState) => {
        const {
          endUser: { data: newData },
        } = getState();

        const { data } = response?.data;

        let newDataArray = [];
        isActiveReload(newDataArray, newData, data);

        dispatch(
          setClient({
            newDataArray,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setClient({ errorMessage: error?.data?.violations }));
      }
    ),

    getClientList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        search = undefined,
        sortBy = undefined,
        sortType = undefined,
        isActive = undefined,
        pagination = undefined,
      } = {}) => {
        return {
          url: "/clients",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            search: search,
            sortBy,
            sortType,
            isActive: isActive,
            pagination: pagination,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              client: {
                page,
                itemsPerPage,
                search,
                sortBy,
                sortType,
                isActive,
                pagination,
              },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              search,
              sortBy,
              sortType,
              isActive,
              pagination,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setClient({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setClient({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(setClient({ errorMessage: error?.data?.error }));
      }
    ),
    getClient: buildMutationHelper(
      builder,
      (id) => ({
        url: `/client/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setClient({
            clientData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setClient({ errorMessage: error?.data?.error }));
      }
    ),
    getClientLogo: buildMutationHelper(
      builder,
      (id) => ({
        url: `/client-logo/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setClient({
            clientData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setClient({ errorMessage: error?.data?.error }));
      }
    ),
    deleteClient: buildMutationHelper(
      builder,
      (id) => ({
        url: `/client/delete/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      ({ error }, dispatch) => {
        dispatch(setClient({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});

export const {
  useAddClientMutation,
  useUploadAddLogoClientMutation,
  useGetClientListMutation,
  useGetClientMutation,
  useGetClientLogoMutation,
  useEditClientMutation,
  useDeleteClientMutation,
} = clientApi;
