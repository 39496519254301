import { mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import FieldCountrySelect from "../../../../components/FieldComponents/FieldCountrySelect";

const Country = (props) => {
  const { country, setTimeZoneSelectListData, resetField } = props;
  const { t } = useTranslation();
  const style = Style();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldCountrySelect
          label={t("project.country")}
          validationMessage={props.errors?.country?.message}
          id="country"
          placeholder={t("project.select_country")}
          name="country"
          inputClassName={mergeClasses(style.textboxInput)}
          setTimeZoneSelectListData={setTimeZoneSelectListData}
          inputDefaultValue={country}
          timeZoneName={"timeZone"}
          resetField={resetField}
          validation={props.register("country", {
            required: `${t("validation_message.country_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default Country;
