import { Stack, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import Layout from "../../../styles/Layout.styles";
import Style from "./style";
import {
  Divider,
  mergeClasses,
  Image,
  Button,
  Spinner,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import close from "../../../images/close.svg";
import { dateTimeFormatted } from "../../../utils/convert-date-time";
import { useGetVisitorMutation } from "../../../store/action/visitor";

const VisitorDetails = (props) => {
  const { enableDetailsId, setIsEnableDetails } = props;
  const [GetVisitorDetails, { isLoading }] = useGetVisitorMutation();
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();
  const [viewData, setViewData] = useState();

  useEffect(() => {
    if (enableDetailsId) {
      if (!isLoading) {
        GetVisitorDetails(enableDetailsId).then((res) => {
          setViewData(res?.data);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetVisitorDetails, enableDetailsId]);
  return (
    <>
      <Stack
        className={mergeClasses(
          style.employeeDetails,
          "cardShadow",
          "sidebarDetails",
          layout.mt_0
        )}
      >
        <h2 className={mergeClasses(style.detailTitle, layout.primary)}>
          {t("visitor.visitor_details")}
          <Button
            appearance="subtle"
            onClick={() => setIsEnableDetails(false)}
            aria-label="close"
            icon={<Image alt="" src={close} height={20} width={20} />}
          />
        </h2>
        {isLoading ? (
          <Stack
            className={mergeClasses(layout.positionRelative, layout.w_100)}
          >
            <Spinner className={layout.m_auto} />
          </Stack>
        ) : (
          <>
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>{t("visitor.name")}</Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >{`${viewData?.name?.length > 0 ? viewData?.name : "-"}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />

            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>
                {t("visitor.phone_number")}
              </Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >
                ({`${viewData?.phonePrefix}`}) {`${viewData?.phoneNumber}`}
              </Text>
            </Stack>
            <Divider className={style.detailsDivider} />

            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>{t("visitor.check_in")}</Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >
                {" "}
                {dateTimeFormatted(viewData?.checkinDate, viewData?.timezone)}
              </Text>
            </Stack>
            <Divider className={style.detailsDivider} />

            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>{t("visitor.host_name")}</Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >{`${
                viewData?.hosts?.length > 0 ? viewData?.hosts?.join(", ") : "-"
              }`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />

            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>{t("visitor.message")}</Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >{`${
                viewData?.message?.length > 0 ? viewData?.message : "-"
              }`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>
                {t("visitor.project_name")}
              </Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >{`${
                viewData?.projectLocation?.project?.title?.length > 0
                  ? viewData?.projectLocation?.project?.title
                  : "-"
              }`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>
                {t("visitor.location_name")}
              </Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >
                {`${
                  viewData?.projectLocation?.name?.length > 0
                    ? viewData?.projectLocation?.name
                    : "-"
                }`}
              </Text>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default VisitorDetails;
