import React from "react";
import { useParams } from "react-router-dom";
import UsersProfileForm from "./UsersProfileForm";

const EditUsersProfile = () => {
  const { id } = useParams();
  return <UsersProfileForm id={id} />;
};

export default EditUsersProfile;
