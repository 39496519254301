import { createSlice } from "@reduxjs/toolkit";

const projectDepartment = createSlice({
  name: "projectDepartment",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    isActive: "true",
  },
  reducers: {
    setProjectDepartment(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setProjectDepartment } = projectDepartment.actions;
export default projectDepartment.reducer;
