import { Stack, Text } from "@fluentui/react";
import Layout from "../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";
import { DeletePopup, TableCell, TableRow } from "../../components";
import { useState } from "react";
import {
  useClientLicenseListMutation,
  useDeleteClientLicenseMutation,
} from "../../store/action/license";
import accessRole from "../../components/Common/accessRole";

const LicensesRow = (props) => {
  const { item, itemExpired } = props;
  const layout = Layout();

  const [open, setOpen] = useState(false);
  const [deleteClientLicense, { isDeleteLoading }] =
    useDeleteClientLicenseMutation();
  const [getClientLicensesList] = useClientLicenseListMutation();

  const handleDelete = (id) => {
    if (!isDeleteLoading) {
      deleteClientLicense(id).then((res) => {
        getClientLicensesList();
        setOpen(false);
      });
    }
  };

  return (
    <>
      <TableRow key={item?.key}>
        <TableCell tabIndex={item?.key} role="gridcell">
          <Stack className={mergeClasses(layout.flex_row, layout.alignCenter)}>
            <Text
              truncate
              block
              className={mergeClasses(
                layout.textOverFlowHidden,
                layout.cursor_pointer
              )}
            >
              {item?.systemUser?.fullName}
            </Text>
          </Stack>
        </TableCell>

        {accessRole()?.has("license_delete") && (
          <TableCell tabIndex={item?.key}>
            <Stack
              className={mergeClasses(
                layout.tableIcons,
                itemExpired ? "disableIcons" : ""
              )}
            >
              <DeletePopup
                className={layout.tableIcon}
                open={open}
                onOpenChange={(_, data) => setOpen(data.open)}
                onClick={() => setOpen(true)}
                handleDelete={() => handleDelete(item?.id)}
                handleCancel={() => setOpen(false)}
              />
            </Stack>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};
export default LicensesRow;
