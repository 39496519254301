import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const style = makeStyles({
  priceText: {
    fontSize: "24px",
    lineHeight: "20px",
  },
  paymentStatus: {
    fontSize: "20px",
    lineHeight: "20px",
  },
  description: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  mb_30: {
    marginBottom: "30px",
  },
  padding_30: {
    ...shorthands.padding("30px"),
  },
  textSuccess: {
    color: "#0E8F3D",
  },
  textFailure: {
    color: "#CF2A2A",
  },
  cardShadow: {
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
  textDanger: {
    color: tokens.colorStatusDangerBackground3,
    fontWeight: "600",
    marginBottom: "20px",
  },
});

export default style;
