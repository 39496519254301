import {
  TableHeader as FluentTableHeader,
  mergeClasses,
} from "@fluentui/react-components";
import Style from "./Style";

const TableHeader = (props) => {
  const { children, className } = props;
  const style = Style();

  return (
    <FluentTableHeader className={mergeClasses(style.customHeader, className)}>
      {children}
    </FluentTableHeader>
  );
};

export default TableHeader;
