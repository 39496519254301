import React, { useState, useEffect } from "react";
import { Stack, Text } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../../components/Table";
import { useSelector } from "react-redux";
import Pagination from "../../../../components/Pagination";
import Layout from "../../../../styles/Layout.styles";
import ApprovedRow from "./ApprovedRow";
import { setTimeAndAttendanceApprove } from "../../../../store/reducer/time-and-attendance/time-and-attendanceApprove";
import { useGetApprovedDataMutation } from "../../../../store/action/time-and-attendance/time-and-attendanceApprove";
import { t } from "i18next";

const TimeSheetAndAttendanceApproved = (props) => {
  const {
    isEmployeeDetailsOpen,
    employeeSelectedId,
    assignedProjectId,
    setEmployeeSelectedId,
    setIsEmployeeDetailsOpen,
  } = props;
  const layout = Layout();
  const [getApproveDataList, { isLoading }] = useGetApprovedDataMutation();
  const timeAndAttendanceList = useSelector(
    (state) => state.timeAndAttendanceApprove
  );
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      if (assignedProjectId) {
        getData(
          timeAndAttendanceList?.page,
          timeAndAttendanceList?.itemsPerPage
        );

        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assignedProjectId,
    initialLoad,
    getApproveDataList,
    isLoading,
    timeAndAttendanceList,
  ]);

  const getData = async (page, itemsPerPage) => {
    await getApproveDataList({
      page: page,
      itemsPerPage: itemsPerPage,
      projectId: assignedProjectId,
    });
  };

  const columns = [
    {
      columnKey: "systemEndUser.firstName",
      label: `${t("project.employee_name")}`,
      isSort: true,
    },
    { columnKey: "clockedCount", label: `${t("clocked_count")}` },
    { columnKey: "createdAt", label: `${t("date")}`, isSort: true },
    { columnKey: "checkinDate", label: `${t("clock_in")}`, isSort: true },
    { columnKey: "checkoutDate", label: `${t("clock_out")}`, isSort: true },
    { columnKey: "totalHours", label: `${t("total_hour")}`, isSort: true },
  ];
  return (
    <Stack className={mergeClasses("contentData", layout.mt_1)}>
      <Stack className="overflow-y-auto">
        <Table className="tbl-layout-auto">
          <TableHeader className={layout.headerBackground}>
            <TableRow>
              {columns?.map((column, key) => (
                <TableHeaderCell
                  key={key}
                  onSortApi={getData}
                  onSet={setTimeAndAttendanceApprove}
                  column={column}
                  sortBy={
                    column?.isSort ? timeAndAttendanceList?.sortBy : false
                  }
                >
                  <Text className={layout.headerLabel}>{column.label}</Text>
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody isLoading={isLoading}>
            {timeAndAttendanceList?.data?.map((item, key) => (
              <ApprovedRow
                key={key}
                item={item}
                employeeSelectedId={employeeSelectedId}
                setEmployeeSelectedId={setEmployeeSelectedId}
                setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
                isEmployeeDetailsOpen={isEmployeeDetailsOpen}
              />
            ))}
          </TableBody>
        </Table>
      </Stack>
      <Pagination
        totalCount={assignedProjectId ? timeAndAttendanceList?.totalCount : 0}
        itemsPerPage={timeAndAttendanceList?.itemsPerPage}
        onSetListReducer={setTimeAndAttendanceApprove}
        onGetList={getData}
        page={timeAndAttendanceList?.page}
      />
    </Stack>
  );
};

export default TimeSheetAndAttendanceApproved;
