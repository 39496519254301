import React, { useEffect, useState } from "react";
import {
  TableBody,
  TableRow,
  Table,
  TableHeader,
  mergeClasses,
  Text,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { makeStaticStyles, shorthands } from "@fluentui/react-components";
import ThemeColor from "./ThemeColor";
import Breadcrumb from "../../../../components/Breadcrumb";
import ThemeFilter from "./ThemeFilter";
import { useSelector } from "react-redux";
import { setTheme } from "../../../../store/reducer/theme";
import Layout from "../../../../styles/Layout.styles";
import { useGetThemeListMutation } from "../../../../store/action/theme";
import Pagination from "../../../../components/Pagination";
import { useTranslation } from "react-i18next";
import { TableHeaderCell } from "../../../../components";
import accessRole from "../../../../components/Common/accessRole";

const menuStyle = makeStaticStyles({
  ".openMenu .contentData": {
    maxWidth: "calc(100% - 450px)",
    ...shorthands.overflow("auto"),
  },
});
const ThemeList = () => {
  const layout = Layout();
  const role = accessRole();
  menuStyle();
  const { t } = useTranslation();
  const [getThemeApi, { isLoading }] = useGetThemeListMutation();
  const themeList = useSelector((state) => state?.theme);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getThemeApi();
        setInitialLoad(false);
      }
    }
  }, [initialLoad, isLoading, getThemeApi]);

  const columns = [
    { columnKey: "theme.name", label: t("theme_nm"), isSort: true },
    { columnKey: "isActive", label: t("theme_set_default"), isSort: true },
    { columnKey: "createdAt", label: t("theme_created_At"), isSort: true },
    { columnKey: "updatedAt", label: t("theme_updated_at"), isSort: true },
    { columnKey: "action", label: t("theme_action") },
  ];

  const breadcrumbData = [
    {
      title: "Theme",
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
        <ThemeFilter
          getThemeApi={getThemeApi}
          themeList={themeList}
          setInitialLoad={setInitialLoad}
          role={role}
        />

        <Stack className="contentData">
          <Stack className="overflow-y-auto">
            <Table className="tbl-layout-auto">
              <TableHeader className={layout.headerBackground}>
                <TableRow className={layout.customRow}>
                  {columns.map((column, key) => (
                    <TableHeaderCell
                      key={key}
                      onSortApi={getThemeApi}
                      onSet={setTheme}
                      sortBy={column?.isSort ? column?.columnKey : false}
                    >
                      <Text
                        className={mergeClasses(
                          layout.headerLabel,
                          layout.fontWeightSemibold,
                          layout.textNowrap
                        )}
                      >
                        {column.label}
                      </Text>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody className={layout.customTblBody} isLoading={isLoading}>
                {themeList?.data?.map((item, key) => (
                  <ThemeColor key={key} item={item} role={role} />
                ))}
              </TableBody>
            </Table>
          </Stack>
          <Pagination
            totalCount={themeList?.totalCount}
            itemsPerPage={themeList?.itemsPerPage}
            onSetListReducer={setTheme}
            page={themeList?.page}
            onGetList={getThemeApi}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ThemeList;
