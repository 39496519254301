import Style from "../AddProject.style";
import { useTranslation } from "react-i18next";
import { Stack } from "@fluentui/react";
import { useState } from "react";
import { FieldTagInput } from "../../../../components";
import { mergeClasses } from "@fluentui/react-components";
import Layout from "../../../../styles/Layout.styles";

const Tags = (props) => {
  const { setVisibleTags, visibleTags } = props;
  const { t } = useTranslation();

  const style = Style();
  const layout = Layout();

  const removeItem = (_e, { value }) => {
    setVisibleTags([...visibleTags].filter((tag) => tag?.name !== value));
  };

  const [tagInputValue, setTagInputValue] = useState("");
  const [tagError, setTagError] = useState();

  const handleTagsValue = (e) => {
    e.preventDefault();
    const insertAt = 0;

    const tagListArray = [
      ...visibleTags.slice(0, insertAt),
      { name: tagInputValue?.trim() },
      ...visibleTags.slice(insertAt),
    ];

    if (
      visibleTags.find((tag) => tag?.name === tagInputValue?.trim()) &&
      tagInputValue?.trim()?.length > 0
    ) {
      setTagError(t("validation_message.this_value_is_already_used"));
      setTagInputValue("");
    } else if (
      tagInputValue?.trim()?.length === 0 ||
      tagInputValue?.length === 0
    ) {
      setTagError("Input value is blank");
      t("validation_message.input_value_is_blank");
      setTagInputValue("");
    } else {
      setVisibleTags(tagListArray);
      setTagInputValue("");
      setTagError(false);
    }
  };

  const onTagsChanges = (e) => {
    setTagInputValue(e?.target?.value);
    setTagError(false);
  };
  return (
    <>
      <Stack className={mergeClasses(style.client_field, "project_tags")}>
        <FieldTagInput
          label={t("project.tags")}
          name="tags"
          id="tags"
          value={tagInputValue}
          placeholder={t("project.enter_tags")}
          onChange={onTagsChanges}
          onClick={handleTagsValue}
          visibleTags={visibleTags}
          onDismiss={removeItem}
          validation={props.register("tags")}
          noRequired
        />
        {visibleTags && <p className={layout.colorEbc2f32}> {tagError}</p>}
      </Stack>
    </>
  );
};

export default Tags;
