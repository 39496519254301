import { Stack } from "@fluentui/react";
import { Button, mergeClasses } from "@fluentui/react-components";
import { ReactComponent as Pencil } from "../../../images/pencil.svg";
import { useState } from "react";
import { TableCell, TableRow } from "../../../components/Table";
import DeletePopup from "../../../components/FieldComponents/DeletePopup";
import { useSelector } from "react-redux";
import Layout from "../../../styles/Layout.styles";
import {
  useDeleteTeamsMutation,
  useTeamsListMutation,
} from "../../../store/action/teams";
import TeamsWrapper from "../TeamsManagement/TeamsWrapper";

const TeamsListRow = ({ item, projectId, role }) => {
  const [open, setOpen] = useState(false);
  const [getDeleteTeams] = useDeleteTeamsMutation();
  const [getTeamsListApi] = useTeamsListMutation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const layout = Layout();
  const teamsList = useSelector((state) => state.teams);

  const onGetTeams = async (page) => {
    await getTeamsListApi({
      page: page,
      project: projectId,
    });
  };

  const handleDelete = (id) => {
    getDeleteTeams(id).then((res) => {
      onGetTeams(
        teamsList?.data?.length === 1 && teamsList?.page > 0
          ? teamsList?.page - 1
          : teamsList?.page
      );
      setOpen(false);
    });
  };

  const colorBoxStyles = {
    backgroundColor: item?.colorCode,
    width: "20px",
    marginLeft: "10px",
    height: "20px",
  };

  return (
    <>
      <TableRow key={item?.project?.label}>
        <TableCell
          tabIndex={item?.key}
          className={mergeClasses(layout.customTblCell, layout.wordBreak)}
          role="gridcell"
        >
          {item?.name}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          <Stack styles={{ root: colorBoxStyles }}></Stack>
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.description}
        </TableCell>

        {role?.has("project_teams_edit") && (
          <TableCell
            tabIndex={item?.key}
            className={layout.customTblCell}
            role="gridcell"
          >
            <Stack className={layout.tableIcons}>
              {isOpenDialog && (
                <TeamsWrapper
                  teamId={item?.id}
                  isOpenDialog={isOpenDialog}
                  setIsOpenDialog={setIsOpenDialog}
                  projectId={projectId}
                />
              )}
              <Button
                className={mergeClasses(layout.customEdit)}
                appearance="transparent"
                onClick={() => setIsOpenDialog(true)}
                aria-label="edit"
              >
                <Pencil height={18} width={18} />
              </Button>

              <DeletePopup
                className={layout.tableIcon}
                open={open}
                onOpenChange={(_, data) => setOpen(data.open)}
                onClick={() => setOpen(true)}
                handleDelete={() => handleDelete(item.id)}
                handleCancel={() => setOpen(false)}
              />
            </Stack>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};
export default TeamsListRow;
