import React, { useState, useEffect } from "react";
import { Stack, Text } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../../components/Table";
import { useSelector } from "react-redux";
import Pagination from "../../../../components/Pagination";
import Layout from "../../../../styles/Layout.styles";
import { t } from "i18next";
import { setBilling } from "../../../../store/reducer/billing";
import BillingListRow from "./BillingListRow";

const Invoices = ({ getBillingDataList, isLoading }) => {
  const layout = Layout();

  const billingList = useSelector((state) => state.billing);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getBillingDataList();

        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, getBillingDataList, isLoading, billingList]);

  const columns = [
    {
      columnKey: "id",
      label: `${t("bills.id")}`,
    },
    {
      columnKey: "createdAt",
      label: `${t("bills.invoiceDate")}`,
      isSort: true,
    },
    {
      columnKey: "totalAmount",
      label: `${t("bills.totalAmount")}`,
    },
    { columnKey: "paymentStatus", label: `${t("bills.status")}`, isSort: true },
    { columnKey: "pay", label: `${t("pay")}` },
    {
      columnKey: "downloadPdf",
      label: `${t("bills.downloadPdf")}`,
    },
    {
      columnKey: "billingAccount",
      label: `${t("bills.billingAccount")}`,
    },
    {
      columnKey: "billingProfile",
      label: `${t("bills.billingProfile")}`,
    },
  ];
  return (
    <Stack className={mergeClasses("contentData", layout.mt_1)}>
      <Stack className="overflow-y-auto">
        <Table className="tbl-layout-auto">
          <TableHeader className={layout.headerBackground}>
            <TableRow>
              {columns?.map((column, key) => (
                <TableHeaderCell
                  key={key}
                  onSortApi={getBillingDataList}
                  onSet={setBilling}
                  column={column}
                  sortBy={column?.isSort ? billingList?.sortBy : false}
                >
                  <Text className={layout.headerLabel}>{column.label}</Text>
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody isLoading={isLoading}>
            {billingList?.data?.map((item, key) => (
              <BillingListRow key={key} item={item} />
            ))}
          </TableBody>
        </Table>
      </Stack>
      <Pagination
        totalCount={billingList?.totalCount}
        itemsPerPage={billingList?.itemsPerPage}
        onSetListReducer={setBilling}
        onGetList={getBillingDataList}
        page={billingList?.page}
      />
    </Stack>
  );
};

export default Invoices;
