import Layout from "../../../../styles/Layout.styles";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../../components/Table";
import { Text } from "@fluentui/react";
import ClockLogRow from "./ClockLogRow";
import { t } from "i18next";
import accessRole from "../../../../components/Common/accessRole";
import { mergeClasses } from "@fluentui/react-components";

const ClockLogs = (props) => {
  const {
    projectTnaDetails,
    isLoading,
    assignedProjectId,
    viewDetails,
    selectedValue,
  } = props;
  const layout = Layout();

  const column = [
    { columnKey: "clockIn", label: t("clock_in") },
    { columnKey: "clockOut", label: t("clock_out") },
    { columnKey: "autoClockOut", label: t("autoClockOut") },
    { columnKey: "logType", label: t("logType") },
  ];

  const selectedValueColumn = [{ columnKey: "action", label: "Action" }];

  const columns =
    (selectedValue === "review" && accessRole()?.has("can_edit_time_log")) ||
    (selectedValue === "review" && accessRole()?.has("can_delete_time_log"))
      ? [...column, ...selectedValueColumn]
      : column;

  return (
    <Table className={layout.clockLogTable}>
      <TableHeader className={layout.headerBackground}>
        <TableRow>
          {columns?.map((column, key) => (
            <TableHeaderCell key={key}>
              <Text
                className={mergeClasses(layout.headerLabel, "header-column")}
              >
                {column?.label}
              </Text>
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>

      <TableBody isLoading={isLoading}>
        {projectTnaDetails?.clockLog?.map((item, key) => (
          <ClockLogRow
            key={key}
            item={item}
            projectTnaDetails={projectTnaDetails}
            assignedProjectId={assignedProjectId}
            viewDetails={viewDetails}
            selectedValue={selectedValue}
            isLoading={isLoading}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ClockLogs;
