import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setQuestions } from "../reducer/question";
import { defaultFromState } from "../../utils/defaultFromState";

export const questionsApi = createApi({
  reducerPath: "questionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    questionsList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        question = undefined,
        sortBy = undefined,
        sortType = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/tna/questions",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            question: question,
            sortBy,
            sortType,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              questions: {
                page,
                itemsPerPage,
                question,
                sortBy,
                sortType,
                client,
              },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              question,
              sortBy,
              sortType,
              client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setQuestions({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setQuestions({ errorMessage: error?.data?.error }));
      }
    ),
    addQuestion: buildMutationHelper(
      builder,
      ({ questionsData }) => ({
        url: `/tna/question`,
        method: "POST",
        body: questionsData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setQuestions({
            questionsData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setQuestions({ errorMessage: error?.data?.violations }));
      }
    ),
    getQuestions: buildMutationHelper(
      builder,
      (id) => ({
        url: `/tna/question/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const data = response?.data;
        dispatch(
          setQuestions({
            questionsData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setQuestions({ errorMessage: error?.data?.error }));
      }
    ),
    editQuestions: buildMutationHelper(
      builder,
      ({ questionsData, id }) => ({
        url: `/tna/question/${id}`,
        method: "PATCH",
        body: JSON.stringify(questionsData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setQuestions({
            questionsData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setQuestions({ errorMessage: error?.data?.error }));
      }
    ),
    deleteQuestions: buildMutationHelper(
      builder,
      (id) => ({
        url: `/tna/question/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setQuestions({
            questionsData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setQuestions({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const {
  useQuestionsListMutation,
  useAddQuestionMutation,
  useGetQuestionsMutation,
  useEditQuestionsMutation,
  useDeleteQuestionsMutation,
} = questionsApi;
