import { useTranslation } from "react-i18next";
import {
  Combobox,
  Divider,
  Text,
  makeStyles,
  mergeClasses,
  typographyStyles,
  shorthands,
  Option,
} from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
  },
  description: {
    ...typographyStyles.caption1,
  },
});

const SystemUserTab = ({
  options,
  projectId,
  selectedOptionProjectId,
  setSelectedOptionProjectId,
  setProjectUsers,
}) => {
  const { t } = useTranslation();
  const layout = Layout();
  const [customSearch, setCustomSearch] = useState("" | undefined);
  // eslint-disable-next-line no-unused-vars
  const [randomSearch, setRandomSearch] = useState("");
  const [matchingOptions, setMatchingOptions] = useState([...options]);
  const [value, setValue] = useState("");

  const styles = useStyles();

  useEffect(() => {
    setMatchingOptions([...options]);
  }, [options]);

  const onSelectOption = (e, data) => {
    // setMatchingOptions([...options]);
    setSelectedOptionProjectId(data?.selectedOptions);

    const matchingOption =
      data?.optionText && matchingOptions?.includes(data?.optionText);
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data?.optionText);
    }

    setProjectUsers({
      projectId: projectId,
      userIds: data?.selectedOptions,
    });
  };

  const chosenArray = options.filter((item) =>
    [...new Set(selectedOptionProjectId)]?.includes(item?.value)
  );

  const onChange = (event) => {
    setRandomSearch(event?.target?.value);
    const value = event?.target?.value?.trim();
    const matches = options?.filter((option) =>
      option?.children?.toLowerCase().includes(value?.toLowerCase())
    );
    setMatchingOptions(matches);
    if (value?.length && matches?.length < 1) {
      setCustomSearch(value);
    } else {
      setCustomSearch(undefined);
    }
  };

  const onInput = (ev) => {
    setValue(ev.target.value);
  };

  return (
    <>
      <Stack className={mergeClasses(styles.root)}>
        <Combobox
          multiselect={true}
          className={mergeClasses(layout.w_100, layout.min_w_46)}
          placeholder={t("search")}
          onOptionSelect={onSelectOption}
          selectedOptions={[...new Set(selectedOptionProjectId)]}
          onChange={onChange}
          matchingOptions={matchingOptions}
          value={value}
          onInput={onInput}
        >
          {!matchingOptions && customSearch && (
            <Option key="freeform" text={customSearch}></Option>
          )}
          {matchingOptions?.map((option, key) => (
            <Option key={key} className="field-option" value={option?.value}>
              {option?.children}
            </Option>
          ))}
        </Combobox>
      </Stack>
      <Text
        className={mergeClasses(
          layout.fontWeightBold,
          layout.color616161,
          layout.mt_1_0,
          layout.mb_1_0
        )}
      >
        {chosenArray?.length > 0 ? t("selected_users") : ""}
      </Text>
      <Stack className={mergeClasses(layout.mx_over)}>
        {chosenArray.map((item, key) => (
          <Stack key={key}>
            <Divider />
            <Stack
              key={key}
              className={mergeClasses(layout.mt_1_0, layout.mb_1_0)}
            >
              {item.children}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default SystemUserTab;
