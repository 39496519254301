import {
  TableBody as FluentTableBody,
  Spinner,
  mergeClasses,
} from "@fluentui/react-components";
import Style from "./Style";
import Layout from "../../styles/Layout.styles";
import { Stack } from "@fluentui/react";
import TableRow from "./TableRow";
import TableCell from "./TableCell";

const TableBody = (props) => {
  const { children, className, isLoading } = props;
  const style = Style();
  const layout = Layout();

  return (
    <>
      {isLoading ? (
        <FluentTableBody
          className={mergeClasses(style.customTblBody, className)}
        >
          <TableRow className={style.spinnerTableRow}>
            <TableCell className={style.spinnerTableCol}>
              <Stack
                className={mergeClasses(layout.positionRelative, layout.w_100)}
              >
                <Spinner className={mergeClasses(layout.m_auto)} />
              </Stack>
            </TableCell>
          </TableRow>
        </FluentTableBody>
      ) : (
        <FluentTableBody
          className={mergeClasses(style.customTblBody, className)}
        >
          {children}
        </FluentTableBody>
      )}
    </>
  );
};

export default TableBody;
