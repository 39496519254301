import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./i18n";
import { Providers } from "./store/Provider";

if (process.env.NODE_ENV === "development") {
  const originalWarn = console.error;
  console.error = (message, ...args) => {
    if (/Each child in a list should have a unique "key" prop/.test(message)) {
      throw new Error(message);
    }
    originalWarn(message, ...args);
  };
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
);
