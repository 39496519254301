import Style from "../AddProject.style";
import { useTranslation } from "react-i18next";
import { Stack } from "@fluentui/react";
import FieldDatepicker from "../../../../components/FieldComponents/FieldDatepicker";
import { useState } from "react";
import { format } from "date-fns";

const CompletionDate = (props) => {
  const { completionDate, control, setValue } = props;
  const { t } = useTranslation();
  const style = Style();
  const [selectedDate, setSelectedDate] = useState("");

  const onFormatDate = (date) => {
    return !date ? "" : format(new Date(date), "yyyy/MM/dd");
  };

  const onSelectDate = (e) => {
    setValue(
      "completionDate",
      new Date(e),
      !completionDate && { shouldValidate: true }
    );

    setSelectedDate(e);
  };

  return (
    <>
      <Stack className={style.client_field}>
        <FieldDatepicker
          label={t("project.completion_date")}
          control={control}
          onSelectDate={onSelectDate}
          name="completionDate"
          placeholderText={t("project.select_completion_date")}
          defaultValue={
            selectedDate
              ? new Date(selectedDate)
              : completionDate
              ? new Date(completionDate)
              : ""
          }
          onFormatDate={onFormatDate}
          validationMessage={props?.errors?.completionDate?.message}
          validation={props.register("completionDate", {
            required: `${t("validation_message.completiondate_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default CompletionDate;
