import { useTranslation } from "react-i18next";
import { useStyle } from "../Style";
import { TableHeader, TableHeaderCell, TableRow } from "../../../components";
import { Stack } from "@fluentui/react";
import { Text, mergeClasses } from "@fluentui/react-components";
import Layout from "../../../styles/Layout.styles";

const DetailedTimesheetHeader = (props) => {
  const { timesheetHeader = [] } = props;
  const { t } = useTranslation();
  const style = useStyle();
  const layout = Layout();

  return (
    <TableHeader className={mergeClasses(layout.headerBackground)}>
      <TableRow className={layout.color475569}>
        <TableHeaderCell
          className={mergeClasses(style.teamsHeader, layout.fontWeightSemibold)}
        >
          {t("time_and_attandance.teams_user")}
        </TableHeaderCell>
        <TableHeaderCell
          className={mergeClasses(
            style.loggedHeader,
            layout.fontWeightSemibold
          )}
        >
          {t("time_and_attandance.logged")}
        </TableHeaderCell>
        {timesheetHeader.map((i, key) => (
          <TableHeaderCell key={key} className={style.dayHeader}>
            <Stack className={style.headerCellWrapper}>
              <Text
                className={mergeClasses(
                  style.headerLabel,
                  layout.fontWeightSemibold
                )}
              >
                {i.monthDate}
              </Text>
              <Text
                className={mergeClasses(
                  style.headerLabel,
                  layout.fontWeightSemibold
                )}
              >
                {i.weekDay}
              </Text>
            </Stack>
          </TableHeaderCell>
        ))}
      </TableRow>
    </TableHeader>
  );
};

export default DetailedTimesheetHeader;
