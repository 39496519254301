import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { useTranslation } from "react-i18next";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";

const Builder = (props) => {
  const { builder } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t('project.builder')}
          validationMessage={props.errors?.builder?.message}
          id="builder"
          placeholder={t('project.enter_builder_name')}
          name="builder"
          inputDefaultValue={builder}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("builder", {
            required: `${t('validation_message.builder_name_is_required')}`,
          })}
        />
      </Stack>
    </>
  );
};

export default Builder;
