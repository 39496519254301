import { makeStyles, shorthands } from "@fluentui/react-components";
import { tokens } from "@fluentui/react-theme";

const Common = makeStyles({
  root: {
    ...shorthands.border("2px", "solid", "#ccc"),
    ...shorthands.overflow("hidden"),
    display: "flex",
    height: "480px",
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    fontSize: "22px",
  },
  content: {
    ...shorthands.margin(tokens.spacingVerticalXL, tokens.spacingHorizontalXL),
    ...shorthands.flex(1),
    ...shorthands.margin(0),
    ...shorthands.overflow("auto"),
    gridRowGap: tokens.spacingVerticalXXL,
  },

  tokenColor: { color: tokens.colorBrandBackground },

  overflow_auto: {
    ...shorthands.overflow("auto"),
  },

  over_y: {
    overflowX: "auto",
  },
  d_flex: {
    display: "flex",
  },
  flex_row: {
    flexDirection: "row",
  },
  wrap: {
    flexWrap: "wrap",
  },
  flex_column: {
    flexDirection: "column",
  },
  flex_between: {
    justifyContent: "space-between",
  },
  flex_around: {
    justifyContent: "space-around",
  },
  align_items_end: {
    alignItems: "flex-end",
  },
  w_25: {
    width: "25%",
  },
  w_50: {
    width: "50%",
  },
  w_100: {
    width: "100%",
  },
  w_px: {
    width: "260px",
  },
  w_lg_50: {
    "@media screen and (min-width: 992px)": {
      width: "50%",
    },
  },
  m_auto: {
    ...shorthands.margin("auto"),
  },
  m_0: {
    ...shorthands.margin(0),
  },
  m_10: {
    ...shorthands.margin("10px"),
  },

  mr_1: {
    ...shorthands.margin(0, "10px", 0, 0),
  },
  mr_1_5: {
    ...shorthands.margin(0, "15px", 0, 0),
  },
  h100vh: {
    height: "100vh",
  },
  lg_h100vh: {
    "@media screen and (min-width: 992px)": {
      height: "100vh",
    },
  },
  headerSearchResp: {
    "@media screen and (max-width: 1025px)": {
      flexDirection: "column",
    },
  },
  headerSearch: {
    flexDirection: "row-reverse",
  },
  drawerBody: {
    ...shorthands.padding(0, 0, 0, "19px"),
  },
  mainContent: {
    ...shorthands.margin("30px"),
  },
  mb_0_5: {
    ...shorthands.margin(0, 0, "5px", 0),
  },
  mb_1: {
    ...shorthands.margin(0, 0, "10px", 0),
  },
  mt_1: {
    ...shorthands.margin("10px", 0, 0, 0),
  },
  mt_0: {
    marginTop: "0px",
  },
  mt_2: {
    marginTop: "20px",
  },
  mt_3: {
    marginTop: "30px",
  },
  mt_1_0: {
    marginTop: "10px",
  },
  mb_1_0: {
    marginBottom: "10px",
  },
  m_0_5: {
    ...shorthands.margin("5px"),
  },
  mx_over: {
    maxHeight: "164px",
    overflowY: "auto",
  },
  mb_2: {
    ...shorthands.margin(0, 0, "20px", 0),
  },
  mx_2: {
    ...shorthands.margin(0, "20px", 0, "20px"),
  },
  mb_2_4: {
    ...shorthands.margin(0, 0, "24px", 0),
  },
  mb_3_4: {
    ...shorthands.margin(0, 0, "34px", 0),
  },
  textCenter: {
    textAlign: "center",
  },
  pl_10: {
    paddingLeft: "10px",
  },
  pl_8: {
    paddingLeft: "8px",
  },
  px_0: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  fontWeight: {
    fontWeight: "bold",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignTop: {
    alignItems: "flex-start",
  },
  forgetLink: {
    position: "absolute",
    right: 0,
  },
  positionRelative: {
    position: "relative",
  },
  border: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
  },
  padding1_5: {
    ...shorthands.padding("15px"),
  },
  padding1_5x: {
    ...shorthands.padding(0, "15px"),
  },
  padding1_5y: {
    ...shorthands.padding("15px", 0),
  },
  padding2: {
    ...shorthands.padding("20px"),
  },
  p_0: {
    ...shorthands.padding("0"),
  },
  py_0: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  pb_0: {
    paddingBottom: 0,
  },
  pt_0: {
    paddingTop: 0,
  },
  align_self_start: {
    alignSelf: "start",
  },
  gap_15: {
    ...shorthands.gap("15px"),
  },
  customTable: {
    borderRadiusMedium: tokens.borderRadiusMedium,
    ...shorthands.border("1px", "solid", "#E2E8F0"),
    "@media (max-width: 1024px)": {
      display: "block",
      ...shorthands.overflow("auto", "auto"),
    },
  },
  customHeader: {
    ...shorthands.borderTop("1px", "solid", "#E2E8F0"),
    ...shorthands.borderRight("1px", "solid", "#E2E8F0"),
    backgroundColor: tokens.colorNeutralBackground2,
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
  customTblCell: {
    ...shorthands.padding("", "12px"),
    borderRadiusMedium: tokens.borderRadiusMedium,
    ...shorthands.borderRight("1px", "solid", "#E2E8F0"),
  },
  headerLabel: {
    textAlign: "center",
    whiteSpace: "nowrap",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "40px",
  },
  headerBackground: {
    backgroundColor: tokens.colorSubtleBackgroundHover,
  },
  columnHeader: {
    ...shorthands.borderRight("1px", "solid", "#E2E8F0"),
  },
  tableIcons: {
    display: "flex",
    flexDirection: "row",
  },
  tableIcon: {
    minWidth: "auto",
    ...shorthands.padding("0", "6px"),
    ...shorthands.border("none"),
    backgroundColor: "transparent",
    color: tokens.colorBrandBackgroundPressed,
  },
  projectContent: {
    ...shorthands.padding("20px"),
    ...shorthands.margin("30px", 0, 0, 0),
  },
  pl_0: {
    paddingLeft: 0,
  },
  my_auto: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  // projectFilter: {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   flexDirection: "row",
  //   marginBottom: "20px",
  //   "@media (max-width: 660px)": {
  //     flexDirection: "column",
  //     ...shorthands.gap("10px"),
  //     ...shorthands.margin(0, 0, "20px", 0),
  //   },
  // },

  projectFilter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
    ...shorthands.gap("10px"),
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "start",
    },
    // "@media (max-width: 660px)": {
    //   width: "100%",
    //   flexDirection: "column",
    //   alignItems: "start",
    // },
  },

  filterWrapper: {
    ...shorthands.gap("10px"),
  },
  detailedFilter: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "@media (max-width: 660px)": {
      flexDirection: "column",
      ...shorthands.gap("10px"),
    },
  },

  h_40: {
    height: "40px",
  },
  h_32: {
    height: "32px",
  },
  mh_40: {
    minHeight: "40px",
  },
  mt_0: {
    marginTop: 0,
  },
  mb_1_8: {
    marginBottom: "18px",
  },
  mx_1: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  mr_5: {
    marginRight: "5px",
  },
  size18: {
    fontSize: "18px",
  },
  textNowrap: {
    textWrap: "nowrap",
    whiteSpace: "nowrap",
  },
  textWrap: {
    textWrap: "wrap",
  },
  textOverFlowHidden: {
    ...shorthands.overflow("hidden"),
  },
  primary: {
    color: tokens.colorBrandBackgroundPressed,
  },
  sidebarColor: {
    color: tokens.colorBrandBackgroundPressed,
  },
  cursor_pointer: {
    cursor: "pointer",
  },
  detailsSplite: {
    ...shorthands.padding("15px"),
    wordBreak: "break-word",
    display: "flex",
    "@media (max-width: 1024px)": {
      flexDirection: "column",
    },
  },
  themeSwitch: {
    ...shorthands.margin("-10px", 0, 0, 0),
  },
  w_60: {
    width: "60%",
  },
  alignItemEnd: {
    alignItems: "flex-end",
  },
  min_w_100: {
    minWidth: "100px",
  },
  min_w_46: {
    minWidth: "46px",
  },
  min_w_50: {
    minWidth: "50px",
  },

  min_w_200: {
    minWidth: "200px",
  },
  customEdit: {
    minWidth: 0,
    color: tokens.colorBrandBackgroundPressed,
    ...shorthands.padding(0),
  },
  mb_14: {
    ...shorthands.margin(0, 0, "14px", 0),
  },
  mb_15: {
    ...shorthands.margin(0, 0, "15px", 0),
  },
  smallBtn: {
    minWidth: "51px",
  },
  customTags: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    borderRadiusMedium: tokens.borderRadiusMedium,
    flexDirection: "column",
  },
  customTag: {
    ...shorthands.margin("10px"),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    ...shorthands.margin("10px", 0),
    justifyContent: "space-between",
  },
  colorEbc2f32: {
    color: "#bc2f32",
  },
  color475569: {
    color: "#475569",
  },
  color616161: {
    color: tokens.colorNeutralForeground3,
  },
  detailsText: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  maxHeight30: {
    maxHeight: "30px",
  },
  maxWidth32: {
    maxWidth: "32px",
  },
  height30: {
    height: "30px",
  },
  my2: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  pr1: {
    paddingRight: "10px",
  },
  justifyContent: {
    justifyContent: "center",
  },
  justifyContentTop: {
    justifyContent: "top",
  },
  p_1: {
    ...shorthands.padding("10px"),
  },
  p_2: {
    ...shorthands.padding("20px"),
  },
  py_1: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  bgF8FAFC: {
    backgroundColor: "#F8FAFC",
  },
  lnColor: {
    color: "#0C3B5E",
  },
  greenColor: {
    color: "green",
  },
  ml_2_0: {
    marginLeft: "20px",
  },
  overFlowHidden: {
    ...shorthands.overflow("hidden"),
  },
  gap10: {
    ...shorthands.gap("10px"),
  },
  gap1: {
    ...shorthands.gap("1px"),
  },
  borderNone: {
    ...shorthands.border(0),
  },
  justifyContentLeft: {
    justifyContent: "left",
  },
  textLeft: {
    textAlign: "left",
  },
  w_auto: {
    width: "auto",
  },
  borderRadiusTop: {
    borderTopLeftRadius: tokens.borderRadiusMedium,
    borderTopRightRadius: tokens.borderRadiusMedium,
  },
  borderRadiusBottom: {
    borderBottomLeftRadius: tokens.borderRadiusMedium,
    borderBottomRightRadius: tokens.borderRadiusMedium,
  },
  bundleCard: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("20px"),
    flexWrap: "wrap",
  },
  justifyContentStart: {
    justifyContent: "start",
  },
  justifyContentEnd: {
    justifyContent: "end",
  },
  alignItemsEnd: {
    alignItems: "flex-end",
  },
  alignItemsStart: {
    alignItems: "flex-start",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  ml_1: {
    marginLeft: "10px",
  },
  ml_65: {
    marginLeft: "65px",
  },
  borderLeft: {
    ...shorthands.borderLeft("3px solid "),
  },

  modalBackground: {
    backdropFilter: "blur(10px)",
    position: "absolute",
    ...shorthands.overflow("auto"),
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    zIndex: 9999,
  },
  cardPopup: {
    ...shorthands.padding("20px"),
    backgroundColor: "white",
    marginTop: "50%",
    minWidth: "300px",
    position: "absolute",
    ...shorthands.overflow("auto"),
    top: "20%",
    left: "10px",
    right: "10px",
    maxWidth: "500px",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 9999,
  },
  hourStyle: {
    marginRight: "16px",
    fontSize: "16px",
  },
  durationText: {
    color: "#d13438",
  },
  clockLogTable: {
    "@media screen and (min-width: 768px)": {
      tableLayout: "auto",
    },
  },
  size15: {
    fontSize: "15px",
  },
  fontSize12: {
    fontSize: "12px",
  },
  fontSizeLarge: {
    fontSize: "large",
  },
  whiteSpace: {
    whiteSpace: "pre",
  },
});

export default Common;
