import { Stack, Text } from "@fluentui/react";
import Layout from "../../../styles/Layout.styles";
import Style from "./ViewProject.style";
import React, { useEffect, useState } from "react";
import {
  Divider,
  Toast,
  ToastTitle,
  Toaster,
  mergeClasses,
  useId,
  useToastController,
} from "@fluentui/react-components";
import {
  useGetProjectMutation,
  useProjectStatusChangeMutation,
} from "../../../store/action/project";
import { useTranslation } from "react-i18next";
import { statusType } from "../../../utils/projectStatus";
import { format } from "date-fns";
import ProjectFilter from "../ProjectList/ProjectFilter";
import { Breadcrumb } from "../../../components";
import accessRole from "../../../components/Common/accessRole";
import FieldRadioButton from "../../../components/FieldComponents/FieldRadioButton";
import { useDispatch } from "react-redux";
import { setProject } from "../../../store/reducer/project";

const ViewProjectForm = (props) => {
  const layout = Layout();
  const style = Style();
  const [viewData, setViewData] = useState();
  const [initialLoad, setInitialLoad] = useState(true);
  const [GetProjectApi, { isLoading, isError }] = useGetProjectMutation();
  const [ProjectStatusChange] = useProjectStatusChangeMutation();
  const { t } = useTranslation();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const dispatch = useDispatch();
  const [statusArray, setLoadStatus] = useState([]);
  const [activeStatus, setActiveStatus] = useState();

  const awaitingOptions = [
    { value: "awaiting_approval", label: "Awaiting approval" },
    { value: "live", label: "Approved" },
  ];

  const liveOptions = [
    { value: "live", label: "Approved" },
    { value: "completed", label: "Completed" },
  ];
  const completedOptions = [{ value: "completed", label: "Completed" }];

  const updateStatus = (status) => {
    if (status === "awaiting_approval") {
      setLoadStatus(awaitingOptions);
    } else if (status === "live") {
      setLoadStatus(liveOptions);
    } else {
      setLoadStatus(completedOptions);
    }
  };

  const breadcrumbData = [
    {
      title: `${t("project.project")}`,
      url: "/project-list",
    },
    {
      title: `${t("project_details")}`,
    },
  ];
  const { id } = props;
  useEffect(() => {
    updateStatus(viewData?.data?.status);
    setActiveStatus(viewData?.data?.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        if (id) {
          GetProjectApi(props?.id).then((res) => {
            setViewData(res);
          });
        }
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isLoading]);

  if (isError || viewData === undefined) {
    return "";
  }

  const notify = (status) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`Project is ${status}`}</ToastTitle>
      </Toast>,
      { intent: "success" }
    );
  };

  const onStatusChange = (e, data) => {
    const projectData = {
      status: data?.value,
    };
    ProjectStatusChange({ projectData, id: props?.id });
    notify(data?.value);
    updateStatus(data?.value);
    setActiveStatus(data?.value);
    dispatch(setProject({ trigger: true }));
  };

  if (viewData?.data?.status === undefined || statusArray === undefined) {
    return "";
  }

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack
        className={mergeClasses(
          layout.projectContent,
          layout.w_100,
          style.marginTop29,
          layout.pb_0,
          "cardShadow"
        )}
      >
        {accessRole()?.has("project_status_update") && (
          <>
            <Stack
              className={mergeClasses(
                layout.flex_row,
                layout.w_100,
                layout.flex_between,
                layout.justifyContentEnd,
                layout.mb_2
              )}
            >
              <Stack>
                <Toaster position="top" timeout={3000} toasterId={toasterId} />
                <Stack
                  className={mergeClasses(
                    layout.d_flex,
                    layout.flex_row,
                    layout.alignCenter
                  )}
                >
                  <label
                    className={mergeClasses(layout.mr_1, layout.fontWeight)}
                  >
                    {t("view_project_status")}{" "}
                  </label>
                  <FieldRadioButton
                    className={mergeClasses(
                      layout.mr_1,
                      layout.alignCenter,
                      layout.mb_0
                    )}
                    labelClass={false}
                    radioValue={statusArray}
                    defaultValue={activeStatus}
                    defaultValueKey={true}
                    onChange={onStatusChange}
                    inputClassName={mergeClasses(
                      style.textboxInput,
                      layout.alignCenter,
                      layout.mb_0,
                      layout.w_100
                    )}
                    noRequired
                  />
                </Stack>
              </Stack>

              <Stack className={layout.padding1_5x}>
                <ProjectFilter projectId={`${viewData?.data?.id}`} />
              </Stack>
            </Stack>{" "}
            <Divider />
          </>
        )}
        <Stack className={mergeClasses(style.projectMainDetails)}>
          <Stack className={mergeClasses(style.projectViewLeft)}>
            <Stack className={layout.my_auto}>
              <Stack
                className={mergeClasses(
                  style.padding2,
                  layout.border,
                  layout.textCenter
                )}
              >
                <h2
                  className={mergeClasses(
                    style.headerTitle,
                    layout.padding2,
                    layout.mt_0
                  )}
                >
                  {`${viewData?.data?.title}`}
                </h2>
                <h4 className={mergeClasses(style.projectCode, layout.mt_0)}>
                  {t("project.project")} #
                  <Text
                    className={mergeClasses(style.projectCodeValue)}
                  >{`${viewData?.data?.projectCode}`}</Text>
                </h4>
              </Stack>

              <Stack
                className={mergeClasses(
                  style.padding2,
                  layout.flex_row,
                  layout.textCenter
                )}
              >
                <Text className={mergeClasses(style.projectDetail)}>
                  <Text className={mergeClasses(style.detailsLeft)}>
                    {t("project.project_type")}
                  </Text>
                  <Text>
                    {viewData && viewData.data && statusType(activeStatus)}
                  </Text>
                </Text>
                <Text className={mergeClasses(style.projectDetail)}>
                  <Text className={mergeClasses(style.detailsLeft)}>
                    {t("project.commencement_date")}
                  </Text>
                  <Text>
                    {viewData?.data?.commencementDate &&
                      format(
                        new Date(viewData?.data?.commencementDate),
                        "yyyy/MM/dd"
                      )}
                  </Text>
                </Text>
              </Stack>
              <Stack
                className={mergeClasses(
                  style.padding2,
                  layout.flex_row,
                  layout.textCenter
                )}
              >
                <Text className={mergeClasses(style.projectDetail)}>
                  <Text className={mergeClasses(style.detailsLeft)}>
                    {t("project.completion_date")}
                  </Text>
                  <Text>
                    {viewData?.data?.completionDate &&
                      format(
                        new Date(viewData?.data?.completionDate),
                        "yyyy/MM/dd"
                      )}
                  </Text>
                </Text>
                <Text className={mergeClasses(style.projectDetail)}>
                  <Text className={mergeClasses(style.detailsLeft)}>
                    {t("project.total_team_member")}
                  </Text>
                  <Text>{`${viewData?.data?.totalMember}`}</Text>
                </Text>
              </Stack>
              <Stack
                className={mergeClasses(
                  style.padding2,
                  layout.flex_row,
                  layout.textCenter
                )}
              >
                <Text className={mergeClasses(style.projectDetail)}>
                  <Text className={mergeClasses(style.detailsLeft)}>
                    {t("project.branch")}
                  </Text>
                  <Text>{`${viewData?.data?.branch}`}</Text>
                </Text>
                <Text className={mergeClasses(style.projectDetail)}>
                  <Text className={mergeClasses(style.detailsLeft)}>
                    {t("project.contract_value")}
                  </Text>
                  <Text className={layout.fontWeightBold}>
                    ${`${viewData?.data?.contractValue.toLocaleString()}`}
                  </Text>
                </Text>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            className={mergeClasses(style.projectViewRight, layout.padding2)}
          >
            <Text className={style.detailsText}>
              <Text className={style.textElement}>
                {t("project.todays_clock_in")}
              </Text>
              <Text
                className={style.inputValue}
              >{`${viewData?.data?.todayClockIn}`}</Text>
            </Text>
            <Divider />
            <Text className={style.detailsText}>
              <Text className={style.textElement}>
                {t("project.client_contact_name")}
              </Text>
              <Text
                className={style.inputValue}
              >{`${viewData?.data?.clientContactName}`}</Text>
            </Text>
            <Divider />
            <Text className={style.detailsText}>
              <Text className={style.textElement}>
                {t("project.client_contact_number")}
              </Text>
              <Text className={style.inputValue}>
                {`${viewData?.data?.clientContactNumber}`}
              </Text>
            </Text>
            <Divider />
            <Text className={style.detailsText}>
              <Text className={style.textElement}>
                {t("project.site_phone_number")}
              </Text>
              <Text className={style.inputValue}>
                ({`${viewData?.data?.sitePhonePrefix}`}){" "}
                {`${viewData?.data?.sitePhoneNumber}`}
              </Text>
            </Text>
            <Divider />
            <Text className={style.detailsText}>
              <Text className={style.textElement}>{t("project.builder")}</Text>
              <Text className={style.inputValue}>
                {`${viewData?.data?.builder}`}
              </Text>
            </Text>
            <Divider />
            <Text className={mergeClasses(style.detailsText)}>
              <Text className={style.textElement}>
                {t("project.project_management_team")}
              </Text>
              <Text className={style.inputValue}>{`${
                viewData?.data?.projectManagementTeam?.length > 0
                  ? viewData?.data?.projectManagementTeam
                  : "-"
              }`}</Text>
            </Text>
            <Divider />
            <Text className={style.detailsText}>
              <Text className={style.textElement}>
                {t("project.design_team")}
              </Text>
              <Text className={style.inputValue}>
                {`${
                  viewData?.data?.designTeam?.length > 0
                    ? viewData?.data?.designTeam
                    : "-"
                }`}
              </Text>
            </Text>
            <Divider />
            <Text className={style.detailsText}>
              <Text className={style.textElement}>
                {t("project.time_zone")}
              </Text>
              <Text className={style.inputValue}>
                {`${
                  viewData?.data?.timezone?.length > 0
                    ? viewData?.data?.timezone
                    : "-"
                }`}
              </Text>
            </Text>
            <Divider />
            <Text className={mergeClasses(style.detailsText)}>
              <Text className={style.textElement}>
                {t("project.project_address")}
              </Text>
              <Text className={style.inputValue}>{`${
                viewData?.data?.address?.length > 0
                  ? viewData?.data?.address
                  : "-"
              }`}</Text>
            </Text>
            <Divider />
            <Text className={mergeClasses(style.detailsText)}>
              <Text className={style.textElement}>
                {t("project.description")}
              </Text>
              <Text
                className={style.inputValue}
              >{`${viewData?.data?.description}`}</Text>
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewProjectForm;
