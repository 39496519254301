import React, { useEffect, useState } from "react";
import { Text } from "@fluentui/react-components";
import ClientFilter from "./ClientFilter";
import { Stack } from "@fluentui/react";
import Pagination from "../../../components/Pagination";
import { mergeClasses } from "@fluentui/react-components";
import { useGetClientListMutation } from "../../../store/action/client";
import ClientListRow from "./ClientListRow";
import Breadcrumb from "../../../components/Breadcrumb";
import { useSelector } from "react-redux";
import { setClient } from "../../../store/reducer/client";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../components/Table";
import Layout from "../../../styles/Layout.styles";
import { useTranslation } from "react-i18next";
import accessRole from "../../../components/Common/accessRole";

const ClientList = (props) => {
  const role = accessRole();
  const layout = Layout();
  const [getClientListApi, { isLoading }] = useGetClientListMutation();
  const ClientList = useSelector((state) => state.client);
  const [initialLoad, setInitialLoad] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getClientListApi().then((res) => {});
        setInitialLoad(false);
      }
    }
  }, [initialLoad, getClientListApi, isLoading, ClientList]);

  const columns = [
    { columnKey: "companyName", label: t("company_name"), isSort: true },
    { columnKey: "companyAddress", label: t("company_address"), isSort: true },
    { columnKey: "email", label: t("email"), isSort: true },
    {
      columnKey: "accountPerson.firstName",
      label: t("account_person"),
      isSort: true,
    },
    {
      columnKey: "contactPerson.firstName",
      label: t("contact_person"),
      isSort: true,
    },
    { columnKey: "action", label: t("action") },
    {
      columnKey: "activeInactive",
      label: t("activeInactive"),
    },
  ];

  const breadcrumbData = [
    {
      title: t("client"),
      url: "/organization-list",
    },
    {
      title: t("client_list"),
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
        <ClientFilter
          getClientListApi={getClientListApi}
          setInitialLoad={setInitialLoad}
          role={role}
        />
        <Stack className="contentData">
          <Stack className="overflow-y-auto">
            <Table className="tbl-layout-auto">
              <TableHeader className={layout.headerBackground}>
                <TableRow>
                  {columns.map((column, key) => (
                    <TableHeaderCell
                      key={key}
                      onSortApi={getClientListApi}
                      onSet={setClient}
                      column={column}
                      sortBy={column?.isSort ? ClientList?.sortBy : false}
                    >
                      <Text className={layout.headerLabel}>{column.label}</Text>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody isLoading={isLoading}>
                {ClientList?.data?.map((item, key) => (
                  <ClientListRow item={item} key={key} role={role} />
                ))}
              </TableBody>
            </Table>
          </Stack>

          <Pagination
            totalCount={ClientList?.totalCount}
            itemsPerPage={ClientList?.itemsPerPage}
            onSetListReducer={setClient}
            onGetList={getClientListApi}
            page={ClientList.page}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ClientList;
