import * as React from "react";
import {
  mergeClasses,
  Spinner,
  Toast,
  Toaster,
  ToastTitle,
  Tooltip,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { SearchBox } from "@fluentui/react-search-preview";
import { Button } from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../../../styles/Layout.styles";
import { setSystemEndUser } from "../../../store/reducer/systemEndUser";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import FieldCombobox from "../../../components/FieldComponents/FieldCombobox";
import { useSyncTnaEndUserMutation } from "../../../store/action/externalSources";

const SystemEndUserFilter = ({
  systemEndUserId,
  getSystemEndUserListApi,
  setInitialLoad,
  role,
}) => {
  const layout = Layout();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [importApi, { isLoading }] = useSyncTnaEndUserMutation();
  const systemEndUserList = useSelector((state) => state.systemEndUser);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const canBeImportEmployee = localStorage.getItem("canBeImportEmployee");

  const notify = (message, valid) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`${message}`}</ToastTitle>
      </Toast>,
      { intent: valid ? "success" : "error" }
    );
  };

  const onChange = (data) => {
    dispatch(setSystemEndUser({ page: 0, search: data }));
    getSystemEndUserListApi();
    setInitialLoad(false);
  };

  const onStatusChange = (e, data) => {
    dispatch(setSystemEndUser({ page: 0, isActive: data?.optionValue }));
    getSystemEndUserListApi();
  };

  const options = [
    { value: "true", children: t("active") },
    { value: "false", children: t("inactive") },
    { value: "undefined", children: t("all") },
  ];

  const matchedOption = options.find(
    (option) => option.value === systemEndUserList?.isActive
  );

  const handleImport = () => {
    importApi({ endUserData: {} }).then((res) => {
      notify(
        "Import of users is in progress, it may take some more time.",
        // `Created : ${res?.data?.created}, Updated : ${res?.data?.updated}`,
        true
      );
    });
  };

  return (
    <>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <Stack className={layout.projectFilter}>
        <SearchBox
          placeholder={t("search")}
          className={mergeClasses(
            layout.headerSearch,
            "headerSearch",
            "no-reset"
          )}
          defaultValue={systemEndUserList?.search}
          iconProps={{ iconName: "Search", styles: { root: { right: "8px" } } }}
          onChange={(e) => onChange(e?.target?.value)}
        />
        <Stack className={mergeClasses(layout.projectFilter)}>
          <FieldCombobox
            multiselect={false}
            name="status"
            readOnly
            stackCombobox="minwidth-combobox"
            className={mergeClasses(layout.mr_1)}
            defaultValue={matchedOption?.children}
            defaultSelectedOptions={[matchedOption?.value]}
            onOptionSelect={onStatusChange}
            matchingOptions={options}
          />
          {(role?.has("system_end_user_create") ||
            role?.has("system_end_user_edit")) && (
            <Link
              to={
                systemEndUserId
                  ? `/system-end-user-list/${systemEndUserId}/edit`
                  : "/system-end-user-list/add"
              }
            >
              <Button
                appearance="primary"
                className={mergeClasses(
                  layout.smallBtn,
                  layout.mr_1,
                  "custom-primary"
                )}
              >
                {systemEndUserId ? t("users.edit") : t("users.add")}
              </Button>
            </Link>
          )}
          {role?.has("system_end_user_import") &&
            canBeImportEmployee === "true" && (
              <Tooltip
                withArrow
                content={"import employees form employment hero"}
              >
                <Button
                  appearance="primary"
                  className={mergeClasses(
                    layout.smallBtn,
                    layout.mr_1,
                    "custom-primary"
                  )}
                  onClick={handleImport}
                >
                  {isLoading ? (
                    <Spinner appearance="inverted" size="tiny" />
                  ) : (
                    t("import")
                  )}
                </Button>
              </Tooltip>
            )}
        </Stack>
      </Stack>
    </>
  );
};

export default SystemEndUserFilter;
