import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  projectHeader: {
    fontSize: "18px",
    fontWeight: tokens.fontWeightSemibold,
    ...shorthands.margin(0),
    ...shorthands.padding("5px", 0),
  },
  card: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "33.33%",
    width: "calc( 33.33% - 20px )",
    "@media screen and (max-width: 1280px)": {
      maxWidth: "50%",
      width: "calc( 50% - 20px )",
    },
    "@media screen and (max-width: 768px)": {
      maxWidth: "100%",
      width: "calc( 100% - 20px )",
    },
    ...shorthands.margin("10px"),
  },
  cardTitle: {
    height: "40px",
    paddingLeft: "15px",
    justifyContent: "center",
  },
  cardBody: {
    ...shorthands.padding("15px"),
  },
  mainCard: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: "-10px",
    marginRight: "-10px",
  },
  maxdropHeight: {
    maxHeight: "10px",
  },
  client_field: {
    ...shorthands.margin(0, 0, "15px", 0),
    "@media (min-width: 1024px)": {
      width: "calc(100% - 15px)",
    },

    "@media (max-width: 1024px)": {
      width: "100%",
    },
  },
});

export default style;
