import React from "react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Drawer,
  Image,
  mergeClasses,
} from "@fluentui/react-components";
import Menu from "./Menu";
import LayoutStyle from "../../styles/Layout.styles";
import staticStyle from "./staticStyle";
import { headerImage } from "./SideMenuRoute";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

const Sidebar = (props) => {
  staticStyle();
  const { type, isOpen, setIsOpen, openAccordion, setOpenAccordion } = props;
  const layout = LayoutStyle();
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const { t } = useTranslation();
  const sidebarOpen = isOpen === true ? "" : "sidebar-close";

  const onMediaQueryChange = useCallback(
    ({ matches }) => setSideBarOpen(matches ? isOpen : true),
    [isOpen]
  );

  useEffect(() => {
    const match = window.matchMedia("(max-width: 767px)");

    if (match.matches) {
      setSideBarOpen(isOpen);
    } else {
      setSideBarOpen(true);
    }

    match.addEventListener("change", onMediaQueryChange);

    return () => match.removeEventListener("change", onMediaQueryChange);
  }, [onMediaQueryChange, isOpen]);

  return (
    <Drawer
      type={type}
      separator
      position="start"
      open={sideBarOpen}
      className={mergeClasses(layout.drawer, "drawer", sidebarOpen)}
      onOpenChange={(_, { open }) => setIsOpen(open)}
    >
      <DrawerHeader className={layout.drawerHeader}>
        <DrawerHeaderTitle>
          <Stack className={layout.headerDrawer}>
            <Image
              src={headerImage.image}
              className={layout.headerLogo}
              height={21}
              width={21}
            />

            <Stack className={layout.logo}>
              <span
                className={mergeClasses(
                  "menu-item-name",
                  layout.textNowrap,
                  layout.primary
                )}
              >
                {t("halo")} <b>{t("analytica")}</b>
              </span>
            </Stack>
          </Stack>
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody className={layout.drawerBody}>
        <Menu
          isOpen={isOpen}
          setOpenAccordion={setOpenAccordion}
          openAccordion={openAccordion}
        />
      </DrawerBody>
    </Drawer>
  );
};

export default Sidebar;
