import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { urlBuilder } from "../../utils/urlBuilder";
import { setDevice } from "../reducer/device";

export const deviceApi = createApi({
  reducerPath: "deviceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    getQuestionBank: buildMutationHelper(
      builder,
      () => ({
        url: `/tna/all-questions`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setDevice({
            data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setDevice({ errorMessage: error?.data?.error }));
      }
    ),
    addDevice: buildMutationHelper(
      builder,
      ({ deviceData }) => ({
        url: `/tna/device`,
        method: "POST",
        body: deviceData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setDevice({
            deviceData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setDevice({ errorMessage: error?.data?.violations }));
      }
    ),
    deleteLocationDevice: buildMutationHelper(
      builder,
      (id) => ({
        url: `/tna/project-location-devices/delete/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),

      ({ error }, dispatch) => {
        dispatch(setDevice({ errorMessage: error?.data?.error }));
      }
    ),
    assignLocation: buildMutationHelper(
      builder,
      ({ deviceData, id }) => ({
        url: `/tna/project-location-devices/${id}`,
        method: "PATCH",
        body: JSON.stringify(deviceData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setDevice({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setDevice({ errorMessage: error?.data?.violations }));
      }
    ),
    getAddedDevice: buildMutationHelper(
      builder,
      (id) => ({
        url: `/tna/device/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setDevice({
            deviceData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setDevice({ errorMessage: error?.data?.error }));
      }
    ),

    editDevice: buildMutationHelper(
      builder,
      ({ deviceData, id }) => ({
        url: `/tna/device/${id}`,
        method: "PATCH",
        body: JSON.stringify(deviceData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setDevice({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setDevice({ errorMessage: error?.data?.error }));
      }
    ),
    locationDeviceQuestion: buildMutationHelper(
      builder,
      ({
        page = 0,
        itemsPerPage = 10,
        projectLocationDevices = undefined,
      } = {}) => ({
        url: urlBuilder(`/tna/project-location-devices-questions`, [
          { page: page + 1 },
          { itemsPerPage: itemsPerPage },
          { projectLocationDevices: projectLocationDevices },
        ]),
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setDevice({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setDevice({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const {
  useGetQuestionBankMutation,
  useAddDeviceMutation,
  useGetAddedDeviceMutation,
  useDeleteLocationDeviceMutation,
  useAssignLocationMutation,
  useEditDeviceMutation,
  useLocationDeviceQuestionMutation,
} = deviceApi;
