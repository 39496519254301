import { createSlice } from "@reduxjs/toolkit";

const projectSummaryComplete = createSlice({
  name: "projectSummaryComplete",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    status: "",
    projectSummaryId: "",
    exportData: [],
  },

  reducers: {
    setProjectSummaryComplete(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setProjectSummaryComplete } = projectSummaryComplete.actions;
export default projectSummaryComplete.reducer;
