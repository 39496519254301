import { Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import {
  Divider,
  mergeClasses,
  Image,
  Button,
  Avatar,
} from "@fluentui/react-components";
import {
  useGetUsersMutation,
  useGetUsersPhotoMutation,
} from "../../../store/action/users";
import { useTranslation } from "react-i18next";
import Layout from "../../../styles/Layout.styles";
import Style from "./Style";
import { ReactComponent as Email } from "../../../images/email.svg";
import { ReactComponent as Phone } from "../../../images/phone.svg";
import { Breadcrumb } from "../../../components";
import { Link } from "react-router-dom";
import imageTimeStamp from "../../../utils/imageTimeStamp";
import accessRole from "../../../components/Common/accessRole";

const ViewUserForm = (props) => {
  const { id } = props;
  const role = accessRole();
  const layout = Layout();
  const style = Style();
  const [viewData, setViewData] = useState();
  const [initialLoad, setInitialLoad] = useState(true);
  const [getUserApi, { isLoading: isGetLoading }] = useGetUsersMutation();
  const [getUsersPhotoApi] = useGetUsersPhotoMutation();
  const [userPhoto, setGetUsersPhoto] = useState();
  const { t } = useTranslation();

  const breadcrumbData = [
    {
      title: `${t("users_text")}`,
      url: "/users-list",
    },
    {
      title: `${t("users_details")}`,
    },
  ];

  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getUserApi(id).then((res) => {
            setViewData(res?.data);
          });
          getUsersPhotoApi(id).then((res) => {
            setGetUsersPhoto(res?.data?.imageUrl);
          });
        }
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isGetLoading]);

  if (id && !viewData) {
    return null;
  }

  const updatedRoles = { ...viewData?.rolesText };
  delete updatedRoles["ROLE_USER"];

  const roleList = Object.values(updatedRoles)?.map((value, index) => value);
  //disable image caching
  let imageUrl = imageTimeStamp(userPhoto);

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack
        className={mergeClasses(
          layout.projectContent,
          layout.w_100,
          "cardShadow"
        )}
      >
        <Stack className={layout.projectFilter}>
          <Stack></Stack>
          {(role?.has("system_user_create") ||
            role?.has("system_user_edit")) && (
            <Link to={id ? `/users-list/${id}/edit` : "/users-list/add"}>
              <Button
                appearance="primary"
                className={mergeClasses(layout.smallBtn, "custom-primary")}
                disabled={
                  viewData.isActive === false || viewData.canBeEdit === false
                }
              >
                {id ? t("userview_edit") : t("userview_add")}
              </Button>
            </Link>
          )}
        </Stack>

        <Stack className={mergeClasses(style.userMainDetails)}>
          <Stack
            className={mergeClasses(
              style.userViewLeft,
              layout.padding2,
              layout.borderRadiusMedium,
              "border4"
            )}
          >
            <Stack
              className={mergeClasses(
                layout.textCenter,
                layout.m_auto,
                layout.mb_2
              )}
            >
              {userPhoto ? (
                <Image
                  alt="image"
                  shape="circular"
                  className={layout.m_auto}
                  src={imageUrl}
                  height={96}
                  width={96}
                />
              ) : (
                <Avatar
                  className={layout.m_auto}
                  name={`${viewData?.fullName}`}
                  size={96}
                  color="colorful"
                />
              )}

              <Text className={style.userTitle}>{viewData?.fullName}</Text>
              <Text className={layout.color616161}>
                {`${roleList.join(", ")}`}
              </Text>
            </Stack>
            <Stack>
              <Text
                className={mergeClasses(
                  layout.d_flex,
                  layout.alignCenter,
                  layout.mb_15,
                  layout.color616161
                )}
              >
                <Email height={18} width={18} className={layout.mr_1_5} />
                <Text
                  className={layout.color616161}
                >{`${viewData?.email}`}</Text>
              </Text>
              <Text
                className={mergeClasses(
                  layout.d_flex,
                  layout.alignCenter,
                  layout.color616161
                )}
              >
                <Phone height={18} width={18} className={layout.mr_1_5} />
                <Text className={layout.color616161}>
                  ({`${viewData?.phonePrefix}`}) {`${viewData?.phoneNumber}`}
                </Text>
              </Text>
            </Stack>
          </Stack>

          <Stack
            className={mergeClasses(
              style.userViewRight,
              layout.padding2,
              "border4"
            )}
          >
            <Text className={style.detailsText}>
              <Stack className={style.textElement}>
                {t("project.project")}
              </Stack>
              <Text className={style.textDetails}>
                {viewData?.projects?.length > 0
                  ? `${viewData?.projects?.map((data) => data?.title)}`
                  : "-"}
              </Text>
            </Text>
            <Divider
              className={mergeClasses(layout.maxHeight30, layout.height30)}
            />
            <Text className={mergeClasses(style.detailsText)}>
              <Stack className={style.textElement}>
                {t("project.address")}
              </Stack>
              <Text className={style.textDetails}>{`${
                viewData?.address?.length > 0 ? viewData?.address : "-"
              }`}</Text>
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewUserForm;
