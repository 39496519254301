import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import FieldsTextarea from "../../../../components/FieldComponents/FieldsTextarea";

const UserAddress = (props) => {
  const { userAddress } = props;
  const style = Style();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsTextarea
          label={t("address")}
          validationMessage={props?.errors?.userAddress?.message}
          placeholder={t("enter_address")}
          name="userAddress"
          id="userAddress"
          maxLength={255}
          inputClassName={style.textboxInput}
          inputDefaultValue={userAddress}
          validation={props.register("userAddress")}
          noRequired
        />
      </Stack>
    </>
  );
};

export default UserAddress;
