import { createSlice } from "@reduxjs/toolkit";

const questions = createSlice({
  name: "questions",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
  },
  reducers: {
    setQuestions(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setQuestions } = questions.actions;
export default questions.reducer;
