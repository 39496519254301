import {
  makeStyles,
  makeStaticStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const style = makeStyles({
  userDropdownSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "300px",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },

  assignLink: {
    flexDirection: "row",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "start",
      marginTop: "10px",
    },
  },

  userDropdown: {
    minWidth: "240px",
    "@media (max-width: 640px)": {
      minWidth: "auto",
    },
  },
  licenseHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  section: {
    width: "calc( 33.33% - 20px )",
    marginTop: 0,
    "@media (max-width: 640px)": {
      width: "100%",
    },
    "@media (max-width: 1280px)": {
      width: "calc( 50% - 20px )",
    },
    "@media (min-width: 1280px)": {
      width: "calc( 33.33% - 20px )",
    },
  },
  headerPart: {
    backgroundColor: tokens.colorBrandBackgroundPressed,
    height: "92px",
    justifyContent: "center",
  },
  headerNestedPart: {
    backgroundColor: tokens.colorSubtleBackgroundHover,
    color: tokens.colorBrandBackgroundPressed,
    height: "53px",
    alignItems: "center",
    justifyContent: "center",
  },
  bundlePoint: {
    ...shorthands.padding("20px", "30px"),
  },
  itemPoint: {
    ...shorthands.padding("20px", "0px"),
  },
  headerText: {
    fontSize: tokens.fontSizeBase600,
    textAlign: "center",
    fontWeight: tokens.fontWeightSemibold,
  },
  title: {
    fontSize: tokens.fontSizeBase600,
    lineHeight: "40px",
    color: tokens.colorNeutralBackground1,
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: tokens.fontWeightSemibold,
  },
  Itemtitle: {
    fontSize: "18px",
    lineHeight: "40px",
    color: tokens.colorBrandBackgroundPressed,
    textAlign: "center",
    fontWeight: tokens.fontWeightSemibold,
  },
  textBlack: {
    color: tokens.colorStrokeFocus2,
  },
  icon18: {
    height: "18px",
    width: "18px",
    minWidth: "18px",
  },
  licensesBtn: {
    alignItems: "center",
    height: "40px",
  },
  itemButtons: {
    flexDirection: "row",
    ...shorthands.gap("10px"),
  },
  Itemsection: {
    width: "610px",
    ...shorthands.margin(0, "auto"),
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  buttonWidth: {
    "@media (min-width: 640px)": {
      minWidth: "150px",
      maxWidth: "150px",
    },
  },
  searchWithClient: {
    "@media (max-width: 991px)": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  },
});

const staticStyle = makeStaticStyles({
  ".counter": {
    ...shorthands.border("1px", "solid", tokens.colorBrandBackgroundPressed),
    borderRadius: "6px",
  },
  ".qty-details, .counter": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ".increment, .decrement": {
    height: "33px",
    minWidth: "32px",
    backgroundColor: tokens.colorBrandBackgroundPressed,
    color: "white",
    ...shorthands.border(0),
    ...shorthands.padding(0),
  },
  ".increment": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  ".decrement": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  ".counterInput": {
    height: "30px",
    ...shorthands.padding("0", "5px"),
    width: "40px",
    ...shorthands.border(0),
  },
});

export default style;
export { staticStyle };
