import { dateObjFormatted } from "../../../utils/convert-date-time";

const generateHierarchicalData = (apiResponse) => {
  const teams = {};

  for (const key in apiResponse) {
    const entry = apiResponse[key];
    const {
      teamName,
      teamColorCode,
      employeeName,
      checkinDate,
      checkoutDate,
      status,
      totalHours,
      timezone,
      id,
    } = entry;

    if (!teams[teamName]) {
      teams[teamName] = {
        teamName: teamName,
        teamColorCode: teamColorCode,
        teamMembers: [],
      };
    }

    const entryDate = dateObjFormatted(checkinDate, timezone);
    const duration = totalHours ? totalHours.toString() : 0;

    const timesheetEntry = {
      entryDate: entryDate,
      duration: duration,
      timesheetDetails: [
        {
          duration: 1,
          checkOutDate: dateObjFormatted(checkoutDate, timezone),
          checkInDate: dateObjFormatted(checkinDate, timezone),
          logStatus: status,
          logId: id,
          taskName: "TASK123",
        },
      ],
    };

    const memberIndex = teams[teamName].teamMembers.findIndex(
      (member) => member.name === employeeName
    );
    if (memberIndex === -1) {
      teams[teamName].teamMembers.push({
        name: employeeName,
        timesheet: [timesheetEntry],
      });
    } else {
      teams[teamName].teamMembers[memberIndex].timesheet.push(timesheetEntry);
    }
  }

  return Object.values(teams);
};

const getSumOfData = (log, duration) =>
  (log = parseFloat(log) + parseFloat(duration));

const generateTimeSheet = (data, timesheetHeader) => {
  const teams = {};
  // eslint-disable-next-line array-callback-return
  data.map((team, key) => {
    teams[team.teamName] = {
      teamName: team.teamName,
      teamColorCode: team.teamColorCode,
      teamCount: {},
      userDetails: {},
    };
    // eslint-disable-next-line array-callback-return
    timesheetHeader.map((time) => {
      let loggedData = 0;
      // eslint-disable-next-line array-callback-return
      team.teamMembers.map((j, key) => {
        let date = j.timesheet.find(
          (t) =>
            new Date(t.entryDate).getDate() === time.monthDate &&
            new Date(t.entryDate).getMonth() === time.month
        );

        if (date?.duration) {
          loggedData = parseFloat(loggedData) + parseFloat(date?.duration);
        }
      });

      Object.assign(teams[team.teamName].teamCount, {
        [`${time.monthDate}/${time.month}`]: Math.floor(loggedData * 100) / 100,
      });
    });

    //team wise
    // eslint-disable-next-line array-callback-return
    team.teamMembers.map((member) => {
      let loggedData = 0;
      let participantTotal = 0;
      let loggedTime = {};
      let loggedStatus = {};
      let timelogId = {};
      // eslint-disable-next-line array-callback-return
      timesheetHeader.map((time) => {
        let date = member.timesheet.find(
          (t) =>
            new Date(t.entryDate).getDate() === time.monthDate &&
            new Date(t.entryDate).getMonth() === time.month
        );

        if (date?.duration) {
          participantTotal = getSumOfData(participantTotal, date?.duration);
        }

        //team member
        // eslint-disable-next-line array-callback-return
        team.teamMembers.map((teamMember) => {
          let date = teamMember.timesheet.find(
            (t) =>
              new Date(t.entryDate).getDate() === time.monthDate &&
              new Date(t.entryDate).getMonth() === time.month
          );

          if (date?.duration) {
            loggedData = getSumOfData(loggedData, date?.duration);
          }
        });

        //fixed: Cannot add property timesheetHeader, object is not extensible
        // if (date?.duration) {
        //   timesheetHeader[time.monthDate - 1].timesheetHeader += date?.duration;
        // }

        // let userTimelog = date?.timesheetDetails.find(
        //   (t) =>
        //     new Date(t.entryDate).getDate() === time.monthDate &&
        //     new Date(t.entryDate).getMonth() === time.month
        // );

        Object.assign(loggedTime, {
          [`${time.monthDate}/${time.month}`]: date?.duration,
        });

        Object.assign(loggedStatus, {
          [`${time.monthDate}/${time.month}`]:
            date?.timesheetDetails[0]?.logStatus,
        });

        Object.assign(timelogId, {
          [`${time.monthDate}/${time.month}`]: date?.timesheetDetails[0]?.logId,
        });
      });
      //client log total
      Object.assign(teams[team.teamName], {
        userLogCount: Math.floor(loggedData * 100) / 100,
      });

      //Math.floor is use for fixed 2 digit slice
      //set user details
      Object.assign(teams[team.teamName].userDetails, {
        [member.name]: {
          userName: member.name,
          userTotalLogged: Math.floor(participantTotal * 100) / 100,
          loggedTime: loggedTime,
          loggedStatus: loggedStatus,
          timelogId: timelogId,
          logStatus: "red",
        },
      });
    });
  });
  return teams;
};

const timeSheetLogic = (sheetData, sheetHeader) => {
  const hierarchical = generateHierarchicalData(sheetData, sheetHeader);
  const generateSheet = generateTimeSheet(hierarchical, sheetHeader);

  let sumTotalTeam = teamCumulativeTotal(generateSheet);

  let timesheetData = {
    timesheet: generateSheet,
    cumulativeTotal: sumTotalTeam,
  };

  return timesheetData;
};

const teamCumulativeTotal = (sheetData) => {
  let cumulative = 0;
  let userLogObject = [];

  // eslint-disable-next-line array-callback-return
  Object.keys(sheetData).map((teams) => {
    cumulative = getSumOfData(cumulative, sheetData[teams].userLogCount);
    userLogObject.push(sheetData[teams].teamCount);
  });

  const mergedCounts = {};

  userLogObject.forEach((teamCount) => {
    Object.keys(teamCount).forEach((date) => {
      if (!mergedCounts[date]) {
        mergedCounts[date] = 0;
      }
      mergedCounts[date] += Math.floor(teamCount[date] * 100) / 100;
    });
  });

  let teamCumulativeTotal = {
    teamTotal: Math.floor(cumulative * 100) / 100,
    empTotal: mergedCounts,
  };

  return teamCumulativeTotal;
};
export default timeSheetLogic;
