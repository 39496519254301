import React, { useEffect, useState } from "react";
import { Text, mergeClasses } from "@fluentui/react-components";
import ProjectFilter from "./ProjectFilter";
import { Stack } from "@fluentui/react";
import Pagination from "../../../components/Pagination";
import { useProjectListMutation } from "../../../store/action/project";
import ProjectListRow from "./ProjectListRow";
import Breadcrumb from "../../../components/Breadcrumb";
import { useSelector } from "react-redux";
import { setProject } from "../../../store/reducer/project";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../components/Table";
import Layout from "../../../styles/Layout.styles";
import { t } from "i18next";
import accessRole from "../../../components/Common/accessRole";

const ProjectList = (props) => {
  const layout = Layout();
  const [getProjectListApi, { isLoading }] = useProjectListMutation();
  const projectList = useSelector((state) => state.project);
  const [initialLoad, setInitialLoad] = useState(true);
  let role = accessRole();

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getProjectListApi();
        setInitialLoad(false);
      }
    }
  }, [initialLoad, getProjectListApi, isLoading, projectList]);

  const columns = [
    {
      columnKey: "projectCode",
      label: t("project.project_code"),
      isSort: true,
      isVisible: true,
    },
    {
      columnKey: "title",
      label: t("project.project_title"),
      isSort: true,
      isVisible: true,
    },
    { columnKey: "Company name", label: t("company_name"), isVisible: true },
    { columnKey: "Created By", label: t("created_by"), isVisible: true },
    {
      columnKey: "siteContact",
      label: t("project.project_site"),
      isSort: true,
      isVisible: true,
    },
    {
      columnKey: "commencementDate",
      label: t("project.commencement_date"),
      isSort: true,
      isVisible: true,
    },
    {
      columnKey: "completionDate",
      label: t("project.completion_date"),
      isSort: true,
      isVisible: true,
    },
    {
      columnKey: "status",
      label: t("project.status"),
      isSort: true,
      isVisible: true,
    },
    {
      columnKey: "action",
      label: t("project.action"),
      isVisible: role?.size && role?.has("project_view") ? true : false,
    },
    {
      columnKey: "activeInactive",
      label: t("activeInactive"),
      isVisible: true,
    },
  ];

  const breadcrumbData = [
    {
      title: t("project.project"),
    },
    {
      title: t("project.project_list"),
    },
  ];

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
        <ProjectFilter
          role={role}
          getProjectListApi={getProjectListApi}
          setInitialLoad={setInitialLoad}
        />
        <Stack className="contentData">
          <Stack className="overflow-y-auto">
            <Table className="tbl-layout-auto">
              <TableHeader className={layout.headerBackground}>
                <TableRow>
                  {columns?.map(
                    (column, key) =>
                      column.isVisible && (
                        <TableHeaderCell
                          key={key}
                          onSet={setProject}
                          onSortApi={getProjectListApi}
                          column={column}
                          sortBy={column?.isSort ? projectList?.sortBy : false}
                        >
                          <Text className={layout.headerLabel}>
                            {column.label}
                          </Text>
                        </TableHeaderCell>
                      )
                  )}
                </TableRow>
              </TableHeader>
              <TableBody isLoading={isLoading}>
                {projectList?.data?.map((item, key) => (
                  <ProjectListRow key={key} item={item} role={role} />
                ))}
              </TableBody>
            </Table>
          </Stack>

          <Pagination
            totalCount={projectList?.totalCount}
            itemsPerPage={projectList?.itemsPerPage}
            onSetListReducer={setProject}
            onGetList={getProjectListApi}
            page={projectList?.page}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectList;
