import React, { useState, useEffect } from "react";
import QuestionsForm from "./QuestionsForm";
import { useGetQuestionsMutation } from "../../../store/action/question";

const QuestionsWrapper = (props) => {
  const { questionId, isOpenDialog, setIsOpenDialog } = props;
  const [initialLoad, setInitialLoad] = useState(true);
  const [getQuestionsApi, { isLoading: isGetLoading }] =
    useGetQuestionsMutation();
  const [questionEditData, setQuestionEditData] = useState({});

  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (questionId) {
          getQuestionsApi(questionId).then((res) => {
            setQuestionEditData(res);
          });
        }
      }
      setInitialLoad(false);
    }
  }, [initialLoad, isGetLoading, getQuestionsApi, questionId]);

  if (questionId && !questionEditData.data) {
    return null;
  }

  return (
    <QuestionsForm
      questionId={questionId}
      questionEditData={questionEditData}
      isOpenDialog={isOpenDialog}
      setIsOpenDialog={setIsOpenDialog}
    />
  );
};

export default QuestionsWrapper;
