import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { FieldsInput } from "../../../../components";
import Layout from "../../../../styles/Layout.styles";

const QuestionField = (props) => {
  const { question } = props;
  const style = Style();
  const layout = Layout();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={mergeClasses(layout.w_100, layout.mb_15)}>
        <FieldsInput
          label={t("question")}
          placeholder={t("enter_question")}
          fieldClassName="custom-label"
          validationMessage={props.errors?.question?.message}
          id="question"
          name="question"
          inputDefaultValue={question}
          inputClassName={mergeClasses(style.textboxInput, layout.w_100)}
          validation={props.register("question", {
            required: `${t("validation_message.question_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default QuestionField;
