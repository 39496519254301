import React from "react";
import { useParams } from "react-router-dom";
import ClientFormWrapper from "./ClientFormWrapper";

const EditClient = () => {
  const { id } = useParams();
  return <ClientFormWrapper id={id} />;
};

export default EditClient;
