import React from "react";
import { useParams } from "react-router-dom";
import ClientViewForm from "./ClientViewForm";

const ViewClient = () => {

  const { id } = useParams();
  return (<ClientViewForm id={id} />);
};

export default ViewClient;
