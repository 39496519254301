import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { urlBuilder } from "../../utils/urlBuilder";
import { setTimeAndAttendance } from "../reducer/time-and-attendance";

export const timeAndAttendanceApi = createApi({
  reducerPath: "timeAndAttendanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),
  endpoints: (builder) => ({
    getEmployeeDetail: buildMutationHelper(
      builder,
      (id) => ({
        url: `/tna/project-tna/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response;
        dispatch(
          setTimeAndAttendance({
            employeeDetails: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTimeAndAttendance({ errorMessage: error?.data?.error }));
      }
    ),
    projectApprove: buildMutationHelper(
      builder,
      ({ tnaData }) => ({
        url: `/approve-project-tna`,
        method: "POST",
        body: tnaData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setTimeAndAttendance({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTimeAndAttendance({ errorMessage: error?.data?.detail }));
      }
    ),
    projectReject: buildMutationHelper(
      builder,
      ({ tnaData }) => ({
        url: `/reject-project-tna`,
        method: "POST",
        body: tnaData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setTimeAndAttendance({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTimeAndAttendance({ errorMessage: error?.data?.detail }));
      }
    ),
    getProjectTimeSheet: buildMutationHelper(
      builder,
      ({
        page = 0,
        itemsPerPage = 10,
        projectId = undefined,
        teamId = undefined,
        pagination = undefined,
        startDate = undefined,
        endDate = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => ({
        url: urlBuilder(`/tna/project-timesheet-details`, [
          { page: page + 1 },
          { itemsPerPage: itemsPerPage },
          { pagination: pagination },
          { "projectTnaDetails.projectLocation.project": projectId },
          { "projectTnaDetails.projectTeam": teamId },
          { "checkinDate[after]": startDate },
          {
            "checkoutDate[before]":
              endDate &&
              (endDate === startDate ? `${endDate} 23:59:59` : endDate),
          },
          { client: client },
        ]),
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const data = response?.data;
        const total = response?.data?.totalItems;
        dispatch(
          setTimeAndAttendance({
            data,
            total,
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTimeAndAttendance({ errorMessage: error?.data?.error }));
      }
    ),
    addClockLog: buildMutationHelper(
      builder,
      ({ tnaData }) => ({
        url: `/tna/project-tna-detail/add-timelog`,
        method: "POST",
        body: tnaData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setTimeAndAttendance({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTimeAndAttendance({ errorMessage: error?.data?.detail }));
      }
    ),
    editClockLog: buildMutationHelper(
      builder,
      ({ tnaData, id }) => ({
        url: `/tna/project-tna-detail/edit-timelog/${id}`,
        method: "PATCH",
        body: JSON.stringify(tnaData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;

        dispatch(setTimeAndAttendance({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(
          setTimeAndAttendance({ errorMessage: error?.data?.violations })
        );
      }
    ),
    deleteClockLog: buildMutationHelper(
      builder,
      (id) => ({
        url: `/tna/project-tna-detail/delete-timelog/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      ({ error }, dispatch) => {
        dispatch(setTimeAndAttendance({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});

export const {
  useGetEmployeeDetailMutation,
  useProjectApproveMutation,
  useGetProjectTimeSheetMutation,
  useProjectRejectMutation,
  useAddClockLogMutation,
  useEditClockLogMutation,
  useDeleteClockLogMutation,
} = timeAndAttendanceApi;
