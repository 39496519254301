import { createSlice } from "@reduxjs/toolkit";

const client = createSlice({
  name: "client",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    isActive: "true",
  },
  reducers: {
    setClient(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setClient } = client.actions;
export default client.reducer;
