import React, { useState } from "react";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import Layout from "../../../../styles/Layout.styles";
import Style from "./Style";
import LocationCard from "./LocationCard";
import { Pagination } from "../../../../components";
import { setLocation } from "../../../../store/reducer/location";
import { useLocationListMutation } from "../../../../store/action/location";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Location = ({ projectId, role }) => {
  const layout = Layout();
  const style = Style();
  const [getLocationListApi] = useLocationListMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const locationList = useSelector((state) => state.location);

  useEffect(() => {
    if (initialLoad) {
      if (projectId) {
        getLocationDataListApi(locationList?.page, locationList?.itemsPerPage);
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, getLocationListApi, projectId, locationList]);

  const getLocationDataListApi = async (page, itemsPerPage) => {
    await getLocationListApi({
      page: page,
      itemsPerPage: itemsPerPage,
      project: projectId,
    });
  };
  return (
    <>
      <Stack
        className={mergeClasses(
          layout.flex_row,
          layout.wrap,
          style.locationRow
        )}
      >
        {locationList?.data?.map((item, key) => {
          return (
            <LocationCard
              key={key}
              item={item}
              role={role}
              projectId={projectId}
              locationId={item.id}
            />
          );
        })}
      </Stack>
      <Stack>
        <Pagination
          startPage={locationList?.startPage}
          totalCount={locationList?.totalCount}
          itemsPerPage={locationList?.itemsPerPage}
          onSetListReducer={setLocation}
          onGetList={getLocationDataListApi}
          page={locationList?.page}
        />
      </Stack>
    </>
  );
};

export default Location;
