import { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { Breadcrumb } from "../../../components";
import { mergeClasses } from "@fluentui/react-components";
import DetailedTimesheetFilter from "./DetailedTimesheetFilter";
import DetailedTimesheetTable from "./DetailedTimesheetTable";
import Layout from "../../../styles/Layout.styles";
import { useGetProjectTimeSheetMutation } from "../../../store/action/time-and-attendance";
import { setTimeAndAttendance } from "../../../store/reducer/time-and-attendance";
import { useSelector, useDispatch } from "react-redux";
import { generateResult } from "../../../utils/timesheet";
import { t } from "i18next";

const DetailedTimesheet = (props) => {
  const layout = Layout();
  const [initialLoad, setInitialLoad] = useState(true);
  const [timeSheet, setTimesheet] = useState(true);
  const [assignedProjectId, setAssignedProjectId] = useState();
  const [assignedTeamId, setAssignedTeamId] = useState();
  const [isProjectLoader, setIsProjectLoader] = useState(false);
  const [isTeamLoader, setIsTeamLoader] = useState(false);
  const [isDateLoader, setIsDateLoader] = useState(false);
  const [timeAndAttendanceApi, { isLoading }] =
    useGetProjectTimeSheetMutation();

  const timeAndAttendanceList = useSelector(
    (state) => state?.timeAndAttendance
  );

  const breadcrumbData = [
    {
      title: t("time_and_attendance"),
    },
    {
      title: t("detailed_timeSheet"),
    },
  ];

  useEffect(() => {
    if (assignedProjectId) {
      if (initialLoad) {
        if (!isLoading) {
          timeAndAttendanceApi({
            pagination: false,
            projectId: assignedProjectId,
          }).then((record) => {});
          setInitialLoad(false);
        }
      }
    }
  }, [
    initialLoad,
    isLoading,
    timeAndAttendanceList,
    timeAndAttendanceApi,
    assignedProjectId,
  ]);

  const [filter, setFilter] = useState({
    projectId: "",
    startDate: new Date(),
    endDate: new Date(),
  });

  const dispatch = useDispatch();
  const timesheetHeader = generateResult(new Date(), new Date());

  if (timeSheet) {
    dispatch(setTimeAndAttendance({ headerData: timesheetHeader }));
    setTimesheet(false);
  }
  const headerList = useSelector(
    (state) => state?.timeAndAttendance?.headerData
  );

  return (
    <>
      <Breadcrumb data={breadcrumbData} />

      <Stack
        className={mergeClasses(
          layout.projectContent,
          "cardShadow",
          "timesheet-details"
        )}
      >
        <Stack className="contentData">
          <DetailedTimesheetFilter
            filter={filter}
            setFilter={setFilter}
            setAssignedTeamId={setAssignedTeamId}
            assignedProjectId={assignedProjectId}
            setAssignedProjectId={setAssignedProjectId}
            timesheetHeader={headerList}
            assignedTeamId={assignedTeamId}
            timeSheetData={timeAndAttendanceList?.data ?? []}
            setIsProjectLoader={setIsProjectLoader}
            setIsTeamLoader={setIsTeamLoader}
            setIsDateLoader={setIsDateLoader}
          />

          <DetailedTimesheetTable
            filter={filter}
            setFilter={setFilter}
            timesheetHeader={headerList}
            timeSheetData={timeAndAttendanceList?.data}
            isLoading={isLoading}
            isProjectLoader={isProjectLoader}
            isTeamLoader={isTeamLoader}
            isDateLoader={isDateLoader}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default DetailedTimesheet;
