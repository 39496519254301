import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { setProject } from "../reducer/project";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { defaultFromState, isActiveReload } from "../../utils/defaultFromState";

export const projectApi = createApi({
  reducerPath: "projectApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    addProject: buildMutationHelper(
      builder,
      ({ projectData }) => ({
        url: `/project`,
        method: "POST",
        body: projectData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setProject({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setProject({ errorMessage: error?.data?.violations }));
      }
    ),
    getProject: buildMutationHelper(
      builder,
      (id) => ({
        url: `project/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setProject({
            projectData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setProject({ errorMessage: error?.data?.error }));
      }
    ),
    editProject: buildMutationHelper(
      builder,
      ({ projectData, id }) => ({
        url: `/project/${id}`,
        method: "PATCH",
        body: JSON.stringify(projectData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch, getState) => {
        const {
          endUser: { data: newData },
        } = getState();

        const { data } = response?.data;
        let newDataArray = [];
        isActiveReload(newDataArray, newData, data);

        dispatch(setProject({ newDataArray, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setProject({ errorMessage: error?.data?.violations }));
      }
    ),
    activeProject: buildMutationHelper(
      builder,
      ({ projectData, id }) => ({
        url: `/project/active/${id}`,
        method: "PATCH",
        body: JSON.stringify(projectData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch, getState) => {
        const {
          endUser: { data: newData },
        } = getState();

        const { data } = response?.data;
        let newDataArray = [];
        isActiveReload(newDataArray, newData, data);

        dispatch(setProject({ newDataArray, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setProject({ errorMessage: error?.data?.violations }));
      }
    ),
    projectList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        search = undefined,
        sortBy = undefined,
        sortType = undefined,
        isActive = undefined,
        status = undefined,
        pagination = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/projects",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            search: search,
            pagination: pagination,
            sortBy,
            sortType,
            isActive: isActive,
            status: status,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              project: {
                page,
                itemsPerPage,
                search,
                pagination,
                sortBy,
                sortType,
                isActive,
                status,
                client,
              },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              search,
              pagination,
              sortBy,
              sortType,
              isActive,
              status,
              client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setProject({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setProject({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(setProject({ errorMessage: error?.data?.error }));
      }
    ),
    deleteProject: buildMutationHelper(
      builder,
      (id) => ({
        url: `/project_inActive/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      ({ error }, dispatch) => {
        dispatch(setProject({ errorMessage: error?.data?.error }));
      }
    ),
    getAssignedProjects: buildMutationHelper(
      builder,
      ({ client = localStorage?.selectedClientId ?? undefined } = {}) => {
        return {
          url: `assigned-projects`,
          params: {
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              project: { client },
            } = getState();

            let stateParams = {
              client,
              withoutPage: true,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setProject({
            projectData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setProject({ errorMessage: error?.data?.error }));
      }
    ),
    projectStatusChange: buildMutationHelper(
      builder,
      ({ projectData, id }) => ({
        url: `/project/status/${id}`,
        method: "PATCH",
        body: JSON.stringify(projectData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setProject({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setProject({ errorMessage: error?.data?.violations }));
      }
    ),
  }),
});
export const {
  useProjectListMutation,
  useEditProjectMutation,
  useGetProjectMutation,
  useAddProjectMutation,
  useDeleteProjectMutation,
  useGetAssignedProjectsMutation,
  useProjectStatusChangeMutation,
  useActiveProjectMutation,
} = projectApi;
