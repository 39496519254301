import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../../utils/requestHeaders";
import { API_HOST } from "../../../utils/constants";
import { buildMutationHelper } from "../../../utils/buildMutationHelper";
import { setProjectSummaryAwait } from "../../reducer/projectSummary/projectSummaryAwait";
import { defaultFromState } from "../../../utils/defaultFromState";

export const projectSummaryAwaitApi = createApi({
  reducerPath: "projectSummaryAwaitApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    projectSummaryAwait: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        search = undefined,
        pagination = undefined,
        status = undefined,
        sortBy = undefined,
        sortType = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/projects_summary",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            search: search,
            pagination: pagination,
            status: status,
            sortBy,
            sortType,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              projectSummaryAwait: {
                page,
                itemsPerPage,
                search,
                pagination,
                status,
                sortBy,
                sortType,
                client,
              },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              search,
              pagination,
              status,
              sortBy,
              sortType,
              client,
            };
            return defaultFromState(stateParams, args);
          },
        };
      },

      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setProjectSummaryAwait({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setProjectSummaryAwait({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(setProjectSummaryAwait({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const { useProjectSummaryAwaitMutation } = projectSummaryAwaitApi;
