import { makeStyles, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  client_field: {
    ...shorthands.margin(0, 0, "20px", 0),
    "@media (min-width: 1024px)": {
      width: "calc(50% - 15px)",
    },
    "@media (max-width: 1024px)": {
      width: "100%",
    },
  },
});

export default style;
