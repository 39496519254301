import * as React from "react";
import { Field, Input, mergeClasses } from "@fluentui/react-components";

const FieldsInput = ({
  label,
  validationMessage,
  validationMessageIcon = "",
  size = "large",
  inputType = "text",
  placeholder,
  value,
  name,
  className,
  inputClassName,
  inputId,
  inputDefaultValue,
  validation,
  noRequired,
  disabled,
  contentAfter,
  contentBefore,
  maxLength,
  onKeyDown,
  onFocus,
}) => {
  return (
    <Field
      label={label}
      validationMessage={validationMessage}
      className={mergeClasses("custom-label", className)}
      validationMessageIcon={validationMessageIcon}
      size={size}
      required={noRequired ? undefined : true}
    >
      <Input
        id={inputId}
        className={inputClassName}
        type={inputType}
        value={value}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        name={name}
        disabled={disabled}
        defaultValue={inputDefaultValue}
        maxLength={maxLength}
        placeholder={placeholder}
        contentBefore={contentBefore}
        contentAfter={contentAfter}
        {...validation}
      />
    </Field>
  );
};

export default FieldsInput;
