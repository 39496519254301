import { Stack } from "@fluentui/react";
import React from "react";
import { Form, FormButton, CustomDialog } from "../../../components";
import { useForm } from "react-hook-form";
import Layout from "../../../styles/Layout.styles";
import {
  Button,
  DialogTrigger,
  mergeClasses,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import StaticStyle from "../../Client/ClientManagement/Style";
import QuestionField from "./Form/QuestionField";
import QuestionType from "./Form/QuestionType";
import {
  useAddQuestionMutation,
  useEditQuestionsMutation,
  useQuestionsListMutation,
} from "../../../store/action/question";
import { trailWhiteSpace } from "../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../utils/errorHandler";

const QuestionsForm = (props) => {
  const { questionId, isOpenDialog, setIsOpenDialog, questionEditData } = props;
  const layout = Layout();
  const [addQuestionsApi, { isLoading: isAddLoading }] =
    useAddQuestionMutation();
  const [editQuestionsApi, { isLoading: isEditLoading }] =
    useEditQuestionsMutation();
  const [getQuestionsListApi] = useQuestionsListMutation();

  const { t } = useTranslation();
  StaticStyle();

  // -- don't remove
  // useEffect(() => {
  //   setRadioFieldCheck(questionEditData?.data?.type);
  // }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm();

  if (questionId && !questionEditData?.data) {
    return null;
  }

  const commonProps = { register, errors, setValue };

  const onSubmit = (value) => {
    let formData = trailWhiteSpace(value);
    const newQuestionsData = {
      // type: radioFieldCheck,     -- don't remove
      type: "checkbox",
      question: formData?.question,
    };

    if (questionId) {
      if (!isEditLoading) {
        editQuestionsApi({
          questionsData: newQuestionsData,
          id: questionId,
        }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res?.error) {
            setIsOpenDialog(false);
            getQuestionsListApi();
          }
        });
      }
    } else {
      if (!isAddLoading) {
        addQuestionsApi({ questionsData: newQuestionsData }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res?.error) {
            setIsOpenDialog(false);
            getQuestionsListApi();
          }
        });
      }
    }
  };

  return (
    <>
      <CustomDialog
        dialogTitle={questionId ? t("edit_question") : t("add_question")}
        isEdit={questionId ? true : false}
        dialogTitleClass=""
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
        dialogContent={
          <Form className={layout.w_100} onSubmit={handleSubmit(onSubmit)}>
            <Stack className={mergeClasses(layout.w_100)}>
              <Stack className={mergeClasses(layout.textbox)}>
                <QuestionField
                  {...commonProps}
                  question={questionEditData?.data?.question}
                />
                <QuestionType
                  {...commonProps}
                  // setRadioFieldCheck={setRadioFieldCheck}  -- don't remove
                  // radioFieldCheck={radioFieldCheck}
                  questionType={questionEditData?.data?.type}
                />
              </Stack>
            </Stack>

            <Stack className={mergeClasses(layout.flex_row)}>
              <FormButton
                type="submit"
                size="medium"
                appearance="primary"
                loading={isAddLoading || isEditLoading}
                className={mergeClasses(
                  "custom-primary",
                  layout.smallBtn,
                  layout.mr_1
                )}
              >
                {questionId ? t("update") : t("add")}
              </FormButton>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => setIsOpenDialog(false)}
                  className={mergeClasses("custom-primary", layout.smallBtn)}
                >
                  {t("cancel")}
                </Button>
              </DialogTrigger>
            </Stack>
          </Form>
        }
        dialogContentClass=""
        dialogClose="Close"
        dialogCloseClass=""
        dialogSubmit="Do Something"
        dialogSubmitClass="Do Something"
        appearance="primary"
      />
    </>
  );
};

export default QuestionsForm;
