import exportFile from "../../../utils/exportFile";
import ExportButtonComponent from "../../../components/FieldComponents/ExportButton";
import {
  dateTimeFormatted,
  getTimezoneOffsetData,
} from "../../../utils/convert-date-time";

const FilterActions = (props) => {
  const { timeSheetData } = props;

  const exportDataList = timeSheetData?.map?.((item, label) => ({
    employeeName: item?.employeeName,
    teamColorCode: item?.teamColorCode,
    teamName: item?.teamName,
    status: item?.status,
    approvedByName: item?.approvedByName,
    totalHours: item?.totalHours?.toFixed(2),
    checkinDate: dateTimeFormatted(item?.checkinDate, item?.timezone),
    checkoutDate: dateTimeFormatted(item?.checkoutDate, item?.timezone),
    clockCount: item?.clockCount,
    createdAt: dateTimeFormatted(item?.createdAt, item?.timezone),
    timezone: `${item?.timezone} ${getTimezoneOffsetData(item?.timezone)}`,
  }));

  let fileRecord = exportFile(exportDataList);

  return (
    <ExportButtonComponent
      exportData={fileRecord}
      isExportButtonDisable={timeSheetData?.length === 0}
    />
  );
};

export default FilterActions;
