import React, { useEffect, useState } from "react";
import { Text } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import {
  Pagination,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../components";
import { useSelector } from "react-redux";
import Layout from "../../../styles/Layout.styles";
import { useTeamsListMutation } from "../../../store/action/teams";
import { setTeams } from "../../../store/reducer/teams";
import TeamsFilter from "./TeamsFilter";
import TeamsListRow from "./TeamsListRow";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import accessRole from "../../../components/Common/accessRole";

const TeamsList = () => {
  const { id } = useParams();
  const layout = Layout();
  const role = accessRole();
  const [getTeamsListApi, { isLoading }] = useTeamsListMutation();
  const teamsList = useSelector((state) => state.teams);
  const [initialLoad, setInitialLoad] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialLoad) {
      if (id) {
        onGetTeams(0, 10, "", "", "");
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isLoading, getTeamsListApi, id, teamsList]);

  const onGetTeams = async (page, itemsPerPage, search, sortBy, sortType) => {
    await getTeamsListApi({
      page: page,
      itemsPerPage: itemsPerPage,
      search: search,
      sortBy: sortBy,
      sortType: sortType,
      project: id,
    });
  };

  const column = [
    { columnKey: "name", label: t("teams.team_name"), isSort: true },
    { columnKey: "colorCode", label: t("teams.color_code"), isSort: true },
    {
      columnKey: "description",
      label: t("teams.team_description"),
      isSort: true,
    },
  ];
  const actionColumn = [{ columnKey: "action", label: t("action") }];

  const columns = role?.has("project_teams_edit")
    ? [...column, ...actionColumn]
    : column;

  return (
    <>
      <TeamsFilter
        getTeamsListApi={getTeamsListApi}
        setInitialLoad={setInitialLoad}
        projectId={id}
        role={role}
      />
      <Stack className="contentData">
        <Stack className="overflow-y-auto">
          <Table className="tbl-layout-auto">
            <TableHeader className={layout.headerBackground}>
              <TableRow>
                {columns.map((column, key) => (
                  <TableHeaderCell
                    key={key}
                    onSortApi={onGetTeams}
                    onSet={setTeams}
                    onGetTeams={onGetTeams}
                    column={column}
                    sortBy={column?.isSort ? teamsList?.sortBy : false}
                  >
                    <Text className={layout.headerLabel}>{column.label}</Text>
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody isLoading={isLoading}>
              {teamsList?.data?.map((item, key) => (
                <TeamsListRow
                  key={key}
                  item={item}
                  projectId={id}
                  role={role}
                />
              ))}
            </TableBody>
          </Table>
        </Stack>
        <Pagination
          totalCount={teamsList?.totalCount}
          itemsPerPage={teamsList?.itemsPerPage}
          onSetListReducer={setTeams}
          onGetList={onGetTeams}
          page={teamsList?.page}
        />
      </Stack>
    </>
  );
};

export default TeamsList;
