import React from "react";
import {
  Button,
  DialogTrigger,
  mergeClasses,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import Layout from "../../../../../styles/Layout.styles";
import { Form, FormButton, CustomDialog } from "../../../../../components";
import { useForm } from "react-hook-form";
import {
  useAddLocationMutation,
  useLocationListMutation,
} from "../../../../../store/action/location";
import { useSelector } from "react-redux";
import LocationName from "./Form/LocationName";
import LocationShortCode from "./Form/LocationShortCode";
import LocationAddress from "./Form/LocationAddress";
import { trailWhiteSpace } from "../../../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../../../utils/errorHandler";

const LocationForm = (props) => {
  const { id, isOpenDialog, setIsOpenDialog, projectId } = props;
  const layout = Layout();
  const { t } = useTranslation();

  const [addLocationApi, { isLoading: isAddLoading }] =
    useAddLocationMutation();
  const [getLocationListApi] = useLocationListMutation();
  const locationList = useSelector((state) => state.location);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useForm();

  const getProjectId = /project/ + projectId;

  const commonProps = { register, errors, setValue };

  const onSubmit = (value) => {
    let formData = trailWhiteSpace(value);

    const newLocationData = {
      name: formData?.locationName,
      project: getProjectId,
      isActive: true,
      shortCode: formData?.locationShortCode,
      address: formData?.locationAddress,
    };

    if (!isAddLoading) {
      addLocationApi({ locationData: newLocationData }).then((res) => {
        let errorLists = res?.error?.data?.violations;
        if (errorLists) {
          formErrorHandler("location", errorLists, setError);
        }
        if (!res?.error) {
          setIsOpenDialog(false);
          getLocationListApi({
            page: locationList?.page,
            itemsPerPage: locationList?.itemsPerPage,
            project: projectId,
          });
        }
      });
    }
  };

  return (
    <>
      <CustomDialog
        dialogTitle={t("project.pl_add_location")}
        isEdit={id ? true : false}
        dialogTitleClass=""
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
        dialogContent={
          <Form className={layout.w_100} onSubmit={handleSubmit(onSubmit)}>
            <Stack className={layout.mb_2}>
              <LocationName {...commonProps} locationList={locationList} />
            </Stack>
            <Stack className={layout.mb_2}>
              <LocationShortCode {...commonProps} />
            </Stack>
            <Stack className={layout.mb_2}>
              <LocationAddress {...commonProps} />
            </Stack>
            <Stack className={mergeClasses(layout.flex_row)}>
              <FormButton
                type="submit"
                size="medium"
                appearance="primary"
                className={mergeClasses(
                  layout.smallBtn,
                  "custom-primary",
                  layout.mr_1
                )}
              >
                {t("add")}
              </FormButton>

              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => setIsOpenDialog(false)}
                  className={mergeClasses(layout.smallBtn, "custom-primary")}
                >
                  {t("cancel")}
                </Button>
              </DialogTrigger>
            </Stack>
          </Form>
        }
        dialogContentClass=""
        dialogClose="Close"
        dialogCloseClass=""
        dialogSubmit="Do Something"
        dialogSubmitClass="Do Something"
        appearance="primary"
      />
    </>
  );
};

export default LocationForm;
