import { Stack } from "@fluentui/react";
import {
  mergeClasses,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Dropdown,
  Option,
  OptionGroup,
  Avatar,
  Button,
  tokens,
} from "@fluentui/react-components";
import React from "react";
import Layout from "../../styles/Layout.styles";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { headerStyle, headerStaticStyle } from "./Style";
import { useTranslation } from "react-i18next";
import HeaderMenu from "./HeaderMenu";
import LoggedUser from "../LoggedUser";
import ClientListDropdown from "./ClientListDropdown";
import accessRole from "../Common/accessRole";
import imageTimeStamp from "../../utils/imageTimeStamp";

const Header = ({ setOpenAccordion }) => {
  LoggedUser();
  const navigate = useNavigate();
  const layout = Layout();
  const style = headerStyle();
  headerStaticStyle();
  const { t } = useTranslation();
  let user = localStorage.getItem("activeUser");
  let companyName = localStorage.getItem("companyName");

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };
  const profileRedirection = () => {
    localStorage.setItem("edit_profile", true);
    navigate(`/user-profile/${localStorage?.activeUserId}/edit`);
  };
  const clientRedirection = () => {
    localStorage.setItem("edit_organization_profile", true);
    navigate(`/edit-organization-profile/${localStorage?.loginClientId}/edit`);
  };

  let menusArray = HeaderMenu().filter(menuFilter);

  function menuFilter(menu) {
    if (window.location.pathname.includes(menu.url)) {
      return true;
    }
  }

  const onClick = (menu) => {
    setOpenAccordion(false);
  };

  const onAvatarClick = () => {
    setOpenAccordion(false);
  };

  return (
    <Stack
      className={mergeClasses(
        style.headerNav,
        "headerNavbar",
        layout.flex_between
      )}
    >
      <Stack
        className={mergeClasses(
          style.headerLinks,
          layout.headerSearchResp,
          "headerSearchbar"
        )}
      >
        <Stack className={mergeClasses(style.desktopMenu, "desktopMenu")}>
          <Stack className={layout.flex_row}>
            {HeaderMenu()?.map((menu, key) => {
              return (
                <React.Fragment key={key}>
                  {menu?.child?.length && menu?.visible ? (
                    <Menu key={key}>
                      <MenuTrigger disableButtonEnhancement>
                        <Button
                          appearance="transparent"
                          className={mergeClasses(
                            style.menuDropdownBtn,
                            menu.name.toLowerCase() && layout.fontWeightBold
                          )}
                          onClick={() => onClick(menu.key)}
                        >
                          {menu.name}
                        </Button>
                      </MenuTrigger>
                      <MenuPopover className="headerDeskTopMenu">
                        <MenuList>
                          {menu?.child?.map(
                            (subMenu, subkey) =>
                              subMenu?.visible && (
                                <MenuItem
                                  key={subkey}
                                  className={mergeClasses(
                                    subMenu.name.toLowerCase() ===
                                      layout.fontWeightBold,
                                    style.menuItems
                                  )}
                                >
                                  <Link
                                    className={mergeClasses(
                                      style.headerLink,
                                      layout.d_flex,
                                      layout.alignCenter
                                    )}
                                    to={subMenu.url || "#"}
                                  >
                                    {subMenu.name}
                                  </Link>
                                </MenuItem>
                              )
                          )}
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  ) : (
                    menu?.visible && (
                      <MenuItem key={key} onClick={() => onClick(menu.key)}>
                        <NavLink
                          key={menu.key}
                          style={({ isActive }) => {
                            return isActive
                              ? {
                                  fontWeight: "bold",
                                  color: tokens.colorBrandBackground,
                                }
                              : {};
                          }}
                          to={menu.url || "#"}
                        >
                          {menu.name}
                        </NavLink>
                      </MenuItem>
                    )
                  )}
                </React.Fragment>
              );
            })}
          </Stack>
        </Stack>
        <Dropdown
          placeholder="Menu"
          defaultValue={menusArray?.[0]?.name}
          value={menusArray?.[0]?.name}
          defaultSelectedOptions={[menusArray?.[0]?.name]}
          className={mergeClasses(style.responsiveMenu, "responsiveMenu")}
          selectedOptions={[menusArray?.[0]?.name]}
        >
          {HeaderMenu().map((menu, key) => {
            return menu?.child?.length && menu?.visible ? (
              <OptionGroup label={menu.name} key={key}>
                {menu?.child?.map(
                  (subMenu, subkey) =>
                    subMenu?.visible && (
                      <Option
                        key={subkey}
                        text={subMenu.name}
                        defaultValue="users"
                        defaultSelectedOptions={["users"]}
                        className={mergeClasses(
                          "headerOptionResp",
                          layout.ml_2
                        )}
                      >
                        <Link
                          key={subMenu.key}
                          className={mergeClasses(
                            style.headerLink,
                            layout.w_100,
                            layout.mb_0,
                            "headerLinkResp"
                          )}
                          to={subMenu.url || "#"}
                        >
                          {subMenu.name}
                        </Link>
                      </Option>
                    )
                )}
              </OptionGroup>
            ) : (
              menu?.visible && (
                <Option
                  key={menu.key}
                  text={menu.name}
                  className="headerOptionResp"
                >
                  <Link
                    onClick={() => onClick(menu.key)}
                    key={menu.key}
                    className={mergeClasses(
                      style.headerLink,
                      layout.w_100,
                      layout.mb_0,
                      "headerLinkResp"
                    )}
                    to={menu.url || "#"}
                  >
                    {menu.name}
                  </Link>
                </Option>
              )
            );
          })}
        </Dropdown>
      </Stack>
      <Stack
        className={mergeClasses(
          style.headerLinks,
          style.customMenuReverse,
          "customMenuReverse"
        )}
      >
        <Stack
          className={mergeClasses(
            style.headerGroup,
            layout.alignItemsEnd,
            "headerGroup"
          )}
        >
          <Stack
            className={mergeClasses(layout.flex_column, layout.alignItemsEnd)}
          >
            <Stack
              className={mergeClasses(layout.alignItemsCenter, layout.flex_row)}
            >
              {companyName !== "undefined" ? companyName : user}
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <Avatar
                    image={{
                      src: `${imageTimeStamp(localStorage.activeUserImage)}`,
                    }}
                    name={user}
                    badge={{ status: "available" }}
                    size={32}
                    className={mergeClasses(layout.ml_1)}
                    color="colorful"
                  />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList onClick={onAvatarClick}>
                    {accessRole()?.has("edit_profile") && (
                      <MenuItem onClick={profileRedirection}>
                        {t("profile")}
                      </MenuItem>
                    )}
                    {accessRole()?.has("edit_organization_profile") && (
                      <MenuItem onClick={clientRedirection}>
                        {t("edit_client")}
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
            </Stack>

            {!window.location.pathname.includes("organization-list") &&
              !window.location.pathname.includes("organization") &&
              accessRole()?.has("view_client_dropdown") && (
                <ClientListDropdown />
              )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
