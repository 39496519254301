import React, { useState, useEffect } from "react";
import { useGetTeamsMutation } from "../../../store/action/teams";
import TeamsForm from "./TeamsForm";

const TeamsWrapper = (props) => {
  const { teamId, isOpenDialog, setIsOpenDialog, projectId } = props;
  const [initialLoad, setInitialLoad] = useState(true);
  const [getTeamsApi, { isLoading: isGetLoading }] = useGetTeamsMutation();
  const [userEditData, setUserEditData] = useState({});
  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (teamId) {
          getTeamsApi(teamId).then((res) => {
            setUserEditData(res);
          });
        }
      }
      setInitialLoad(false);
    }
  }, [initialLoad, isGetLoading, getTeamsApi, teamId]);

  if (teamId && !userEditData.data) {
    return null;
  }

  return (
    <TeamsForm
      teamId={teamId}
      userEditData={userEditData}
      isOpenDialog={isOpenDialog}
      projectId={projectId}
      setIsOpenDialog={setIsOpenDialog}
    />
  );
};

export default TeamsWrapper;
