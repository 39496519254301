import {
  TableHeaderCell as FluentTableHeaderCell,
  Image,
  mergeClasses,
} from "@fluentui/react-components";
import Style from "./Style";
import { useState } from "react";
import { useDispatch } from "react-redux";
import arrowUp from "../../images/sort-asc-sortable-anchor-table-order-desc-asc.svg";
import arrowDown from "../../images/sort-desc-sortable-anchor-table-order-desc-asc.svg";
import arrowDisable from "../../images/sort-disabled-sortable-anchor-table-order-desc-asc.svg";

const TableHeaderCell = (props) => {
  const { children, className, onSortApi, sortBy, onSet, column } = props;
  const style = Style();
  const [checked, setChecked] = useState();
  const dispatch = useDispatch();

  const handleHeaderClick = () => {
    if (column?.isSort) {
      dispatch(
        onSet({ sortType: checked ? "desc" : "asc", sortBy: column?.columnKey })
      );
      setChecked((value) => !value);
      onSortApi();
    }
  };

  return (
    <FluentTableHeaderCell
      className={mergeClasses(style.columnHeader, className)}
      onClick={handleHeaderClick}
    >
      {children}
      {column?.isSort ? (
        sortBy === column?.columnKey ? (
          checked ? (
            <Image src={arrowUp} height={20} width={20} />
          ) : (
            <Image src={arrowDown} height={20} width={20} />
          )
        ) : (
          <Image src={arrowDisable} height={20} width={20} />
        )
      ) : (
        ""
      )}
    </FluentTableHeaderCell>
  );
};

export default TableHeaderCell;
