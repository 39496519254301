import { Stack } from "@fluentui/react";
import { Button, mergeClasses } from "@fluentui/react-components";
import Style from "./Style";
import { ReactComponent as Pencil } from "../../../images/pencil.svg";
import { useState } from "react";
import { TableCell, TableRow } from "../../../components/Table";
import DeletePopup from "../../../components/FieldComponents/DeletePopup";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../styles/Layout.styles";
import QuestionsWrapper from "../QuestionsManagement/QuestionsWrapper";
import {
  useDeleteQuestionsMutation,
  useQuestionsListMutation,
} from "../../../store/action/question";
import { setQuestions } from "../../../store/reducer/question";

const QuestionsListRow = ({ item, projectId }) => {
  const [open, setOpen] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [getDeleteQuestion] = useDeleteQuestionsMutation();
  const [getQuestionsListApi] = useQuestionsListMutation();
  const questionsList = useSelector((state) => state.questions);
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    getDeleteQuestion(id).then((res) => {
      dispatch(
        setQuestions({
          page:
            questionsList?.data?.length === 1 && questionsList?.page > 0
              ? questionsList?.page - 1
              : questionsList?.page,
        })
      );
      getQuestionsListApi();
      setOpen(false);
    });
  };

  const style = Style();
  const layout = Layout();

  let disableButton = item.canBeDeleted === false;

  return (
    <>
      <TableRow key={item?.project?.label}>
        <TableCell
          tabIndex={item?.key}
          className={mergeClasses(style.customTblCell, style.wordBreak)}
          role="gridcell"
        >
          {item?.question}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          {item?.type}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={style.customTblCell}
          role="gridcell"
        >
          <Stack className={layout.tableIcons}>
            {isOpenDialog && (
              <QuestionsWrapper
                questionId={item?.id}
                isOpenDialog={isOpenDialog}
                setIsOpenDialog={setIsOpenDialog}
              />
            )}
            <Button
              className={mergeClasses(layout.customEdit)}
              appearance="transparent"
              onClick={() => setIsOpenDialog(true)}
              aria-label="edit"
            >
              <Pencil height={18} width={18} />
            </Button>
            <Stack
              className={mergeClasses(
                layout.tableIcons,
                disableButton ? "disableIcons" : ""
              )}
            >
              <DeletePopup
                className={layout.tableIcon}
                open={open}
                onOpenChange={(_, data) => setOpen(data.open)}
                onClick={() => setOpen(true)}
                handleDelete={() => handleDelete(item.id)}
                handleCancel={() => setOpen(false)}
              />
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
export default QuestionsListRow;
