import {
  tokens,
  shorthands,
  makeStaticStyles,
} from "@fluentui/react-components";

const timepickerStyle = makeStaticStyles({
  ".rdtCounters": {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("10px"),
    textAlign: "center",
    alignItems: "center",
  },
  ".rdtBtn": {
    cursor: "pointer",
    color: tokens.colorBrandBackgroundPressed,
  },
  ".rdt": {
    width: "100%",
  },
  ".timePickerInput": {
    cursor: "pointer",
    width: "100%",
    minWidth: "auto",
    ...shorthands.padding("12px", "10px"),
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
  },

  ".errorBorder": {
    ...shorthands.border("1px", "solid", tokens.colorPaletteRedBorder2),
  },
});
export default timepickerStyle;
