import FieldsInput from "../../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const ContactDepartment = (props) => {
  const { contactDepartment } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("department")}
          validationMessage={props.errors?.contactDepartment?.message}
          id="contactDepartment"
          placeholder={t("enter_department")}
          name="contactDepartment"
          inputDefaultValue={contactDepartment}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("contactDepartment", {
            required: `${t("validation_message.department_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default ContactDepartment;
