import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { setUser } from "../reducer/user";
import { API_HOST, BASE_COLOR } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { defaultFromState } from "../../utils/defaultFromState";

export const userApi = createApi({
  reducerPath: "login",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    login: buildMutationHelper(
      builder,
      (getData) => ({
        method: "POST",
        url: `/auth`,
        body: getData,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      (response, dispatch) => {
        const { token } = response?.data;
        localStorage.setItem("token", token);
        dispatch(setUser({ token, isAuthenticated: true, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setUser({ errorMessage: error?.data?.message }));
      }
    ),
    forgotPasswordUser: buildMutationHelper(
      builder,
      (params) => ({
        url: `/user/forgot_password`,
        method: "POST",
        body: params,
        headers: getHeaders({ isAuth: false }),
      }),
      (response, dispatch) => {
        const { data } = response.data;
        dispatch(setUser({ data, isAuthenticated: true, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setUser({ errorMessage: error?.data?.error }));
      }
    ),
    verifyTokenUser: buildMutationHelper(
      builder,
      ({ token }) => ({
        url: `/user/verify_token/${token}`,
        method: "GET",
        headers: getHeaders({ isAuth: false }),
      }),
      (response, dispatch) => {
        const { data } = response.data;
        dispatch(setUser({ data, isAuthenticated: true, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setUser({ errorMessage: error?.data?.error }));
      }
    ),
    resetPasswordUser: buildMutationHelper(
      builder,
      (params) => ({
        url: `/user/reset_forgetpassword`,
        method: "POST",
        body: params,
        headers: getHeaders({ isAuth: false }),
      }),
      (response, dispatch) => {
        const { data } = response.data;
        dispatch(setUser({ data, isAuthenticated: true, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setUser({ errorMessage: error?.data?.error }));
      }
    ),
    loginUser: buildMutationHelper(
      builder,
      (params) => ({
        url: `/login-user`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const data = response?.data;
        localStorage.setItem(
          "themeColor",
          data?.client?.activeThemes?.theme?.themeCode ?? BASE_COLOR
        );
        dispatch(
          setUser({
            data,
            isAuthenticated: true,
            errorMessage: "",
            userTheme: data?.client?.activeThemes?.theme?.themeCode,
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setUser({ errorMessage: error?.data?.error }));
      }
    ),
    getClientSystemUserList: buildMutationHelper(
      builder,
      ({ client = localStorage?.selectedClientId ?? undefined } = {}) => ({
        url: `/client-system-users`,
        method: "GET",
        headers: getHeaders(),
        params: {
          client: client,
          pagination: false,
        },
        defaultFromState: (getState, args) => {
          const {
            project: { client, pagination },
          } = getState();

          let stateParams = {
            client,
            pagination,
            withoutPage: true,
          };

          return defaultFromState(stateParams, args);
        },
      }),
      (response, dispatch) => {
        const data = response?.data;
        dispatch(
          setUser({
            data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setUser({ errorMessage: error?.data?.error }));
      }
    ),
    systemUserResetPasscode: buildMutationHelper(
      builder,
      ({ systemEndUserData, id }) => ({
        url: `/system-user/reset-passcode/${id}`,
        method: "POST",
        body: systemEndUserData,
        headers: getHeaders({ noAuth: false }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setUser({
            data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(
          setUser({
            errorMessage: error?.data?.violations,
          })
        );
      }
    ),
  }),
});
export const {
  useLoginMutation,
  useForgotPasswordUserMutation,
  useResetPasswordUserMutation,
  useVerifyTokenUserMutation,
  useLoginUserMutation,
  useSystemUserResetPasscodeMutation,
  useGetClientSystemUserListMutation,
} = userApi;
