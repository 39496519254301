import React from "react";
import ProjectForm from "./ProjectForm";
import { useParams } from "react-router-dom";

const EditProject = () => {
  const { id } = useParams();
  return <ProjectForm id={id} />;
};

export default EditProject;
