import { t } from "i18next";
import { FieldDropDown } from "../../../components";
import { Stack } from "@fluentui/react";
import Style from "../../ExternalSources/ExternalSource.style";
import { useGetProjectExternalLocationListMutation } from "../../../store/action/externalSources";
import { useEffect } from "react";
import { Spinner } from "@fluentui/react-components";

const Location = (props) => {
  const {
    setValue,
    locationDataList,
    setSelectedLocationId,
    location,
    setLocationDataList,
    selectedBusinessId,
    locationText,
    setLocationText,
  } = props;
  const style = Style();

  const [getLocationApi, { isLoading }] =
    useGetProjectExternalLocationListMutation();

  useEffect(() => {
    if (selectedBusinessId) {
      getLocationApi(selectedBusinessId).then((res) => {
        const listData = Object.entries(res?.data)?.map(([value, label]) => ({
          value: label?.id?.toString(),
          label: label?.name,
        }));
        setLocationDataList(listData);
      });
    }
  }, [getLocationApi, selectedBusinessId, location?.clientDepartment?.id]);

  const handleChange = (e, data) => {
    setSelectedLocationId(data?.optionValue);
    setLocationText(data?.optionText);
    setValue("location", data?.optionText, { shouldValidate: true });
  };

  return (
    <Stack className={style.client_field}>
      {isLoading ? (
        <Spinner />
      ) : (
        <FieldDropDown
          label={t("location")}
          validationMessage={props.errors?.location?.message}
          id="location"
          placeholder={t("enter_location")}
          name="location"
          options={locationDataList}
          onOptionSelect={handleChange}
          defaultSelectedOptions={[location?.externalLocationRef]}
          value={locationText}
          inputClassName={style.textboxInput}
          validation={props.register("location", {
            required: `${t("validation_message.location_is_required")}`,
          })}
        />
      )}
    </Stack>
  );
};

export default Location;
