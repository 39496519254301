import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setCommon } from "../reducer/common";

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    getPhonePrefix: buildMutationHelper(
      builder,
      (id) => ({
        url: `phone-prefix`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setCommon({
            commonData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setCommon({ errorMessage: error?.data?.error }));
      }
    ),
    getRoleList: buildMutationHelper(
      builder,
      (id) => ({
        url: `roles`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setCommon({
            commonData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setCommon({ errorMessage: error?.data?.error }));
      }
    ),
    countryList: buildMutationHelper(
      builder,
      (id) => ({
        url: `countries`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setCommon({ commonData: data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setCommon({ errorMessage: error?.data?.error }));
      }
    ),
    timeZoneList: buildMutationHelper(
      builder,
      (id) => ({
        url: `timezones`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setCommon({ commonData: data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setCommon({ errorMessage: error?.data?.error }));
      }
    ),
    timeZoneSelectList: buildMutationHelper(
      builder,
      (country) => ({
        url: `timezones/${country}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setCommon({ commonData: data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setCommon({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});

export const {
  useGetPhonePrefixMutation,
  useGetRoleListMutation,
  useCountryListMutation,
  useTimeZoneListMutation,
  useTimeZoneSelectListMutation,
} = commonApi;
