import { createSlice } from "@reduxjs/toolkit";

const teams = createSlice({
  name: "teams",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    project: "",
    pagination: "",
  },
  reducers: {
    setTeams(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setTeams } = teams.actions;
export default teams.reducer;
