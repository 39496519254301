const generateColorScale = (baseColor) => {
  // Convert hex to RGB
  function hexToRgb(hex) {
    return [
      parseInt(hex.substring(1, 3), 16),
      parseInt(hex.substring(3, 5), 16),
      parseInt(hex.substring(5, 7), 16),
    ];
  }

  // Convert RGB to hex
  function rgbToHex(rgb) {
    return (
      "#" +
      rgb
        .map(function (value) {
          return ("0" + value.toString(16)).slice(-2);
        })
        .join("")
    );
  }

  const colorScale = {};
  const baseRGB = hexToRgb(baseColor);

  for (let i = 10; i <= 160; i += 10) {
    const factor = i / 100;
    const scaledRGB = baseRGB.map((value) => Math.round(value * factor));
    const scaledColor = rgbToHex(scaledRGB);
    colorScale[i] = scaledColor;
  }

  return colorScale;
};

export default generateColorScale;
