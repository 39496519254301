import FieldsTextarea from "../../../../components/FieldComponents/FieldsTextarea";
import Style from "../AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const CompanyAddress = (props) => {
  const { companyAddress } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsTextarea
          label={t("address")}
          required
          validationMessage={props?.errors?.companyAddress?.message}
          placeholder={t("enter_address")}
          name="companyAddress"
          id="companyAddress"
          maxLength={255}
          inputClassName={style.textboxInput}
          inputDefaultValue={companyAddress}
          validation={props.register("companyAddress", {
            required: `${t("validation_message.address_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default CompanyAddress;
