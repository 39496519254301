import React, { useState } from "react";
import { mergeClasses, Button } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import LocationForm from "./LocationManagement/LocationForm";
import Layout from "../../../../styles/Layout.styles";

const LocationFilter = ({ projectId, role }) => {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const layout = Layout();

  return (
    <>
      <Stack className={layout.projectFilter}>
        <Stack></Stack>
        {role?.has("project_location_create") && (
          <Button
            appearance="primary"
            onClick={() => setIsOpenDialog(true)}
            className={mergeClasses(
              layout.prodBtn,
              "custom-primary",
              layout.max150
            )}
          >
            {t("project.pl_add_location")}
          </Button>
        )}

        {isOpenDialog && (
          <LocationForm
            projectId={projectId}
            isOpenDialog={isOpenDialog}
            setIsOpenDialog={setIsOpenDialog}
          />
        )}
      </Stack>
    </>
  );
};

export default LocationFilter;
