import * as React from "react";
import { Field, Select, mergeClasses } from "@fluentui/react-components";
import { useState } from "react";
import { useEffect } from "react";
import {
  useCountryListMutation,
  useTimeZoneSelectListMutation,
} from "../../store/action/common";
import { t } from "i18next";

const FieldCountrySelect = ({
  label,
  size = "large",
  placeholder,
  name,
  inputId,
  inputDefaultValue,
  inputClassName,
  noRequired,
  validation,
  validationMessage,
  className,
  validationMessageIcon = "",
  setTimeZoneSelectListData,
  disabled,
  resetField,
  timeZoneName,
}) => {
  const [countryList] = useCountryListMutation();
  const [countryListData, setCountryListData] = useState([]);

  const [timeZoneSelectList] = useTimeZoneSelectListMutation();
  const array = { key: "", value: t("select_country") };
  const arrayTomeZone = { key: "", value: t("select_timezone") };

  useEffect(() => {
    countryList().then((res) => {
      var resList = Object.entries(res?.data)?.map(([id, value]) => ({
        key: id,
        value,
      }));
      const pushData = [array, ...resList];

      setCountryListData(pushData);
    });

    if (inputDefaultValue) {
      timeZoneSelectList(inputDefaultValue).then((res) => {
        var resList = Object.entries(res?.data)?.map(([id, value]) => ({
          key: id,
          value,
        }));
        setTimeZoneSelectListData([arrayTomeZone, ...resList]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectCountry = (e) => {
    timeZoneSelectList(e?.target?.value).then((res) => {
      var resList = Object.entries(res?.data)?.map(([id, value]) => ({
        key: id,
        value,
      }));
      const pushData = [arrayTomeZone, ...resList];
      setTimeZoneSelectListData(pushData);
      resetField(timeZoneName);
    });
  };

  if (countryListData.length <= 0) {
    return "";
  }

  return (
    <Field
      label={label}
      className={mergeClasses("custom-label", className, "disablePointing")}
      size={size}
      validationMessageIcon={validationMessageIcon}
      validationMessage={validationMessage}
      required={noRequired ? undefined : true}
    >
      <Select
        id={inputId}
        className={inputClassName}
        name={name}
        defaultValue={inputDefaultValue}
        placeholder={placeholder}
        onClick={handleSelectCountry}
        {...validation}
        disabled={disabled}
      >
        {countryListData?.map((option, i) => (
          <option key={i} value={option?.key}>
            {option?.value}
          </option>
        ))}
      </Select>
    </Field>
  );
};

export default FieldCountrySelect;
