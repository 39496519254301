import { makeStyles, shorthands } from "@fluentui/react-components";

const TextBox = makeStyles({
  textbox: {
    display: "flex",
    flexDirection: "column",
  },
  textboxInput: {
    height: "45px",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: "0",
    ...shorthands.border("1px", "solid", "#D1D1D1"),
    ...shorthands.padding("0px", "12px"),
  },
});

export default TextBox;
