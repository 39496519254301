import { makeStaticStyles, tokens } from "@fluentui/react-components";

const staticStyle = makeStaticStyles({
  ".clientContent": {
    flexFlow: "wrap",
    width: "100%",
    boxShadow:
      "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
});

export default staticStyle;
