import React, { useState, useEffect } from "react";
import { useGetThemeMutation } from "../../../../../store/action/theme";
import ThemeManagement from "./ThemeManagement";

const ThemeManagementWrapper = (props) => {
  const { id, isOpenDialog, setIsOpenDialog } = props;
  const [initialLoad, setInitialLoad] = useState(true);
  const [getThemeApi, { isLoading: isGetLoading }] = useGetThemeMutation();
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getThemeApi(id).then((res) => {
            setEditData(res);
          });
        }
      }
      setInitialLoad(false);
    }
  }, [initialLoad, isGetLoading, id, getThemeApi]);

  if (id && !editData.data) {
    return null;
  }

  return (
    <ThemeManagement
      id={id}
      editData={editData}
      isOpenDialog={isOpenDialog}
      setIsOpenDialog={setIsOpenDialog}
    />
  );
};

export default ThemeManagementWrapper;
