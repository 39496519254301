import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CLIENT_SLUG_PATTERN } from "../../../../utils/constants";

const ClientSlug = (props) => {
  const { slug } = props;
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [valid, setValid] = useState(true);

  const inputChange = (input) => {
    const amount = input;
    if (!amount || amount.match(/^\d*\.\d+$/)) {
      setValid(false);
    } else {
      setValid(true);
    }
  };

  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("client_slug")}
          validationMessage={props.errors?.slug?.message}
          id="slug"
          placeholder={t("enter_client_slug_name")}
          onChange={(e) => inputChange(e.target.value)}
          name="slug"
          inputDefaultValue={slug}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("slug", {
            required: `${t("validation_message.client_slug_is_required")}`,
            pattern: {
              value: CLIENT_SLUG_PATTERN,
              message: `${t("validation_message.slug_not_valid_format")}`,
            },
          })}
        />
      </Stack>
    </>
  );
};

export default ClientSlug;
