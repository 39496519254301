import { t } from "i18next";
import { FieldDropDown } from "../../../components";
import { Stack } from "@fluentui/react";
import Style from "../ExternalSource.style";
import { useSelector } from "react-redux";
import {
  Toast,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";

const ExternalSource = (props) => {
  const {
    setValue,
    setAuthTypeName,
    externalSourcesList,
    setExternalSourceId,
    setExternalSourcesSelectedOptions,
    externalSourcesSelectedOptions,
  } = props;

  let loginClientId = localStorage.getItem("loginClientId");
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const externalSourcesDataList = useSelector(
    (state) => state.clientExternalSources
  );

  const style = Style();

  const notify = (message) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`${message}`}</ToastTitle>
      </Toast>,
      { intent: "error" }
    );
  };

  const handleChange = (e, data) => {
    setValue("externalSource", data?.optionText, { shouldValidate: true });
    setExternalSourcesSelectedOptions(data?.selectedOptions);
    setExternalSourceId(data?.optionValue);
    const getAuthType = externalSourcesList.find(
      (item) => item.value === data?.optionValue
    );

    const compareIds = externalSourcesDataList?.data?.find((item, key) =>
      item?.client.includes(loginClientId)
    );

    if (!compareIds) {
      setAuthTypeName(getAuthType?.authType);
    } else {
      notify("Already listed");
    }
  };

  return (
    <Stack className={style.client_field}>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <FieldDropDown
        label={t("externalSources.externalSource")}
        validationMessage={props.errors?.externalSource?.message}
        id="externalSource"
        placeholder={t("enter_externalSources")}
        name="externalSource"
        options={externalSourcesList}
        onOptionSelect={handleChange}
        selectedOptions={externalSourcesSelectedOptions}
        inputClassName={style.textboxInput}
        disabled={externalSourcesList?.length === 0}
        validation={props.register("externalSource", {
          required: `${t("validation_message.module_is_required")}`,
        })}
      />
    </Stack>
  );
};

export default ExternalSource;
