import { Button, mergeClasses } from "@fluentui/react-components";
import Style from "../style";
import { Stack } from "@fluentui/react";
import Layout from "../../../../../styles/Layout.styles";
import { Image } from "@fluentui/react-components";
import TrashIcon from "../../../../../images/trash.svg";
import { ArrowDown12Filled, ArrowUp12Filled } from "@fluentui/react-icons";

const QuestionList = (props) => {
  const {
    name,
    id,
    currentIndex,
    setVisibleQuestions,
    visibleQuestions,
    setQuestionDataList,
    questionDataList,
  } = props;
  const layout = Layout();
  const style = Style();

  const handleDelete = (index) => {
    setVisibleQuestions(visibleQuestions.filter((item) => item?.id !== index));
    const addInDropDown = visibleQuestions.filter((item) => item?.id === index);
    const listData = Object.entries(addInDropDown)?.map(([value, label]) => ({
      value: label?.id,
      label: label?.question,
    }));
    setQuestionDataList([...questionDataList, ...listData]);
  };

  const handleClick = (fromIndex, toIndex) => {
    let data = visibleQuestions;
    const element = data[fromIndex];
    data.splice(fromIndex, 1);
    data.splice(toIndex, 0, element);
    setVisibleQuestions([...data]);
  };
  return (
    <>
      <Stack
        className={mergeClasses(
          style.customTag,
          "customTag",
          layout.flex_row,
          layout.flex_between,
          "card-bottom-border",
          "card-remove-border"
        )}
      >
        <Stack className={layout.flex_row}>
          <Stack className={mergeClasses(style.ordering, layout.mr_1)}>
            <Button
              appearance="transparent"
              className={style.orderBtn}
              disabled={currentIndex === visibleQuestions.length - 1}
              onClick={() => handleClick(currentIndex, currentIndex + 1)}
            >
              <ArrowDown12Filled width="10" />
            </Button>
            <Button
              disabled={currentIndex === 0}
              appearance="transparent"
              onClick={() => handleClick(currentIndex, currentIndex - 1)}
              className={style.orderBtn}
            >
              <ArrowUp12Filled />
            </Button>
          </Stack>
          {name}
        </Stack>
        <Stack className={layout.justifyContent}>
          <Image
            alt="delete"
            src={TrashIcon}
            className={layout.cursor_pointer}
            onClick={() => handleDelete(id)}
            width={18}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default QuestionList;
