import { makeStaticStyles, tokens } from "@fluentui/react-components";
import { shorthands } from "@fluentui/react-components";
const menuStyle = makeStaticStyles({
  ".clientContent": {
    flexFlow: "wrap",
    boxShadow:
      "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
});

const staticStyle = makeStaticStyles({
  ".counter": {
    ...shorthands.border("1px", "solid", tokens.colorBrandBackgroundPressed),
    borderRadius: "6px",
  },
  ".qty-details, .counter": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ".increment, .decrement": {
    height: "40px",
    minWidth: "40px",
    backgroundColor: tokens.colorBrandBackground,
    color: "white",
    ...shorthands.border(0),
    ...shorthands.padding(0),
  },
  ".increment:hover, .decrement:hover": {
    backgroundColor: tokens.colorBrandForeground2Hover,
    opacity: 0.5,
  },

  ".increment": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  ".decrement": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  ".counterInput": {
    height: "30px",
    ...shorthands.padding("0", "0", "0", "5px"),
    width: "32px",
    ...shorthands.border(0),
  },
  ".counterInput input": {
    paddingRight: "2px",
  },
});
export default menuStyle;
export { staticStyle };
