import { createSlice } from "@reduxjs/toolkit";

const projectUser = createSlice({
  name: "projectUser",
  initialState: {
    data: [],
    userData: [],
    errorMessage: "",
  },

  reducers: {
    setProjectUser(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setProjectUser } = projectUser.actions;
export default projectUser.reducer;
