import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import FieldCountrySelect from "../../../../components/FieldComponents/FieldCountrySelect";
import { t } from "i18next";

const UserCountry = (props) => {
  const { userCountry, setTimeZoneSelectListData, resetField } = props;
  const style = Style();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldCountrySelect
          label={t("project.country")}
          validationMessage={props.errors?.userCountry?.message}
          id="userCountry"
          placeholder={t("project.select_country")}
          name="userCountry"
          inputClassName={mergeClasses(style.textboxInput)}
          setTimeZoneSelectListData={setTimeZoneSelectListData}
          inputDefaultValue={userCountry}
          validation={props.register("userCountry")}
          noRequired
          resetField={resetField}
          timeZoneName={"userTimeZone"}
        />
      </Stack>
    </>
  );
};

export default UserCountry;
