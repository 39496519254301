import * as React from "react";
import {
  Button,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  mergeClasses,
} from "@fluentui/react-components";
import { t } from "i18next";
import Layout from "../../styles/Layout.styles";
import Form from "./Form";
import { useForm } from "react-hook-form";
import FieldsInput from "./FieldsInput";
import { Stack } from "@fluentui/react";

const PopoverComponent = ({ label, className, onSubmit, open, setOpen }) => {
  const layout = Layout();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  return (
    <>
      <Popover
        trapFocus
        open={open}
        onOpenChange={(_, data) => setOpen(data.open)}
      >
        <PopoverTrigger disableButtonEnhancement>
          <Button className={className}>{label}</Button>
        </PopoverTrigger>

        <PopoverSurface>
          <div className={mergeClasses(layout.d_flex, layout.flex_column)}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Stack className={layout.mb_15}>
                <FieldsInput
                  label={t("reason")}
                  validationMessage={errors?.reason?.message}
                  fieldClassName={layout.mb_0_5}
                  placeholder={t("enter_reason")}
                  name="reason"
                  inputClassName={mergeClasses(layout.textboxInput)}
                  validation={register("reason", {
                    required: `${t("validation_message.reason_is_required")}`,
                  })}
                />
              </Stack>
              <Button
                type="submit"
                appearance="primary"
                className={mergeClasses(
                  "custom-primary",
                  layout.smallBtn,
                  layout.smallBtn,
                  layout.mr_1
                )}
              >
                {t("submit")}
              </Button>
              <Button
                className={layout.smallBtn}
                onClick={() => {
                  setOpen(false);
                  reset({ reason: "" });
                }}
              >
                {t("cancel")}
              </Button>
            </Form>
          </div>
        </PopoverSurface>
      </Popover>
    </>
  );
};

export default PopoverComponent;
