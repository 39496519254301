export const tokenExpiration = () => {
  const token = localStorage.getItem("token");
  if (!token) return true; // Token not present, consider as expired
  const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode token

  if (decodedToken.exp < Date.now() / 1000) {
    localStorage.clear();
    window.location.assign("/login");
  }
};
