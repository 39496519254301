/* eslint-disable no-unused-vars */
import { Stack, Text } from "@fluentui/react";
import { Link } from "react-router-dom";
import { ReactComponent as Pencil } from "../../../images/pencil.svg";
import { ReactComponent as Eye } from "../../../images/eye.svg";
import { useState } from "react";
import Layout from "../../../styles/Layout.styles";
import { TableCell, TableRow } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../components/FieldComponents/Avatar";
import { mergeClasses } from "@fluentui/react-components";
import {
  useGetSystemEndUserPhotoMutation,
  useSystemEndUserActiveInactiveMutation,
  useSystemEndUserDeleteMutation,
  useSystemEndUserListMutation,
} from "../../../store/action/systemEndUser";
import imageTimeStamp from "../../../utils/imageTimeStamp";
import {
  useId,
  useToastController,
  Toast,
  Toaster,
  ToastTitle,
} from "@fluentui/react-components";
import { t } from "i18next";
import SwitchPopup from "../../../components/FieldComponents/SwitchPopup";

const SystemEndUserRow = ({ item, role }) => {
  const [open, setOpen] = useState(false);
  const [getSystemEndUserListApi, { isLoading }] =
    useSystemEndUserListMutation();
  const [switchOn] = useSystemEndUserActiveInactiveMutation();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message) => {
    dispatchToast(
      <Toast>
        <ToastTitle>
          {t(message ? message : "please_fill_mandatory_field")}
        </ToastTitle>
      </Toast>,
      { intent: "error" }
    );
  };

  const handleSwitchChange = () => {
    const systemEndUserData = {
      isActive: item?.isActive ? false : true,
    };

    switchOn({ systemEndUserData: systemEndUserData, id: item?.id }).then(
      (res) => {
        if (res?.error) {
          notify(res?.error?.data?.error);
        }
        if (!isLoading) {
          getSystemEndUserListApi();
          setOpen(false);
        }
      }
    );
  };

  let disableLink = item.isActive === false;

  const layout = Layout();
  return (
    <>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />

      <TableRow key={item?.project?.label}>
        <TableCell tabIndex={item?.key} role="gridcell">
          <Stack className={mergeClasses(layout.flex_row, layout.alignCenter)}>
            <Avatar
              image={imageTimeStamp(item?.profileImageUrl)}
              name={item?.fullName}
              className={layout.mr_1}
            />
            <Text>{item?.fullName}</Text>
          </Stack>
        </TableCell>
        <TableCell tabIndex={item?.key} role="gridcell">
          {item?.email}
        </TableCell>
        <TableCell tabIndex={item?.key} className={layout.customTblCell}>
          <Stack
            className={mergeClasses(layout.tableIcons, layout.alignCenter)}
          >
            {role?.has("system_end_user_list") && (
              <Link
                to={`/system-end-user-list/${item.id}`}
                className={mergeClasses(
                  layout.tableIcon,
                  layout.d_flex,
                  layout.alignCenter
                )}
                aria-label="view"
              >
                <Eye height={18} width={18} />
              </Link>
            )}
            {role?.has("system_end_user_edit") && (
              <Stack
                className={mergeClasses(
                  layout.tableIcons
                  // disableLink ? "disableIcons" : ""
                )}
              >
                <Link
                  to={`/system-end-user-list/${item.id}/edit`}
                  className={mergeClasses(
                    layout.tableIcon,
                    layout.d_flex,
                    layout.alignCenter
                  )}
                  aria-label="edit"
                >
                  <Pencil height={18} width={18} />
                </Link>
              </Stack>
            )}
          </Stack>
        </TableCell>{" "}
        <TableCell tabIndex={item?.key}>
          {role?.has("system_end_user_edit") ? (
            <Stack className={layout.tableIcons}>
              <SwitchPopup
                open={item?.isActive && open}
                onOpenChange={(_, data) => setOpen(data.open)}
                handleSwitchChange={() => {
                  item?.isActive === false && handleSwitchChange();
                }}
                checked={item?.isActive ? true : false}
                handleInactiveSwitch={handleSwitchChange}
                handleCancel={() => setOpen(false)}
                isLoading={isLoading}
              />
            </Stack>
          ) : item?.isActive ? (
            "Active"
          ) : (
            "Inactive"
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
export default SystemEndUserRow;
