import React from "react";
import Layout from "./Layout";
import { useSelector } from "react-redux";

const Main = (props) => {
  const themeColor = useSelector((state) => state.user);
  if (themeColor?.userTheme) {
    props.setActiveTheme(themeColor?.userTheme);
  }
  return (
    <>
      <Layout />
    </>
  );
};

export default Main;
