import React from "react";
import { useParams } from "react-router-dom";
import UsersForm from "./UsersForm";

const EditUsers = () => {
  const { id } = useParams();
  return <UsersForm id={id} />;
};

export default EditUsers;
