import { useState } from "react";
import { FieldCombobox } from "../../../components";
import { useEffect } from "react";
import Layout from "../../../styles/Layout.styles";
import { useGetPendingDataMutation } from "../../../store/action/time-and-attendance/time-and-attendancePending";
import { Stack } from "@fluentui/react";
import { Text, mergeClasses } from "@fluentui/react-components";
import { t } from "i18next";

const AssignedProject = ({
  options,
  assignedProjectId,
  setAssignedProjectId,
  setIsEmployeeDetailsOpen,
  setSelectedValue,
  setChecked,
}) => {
  const [customSearch, setCustomSearch] = useState("" | undefined);
  const [matchingOptions, setMatchingOptions] = useState([...options]);
  const [getPendingDataList] = useGetPendingDataMutation();

  const layout = Layout();
  useEffect(() => {
    setMatchingOptions([...options]);
  }, [options]);

  const onSelectOption = (e, data) => {
    setMatchingOptions([...options]);
    const matchingOption =
      data?.optionText && matchingOptions?.includes(data?.optionText);
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data?.optionText);
    }
    setChecked(false);

    getPendingDataList({
      page: 0,
      itemsPerPage: 10,
      projectId: data?.optionValue,
    });
    setAssignedProjectId(data?.optionValue);
    setIsEmployeeDetailsOpen(false);
    setSelectedValue("review");
  };

  const onChange = (event) => {
    const value = event?.target?.value?.trim();
    const matches = options?.filter((option) =>
      option?.children?.toLowerCase().includes(value?.toLowerCase())
    );

    setMatchingOptions(matches);
    if (value?.length && matches?.length < 1) {
      setCustomSearch(value);
    } else {
      setCustomSearch(undefined);
    }
  };

  if (options[0]?.children === undefined) {
    return "";
  }

  return (
    <>
      <Stack>
        <Stack className={mergeClasses(layout.flex_row, layout.alignCenter)}>
          <label className={layout.mr_1}>{t("assigned_project")}&nbsp;:</label>
          <FieldCombobox
            multiselect={false}
            className={layout.w_100}
            defaultValue={options[0]?.children}
            placeholder={t("project.pl_select_project")}
            defaultSelectedOptions={[options[0]?.value]}
            onOptionSelect={onSelectOption}
            onChange={onChange}
            customSearch={customSearch}
            matchingOptions={matchingOptions}
          />
        </Stack>{" "}
        {!assignedProjectId && (
          <Text className={mergeClasses(layout.colorEbc2f32, layout.ml_65)}>
            {t("project.please_select_project")}
          </Text>
        )}
      </Stack>
    </>
  );
};

export default AssignedProject;
