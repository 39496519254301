import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const style = makeStyles({
  subCard: {
    padding: "20px",
    flexDirection: "column",
    alignItems: "flex-start",
    ...shorthands.gap("15px"),
    ...shorthands.margin("auto"),
    ...shorthands.padding("20px"),
    width: "100%",
    "@media (min-width: 1024px)": {
      width: "650px",
    },
  },
  pointDesign: {
    backgroundColor: tokens.colorBrandBackground,
    textAlign: "center",
    height: "22px",
    width: "22px",
    color: "white",
    marginRight: "15px",
    fontWeight: "700",
  },
  mainTitle: {
    fontSize: "18px",
    fontWeight: "600",
  },
  contentText: {
    justifyContent: "center",
    ...shorthands.margin("auto"),
    textAlign: "center",
  },
  fileWidth: {
    width: "100%",
    "@media (min-width: 1024px)": {
      width: "395px",
    },
  },
  mr_0_5: {
    marginRight: "5px",
  },
});

export default style;
