import { useDispatch, useSelector } from "react-redux";
import { useGetPendingDataMutation } from "../../../store/action/time-and-attendance/time-and-attendancePending";
import {
  startOfMonth,
  startOfWeek,
  endOfMonth,
  endOfWeek,
  format,
} from "date-fns";
import { useGetApprovedDataMutation } from "../../../store/action/time-and-attendance/time-and-attendanceApprove";
import { useGetRejectDataMutation } from "../../../store/action/time-and-attendance/time-and-attendanceReject";
import { FieldCombobox } from "../../../components";
import { setTimeAndAttendancePending } from "../../../store/reducer/time-and-attendance/time-and-attendancePending";
import { setTimeAndAttendanceApprove } from "../../../store/reducer/time-and-attendance/time-and-attendanceApprove";
import { setTimeAndAttendanceReject } from "../../../store/reducer/time-and-attendance/time-and-attendanceReject";
import { t } from "i18next";
import Layout from "../../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";

const DateFilterMenu = ({
  assignedProjectId,
  selectedValue,
  setIsEmployeeDetailsOpen,
}) => {
  const dispatch = useDispatch();
  const layout = Layout();
  const [getPendingDataList] = useGetPendingDataMutation();
  const [getApproveDataList] = useGetApprovedDataMutation();
  const [getRejectDataList] = useGetRejectDataMutation();
  const timeAndAttendancePendingList = useSelector(
    (state) => state.timeAndAttendancePending
  );
  const timeAndAttendanceApproveList = useSelector(
    (state) => state.timeAndAttendanceApprove
  );
  const timeAndAttendanceRejectList = useSelector(
    (state) => state.timeAndAttendanceReject
  );

  const options = [
    { value: "day", children: "Day" },
    { value: "week", children: "Week" },
    { value: "month", children: "Month" },
    { value: "all", children: "All" },
  ];

  const today = new Date();
  const startOfWeekDate = startOfWeek(today, { weekStartsOn: 1 });
  const endOfWeekDate = endOfWeek(today, { weekStartsOn: 1 });

  const todayFormate = format(new Date(), "yyyy/MM/dd");
  const formattedStartOfWeek = format(startOfWeekDate, "yyyy/MM/dd");
  const formattedEndOfWeek = format(endOfWeekDate, "yyyy/MM/dd");
  const firstDayOfMonth = format(startOfMonth(today), "yyyy/MM/dd");
  const lastDayOfMonth = format(endOfMonth(today), "yyyy/MM/dd");

  const onSelectOption = (ev, data) => {
    setIsEmployeeDetailsOpen(false);
    const checkInDate =
      data?.optionValue === "day"
        ? todayFormate
        : data?.optionValue === "week"
        ? formattedStartOfWeek
        : data?.optionValue === "month"
        ? firstDayOfMonth
        : undefined;

    const checkoutDate =
      data?.optionValue === "day"
        ? // eslint-disable-next-line no-useless-concat
          todayFormate + " " + "23:59:59"
        : data?.optionValue === "week"
        ? formattedEndOfWeek
        : data?.optionValue === "month"
        ? lastDayOfMonth
        : undefined;

    dispatch(
      setTimeAndAttendancePending({
        page: 0,
        checkInDate: checkInDate,
        checkOutDate: checkoutDate,
        projectId: assignedProjectId,
      })
    );
    dispatch(
      setTimeAndAttendanceApprove({
        page: 0,
        checkInDate: checkInDate,
        checkOutDate: checkoutDate,
        projectId: assignedProjectId,
      })
    );
    dispatch(
      setTimeAndAttendanceReject({
        page: 0,
        checkInDate: checkInDate,
        checkOutDate: checkoutDate,
        projectId: assignedProjectId,
      })
    );
    if (assignedProjectId && selectedValue === "review") {
      getPendingDataList({
        page: 0,
        itemsPerPage: timeAndAttendancePendingList?.itemsPerPage,
        projectId: assignedProjectId,
        checkInDate: checkInDate,
        checkOutDate: checkoutDate,
      });
    } else if (assignedProjectId && selectedValue === "approved") {
      getApproveDataList({
        page: 0,
        itemsPerPage: timeAndAttendanceApproveList?.itemsPerPage,
        projectId: assignedProjectId,
        checkInDate: checkInDate,
        checkOutDate: checkoutDate,
      });
    } else {
      getRejectDataList({
        page: 0,
        itemsPerPage: timeAndAttendanceRejectList?.itemsPerPage,
        projectId: assignedProjectId,
        checkInDate: checkInDate,
        checkOutDate: checkoutDate,
      });
    }
  };

  return (
    <Stack
      className={mergeClasses(layout.flex_row, layout.mb_1, layout.alignCenter)}
    >
      <label className={mergeClasses(layout.mr_1, layout.textNowrap)}>
        {t("filter_by")}
      </label>
      <FieldCombobox
        multiselect={false}
        readOnly
        name="range"
        stackCombobox="minwidth-combobox"
        matchingOptions={options}
        placeholder={t("select")}
        onOptionSelect={onSelectOption}
        defaultValue={options?.[3]?.children}
        defaultSelectedOptions={[options?.[3]?.value]}
      />
    </Stack>
  );
};

export default DateFilterMenu;
