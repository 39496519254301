import React, { useEffect, useState } from "react";
import { FieldCombobox } from "../../../../components";
import Layout from "../../../../styles/Layout.styles";
import { useLocationListMutation } from "../../../../store/action/location";
import { useVisitorListMutation } from "../../../../store/action/visitor";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { setVisitor } from "../../../../store/reducer/visitor";

const ProjectFilter = (props) => {
  const {
    projectOption,
    setAssignedProjectId,
    setProjectLocationOptions,
    setIsEnableDetails,
    setAssignedLocationId,
    setIsProjectLoader,
  } = props;
  const [customSearch, setCustomSearch] = useState("" | undefined);
  const [matchingOptions, setMatchingOptions] = useState([...projectOption]);
  const [getLocationListApi] = useLocationListMutation();
  const [getVisiterListApi, { isLoading }] = useVisitorListMutation();
  const layout = Layout();
  const dispatch = useDispatch();

  useEffect(() => {
    setMatchingOptions([...projectOption]);
    setIsProjectLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectOption, isLoading]);

  const onSelectOption = (e, data) => {
    setAssignedLocationId(undefined);
    setMatchingOptions([...projectOption]);
    setIsEnableDetails(false);
    const matchingOption =
      data?.optionText && matchingOptions?.includes(data?.optionText);
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data?.optionText);
    }
    setAssignedProjectId(data?.optionValue);

    if (!isLoading) {
      dispatch(setVisitor({ page: 0 }));
      getVisiterListApi({ projectLocationId: data?.optionValue }).then(() => {
        dispatch(setVisitor({ page: 0 }));
        getLocationListApi({
          pagination: false,
          project: data?.optionValue,
          search: "",
          sortBy: "",
          sortType: "",
        }).then((res) => {
          const locationData = res?.data?.map?.((item, label) => ({
            children: item?.name,
            value: item?.id,
          }));
          setProjectLocationOptions(locationData);
        });
      });
    }
  };

  const onChange = (event) => {
    const value = event?.target?.value?.trim();
    const matches = projectOption?.filter((option) =>
      option?.children?.toLowerCase().includes(value?.toLowerCase())
    );
    setMatchingOptions(matches);
    if (value?.length && matches?.length < 1) {
      setCustomSearch(value);
    } else {
      setCustomSearch(undefined);
    }
  };

  if (projectOption?.[0]?.children === undefined) {
    return "";
  }

  return (
    <Stack className={mergeClasses(layout.flex_column, layout.alignTop)}>
      <label className={layout.mb_1}>Project</label>
      <FieldCombobox
        multiselect={false}
        stackCombobox={layout.w_100}
        name="project"
        placeholder={t("project.pl_select_project")}
        className={layout.w_100}
        defaultValue={projectOption?.[0]?.children}
        defaultSelectedOptions={[projectOption?.[0]?.value]}
        onOptionSelect={onSelectOption}
        onChange={onChange}
        customSearch={customSearch}
        matchingOptions={matchingOptions}
      />
    </Stack>
  );
};
export default ProjectFilter;
