import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { useTranslation } from "react-i18next";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";

const ProjectCode = (props) => {
  const { projectCode } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("project.project_code")}
          validationMessage={props.errors?.projectCode?.message}
          id="projectCode"
          placeholder={t("project.enter_project_code")}
          name="projectCode"
          inputDefaultValue={projectCode}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("projectCode", {
            required: `${t("validation_message.project_code_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default ProjectCode;
