import { Stack, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useGetEmployeeDetailMutation } from "../../../../store/action/time-and-attendance";
import Layout from "../../../../styles/Layout.styles";
import Style from "./../pending/Style";
import {
  Divider,
  mergeClasses,
  Image,
  Button,
  Spinner,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import close from "../../../../images/close.svg";
import ClockLog from "./ClockLogs";
import ClockLogAction from "./ClockLogAction";
import ClockLogPopover from "./ClockLogPopover";
import { useDispatch, useSelector } from "react-redux";
import { setTimeAndAttendance } from "../../../../store/reducer/time-and-attendance";
import accessRole from "../../../../components/Common/accessRole";
import { getTimezoneOffsetData } from "../../../../utils/convert-date-time";

const EmployeeDetails = ({
  employeeSelectedId,
  assignedProjectId,
  selectedValue,
  setIsEmployeeDetailsOpen,
}) => {
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();

  const [GetEmployeeDetails, { isLoading }] = useGetEmployeeDetailMutation();
  const [viewDetails, setDetails] = useState();
  const [locationNameList, setLocationNameList] = useState([]);
  const dispatch = useDispatch();
  const employeeDetailsList = useSelector((state) => state.timeAndAttendance);

  useEffect(() => {
    if (employeeSelectedId) {
      if (!isLoading) {
        GetEmployeeDetails(employeeSelectedId).then((res) => {
          setDetails(res);
          const location = res?.data?.projectTnaDetails?.map((item) => ({
            timezone: res?.data?.timezone,
            locationId: item?.locationId,
            locationName: item?.locationName,
            locationShortCode: item?.locationShortCode,
            externalSourceRefName: item?.externalSourceRefName,
            externalLocationRefName: item?.externalLocationRefName,
            clockLog: [
              {
                clockLogId: item?.id,
                checkIn: item?.checkIn,
                checkOut: item?.checkOut,
                isDeleted: item?.isDeleted,
                logType: item?.logType,
                isEdited: item?.isEdited,
                autoCheckoutAt: item?.autoCheckoutAt,
                isAutoClockout: item?.isAutoClockout,
              },
            ],
          }));

          const arrayHashmap = location?.reduce((obj, item) => {
            obj[item?.locationId]
              ? obj[item?.locationId].clockLog?.push(...item?.clockLog)
              : (obj[item?.locationId] = { ...item });
            return obj;
          }, {});

          const mergedArray = Object?.values(arrayHashmap);
          setLocationNameList(mergedArray);
          dispatch(setTimeAndAttendance({ updateLogs: false }));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetEmployeeDetails, employeeSelectedId, employeeDetailsList?.updateLogs]);

  return (
    <>
      <Stack
        className={mergeClasses(
          style.employeeDetails,
          "cardShadow",
          "sidebarDetails",
          layout.mt_0
        )}
      >
        <h2 className={mergeClasses(style.detailTitle, layout.primary)}>
          {t("employee.employee_details")}
          <Button
            appearance="subtle"
            onClick={() => setIsEmployeeDetailsOpen(false)}
            aria-label="close"
            icon={<Image alt="" src={close} height={20} width={20} />}
          />
        </h2>
        {isLoading ? (
          <Stack
            className={mergeClasses(layout.positionRelative, layout.w_100)}
          >
            <Spinner className={layout.m_auto} />
          </Stack>
        ) : (
          <>
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>{t("employee.name")}</Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >{`${viewDetails?.data?.employeeName}`}</Text>
            </Stack>

            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>
                {t("employee.department")}
              </Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >{`${viewDetails?.data?.teamName}`}</Text>
            </Stack>

            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>{t("employee.email")}</Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >{`${viewDetails?.data?.email}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />

            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>{t("employee.address")}</Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >{`${viewDetails?.data?.systemEndUser?.address}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />

            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.detailLbl}>{t("employee.mobile")}</Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.detailText,
                  layout.textOverFlowHidden
                )}
              >
                ({`${viewDetails?.data?.systemEndUser?.phonePrefix}`}){" "}
                {`${viewDetails?.data?.systemEndUser?.phoneNumber}`}
              </Text>
            </Stack>
            <Divider className={style.detailsDivider} />

            <h2
              className={mergeClasses(
                style.detailTitle,
                layout.primary,
                layout.mt_2
              )}
            >
              {t("employee.clockLogs")}
            </h2>
            <Divider />
            <Text className={mergeClasses(layout.mt_1)}>
              {t("all_log_types_marked_with_have_been_edited")}
            </Text>
            {locationNameList?.map((item, key) => {
              return (
                <Stack key={key}>
                  <Stack className={layout.projectFilter}>
                    <h3
                      className={mergeClasses(style.detailLbl, layout.primary)}
                    >
                      {`${item?.locationName}`} ({`${item?.locationShortCode}`})
                      {" - "}
                      {`${item?.timezone}`}
                      {`${getTimezoneOffsetData(item?.timezone)}`}
                      <br /> <br />
                      {item?.externalSourceRefName === undefined
                        ? ""
                        : `${item?.externalSourceRefName}`}
                      {item?.externalLocationRefName === undefined ? "" : " - "}
                      {item?.externalLocationRefName === undefined
                        ? ""
                        : `${item?.externalLocationRefName}`}
                    </h3>

                    <Stack
                      className={mergeClasses(
                        layout.d_flex,
                        layout.flex_row,
                        layout.alignCenter
                      )}
                      key={key}
                    >
                      {" "}
                      {accessRole()?.has("can_add_time_log") &&
                        selectedValue === "review" && (
                          <ClockLogPopover
                            assignedProjectId={assignedProjectId}
                            viewDetails={viewDetails?.data}
                            item={item}
                          />
                        )}
                    </Stack>
                  </Stack>
                  <ClockLog
                    projectTnaDetails={item}
                    assignedProjectId={assignedProjectId}
                    viewDetails={viewDetails?.data}
                    selectedValue={selectedValue}
                    isLoading={isLoading}
                    GetEmployeeDetails={GetEmployeeDetails}
                  />
                </Stack>
              );
            })}

            {selectedValue === "review" && (
              <ClockLogAction
                assignedProjectId={assignedProjectId}
                employeeSelectedId={employeeSelectedId}
                setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
              />
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default EmployeeDetails;
