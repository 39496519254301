import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { t } from "i18next";

const borderColor = "#e2e8f0";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#e2e8f0",
    backgroundColor: "#e0e0e0",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    color: "#111111",
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "50%",
    textAlign: "left",
    paddingLeft: "8px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  status: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: "20%",
  },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description}>{t("invoice.item")}</Text>
    <Text style={styles.qty}>{t("invoice.qty")}</Text>
    <Text style={styles.status}>{t("invoice.status")}</Text>
    <Text style={styles.amount}>{t("invoice.amount")}</Text>
  </View>
);

export default InvoiceTableHeader;
