import * as React from "react";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { SearchBox } from "@fluentui/react-search-preview";
import { Button } from "@fluentui/react-components";
import Style from "../../User/UserList/Style";
import { useTranslation } from "react-i18next";
import TeamsWrapper from "../TeamsManagement/TeamsWrapper";
import Layout from "../../../styles/Layout.styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setTeams } from "../../../store/reducer/teams";
import { useSelector } from "react-redux";

const TeamsFilter = ({ getTeamsListApi, setInitialLoad, projectId, role }) => {
  const style = Style();
  const layout = Layout();
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const teamsList = useSelector((state) => state.teams);

  const onGetTeams = async (search) => {
    await getTeamsListApi({
      page: 0,
      search: search,
      project: projectId,
    });
  };

  const onChange = (data) => {
    if (projectId) {
      dispatch(setTeams({ page: 0, search: data }));
      onGetTeams();
    }
    setInitialLoad(false);
  };

  return (
    <>
      <Stack className={layout.projectFilter}>
        <SearchBox
          placeholder={t("search")}
          onChange={(e) => onChange(e?.target?.value)}
          className={mergeClasses(
            style.headerSearch,
            layout.min150,
            "headerSearch",
            "no-reset"
          )}
          defaultValue={teamsList?.search}
          iconProps={{ iconName: "Search", styles: { root: { right: "8px" } } }}
        />
        {isOpenDialog && (
          <TeamsWrapper
            projectId={projectId}
            isOpenDialog={isOpenDialog}
            setIsOpenDialog={setIsOpenDialog}
          />
        )}

        {role?.has("project_teams_create") && (
          <Button
            appearance="primary"
            className={mergeClasses("custom-primary", layout.smallBtn)}
            onClick={() => setIsOpenDialog(true)}
          >
            {t("teams.add")}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default TeamsFilter;
