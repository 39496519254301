import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { Button, mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import Layout from "../../../../styles/Layout.styles";
import { useState } from "react";

const SystemUserSetPassword = (props) => {
  const { isEditDisable, setValue } = props;
  const style = Style();
  const layout = Layout();
  const [rendomPassWord, setRendomPassWord] = useState("");
  const { t } = useTranslation();

  const rendomPasswordGenerate = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789*&@?/";
    const charactersLength = characters?.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    setRendomPassWord(result);
    setValue("systemUserSetPassword", result, { shouldValidate: true });
    return result;
  };

  const onChange = (e) => {
    setRendomPassWord(e?.target?.value);
  };
  return (
    <>
      <Stack className={style.client_field}>
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.gap_15,
            layout.alignItemEnd
          )}
        >
          <FieldsInput
            label={t("set_passWord")}
            validationMessage={props.errors?.systemUserSetPassword?.message}
            id="systemUserSetPassword"
            placeholder={isEditDisable ? "********" : t("generate_password")}
            name="systemUserSetPassword"
            value={rendomPassWord}
            onChange={onChange}
            className={mergeClasses(layout.w_100, "pointer-none")}
            disabled={isEditDisable}
            inputClassName={mergeClasses(
              style.textboxInput,
              layout.min_w_100,
              "pointer-none"
            )}
            validation={
              !isEditDisable
                ? props.register("systemUserSetPassword", {
                    required: `${t(
                      "validation_message.set_password_is_required"
                    )}`,
                  })
                : ""
            }
          />
          <Stack>
            <Button
              onClick={() => rendomPasswordGenerate(8)}
              appearance="primary"
              className={layout.h_40}
              disabled={isEditDisable}
            >
              {t("generate_btn")}
            </Button>

            {props?.errors?.systemUserSetPassword?.message && (
              <Text className={layout.mb_1_8}> </Text>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SystemUserSetPassword;
