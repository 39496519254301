import { useTranslation } from "react-i18next";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import Layout from "../../../../styles/Layout.styles";
import { Button, Label, Text, mergeClasses } from "@fluentui/react-components";
import { ReactComponent as Plus } from "../../../../images/plus.svg";
import { ReactComponent as Minus } from "../../../../images/minus.svg";
import { useEffect } from "react";
import { FieldsInput } from "../../../../components";
import { staticStyle } from "../Style";

const WorkingDuration = (props) => {
  const {
    setInput,
    inputValue,
    validationMsg,
    setValidationMsg,
    editWorkingDuration,
    setValue,
  } = props;
  const { t } = useTranslation();
  const style = Style();
  const layout = Layout();
  staticStyle();

  useEffect(() => {
    setInput(editWorkingDuration ? editWorkingDuration : inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const counterPlus = (input, button) => {
    let value = parseInt(inputValue) + parseInt(input);
    setInput(0 < value && value < 24 ? value : 24);
    setValidationMsg(false);
    setValue("numberOfHours", value, { shouldValidate: true });
  };

  const counterMinus = (input) => {
    let value = parseInt(inputValue) + parseInt(input);
    setInput(value >= 1 ? value : 1);
    setValidationMsg(false);

    setValue("numberOfHours", value, { shouldValidate: true });
  };

  return (
    <>
      <Stack className={style.client_field}>
        <Label className={mergeClasses("custom-label", layout.mb_1_0)}>
          {t("project.working_duration")}{" "}
          <Text className={layout.durationText}>*</Text>
        </Label>
        <Stack>
          <Stack className={mergeClasses("qty-details")}>
            <Stack className="counter">
              <Button className="increment" onClick={() => counterMinus(-1)}>
                <Stack className="minus">
                  <Minus height={22} width={22} />
                </Stack>
              </Button>

              <FieldsInput
                validationMessage={props.errors?.numberOfHours?.message}
                id="numberOfHours"
                name="numberOfHours"
                inputClassName={mergeClasses(
                  style.textboxInput,
                  "counterInput"
                )}
                value={inputValue === 0 ? "" : inputValue}
                validation={
                  inputValue === "" || inputValue === 0
                    ? props.register("numberOfHours", {
                        required: `${t(
                          "validation_message.working_duration_is_required"
                        )}`,
                      })
                    : ""
                }
              />
              <Stack className={layout.hourStyle}>h</Stack>
              <Button className="decrement" onClick={() => counterPlus(1)}>
                <Stack className="plus">
                  <Plus height={22} width={22} />
                </Stack>
              </Button>
            </Stack>
          </Stack>
          {validationMsg && (
            <p
              className={mergeClasses(
                layout.colorEbc2f32,
                layout.mt_0,
                layout.fontSize12,
                layout.m_0
              )}
            >
              {t("validation_message.please_select_working_hours")}
            </p>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default WorkingDuration;
