import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { useTranslation } from "react-i18next";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";

const Title = (props) => {
  const { title } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t('project.project_title')}
          validationMessage={props.errors?.title?.message}
          id="title"
          placeholder={t('project.enter_project_title')}
          name="title"
          inputDefaultValue={title}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("title", {
            required: `${t('validation_message.project_title_is_required')}`,
          })}
        />
      </Stack>
    </>
  );
};

export default Title;
