import { useVisitorListMutation } from "../../../store/action/visitor";
import { dateTimeFormatted } from "../../../utils/convert-date-time";
import ExportFile from "../../../utils/exportFile";
import ExportButtonComponent from "../../../components/FieldComponents/ExportButton";
import { useSelector } from "react-redux";

const ExportVisitor = ({ assignedLocationId, assignedProjectId }) => {
  const [getVisiterListApi] = useVisitorListMutation();
  const visitorList = useSelector((state) => state.visitor);

  const onExport = async (e) => {
    const res = await getVisiterListApi({
      projectLocationId: assignedProjectId,
      locationId: assignedLocationId,
      pagination: false,
    });
    if (res?.data?.length > 0) {
      const exportData = res?.data?.map?.((item, label) => ({
        visitorName: item?.name,
        phoneNumber: item?.phoneNumber,
        checkinDate: dateTimeFormatted(item?.checkinDate, item?.timezone),
        projectName: item?.projectLocation?.project?.title,
        locationName: item?.projectLocation?.name,
      }));
      return ExportFile(exportData ?? []);
    }
  };

  return (
    <ExportButtonComponent
      exportData={onExport}
      isExportButtonDisable={visitorList?.data?.length === 0}
    />
  );
};

export default ExportVisitor;
