import * as React from "react";
import { Stack } from "@fluentui/react";
import Style from "../AddClient.style";
import { useTranslation } from "react-i18next";
import FileUpload from "../../../../components/FieldComponents/FileUpload";

const ClientAttachement = (props) => {
  const { setFileCallback, clientLogo, setIsDeleteLogo } = props;
  const { t } = useTranslation();
  const style = Style();

  return (
    <Stack className={style.client_field}>
      <FileUpload
        name={"logoFile"}
        accept="image/png, image/jpeg, image/jpg,image/gif"
        label={t("logo_label")}
        placeholder={t("upload_logo")}
        setFileCallback={setFileCallback}
        photo={clientLogo}
        setIsDeleteLogo={setIsDeleteLogo}
      />
    </Stack>
  );
};

export default ClientAttachement;
