import { createSlice } from "@reduxjs/toolkit";

const timeAndAttendanceApprove = createSlice({
  name: "timeAndAttendanceApprove",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    projectId: "",
    exportData: [],
  },
  reducers: {
    setTimeAndAttendanceApprove(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setTimeAndAttendanceApprove } = timeAndAttendanceApprove.actions;
export default timeAndAttendanceApprove.reducer;
