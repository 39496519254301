import React from "react";
import { useParams } from "react-router-dom";
import SystemEndUserFrom from "./SystemEndUserFrom";

const EditSystemEndUser = () => {
  const { id } = useParams();
  return <SystemEndUserFrom id={id} />;
};

export default EditSystemEndUser;
