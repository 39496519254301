import {
  Button,
  Image,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Text,
  mergeClasses,
} from "@fluentui/react-components";
import { Divider } from "@fluentui/react-components";
import Layout from "../../../../styles/Layout.styles";
import { DeletePopup } from "../../../../components";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocationListMutation } from "../../../../store/action/location";
import {
  useAssignLocationMutation,
  useDeleteLocationDeviceMutation,
} from "../../../../store/action/device";
import { ReactComponent as Pencil } from "../../../../images/pencil.svg";
import AddDevice from "../AddDevice/AddDevice";
import QrCodeScanClose from "../../../../images/qr-code-red.svg";
import QrCodeScan from "../../../../images/qr-code.svg";
import LocationQR from "./LocationQR";
import { t } from "i18next";

const DeviceDataList = (props) => {
  const { item, projectId, locationId, role } = props;
  const layout = Layout();
  const [open, setOpen] = useState(false);
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [getLocationListApi] = useLocationListMutation();
  const locationList = useSelector((state) => state.location);
  const [getDeleteLocationDevice] = useDeleteLocationDeviceMutation();
  const [getAssignLocationDevice] = useAssignLocationMutation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenQRDialog, setIsOpenQRDialog] = useState(false);

  const handleDelete = (id) => {
    getDeleteLocationDevice(id).then((res) => {
      getLocationListApi({
        page: locationList?.page,
        itemsPerPage: locationList?.itemsPerPage,
        project: projectId,
      }).then((res) => {
        setOpen(false);
      });
    });
  };

  const deviceQR = {
    locationId: locationId,
    projectLocationDevicesId: item.id,
    deviceId: item.device.id,
    project: projectId,
  };

  const onAssignDevice = (id) => {
    const deviceData = {
      uniqueDevice: null,
      isAssign: false,
    };
    getAssignLocationDevice({ deviceData: deviceData, id: id }).then((res) => {
      getLocationListApi({
        page: locationList?.page,
        itemsPerPage: locationList?.itemsPerPage,
        project: projectId,
      });
    });
  };

  return (
    <>
      {item?.isActive === true ? (
        <React.Fragment>
          <Text
            as="div"
            className={mergeClasses(
              layout.d_flex,
              layout.flex_between,
              layout.padding1_5
            )}
          >
            <Text as="div" className={mergeClasses(layout.d_flex)}>
              <Text>
                {item?.device?.deviceName} - {item?.device?.shortCode}
              </Text>
            </Text>

            <Text className={mergeClasses(layout.d_flex)}>
              {locationList?.data[0]?.project?.status === "live" && (
                <>
                  {item?.isAssign ? (
                    <Popover
                      open={openQrDialog}
                      onOpenChange={(_, data) => setOpenQrDialog(data?.open)}
                      trapFocus
                    >
                      <PopoverTrigger disableButtonEnhancement>
                        <Image
                          className={mergeClasses(
                            layout.tableIcon,
                            layout.w_auto,
                            layout.mr_1
                          )}
                          block="true"
                          src={QrCodeScanClose}
                          height={20}
                          width={20}
                          onClick={() => setOpenQrDialog(true)}
                        />
                      </PopoverTrigger>

                      <PopoverSurface>
                        <div className={layout.mb_1}>
                          {t("field_components.are_you_sure_assign")}
                        </div>
                        <div>
                          <Button
                            appearance="primary"
                            className={layout.mr_1}
                            onClick={() => onAssignDevice(item?.id)}
                          >
                            {t("yes")}
                          </Button>
                          <Button onClick={() => setOpenQrDialog(false)}>
                            {t("no")}
                          </Button>
                        </div>
                      </PopoverSurface>
                    </Popover>
                  ) : (
                    <Button
                      className={mergeClasses(layout.customEdit, layout.mr_1)}
                      appearance="transparent"
                      onClick={() => setIsOpenQRDialog(true)}
                    >
                      <Image src={QrCodeScan} height="22" width="22" />
                    </Button>
                  )}
                </>
              )}
              {isOpenDialog && (
                <AddDevice
                  deviceId={item?.device?.id}
                  isOpenDialog={isOpenDialog}
                  setIsOpenDialog={setIsOpenDialog}
                  projectId={projectId}
                />
              )}
              {isOpenQRDialog && (
                <LocationQR
                  item={item}
                  deviceQR={deviceQR}
                  isOpenDialog={isOpenQRDialog}
                  setIsOpenDialog={setIsOpenQRDialog}
                />
              )}
              {role?.has("device_edit") && (
                <Button
                  className={mergeClasses(layout.customEdit)}
                  appearance="transparent"
                  onClick={() => setIsOpenDialog(true)}
                  aria-label="edit"
                >
                  <Pencil height={18} width={18} />
                </Button>
              )}
              {role?.has("device_edit") && (
                <DeletePopup
                  className={layout.tableIcon}
                  open={open}
                  onOpenChange={(_, data) => setOpen(data?.open)}
                  onClick={() => setOpen(true)}
                  handleDelete={() => handleDelete(item?.id)}
                  handleCancel={() => setOpen(false)}
                />
              )}
            </Text>
          </Text>
          <Divider />
        </React.Fragment>
      ) : (
        ""
      )}
    </>
  );
};

export default DeviceDataList;
