import generateColorScale from "./GenerateColorScale";
import { createLightTheme, createDarkTheme } from "@fluentui/react-components";
import { FIGMA_COLOR } from "./constants";

export const themeGenerator = (activeTheme) => {
  const colorPallete = generateColorScale(activeTheme);
  const lightTheme = {
    ...createLightTheme(colorPallete),
  };

  let darkTheme = {
    ...createDarkTheme(colorPallete),
  };

  //HA-189 [figma color]
  if (activeTheme === FIGMA_COLOR) {
    lightTheme.colorBrandBackground = colorPallete[100];
    lightTheme.colorBrandBackgroundPressed = colorPallete[100];
    lightTheme.colorBrandForeground1 = colorPallete[110];
  }

  darkTheme.colorBrandForeground1 = colorPallete[110];
  darkTheme.colorBrandForeground2 = colorPallete[120];

  return { lightTheme, darkTheme };
};
