import { mergeClasses } from "@fluentui/react-components";
import Style from "../../AddClient.style";
import { Stack } from "@fluentui/react";
import FieldTimeZoneSelect from "../../../../../components/FieldComponents/FieldTimeZoneSelect";
import { t } from "i18next";

const AccountTimeZone = (props) => {
  const { timeZoneSelectAccountData, value, onChange } = props;
  const style = Style();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldTimeZoneSelect
          label={t("time_zone")}
          validationMessage={props.errors?.accountTimeZone?.message}
          id="accountTimeZone"
          placeholder={t("enter_time_zone")}
          name="accountTimeZone"
          value={value}
          onChange={onChange}
          timeZoneSelectListData={timeZoneSelectAccountData}
          inputDefaultValue={value}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("accountTimeZone", {
            required: `${t("validation_message.timezone_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default AccountTimeZone;
