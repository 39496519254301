import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const CompanyName = (props) => {
  const { companyName } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("name")}
          validationMessage={props.errors?.companyName?.message}
          id="companyName"
          placeholder={t("enter_name")}
          name="companyName"
          inputDefaultValue={companyName}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("companyName", {
            required: `${t("validation_message.name_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default CompanyName;
