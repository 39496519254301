import * as React from "react";
import { Button, Tooltip, mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useCSVDownloader } from "react-papaparse";
import { t } from "i18next";
import Style from "../../pages/Project/Summary/style";
import Layout from "../../styles/Layout.styles";

const ExportButtonComponent = ({ exportData, isExportButtonDisable }) => {
  const { CSVDownloader } = useCSVDownloader();
  const style = Style();
  const layout = Layout();
  return (
    <Stack className={layout.projectFilter}>
      <Tooltip
        withArrow
        content={
          isExportButtonDisable
            ? `${t("no_data_found")}`
            : `${t("data_export")}`
        }
      >
        <Button
          appearance="primary"
          className={mergeClasses(style.prodBtn, "custom-primary")}
          disabled={isExportButtonDisable}
        >
          <CSVDownloader
            appearance="primary"
            className={mergeClasses(style.prodBtn, "custom-primary")}
            filename={"download"}
            bom={true}
            data={exportData}
          >
            {t("project_summary.export")}
          </CSVDownloader>
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default ExportButtonComponent;
