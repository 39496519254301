import React, { useState } from "react";
import { Stack } from "@fluentui/react";
import {
  TableCell,
  TableRow,
  mergeClasses,
  Button,
} from "@fluentui/react-components";
import { format } from "date-fns";
import Layout from "../../../../styles/Layout.styles";
import { Switch } from "@fluentui/react-components";
import {
  useSwitchOnMutation,
  useDeleteThemeMutation,
  useGetThemeListMutation,
} from "../../../../store/action/theme";
import DeletePopup from "../../../../components/FieldComponents/DeletePopup";
import ThemeManagementWrapper from "./ThemeManagement/ThemeManagementWrapper";
import { ReactComponent as Pencil } from "../../../../images/pencil.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../../store/reducer/theme";

const ThemeColor = (props) => {
  const [open, setOpen] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { item, role } = props;
  const layout = Layout();
  const [switchOn] = useSwitchOnMutation();
  const [getDeleteProject] = useDeleteThemeMutation();
  const [getThemeList] = useGetThemeListMutation();
  const themeList = useSelector((state) => state?.theme);
  const dispatch = useDispatch();

  const colorBoxStyles = {
    backgroundColor: item.themeCode,
    width: "20px",
    marginLeft: "10px",
    height: "20px",
  };

  const handleSwithOn = (e) => {
    const newThemeData = {
      isActive: true,
    };
    switchOn({ themeData: newThemeData, id: item?.id }).then((res) => {
      localStorage.setItem("themeColor", item?.themeCode);
      getThemeList();
    });
  };

  const handleDelete = (id) => {
    getDeleteProject(id).then((res) => {
      dispatch(
        setTheme({
          page:
            themeList?.data?.length === 1 && themeList?.page > 0
              ? themeList?.page - 1
              : themeList?.page,
        })
      );
      getThemeList();

      setOpen(false);
    });
  };
  let disableLink = item.isDefault === true || item.isActive === true;

  return (
    <>
      <TableRow
        key={item?.project?.label}
        className={mergeClasses(layout.customTblRow, layout.h_40)}
      >
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          <Stack className={mergeClasses(layout.flex_row, layout.flex_between)}>
            {item?.name}
            <Stack styles={{ root: colorBoxStyles }}></Stack>
          </Stack>
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          <Switch
            label=""
            {...props}
            onChange={handleSwithOn}
            checked={item?.isActive ? true : false}
          />
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item && format(new Date(item.createdAt), "yyyy/MM/dd")}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item && format(new Date(item.updatedAt), "yyyy/MM/dd")}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          <Stack
            className={mergeClasses(
              layout.tableIcons,
              disableLink ? "disableIcons" : ""
            )}
          >
            {isOpenDialog === true && (
              <ThemeManagementWrapper
                id={item?.themeId}
                isOpenDialog={isOpenDialog}
                setIsOpenDialog={setIsOpenDialog}
              />
            )}
            {role?.has("client_theme_edit") && (
              <Button
                className={mergeClasses(layout.customEdit)}
                appearance="transparent"
                onClick={() => setIsOpenDialog(true)}
                aria-label="edit"
              >
                <Pencil height={18} width={18} />
              </Button>
            )}
            {role?.has("client_theme_edit") && (
              <DeletePopup
                className={layout.tableIcon}
                open={open}
                onOpenChange={(_, data) => setOpen(data.open)}
                onClick={() => setOpen(true)}
                handleDelete={() => handleDelete(item.id)}
                handleCancel={() => setOpen(false)}
              />
            )}
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ThemeColor;
