import { makeStyles, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  prodBtn: {
    minWidth: "51px",
  },
  columnHeader: {
    ...shorthands.borderRight("1px", "solid", "#E2E8F0"),
  },
  paginationDropdown: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    ...shorthands.margin("auto", "auto", "auto", 0),
    "& .pagination-dropdown": {
      minWidth: "auto",
    },
  },
  rowsdropdown: {
    ...shorthands.margin("auto"),
    ...shorthands.padding("0", "10px"),
    textAlign: "center",
  },
  tableIcon: {
    minWidth: "auto",
    ...shorthands.padding("0", "6px"),
    ...shorthands.border("none"),
    backgroundColor: "transparent",
  },
  paginationMain: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    ...shorthands.margin("22px", 0),
    "@media (max-width: 1024px)": {
      flexDirection: "column",
    },
  },
});

export default style;
