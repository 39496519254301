import {
  TableCell as FluentTableCell,
  mergeClasses,
} from "@fluentui/react-components";
import Style from "./Style";

const TableCell = (props) => {
  const { children, className, tabIndex } = props;
  const style = Style();

  return (
    <FluentTableCell
      tabIndex={tabIndex}
      className={mergeClasses(style.customTblCell, className)}>
      {children}
    </FluentTableCell>
  );
};

export default TableCell;
