import { createSlice } from "@reduxjs/toolkit";

const systemEndUser = createSlice({
  name: "systemEndUser",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    isActive: "true",
  },
  reducers: {
    setSystemEndUser(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSystemEndUser } = systemEndUser.actions;
export default systemEndUser.reducer;
