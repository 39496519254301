import * as React from "react";
import {
  Dropdown,
  Field,
  Option,
  mergeClasses,
} from "@fluentui/react-components";

const FieldDropDown = ({
  label,
  size = "large",
  placeholder,
  name,
  inputId,
  inputDefaultValue,
  options,
  className,
  inputClassName,
  noRequired,
  validation,
  validationMessage,
  onOptionSelect,
  validationMessageIcon = "",
  multiselect,
  selectedOptions,
  value,
  defaultSelectedOptions,
  disabledOption,
  disabled,
}) => {
  return (
    <Field
      label={label}
      className={mergeClasses("custom-label", className, "disablePointing")}
      size={size}
      validationMessageIcon={validationMessageIcon}
      validationMessage={validationMessage}
      required={noRequired ? undefined : true}
    >
      <Dropdown
        className={inputClassName}
        name={name}
        id={inputId}
        defaultValue={inputDefaultValue}
        placeholder={placeholder}
        selectedOptions={selectedOptions}
        onOptionSelect={onOptionSelect}
        defaultSelectedOptions={defaultSelectedOptions}
        multiselect={multiselect}
        disabled={disabled}
        value={value}
        {...validation}
      >
        {options?.map((option, key) => {
          return (
            <Option
              key={key}
              value={option?.value}
              disabled={disabledOption?.includes(option?.value)}
            >
              {option?.label}
            </Option>
          );
        })}
      </Dropdown>
    </Field>
  );
};

export default FieldDropDown;
