import ExportFile from "../../../utils/exportFile";
import { dateTimeFormatted } from "../../../utils/convert-date-time";
import { useGetPendingDataMutation } from "../../../store/action/time-and-attendance/time-and-attendancePending";
import { useGetApprovedDataMutation } from "../../../store/action/time-and-attendance/time-and-attendanceApprove";
import { useGetRejectDataMutation } from "../../../store/action/time-and-attendance/time-and-attendanceReject";
import ExportButtonComponent from "../../../components/FieldComponents/ExportButton";
import { useSelector } from "react-redux";
import { getTimezoneOffsetData } from "../../../utils/convert-date-time";

const TnAExport = ({ assignedProjectId, selectedValue }) => {
  const [getPendingExportDataList] = useGetPendingDataMutation();
  const [getApproveExportDataList] = useGetApprovedDataMutation();
  const [getRejectExportDataList] = useGetRejectDataMutation();
  const timeAndAttendancePendingList = useSelector(
    (state) => state.timeAndAttendancePending
  );
  const timeAndAttendanceApproveList = useSelector(
    (state) => state.timeAndAttendanceApprove
  );
  const timeAndAttendanceRejectList = useSelector(
    (state) => state.timeAndAttendanceReject
  );

  const onExport = async (e) => {
    if (selectedValue === "review") {
      const res = await getPendingExportDataList({
        projectId: assignedProjectId,
        pagination: false,
      });
      if (res?.data?.length > 0) {
        const exportData = res?.data?.map?.((item, label) => ({
          // userId: item?.userId,
          employeeName: item?.employeeName,
          status: item?.status,
          // teamId: item?.teamId,
          teamName: item?.teamName,
          teamColorCode: item?.teamColorCode,
          clockCount: item?.clockCount,
          timezone: `${item?.timezone} ${getTimezoneOffsetData(
            item?.timezone
          )}`,
          checkinDate: dateTimeFormatted(item?.checkinDate, item?.timezone),
          checkoutDate: dateTimeFormatted(item?.checkoutDate, item?.timezone),
          totalHours: item?.totalHours?.toFixed(2),
        }));

        return ExportFile(exportData ?? []);
      }
    } else if (selectedValue === "approved") {
      const res = await getApproveExportDataList({
        projectId: assignedProjectId,
        pagination: false,
      });
      if (res?.data?.length > 0) {
        const exportData = res?.data?.map?.((item, label) => ({
          employeeName: item?.employeeName,
          // userId: item?.userId,
          status: item?.status,
          // teamId: item?.teamId,
          teamName: item?.teamName,
          teamColorCode: item?.teamColorCode,
          clockCount: item?.clockCount,
          timezone: `${item?.timezone} ${getTimezoneOffsetData(
            item?.timezone
          )}`,
          checkinDate: dateTimeFormatted(item?.checkinDate, item?.timezone),
          checkoutDate: dateTimeFormatted(item?.checkoutDate, item?.timezone),
          totalHours: item?.totalHours?.toFixed(2),
        }));
        return ExportFile(exportData ?? []);
      }
    } else {
      const res = await getRejectExportDataList({
        projectId: assignedProjectId,
        pagination: false,
      });
      if (res?.data?.length > 0) {
        const exportData = res?.data?.map?.((item, label) => ({
          employeeName: item?.employeeName,
          // userId: item?.userId,
          status: item?.status,
          rejectedBy: item?.rejectedByName,
          rejectReason: item?.rejectReason,
          // teamId: item?.teamId,
          teamName: item?.teamName,
          teamColorCode: item?.teamColorCode,
          clockCount: item?.clockCount,
          timezone: `${item?.timezone} ${getTimezoneOffsetData(
            item?.timezone
          )}`,
          checkinDate: dateTimeFormatted(item?.checkinDate, item?.timezone),
          checkoutDate: dateTimeFormatted(item?.checkoutDate, item?.timezone),
          totalHours: item?.totalHours?.toFixed(2),
        }));
        return ExportFile(exportData ?? []);
      }
    }
  };

  return (
    <>
      <ExportButtonComponent
        exportData={onExport}
        isExportButtonDisable={
          selectedValue === "review"
            ? timeAndAttendancePendingList?.totalCount === 0
            : selectedValue === "approved"
            ? timeAndAttendanceApproveList?.totalCount === 0
            : timeAndAttendanceRejectList?.totalCount === 0
        }
      />
    </>
  );
};

export default TnAExport;
