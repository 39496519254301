import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useGetClientListMutation } from "../../../../store/action/client";
import { FieldDropDown } from "../../../../components";

const UserClient = (props) => {
  const { userClientName, setUserClient, setValue } = props;
  const style = Style();
  const { t } = useTranslation();
  const [getClientListApi, { isLoading }] = useGetClientListMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentClient, setCurrentClient] = useState([]);
  const [clientText, setClientText] = useState(userClientName?.companyName);

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getClientListApi({ isActive: "true" }).then((res) => {
          const listData = Object.entries(res?.data?.data)?.map(
            ([value, label]) => ({
              value: label?.id,
              label: label?.companyName,
            })
          );
          setCurrentClient(listData);
        });
        setInitialLoad(false);
      }
    }
  }, [initialLoad, getClientListApi, isLoading]);

  const handleChange = (e, data) => {
    setUserClient(data?.optionValue);
    setClientText(data?.optionText);

    setValue("userClient", data?.optionText, { shouldValidate: true });
  };

  return (
    <>
      <Stack className={style.client_field}>
        <FieldDropDown
          label={t("client")}
          multiselect={false}
          validationMessage={props?.errors?.userClient?.message}
          id="userClient"
          placeholder={t("select_client")}
          name="userClient"
          onOptionSelect={handleChange}
          options={currentClient}
          inputClassName={style.textboxInput}
          defaultSelectedOptions={[userClientName?.id]}
          value={clientText}
          validation={props.register("userClient", {
            required: `${t("validation_message.client_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default UserClient;
