import { Stack, Text } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../../components/Table";
import { useSelector } from "react-redux";
import Layout from "../../../../styles/Layout.styles";
import { useEffect } from "react";
import { useState } from "react";
import RejectRow from "./RejectRow";
import Pagination from "../../../../components/Pagination";
import { useGetRejectDataMutation } from "../../../../store/action/time-and-attendance/time-and-attendanceReject";
import { setTimeAndAttendanceReject } from "../../../../store/reducer/time-and-attendance/time-and-attendanceReject";
import { t } from "i18next";

const TimeSheetAndAttendanceReject = (props) => {
  const {
    employeeSelectedId,
    isEmployeeDetailsOpen,
    assignedProjectId,
    setEmployeeSelectedId,
    setIsEmployeeDetailsOpen,
  } = props;
  const layout = Layout();
  const [getRejectApi, { isLoading }] = useGetRejectDataMutation();
  const timeAndAttendanceList = useSelector(
    (state) => state.timeAndAttendanceReject
  );
  const [initialLoad, setInitialLoad] = useState(true);

  const columns = [
    {
      columnKey: "systemEndUser.firstName",
      label: `${t("project.employee_name")}`,
      isSort: true,
    },
    { columnKey: "createdAt", label: `${t("date")}`, isSort: true },
    { columnKey: "clockedCount", label: `${t("clocked_count")}` },
    { columnKey: "checkinDate", label: `${t("clock_in")}`, isSort: true },
    { columnKey: "checkoutDate", label: `${t("clock_out")}`, isSort: true },
    { columnKey: "totalHours", label: `${t("total_hour")}`, isSort: true },
    { columnKey: "rejectedBy", label: `${t("rejected_by")}` },
    { columnKey: "message", label: `${t("message")}` },
  ];

  useEffect(() => {
    if (initialLoad) {
      if (assignedProjectId) {
        getData(
          timeAndAttendanceList?.page,
          timeAndAttendanceList?.itemsPerPage
        );

        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assignedProjectId,
    initialLoad,
    getRejectApi,
    isLoading,
    timeAndAttendanceList,
  ]);

  const getData = async (page, itemsPerPage) => {
    await getRejectApi({
      page: page,
      itemsPerPage: itemsPerPage,
      projectId: assignedProjectId,
    });
  };

  return (
    <Stack className={mergeClasses("contentData", layout.mt_1)}>
      <Stack className="overflow-y-auto">
        <Table className="tbl-layout-auto">
          <TableHeader className={layout.headerBackground}>
            <TableRow>
              {columns?.map((column, key) => (
                <TableHeaderCell
                  key={key}
                  onSortApi={getData}
                  onSet={setTimeAndAttendanceReject}
                  column={column}
                  sortBy={
                    column?.isSort ? timeAndAttendanceList?.sortBy : false
                  }
                >
                  <Text className={layout.headerLabel}>{column.label}</Text>
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody isLoading={isLoading}>
            {timeAndAttendanceList?.data?.map((item, key) => (
              <RejectRow
                key={key}
                item={item}
                setEmployeeSelectedId={setEmployeeSelectedId}
                setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
                isEmployeeDetailsOpen={isEmployeeDetailsOpen}
                employeeSelectedId={employeeSelectedId}
              />
            ))}
          </TableBody>
        </Table>
      </Stack>

      <Pagination
        totalCount={assignedProjectId ? timeAndAttendanceList?.totalCount : 0}
        itemsPerPage={timeAndAttendanceList?.itemsPerPage}
        onSetListReducer={setTimeAndAttendanceReject}
        onGetList={getData}
        page={timeAndAttendanceList?.page}
      />
    </Stack>
  );
};

export default TimeSheetAndAttendanceReject;
