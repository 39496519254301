import React from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useStaticStyles, style } from "./Style";
import { Stack } from "@fluentui/react";
import { Dropdown, Option, mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

const PaginationList = (startPage, pageCount = 6) => {
  const options = [];

  for (let i = 1; i <= pageCount; i++) {
    options.push(`${startPage * i}`);
  }

  return options;
};

const PaginatedItems = (props) => {
  const {
    totalCount,
    itemsPerPage,
    onSetListReducer,
    onGetList,
    page,
    startPage = 10,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  useStaticStyles();
  const stylePage = style();
  const handlePageChange = async (selectedPage) => {
    await dispatch(onSetListReducer({ page: selectedPage?.selected }));

    onGetList(selectedPage?.selected, itemsPerPage);
  };

  const paginationDropdown = (e, data) => {
    dispatch(onSetListReducer({ page: 0, itemsPerPage: data.optionValue }));
    onGetList(0, data.optionValue);
  };

  const pageCount = Math.ceil(totalCount / itemsPerPage);

  if (isNaN(pageCount) || pageCount < 0) {
    return null;
  }

  if ((startPage > totalCount) & (totalCount !== 0)) {
    return null;
  }

  return (
    <>
      {totalCount !== 0 ? (
        <Stack className={stylePage.paginationMain}>
          <Stack className={stylePage.paginationDropdown}>
            <Dropdown
              aria-labelledby={"dropdown-id"}
              placeholder={t("pagination.pages")}
              defaultValue={itemsPerPage}
              className="pagination-dropdown"
              onOptionSelect={paginationDropdown}
            >
              {PaginationList(startPage).map((option) => (
                <Option
                  key={option}
                  disabled={parseInt(option) === parseInt(itemsPerPage)}
                >
                  {`${option}`}
                </Option>
              ))}
            </Dropdown>
            <label
              id={"dropdown-id-page"}
              className={mergeClasses(
                stylePage.rowsdropdown,
                stylePage.textGray
              )}
            >
              {t("pagination.rows_per_page")}
            </label>
          </Stack>

          <Stack className={mergeClasses("custom-pagination")}>
            <ReactPaginate
              pageCount={pageCount}
              forcePage={page === pageCount ? page - 1 : page}
              onPageChange={handlePageChange}
              previousLabel={page !== 0 && t("pagination.previous")}
              nextLabel={page + 1 !== pageCount && t("pagination.next")}
              breakLabel={"..."}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack className={stylePage.textAlign}>
          {t("pagination.no_record_found")}
        </Stack>
      )}
    </>
  );
};

export default PaginatedItems;
