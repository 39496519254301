import { Button, Spinner } from "@fluentui/react-components";

const FormButton = ({
  disabled,
  appearance,
  title,
  children,
  onClick,
  type,
  className,
  loading,
}) => {
  return (
    <Button
      disabled={loading || disabled ? disabled : false}
      appearance={appearance}
      title={title}
      type={type}
      className={className}
      onClick={onClick}>
      {loading ? <Spinner appearance="inverted" size="tiny" /> : children}
    </Button>
  );
};

export default FormButton;
