import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Pagination,
} from "../../../../components";
import { Stack, Text } from "@fluentui/react";
import Layout from "../../../../styles/Layout.styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useProjectSummaryLiveMutation } from "../../../../store/action/projectSummary/projectSummaryLive";
import { setProjectSummaryLive } from "../../../../store/reducer/projectSummary/projectSummaryLive";
import ProjectSummaryLiveRow from "./ProjectSummaryLiveRow";

const ProjectSummaryLive = ({
  status,
  projectSummaryId,
  openSummaryDetails,
  setProjectSummaryId,
  setOpenSummaryDetails,
}) => {
  const layout = Layout();
  const [getProjectSummaryApi, { isLoading }] = useProjectSummaryLiveMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const projectSummaryLive = useSelector((state) => state.projectSummaryLive);

  const { t } = useTranslation();

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getProjectSummaryStatusApi();
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectSummaryApi, isLoading, initialLoad]);

  const getProjectSummaryStatusApi = async () => {
    await getProjectSummaryApi({ status });
  };

  const columns = [
    {
      columnKey: "projectCode",
      label: t("project_summary.project_id"),
      isSort: true,
    },
    {
      columnKey: "title",
      label: t("project_summary.project_name"),
      isSort: true,
    },
    {
      columnKey: "commencementDate",
      label: t("project_summary.commencement_date"),
      isSort: true,
    },
    {
      columnKey: "completionDate",
      label: t("project_summary.completion_date"),
      isSort: true,
    },
    {
      columnKey: "totalTeamMember ",
      label: t("project_summary.total_team_member"),
    },
    { columnKey: "todaysClockin", label: t("project_summary.today_clockin") },
  ];

  return (
    <>
      <Stack className={layout.flex_row}>
        <Stack className={layout.w_100}>
          <Stack className="overflow-x-auto">
            <Table className="tbl-layout-auto">
              <TableHeader className={layout.headerBackground}>
                <TableRow>
                  {columns?.map((column, key) => (
                    <TableHeaderCell
                      key={key}
                      onSortApi={getProjectSummaryStatusApi}
                      onSet={setProjectSummaryLive}
                      column={column}
                      sortBy={
                        column?.isSort ? projectSummaryLive?.sortBy : false
                      }
                    >
                      <Text className={layout.headerLabel}>{column.label}</Text>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody isLoading={isLoading}>
                {projectSummaryLive?.data?.map((item, key) => (
                  <ProjectSummaryLiveRow
                    item={item}
                    key={key}
                    setOpenSummaryDetails={setOpenSummaryDetails}
                    setProjectSummaryId={setProjectSummaryId}
                    projectSummaryId={projectSummaryId}
                    openSummaryDetails={openSummaryDetails}
                  />
                ))}
              </TableBody>
            </Table>
          </Stack>

          <Pagination
            totalCount={projectSummaryLive?.totalCount}
            itemsPerPage={projectSummaryLive?.itemsPerPage}
            onSetListReducer={setProjectSummaryLive}
            onGetList={getProjectSummaryStatusApi}
            page={projectSummaryLive?.page}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectSummaryLive;
