import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { FieldsTextarea } from "../../../../components";

const Address = (props) => {
  const { address } = props;
  const style = Style();
  const { t } = useTranslation();

  return (
    <Stack className={style.client_field}>
      <FieldsTextarea
        label={t("project.address")}
        validationMessage={props?.errors?.address?.message}
        placeholder={t("project.enter_address")}
        name="address"
        id="address"
        inputClassName={style.textboxInput}
        inputDefaultValue={address}
        noRequired
        maxLength={255}
        validation={props.register("address")}
      />
    </Stack>
  );
};

export default Address;
