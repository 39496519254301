import * as React from "react";
import {
  Button,
  Field,
  Input,
  Tag,
  TagGroup,
  mergeClasses,
  Divider,
  Image,
  Text,
} from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import { Stack } from "@fluentui/react";
import TrashIcon from "../../images/trash.svg";
import { ReactComponent as Plus } from "../../images/plus.svg";

const FieldTagInput = ({
  label,
  size = "large",
  btnText = (
    <Stack className="plus">
      <Plus height={22} width={22} />
    </Stack>
  ),
  placeholder,
  value,
  name,
  validation,
  visibleTags,
  onChange,
  onClick,
  onDismiss,
  noRequired,
  validationMessage,
  className,
  tagError,
  customDesign = "false",
  validationMessageIcon = "",
}) => {
  const layout = Layout();

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      onClick(e);
    }
    return true;
  };
  return (
    <>
      <Stack>
        <Field
          label={label}
          validationMessage={validationMessage}
          className={mergeClasses("custom-label", className)}
          validationMessageIcon={validationMessageIcon}
          size={size}
          required={noRequired ? undefined : true}
        >
          <Stack
            className={mergeClasses(
              layout.flex_row,
              layout.gap_15,
              layout.gap_15
            )}
          >
            <Input
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              size={size}
              className={mergeClasses(layout.w_100)}
              onKeyDown={handleEnter}
            />
            <Button
              onClick={onClick}
              appearance="primary"
              className={layout.min_w_46}
            >
              {btnText}
            </Button>
          </Stack>
        </Field>
      </Stack>
      {tagError && visibleTags && (
        <Text className={mergeClasses(layout.mb_0_5, layout.colorEbc2f32)}>
          {" "}
          {tagError}
        </Text>
      )}
      {visibleTags?.length !== 0 && (
        <TagGroup
          onDismiss={onDismiss}
          className={
            customDesign === true &&
            mergeClasses(layout.customTags, layout.mb_1)
          }
        >
          {visibleTags?.map((tag, index, key) => (
            <React.Fragment key={index}>
              {customDesign === true ? (
                <>
                  <Tag
                    name={name}
                    key={tag?.name}
                    dismissible
                    appearance="transparent"
                    className={mergeClasses(layout.customTag, "customTag")}
                    dismissIcon={
                      <Image
                        alt="delete"
                        className={layout.cursor_pointer}
                        src={TrashIcon}
                        width={18}
                      />
                    }
                    value={tag?.name}
                    {...validation}
                  >
                    {tag?.name}
                  </Tag>
                  {index === visibleTags.length - 1 ? (
                    ""
                  ) : (
                    <Divider className={layout.padding1_5x} />
                  )}
                </>
              ) : (
                <Tag
                  name={name}
                  key={tag?.name}
                  dismissible
                  dismissIcon={{ "aria-label": "remove" }}
                  value={tag?.name}
                  className={layout.mt_2}
                  {...validation}
                >
                  {tag?.name}
                </Tag>
              )}
            </React.Fragment>
          ))}
        </TagGroup>
      )}
    </>
  );
};

export default FieldTagInput;
