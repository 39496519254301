import Layout from "../../../styles/Layout.styles";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../../components/Table";
import { Text } from "@fluentui/react";
import LogTimeRow from "./LogTimeRow";
import { t } from "i18next";

const LogTime = (props) => {
  const { projectTnaDetails, isLoading, assignedProjectId, viewDetails } =
    props;
  const layout = Layout();

  const column = [
    { columnKey: "clockIn", label: t("clock_in") },
    { columnKey: "clockOut", label: t("clock_out") },
    { columnKey: "logType", label: t("logType") },
  ];

  const columns = column;

  return (
    <Table>
      <TableHeader className={layout.headerBackground}>
        <TableRow>
          {columns?.map((column, key) => (
            <TableHeaderCell key={key}>
              <Text className={layout.headerLabel}>{column?.label}</Text>
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>

      <TableBody isLoading={isLoading}>
        {projectTnaDetails?.clockLog?.map((item, key) => (
          <LogTimeRow
            key={key}
            item={item}
            projectTnaDetails={projectTnaDetails}
            assignedProjectId={assignedProjectId}
            viewDetails={viewDetails}
            isLoading={isLoading}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default LogTime;
