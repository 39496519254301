import { Stack, Text } from "@fluentui/react";
import Breadcrumb from "../../../components/Breadcrumb";
import Layout from "../../../styles/Layout.styles";
import Style from "./AddClient.style";
import { useGetClientMutation } from "../../../store/action/client";
import React, { useEffect, useState } from "react";
import { Divider, Spinner, mergeClasses } from "@fluentui/react-components";
import ClientViewAccount from "./ClientViewAccount";
import ClientViewContact from "./ClientViewContact";
import { useTranslation } from "react-i18next";
import ClientFilter from "../ClientList/ClientFilter";

const ClientViewForm = (props) => {
  const layout = Layout();
  const style = Style();
  const [viewData, setViewData] = useState();
  const [initialLoad, setInitialLoad] = useState(true);
  const [getClientApi, { isGetLoading }] = useGetClientMutation();
  const { t } = useTranslation();

  const breadcrumbData = [
    {
      title: `${t("client")}`,
      url: "/organization-list",
    },
    {
      title: `${t("client_details")}`,
    },
  ];

  const { id } = props;

  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getClientApi(props?.id).then((res) => {
            setViewData(res);
          });
        }
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad]);

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack className={layout.d_flex}>
        <Stack
          className={mergeClasses(
            style.clientContent,
            layout.w_100,
            "cardShadow"
          )}
        >
          <ClientFilter clientId={`${viewData?.data?.id}`} />
          <Stack className={mergeClasses(layout.border, style.padding2)}>
            <h2 className={mergeClasses(style.headerTitle, layout.padding1_5x)}>
              {t("company")}
            </h2>
            {isGetLoading ? (
              <Stack
                className={mergeClasses(layout.positionRelative, layout.w_100)}
              >
                <Spinner className={layout.m_auto} />
              </Stack>
            ) : (
              <>
                <Stack className={style.subDetails}>
                  <Stack className={mergeClasses(style.fieldsection)}>
                    <Text className={mergeClasses(layout.detailsSplite)}>
                      <Text className={style.textElement}>{t("name")} </Text>
                      {`${viewData?.data?.companyName}`}
                    </Text>
                    <Divider className={layout.padding1_5x} />
                    <Text className={mergeClasses(layout.detailsSplite)}>
                      <Text className={style.textElement}>{t("address")} </Text>
                      <Stack
                        className={layout.w_60}
                      >{`${viewData?.data?.companyAddress}`}</Stack>
                    </Text>
                  </Stack>

                  <Stack className={mergeClasses(style.fieldsection)}>
                    <Text className={mergeClasses(layout.detailsSplite)}>
                      <Text className={style.textElement}>
                        {t("email_address")}{" "}
                      </Text>
                      {`${viewData?.data?.email}`}
                    </Text>
                    <Divider className={layout.padding1_5x} />
                    <Text className={mergeClasses(layout.detailsSplite)}>
                      <Text className={style.textElement}>
                        {t("billing_address")}{" "}
                      </Text>
                      <Stack
                        className={layout.w_60}
                      >{`${viewData?.data?.companyBillingAddress}`}</Stack>
                    </Text>
                  </Stack>
                </Stack>
                <Divider />
                <ClientViewContact viewData={viewData} />
                <Divider />
                <ClientViewAccount viewData={viewData} />
                <Divider />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ClientViewForm;
