import { t } from "i18next";
import { FieldDropDown } from "../../../components";
import { Stack } from "@fluentui/react";
import Style from "../../ExternalSources/ExternalSource.style";
import { useEffect } from "react";
import { useGetClientDepartmentListMutation } from "../../../store/action/clientDepartment";
import { useState } from "react";

const Business = (props) => {
  const {
    setValue,
    setSelectedBusinessId,
    business,
    businessDataList,
    setBusinessDataList,
    setLocationText,
  } = props;

  const style = Style();
  const [getBusinessDepartmentApi] = useGetClientDepartmentListMutation();
  const [businessText, setBusinessText] = useState(
    business?.clientDepartment?.name
  );

  useEffect(() => {
    getBusinessDepartmentApi({ pagination: false }).then((res) => {
      const listData = Object.entries(res?.data)?.map(([value, label]) => ({
        value: label?.id,
        label: label?.name,
      }));
      setBusinessDataList(listData);
    });
  }, [getBusinessDepartmentApi]);

  const handleChange = (e, data) => {
    setValue("business", data?.optionText, { shouldValidate: true });
    setBusinessText(data?.optionText);
    setSelectedBusinessId(data?.optionValue);
    setLocationText("");
  };

  return (
    <Stack className={style.client_field}>
      <FieldDropDown
        label={t("business")}
        validationMessage={props.errors?.business?.message}
        id="business"
        placeholder={t("enter_business")}
        name="business"
        options={businessDataList}
        onOptionSelect={handleChange}
        defaultSelectedOptions={[business?.clientDepartment?.id]}
        value={businessText}
        inputClassName={style.textboxInput}
        validation={props.register("business", {
          required: `${t("validation_message.business_is_required")}`,
        })}
      />
    </Stack>
  );
};

export default Business;
