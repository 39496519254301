import React, { useState } from "react";
import { mergeClasses } from "@fluentui/react-components";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { t } from "i18next";
import { ReactComponent as DateIcon } from "../../../../images/dateIcon.svg";
import { Stack } from "@fluentui/react";
import { dateFormattedDash } from "../../../../utils/convert-date-time";
import Layout from "../../../../styles/Layout.styles";
import { setBilling } from "../../../../store/reducer/billing";

const DateFilterInvoice = ({ getBillingDataList, isLoading }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();

  const layout = Layout();
  const currentDate = new Date();

  const setHeaderRange = (dates) => {
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      startDate.setHours(currentDate.getHours());
      startDate.setMinutes(currentDate.getMinutes());
      startDate.setSeconds(currentDate.getSeconds());

      endDate.setHours(currentDate.getHours());
      endDate.setMinutes(currentDate.getMinutes());
      endDate.setSeconds(currentDate.getSeconds());

      let start = dateFormattedDash(startDate, "UTC");
      let end = dateFormattedDash(endDate, "UTC");

      if (!isLoading) {
        dispatch(
          setBilling({
            createdAtAfter: start,
            createdAtBefore: end,
          })
        );
        getBillingDataList();
      }
    } else {
      dispatch(
        setBilling({
          createdAtBefore: endDate && format(endDate, "yyyy-MM-dd"),
          createdAtAfter: startDate && format(startDate, "yyyy-MM-dd"),
        })
      );
      getBillingDataList();
    }
  };

  return (
    <Stack className={mergeClasses(layout.flex_row)}>
      <Stack
        className={mergeClasses(
          layout.m_auto,
          layout.flex_row,
          layout.alignCenter
        )}
      >
        <label className={mergeClasses(layout.alignCenter, layout.mr_1)}>
          {t("date_filter")} :
        </label>
        <React.Fragment>
          <DatePicker
            showIcon
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            placeholderText={t("select_dates")}
            onChange={(update) => {
              setDateRange(update);
              setHeaderRange(update);
            }}
            maxDate={new Date()}
            icon={<DateIcon height={20} width={20} />}
            isClearable={true}
          />
        </React.Fragment>
      </Stack>
    </Stack>
  );
};
export default DateFilterInvoice;
