import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useGetRoleListMutation } from "../../../../store/action/common";
import { FieldDropDown } from "../../../../components";

const UserRole = (props) => {
  const {
    userRoles,
    setSelectedOptions,
    selectedOptions,
    setValue,
    isEditProfile,
  } = props;

  const style = Style();
  const { t } = useTranslation();
  const [getRoleApi] = useGetRoleListMutation();
  const [roleDataList, setRoleDataList] = useState([]);
  const [roleValue, setRoleValue] = useState("");

  useEffect(() => {
    getRoleApi().then((res) => {
      const listData = Object.entries(res?.data)?.map(([value, label]) => ({
        value: value,
        label: label,
      }));
      setRoleDataList(listData);

      const RoleTexts = userRoles
        ?.filter((item) => item !== "ROLE_USER")
        .filter((item) => item !== "ROLE_TNA_LICENSED_USER")
        .map((item, key) => {
          const roleText = listData?.filter((role) => role?.value === item)[0]
            ?.label;

          return roleText;
        });

      if (userRoles?.length > 0) {
        setSelectedOptions(
          userRoles
            ?.filter((item) => item !== "ROLE_USER")
            .filter((item) => item !== "ROLE_TNA_LICENSED_USER")
        );
        setValue("userRoles", RoleTexts?.join(", "), { shouldValidate: true });
      }
      setRoleValue(RoleTexts?.join(", "));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e, data) => {
    if (data?.optionValue === "ROLE_STANDARD_USER") {
      data.selectedOptions = data.selectedOptions.filter(
        (item) => item === "ROLE_STANDARD_USER"
      );
      setSelectedOptions(data?.selectedOptions);
    }

    if (data?.optionValue !== "ROLE_STANDARD_USER") {
      data.selectedOptions = data.selectedOptions.filter(
        (item) => item !== "ROLE_STANDARD_USER"
      );
      setSelectedOptions(data?.selectedOptions);
    }
    const RoleTexts = data?.selectedOptions?.map((item, key) => {
      const roleText = roleDataList?.filter((role) => role.value === item)[0]
        .label;
      return roleText;
    });
    setRoleValue(RoleTexts?.join(", "));

    setValue("userRoles", RoleTexts?.join(", "), { shouldValidate: true });
  };

  return (
    <>
      <Stack className={style.client_field}>
        <FieldDropDown
          label={t("role")}
          multiselect={true}
          validationMessage={props?.errors?.userRoles?.message}
          id="userRoles"
          placeholder={t("enter_role")}
          name="userRoles"
          options={roleDataList}
          onOptionSelect={handleChange}
          selectedOptions={selectedOptions}
          disabledOption={
            userRoles?.includes("ROLE_CLIENT_ADMIN") && isEditProfile
              ? ["ROLE_STANDARD_USER", "ROLE_CLIENT_ADMIN"]
              : ""
          }
          inputClassName={style.textboxInput}
          value={roleValue}
          validation={props.register("userRoles", {
            required: `${t("validation_message.role_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default UserRole;
