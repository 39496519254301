import React, { useEffect, useState } from "react";
import Style from "../../styles/Unauthentication.styles";
import { Stack } from "@fluentui/react";
import { mergeClasses, Text, Button } from "@fluentui/react-components";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../styles/Layout.styles";
import {
  useResetPasswordUserMutation,
  useVerifyTokenUserMutation,
} from "../../store/action/user";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import UnAuthWrapper from "../../components/UnAuthWrapper";
import Form from "../../components/FieldComponents/Form";
import FieldsInput from "../../components/FieldComponents/FieldsInput";
import { useTranslation } from "react-i18next";
import { Eye24Filled, EyeOff24Filled } from "@fluentui/react-icons";
import { RESET_PASSWORD_PATTERN } from "../../utils/constants";

//eslint-disable-next-line no-unused-vars
let checkPassword = "password did not match";

const ResetPassword = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();
  const { token } = useParams();
  const { t } = useTranslation();

  const layout = Layout();
  const style = Style();
  const navigate = useNavigate();
  const [resetPasswordUserApi] = useResetPasswordUserMutation();
  const [getVerifyTokenApi, { isLoading }] = useVerifyTokenUserMutation();
  const [verifiedMsg, setVerifiedMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [successMsg, setSuccessMsg] = useState();

  useEffect(() => {
    getVerifyTokenApi({ token }).then((res) => {
      if (res?.data?.Success) {
        setVerifiedMsg(true);
      } else {
        setVerifiedMsg(false);
      }
    });
  }, [getVerifyTokenApi, token]);

  const onSubmit = (userInfo) => {
    if (!isLoading) {
      if (userInfo?.newPassword === userInfo?.confirmPassword) {
        setSuccessMsg(
          `${t("validation_message.your_password_successfully_reset")}`
        );
        resetPasswordUserApi({
          newPassword: userInfo.newPassword,
          apiToken: token,
        }).then((res) => {
          if (res?.data?.success) {
            navigate("/login");
          }
        });
      }
    }
  };

  return (
    <>
      <UnAuthWrapper>
        {verifiedMsg ? (
          <Stack className={style.loginMain}>
            <Stack className={style.loginRightContent}>
              <Text
                className={mergeClasses(style.loginRightTitle, style.mb_27)}
              >
                {t("reset_password.reset_password_title")}
              </Text>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Stack className={mergeClasses(style.mb_27)}>
                  <Stack className={mergeClasses(layout.textbox)}>
                    <FieldsInput
                      label={t("reset_password.new_password")}
                      validationMessage={errors?.newPassword?.message}
                      fieldClassName="custom-label"
                      size="large"
                      placeholder={t("reset_password.enter_new_password")}
                      name="newPassword"
                      inputType={showPassword ? "text" : "password"}
                      inputClassName={mergeClasses(
                        layout.textboxInput,
                        layout.w_100,
                        layout.flex_row,
                        layout.alignCenter
                      )}
                      inputId={"new-password"}
                      contentAfter={
                        <Link onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <Eye24Filled aria-label="Enter by voice" />
                          ) : (
                            <EyeOff24Filled aria-label="Enter by voice" />
                          )}
                        </Link>
                      }
                      validation={register("newPassword", {
                        required: `${t(
                          "validation_message.newPassword_is_required"
                        )}`,

                        pattern: {
                          value: RESET_PASSWORD_PATTERN,
                          message: `${t(
                            "validation_message.newPassword_is_not_valid"
                          )}`,
                        },
                      })}
                    />
                  </Stack>
                </Stack>
                <Stack className={style.mb_27}>
                  <Stack className={mergeClasses(layout.textbox)}>
                    <FieldsInput
                      label={t("reset_password.confirm_password")}
                      validationMessage={errors?.confirmPassword?.message}
                      fieldClassName="custom-label"
                      inputType={showConfirmPassword ? "text" : "password"}
                      placeholder={t("reset_password.enter_confirm_password")}
                      name="confirmPassword"
                      inputClassName={mergeClasses(
                        layout.textboxInput,
                        layout.w_100,
                        layout.flex_row,
                        layout.alignCenter
                      )}
                      inputId={"confirm-password"}
                      contentAfter={
                        <Link
                          onClick={() =>
                            setConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <Eye24Filled aria-label="Enter by voice" />
                          ) : (
                            <EyeOff24Filled aria-label="Enter by voice" />
                          )}
                        </Link>
                      }
                      validation={register("confirmPassword", {
                        required: `${t(
                          "validation_message.confirm_password_is_required"
                        )}`,
                        validate: (val) => {
                          if (watch("newPassword") !== val) {
                            return `${t(
                              "validation_message.your_password_not_match"
                            )}`;
                          }
                        },

                        pattern: {
                          value: RESET_PASSWORD_PATTERN,
                          message: `${t(
                            "validation_message.confirm_password_is_not_valid"
                          )}`,
                        },
                      })}
                    />
                  </Stack>
                </Stack>
                <p className={layout.greenColor}>{successMsg}</p>

                <Button
                  type="submit"
                  appearance="primary"
                  disabled={isLoading}
                  className={mergeClasses(layout.btnCustom, "custom-primary")}
                >
                  {isLoading ? "Loading...." : "Reset Password"}
                </Button>
              </Form>
            </Stack>
          </Stack>
        ) : (
          <Stack className={mergeClasses(style.loginMain, layout.alignCenter)}>
            <Stack className={style.loginRightContent}>
              <h1>{t("validation_message.your_token_is_invalid")}</h1>
            </Stack>
          </Stack>
        )}
      </UnAuthWrapper>
    </>
  );
};

export default ResetPassword;
