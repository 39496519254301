import React from "react";
import { Stack } from "@fluentui/react";
import { mergeClasses, Checkbox, Divider } from "@fluentui/react-components";
import Breadcrumb from "../../../components/Breadcrumb";
import Style from "./AddClient.style";
import {
  useAddClientMutation,
  useUploadAddLogoClientMutation,
  useEditClientMutation,
} from "../../../store/action/client";
import FormButton from "../../../components/FieldComponents/FormButton";
import Layout from "../../../styles/Layout.styles";
import { useForm } from "react-hook-form";
import Form from "../../../components/FieldComponents/Form";
import { Link, useNavigate } from "react-router-dom";
import CompanyName from "./Form/CompanyName";
import ClientSlug from "./Form/ClientSlug";
import CompanyAddress from "./Form/CompanyAddress";
import CompanyBillingAddress from "./Form/CompanyBillingAddress";
import {
  AccountDepartment,
  AccountEmail,
  AccountFirstName,
  AccountLastName,
  AccountPhoneNumber,
  ContactDepartment,
  ContactEmail,
  ContactFirstName,
  ContactLastName,
  ContactPhoneNumber,
} from "./Form";
import Email from "./Form/Email";
import staticStyle from "./Style";
import { useTranslation } from "react-i18next";
import AccountTimeZone from "./Form/AccountPerson/AccountTimeZone";
import ContactTimeZone from "./Form/ContactPerson/ContactTimeZone";
import ClientAttachement from "./Form/ClientAttachement";
import { useState } from "react";
import { trailWhiteSpace } from "../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../utils/errorHandler";
import { setClient } from "../../../store/reducer/client";
import { useDispatch } from "react-redux";

const ClientForm = (props) => {
  const { id, userInput, setUserInput, editData } = props;
  const [addClientApi, { isLoading: isAddLoading }] = useAddClientMutation();
  const [addUploadClientLogo, { isLoading: isPhotoEditLoading }] =
    useUploadAddLogoClientMutation();
  const [editClientApi, { isLoading: isEditLoading }] = useEditClientMutation();
  const { t } = useTranslation();
  const [isDeleteLogo, setIsDeleteLogo] = useState(false);
  const style = Style();
  const navigate = useNavigate();
  const layout = Layout();
  const dispatch = useDispatch();

  staticStyle();

  const breadcrumbData = [
    {
      title: t("client"),
      url: "/organization-list",
    },
    {
      title: id ? t("edit_client") : t("add_client"),
    },
  ];

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    setError,
  } = useForm({ mode: "onChange" });

  const onSubmit = (userInfo) => {
    let submitData = trailWhiteSpace(userInfo);
    const newUserData = {
      companyName: submitData?.companyName,
      companyAddress: submitData?.companyAddress,
      slug: submitData?.slug,
      companyBillingAddress: submitData?.companyBillingAddress,
      email: submitData?.email,
      logoDeleted: isDeleteLogo,
      accountPerson: {
        firstName: submitData?.accountFirstName,
        lastName: submitData?.accountLastName,
        email: submitData?.accountEmail,
        address1: submitData?.accountAddressOne,
        address2: submitData?.accountAddressTwo,
        city: submitData?.accountCity,
        country: submitData?.accountCountry,
        phoneNumber: submitData?.accountPhoneNumber,
        phonePrefix: submitData?.accountPhonePrefix,
        department: submitData?.accountDepartment,
        timezone: submitData?.accountTimeZone,
        state: submitData?.accountState,
      },
      contactPerson: {
        firstName: submitData?.contactFirstName,
        lastName: submitData?.contactLastName,
        email: submitData?.contactEmail,
        address1: submitData?.contactAddressOne,
        address2: submitData?.contactAddressTwo,
        city: submitData?.contactCity,
        country: submitData?.contactCountry,
        phoneNumber: submitData?.contactPhoneNumber,
        phonePrefix: submitData?.contactPhonePrefix,
        state: submitData?.contactState,
        timezone: submitData?.contactTimeZone,
        department: submitData?.contactDepartment,
      },
    };
    if (id) {
      if (!isEditLoading) {
        editClientApi({ clientData: newUserData, id: id }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError, true);
          }
          let formData = new FormData();
          if (userInput?.dataLogo && !isPhotoEditLoading) {
            formData.append("logoFile", userInput.dataLogo);
            addUploadClientLogo({ formData, id }).then((imageResp) => {
              if (!errorLists) {
                navigate("/organization-list");
              }
            });
          } else {
            if (!res.error) {
              navigate("/organization-list");
            }
          }
        });
      }
    } else {
      if (!isAddLoading) {
        addClientApi({ clientData: newUserData }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError, true);
          }
          const id = res?.data?.id;
          let formData = new FormData();
          if (userInput?.dataLogo && !isPhotoEditLoading) {
            formData.append("logoFile", userInput.dataLogo);
            addUploadClientLogo({ formData, id }).then((imageResp) => {
              if (!errorLists) {
                navigate("/organization-list");
                dispatch(setClient({ page: 0, isActive: "true" }));
              }
            });
          } else {
            if (!res.error) {
              navigate("/organization-list");
              dispatch(setClient({ page: 0, isActive: "true" }));
            }
          }
        });
      }
    }
  };

  const commonProps = { register, errors, setValue, getValues };
  const [accountData, setAccountData] = useState({
    accountFirstName: id ? editData?.data?.accountPerson?.firstName : "",
    accountLastName: id ? editData?.data?.accountPerson?.lastName : "",
    accountEmail: id ? editData?.data?.accountPerson?.email : "",
    accountPhoneNumber: id ? editData?.data?.accountPerson?.phoneNumber : "",
    accountPhonePrefix: id ? editData?.data?.accountPerson?.phonePrefix : "",
    accountTimeZone: id ? editData?.data?.accountPerson?.timezone : "",
    accountDepartment: id ? editData?.data?.accountPerson?.department : "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountData((prevState) => ({
      ...prevState,
      [name]: value ? value : "",
    }));
    setValue(name, value, { shouldValidate: "true" });
  };

  const checkAsAccount = (e) => {
    if (e?.target?.checked) {
      setValue("accountFirstName", getValues("contactFirstName"), {
        shouldValidate: "true",
      });
      setValue("accountLastName", getValues("contactLastName"), {
        shouldValidate: "true",
      });
      setValue("accountEmail", getValues("contactEmail"), {
        shouldValidate: "true",
      });
      setValue("accountPhoneNumber", getValues("contactPhoneNumber"), {
        shouldValidate: "true",
      });
      setValue("accountPhonePrefix", getValues("contactPhonePrefix"), {
        shouldValidate: "true",
      });
      setValue("accountTimeZone", getValues("contactTimeZone"), {
        shouldValidate: "true",
      });
      setValue("accountDepartment", getValues("contactDepartment"), {
        shouldValidate: "true",
      });
      setAccountData({
        accountFirstName: getValues("contactFirstName"),
        accountLastName: getValues("contactLastName"),
        accountEmail: getValues("contactEmail"),
        accountPhoneNumber: getValues("contactPhoneNumber"),
        accountPhonePrefix: getValues("contactPhonePrefix"),
        accountTimeZone: getValues("contactTimeZone"),
        accountDepartment: getValues("contactDepartment"),
      });
    }
  };

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack>
        <Form onSubmit={handleSubmit(onSubmit)} className={layout.d_flex}>
          <Stack
            className={mergeClasses(
              style.clientContent,
              "clientContent",
              layout.borderRadiusMedium
            )}
          >
            <h2 className={mergeClasses(style.headerTitle)}>Company</h2>
            <Divider className={layout.mb_2} />

            <CompanyName
              {...commonProps}
              companyName={editData?.data?.companyName}
            />
            <Email
              {...commonProps}
              email={editData?.data?.email}
              isDisable={id ? true : false}
            />
            <CompanyAddress
              {...commonProps}
              companyAddress={editData?.data?.companyAddress}
            />

            <CompanyBillingAddress
              {...commonProps}
              companyBillingAddress={editData?.data?.companyBillingAddress}
            />
            <ClientAttachement
              {...commonProps}
              validation={register("logoFile")}
              setFileCallback={(e) => {
                setUserInput({ ...userInput, dataLogo: e });
              }}
              clientLogo={userInput.clientLogo}
              setIsDeleteLogo={setIsDeleteLogo}
            />
            <ClientSlug {...commonProps} slug={editData?.data?.slug} />

            <Stack className={mergeClasses(layout.w_100, layout.mb_2)}>
              <h2
                className={mergeClasses(
                  style.headerTitle,
                  style.mt_0,
                  layout.w_100
                )}
              >
                {t("company_contact_person")}
              </h2>
            </Stack>
            <Divider className={layout.mb_2} />

            <ContactFirstName
              {...commonProps}
              contactFirstName={editData?.data?.contactPerson?.firstName}
            />
            <ContactLastName
              {...commonProps}
              contactLastName={editData?.data?.contactPerson?.lastName}
            />
            <ContactEmail
              {...commonProps}
              contactEmail={editData?.data?.contactPerson?.email}
            />
            <ContactPhoneNumber
              {...commonProps}
              contactPhoneNumber={editData?.data?.contactPerson?.phoneNumber}
              contactPhonePrefix={editData?.data?.contactPerson?.phonePrefix}
            />
            <ContactTimeZone
              {...commonProps}
              contactTimeZone={editData?.data?.contactPerson?.timezone}
              timeZoneSelectContactData={userInput.timeZoneSelectContactData}
              checkAccountData={userInput.checkAccountData}
            />
            <ContactDepartment
              {...commonProps}
              contactDepartment={editData?.data?.contactPerson?.department}
              checkAccountData={userInput.checkAccountData}
            />

            <Stack className={mergeClasses(layout.w_100, layout.mb_2)}>
              <h2 className={mergeClasses(style.headerTitle)}>
                {t("company_account_person")}
              </h2>

              <Checkbox
                name="sameAsContact"
                label={t("same_as_company_contact_person")}
                onChange={(e) => {
                  checkAsAccount(e);
                }}
              />
            </Stack>

            <Divider className={layout.mb_2} />

            <AccountFirstName
              {...commonProps}
              value={accountData?.accountFirstName}
              onChange={handleChange}
            />
            <AccountLastName
              {...commonProps}
              value={accountData?.accountLastName}
              onChange={handleChange}
            />
            <AccountEmail
              {...commonProps}
              onChange={handleChange}
              value={accountData?.accountEmail}
            />
            <AccountPhoneNumber
              {...commonProps}
              onChange={handleChange}
              value={accountData?.accountPhoneNumber}
              selectPhonePrefixValue={accountData?.accountPhonePrefix}
            />
            <AccountTimeZone
              {...commonProps}
              onChange={handleChange}
              value={accountData?.accountTimeZone}
            />
            <AccountDepartment
              {...commonProps}
              onChange={handleChange}
              value={accountData?.accountDepartment}
              className={layout.w_100}
            />
            <Stack className={layout.w_50}></Stack>

            <Stack className={style.formfooter}>
              <FormButton
                type="submit"
                appearance="primary"
                loading={isEditLoading || isAddLoading}
                className={mergeClasses(style.customBtn, "custom-primary")}
              >
                {t("save")}
              </FormButton>
              <Link
                to="/organization-list"
                appearance="primary"
                className={mergeClasses(style.customBtn)}
              >
                <FormButton className={layout.min150}>{t("cancel")}</FormButton>
              </Link>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </>
  );
};

export default ClientForm;
