import { Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Form, FormButton, Breadcrumb } from "../../../components";
import { useForm } from "react-hook-form";
import Layout from "../../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../Client/ClientManagement/AddClient.style";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trailWhiteSpace } from "../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../utils/errorHandler";
import {
  useEditSystemEndUserMutation,
  useGetSystemEndUserMutation,
  useGetSystemEndUserPhotoMutation,
  useSystemEndUserAddMutation,
  useUploadSystemEndUserPhotoMutation,
} from "../../../store/action/systemEndUser";
import SystemUserCity from "./From/SystemUserCity";
import SystemUserAddress from "./From/SystemUserAddress";
import SystemUserCountry from "./From/SystemUserCountry";
import SystemUserEmailAddress from "./From/SystemUserEmailAddress";
import SystemUserFirstName from "./From/SystemUserFirstName";
import SystemUserLastName from "./From/SystemUserLastName";
import SystemUserPhoneNumber from "./From/SystemUserPhoneNumber";
import SystemUserPhotoUpload from "./From/SystemUserPhotoUpload";
import SystemUserSetPassword from "./From/SystemUserSetPassword";
import SystemUserState from "./From/SystemUserState";
import SystemUserTimeZone from "./From/SystemUserTimeZone";
import SystemUserRegeneratePassCode from "./From/SystemUserRegeneratePassCode";
import staticStyle from "../../Client/ClientManagement/Style";
import { setSystemEndUser } from "../../../store/reducer/systemEndUser";

const SystemEndUserFrom = (props) => {
  const { id } = props;

  const navigate = useNavigate();
  const layout = Layout();
  const style = Style();
  const [systemUserEditData, setSystemUserEditData] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [timeZoneSelectListData, setTimeZoneSelectListData] = useState();
  const [addSystemUsersApi, { isLoading: isAddLoading }] =
    useSystemEndUserAddMutation();
  const [getSystemUserApi, { isLoading: isGetLoading }] =
    useGetSystemEndUserMutation();
  const [editSystemUsersApi, { isLoading: isEditLoading }] =
    useEditSystemEndUserMutation();
  const [addUploadSystemUsersPhoto, { isLoading: isPhotoEditLoading }] =
    useUploadSystemEndUserPhotoMutation();
  const [getSystemUsersPhotoApi] = useGetSystemEndUserPhotoMutation();

  const [isDeletePhoto, setIsDeletePhoto] = useState(false);
  const [getUsersPhoto, setGetUsersPhoto] = useState();
  const [isUsersPhoto, setIsUsersPhoto] = useState();
  const [isEditDisable, setIsEditDisable] = useState(false);
  const systemUserList = useSelector((state) => state.systemEndUser);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  staticStyle();

  const breadcrumbData = [
    {
      title: t("system_end_user"),
      url: "/system-end-user-list",
    },
    {
      title: id ? t("edit_system_end_user") : t("add_system_end_user"),
    },
  ];

  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getSystemUserApi(id).then((res) => {
            setSystemUserEditData(res);
            setIsEditDisable(true);
          });
          getSystemUsersPhotoApi(id).then((res) => {
            setGetUsersPhoto(res?.data?.imageUrl);
          });
        }
      }
      setInitialLoad(false);
    }
  }, [initialLoad, isGetLoading, getSystemUserApi, id, getSystemUsersPhotoApi]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    resetField,
  } = useForm();

  const onSubmit = (value) => {
    let formData = trailWhiteSpace(value);

    const newUserData = {
      email: formData?.systemUserEmail,
      firstName: formData?.systemUserFirstName,
      lastName: formData?.systemUserLastName,
      phonePrefix: formData?.systemUserPhonePrefix,
      phoneNumber: formData?.systemUserPhoneNumber,
      password: formData?.systemUserSetPassword,
      passcode: formData?.systemUserPassCode,
      address: formData?.systemUserAddress,
      city: formData?.systemUserCity,
      state: formData?.systemUserState,
      country: formData?.systemUserCountry,
      timezone: formData?.systemUserTimeZone,
      profileImageDeleted: isDeletePhoto,
    };

    if (id) {
      if (!isEditLoading) {
        editSystemUsersApi({ systemEndUserData: newUserData, id: id }).then(
          (res) => {
            let errorLists = res?.error?.data?.violations;
            if (errorLists) {
              formErrorHandler("systemUser", errorLists, setError);
            }
            let formData = new FormData();
            if (isUsersPhoto && !isPhotoEditLoading) {
              formData.append("fileUpload", isUsersPhoto);
              addUploadSystemUsersPhoto({ formData, id }).then((resLogo) => {
                if (!errorLists) {
                  navigate("/system-end-user-list");
                }
              });
            } else {
              if (!res.error) {
                navigate("/system-end-user-list");
              }
            }
          }
        );
      }
    } else {
      if (!isAddLoading) {
        addSystemUsersApi({ systemEndUserData: newUserData }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("systemUser", errorLists, setError);
          }
          const id = res?.data?.id;
          let formData = new FormData();
          if (isUsersPhoto && !isPhotoEditLoading) {
            formData.append("fileUpload", isUsersPhoto);
            addUploadSystemUsersPhoto({ formData, id }).then((resLogo) => {
              if (!errorLists) {
                navigate("/system-end-user-list");
                dispatch(setSystemEndUser({ page: 0, isActive: "true" }));
              }
            });
          } else {
            if (!res.error) {
              navigate("/system-end-user-list");
              dispatch(setSystemEndUser({ page: 0, isActive: "true" }));
            }
          }
        });
      }
    }
  };

  if (id && !systemUserEditData?.data) {
    return null;
  }

  const commonProps = { register, errors, setValue, resetField };
  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack>
        <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <Stack
            className={mergeClasses(
              style.clientContent,
              "clientContent",
              layout.borderRadiusMedium
            )}
          >
            <SystemUserFirstName
              {...commonProps}
              validationMessage={
                systemUserList?.errorMessage?.firstName &&
                systemUserList?.errorMessage?.firstName?.validationMessage
              }
              systemUserFirstName={systemUserEditData?.data?.firstName}
            />
            <SystemUserLastName
              {...commonProps}
              systemUserLastName={systemUserEditData?.data?.lastName}
            />
            <SystemUserPhoneNumber
              {...commonProps}
              systemUserPhoneNumber={systemUserEditData?.data?.phoneNumber}
              systemUserPhonePrefix={systemUserEditData?.data?.phonePrefix}
            />

            <SystemUserEmailAddress
              {...commonProps}
              systemUserEmail={systemUserEditData?.data?.email}
            />
            <SystemUserRegeneratePassCode
              {...commonProps}
              id={id}
              systemUserPassCode={systemUserEditData?.data?.passcode}
              isEditDisable={isEditDisable}
            />
            <SystemUserSetPassword
              {...commonProps}
              systemUserSetPassword={systemUserEditData?.data?.password}
              isEditDisable={isEditDisable}
            />

            <SystemUserAddress
              {...commonProps}
              systemUserAddress={systemUserEditData?.data?.address}
            />
            <SystemUserCountry
              {...commonProps}
              systemUserCountry={systemUserEditData?.data?.country}
              setTimeZoneSelectListData={setTimeZoneSelectListData}
            />
            <SystemUserState
              {...commonProps}
              systemUserState={systemUserEditData?.data?.state}
            />
            <SystemUserCity
              {...commonProps}
              systemUserCity={systemUserEditData?.data?.city}
            />

            <SystemUserTimeZone
              {...commonProps}
              systemUserTimeZone={systemUserEditData?.data?.timezone}
              timeZoneSelectListData={timeZoneSelectListData}
            />

            <SystemUserPhotoUpload
              {...commonProps}
              validation={register("fileUpload")}
              setFileCallback={setIsUsersPhoto}
              userPhoto={getUsersPhoto}
              setIsDeletePhoto={setIsDeletePhoto}
            />

            <Stack className={style.formfooter}>
              <FormButton
                type="submit"
                appearance="primary"
                loading={isEditLoading || isAddLoading || isPhotoEditLoading}
                className={mergeClasses(style.customBtn, "custom-primary")}
              >
                {t("save")}
              </FormButton>
              <Link
                to="/system-end-user-list"
                appearance="primary"
                className={mergeClasses(style.customBtn)}
              >
                <FormButton className={layout.min150}>{t("cancel")}</FormButton>
              </Link>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </>
  );
};

export default SystemEndUserFrom;
