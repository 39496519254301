import { createSlice } from "@reduxjs/toolkit";

const visitor = createSlice({
  name: "visitor",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    locationId: "",
    projectLocationId: "",
    pagination: "",
    exportData: [],
  },
  reducers: {
    setVisitor(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setVisitor } = visitor.actions;
export default visitor.reducer;
