import React, { useEffect, useState } from "react";
import { Text, mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import {
  Pagination,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Breadcrumb,
} from "../../../components";
import { useSelector } from "react-redux";
import Layout from "../../../styles/Layout.styles";
import { t } from "i18next";
import { useUsersListMutation } from "../../../store/action/users";
import { setUsers } from "../../../store/reducer/users";
import UsersListRow from "./UsersListRow";
import UsersFilter from "./UsersFilter";
import accessRole from "../../../components/Common/accessRole";
import { useNavigate } from "react-router-dom";

const UsersList = (props) => {
  const role = accessRole();
  const navigate = useNavigate();
  const layout = Layout();
  const [getUsersListApi, { isLoading }] = useUsersListMutation();
  const usersList = useSelector((state) => state.users);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getUsersListApi();
        setInitialLoad(false);
      }
    }
  }, [initialLoad, getUsersListApi, isLoading, usersList]);

  const columns = [
    { columnKey: "firstName", label: t("users.name"), isSort: true },
    { columnKey: "email", label: t("users.email"), isSort: true },
    { columnKey: "role", label: t("users.role") },
    { columnKey: "action", label: t("action") },
    {
      columnKey: "activeInactive",
      label: t("activeInactive"),
    },
  ];

  const breadcrumbData = [
    {
      title: `${t("users_text")}`,
    },
    {
      title: `${t("users_list")}`,
    },
  ];

  if (!accessRole()?.has("system_user_list")) {
    navigate("/project-list");
  }
  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
        <UsersFilter
          setInitialLoad={setInitialLoad}
          getUsersListApi={getUsersListApi}
          role={role}
        />
        <Stack className="contentData">
          <Stack className="overflow-y-auto">
            <Table className="tbl-layout-auto">
              <TableHeader className={layout.headerBackground}>
                <TableRow>
                  {columns.map((column, key) => (
                    <TableHeaderCell
                      key={key}
                      onSortApi={getUsersListApi}
                      onSet={setUsers}
                      column={column}
                      sortBy={column?.isSort ? usersList?.sortBy : false}
                    >
                      <Text className={layout.headerLabel}>{column.label}</Text>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody isLoading={isLoading}>
                {usersList?.data?.map((item, key) => (
                  <UsersListRow key={key} item={item} role={role} />
                ))}
              </TableBody>
            </Table>
          </Stack>
          <Pagination
            totalCount={usersList?.totalCount}
            itemsPerPage={usersList?.itemsPerPage}
            onSetListReducer={setUsers}
            onGetList={getUsersListApi}
            page={usersList?.page}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default UsersList;
