import React, { useEffect, useState } from "react";
import { FieldCombobox } from "../../../../components";
import Layout from "../../../../styles/Layout.styles";
import { useTeamsListMutation } from "../../../../store/action/teams";
import { useGetProjectTimeSheetMutation } from "../../../../store/action/time-and-attendance";
import { Stack } from "@fluentui/react";
import { mergeClasses } from "@fluentui/react-components";
import { t } from "i18next";

const ProjectFilter = (props) => {
  const {
    projectOption,
    setAssignedProjectId,
    setOptions,
    filterDates,
    setIsProjectLoader,
    setAssignedTeamId,
  } = props;
  const [customSearch, setCustomSearch] = useState("" | undefined);
  const [matchingOptions, setMatchingOptions] = useState([...projectOption]);
  const [getTeamList] = useTeamsListMutation();
  const [startDate, endDate] = filterDates;
  const [timeAndAttendanceApi, { isLoading }] =
    useGetProjectTimeSheetMutation();
  const layout = Layout();

  useEffect(() => {
    setIsProjectLoader(isLoading);
    setMatchingOptions([...projectOption]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectOption, isLoading]);

  const onSelectOption = (e, data) => {
    setAssignedTeamId(undefined);
    setMatchingOptions([...projectOption]);
    const matchingOption =
      data?.optionText && matchingOptions?.includes(data?.optionText);
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data?.optionText);
    }
    setAssignedProjectId(data?.optionValue);

    if (!isLoading) {
      timeAndAttendanceApi({
        pagination: false,
        projectId: data?.optionValue,
        startDate: startDate,
        endDate: endDate,
      }).then(() => {
        getTeamList({
          pagination: false,
          project: data?.optionValue,
          search: "",
          sortBy: "",
          sortType: "",
        }).then((res) => {
          const teamData = res?.data?.map?.((item, label) => ({
            children: item?.name,
            value: item?.id,
          }));
          setOptions(teamData);
        });
      });
    }
  };

  const onChange = (event) => {
    const value = event?.target?.value?.trim();
    const matches = projectOption?.filter((option) =>
      option?.children?.toLowerCase().includes(value?.toLowerCase())
    );
    setMatchingOptions(matches);
    if (value?.length && matches?.length < 1) {
      setCustomSearch(value);
    } else {
      setCustomSearch(undefined);
    }
  };

  if (projectOption?.[0]?.children === undefined) {
    return "";
  }

  return (
    <Stack className={mergeClasses(layout.flex_column, layout.alignTop)}>
      <label className={layout.mb_1}>{t("tna_project")}</label>
      <FieldCombobox
        multiselect={false}
        name="project"
        className={layout.w_100}
        defaultValue={projectOption?.[0]?.children}
        defaultSelectedOptions={[projectOption?.[0]?.value]}
        onOptionSelect={onSelectOption}
        onChange={onChange}
        customSearch={customSearch}
        matchingOptions={matchingOptions}
      />
    </Stack>
  );
};
export default ProjectFilter;
