import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import Layout from "../../../../styles/Layout.styles";
import FieldSelectPhoneCode from "../../../../components/FieldComponents/FieldSelectPhoneCode";
import { NUMBER_VALIDATION_16SIZE_PATTERN } from "../../../../utils/constants";
import { blockInvalidCharPhone } from "../../../../utils/blockInvalidCharPhone";

const SystemUserPhoneNumber = (props) => {
  const { systemUserPhoneNumber, systemUserPhonePrefix } = props;
  const style = Style();
  const layout = Layout();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={style.client_field}>
        <Stack className={layout.flex_row}>
          <FieldSelectPhoneCode
            name={systemUserPhonePrefix}
            inputDefaultValue={
              systemUserPhonePrefix ? systemUserPhonePrefix : "+61"
            }
            className={mergeClasses(
              layout.clientContactPrefix,
              "clientContactPrefix",
              props?.errors?.systemUserPhoneNumber ? "mb-1_5" : ""
            )}
            validation={props.register("systemUserPhonePrefix")}
          />

          <FieldsInput
            label={t("phone_number")}
            validationMessage={props.errors?.systemUserPhoneNumber?.message}
            id="systemUserPhoneNumber"
            placeholder={t("enter_phone_number")}
            name="systemUserPhoneNumber"
            // inputType="number"
            // onKeyDown={blockInvalidCharPhone}
            className="phoneNumberInput"
            inputDefaultValue={systemUserPhoneNumber}
            inputClassName={mergeClasses(style.textboxInput)}
            validation={props.register("systemUserPhoneNumber", {
              required: `${t("validation_message.phone_number_is_required")}`,
              pattern: {
                value: NUMBER_VALIDATION_16SIZE_PATTERN,
                message: `${t("validation_message.number_not_match16_format")}`,
              },
            })}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default SystemUserPhoneNumber;
