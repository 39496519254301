import CustomFieldsInput from "../../../../../components/FieldComponents/CustomFieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../AddClient.style";
import { Stack } from "@fluentui/react";
import { EMAIL_VALIDATION_PATTERN } from "../../../../../utils/constants";
import { useTranslation } from "react-i18next";

const AccountEmail = (props) => {
  const { onChange, value } = props;
  const style = Style();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={style.client_field}>
        <CustomFieldsInput
          label={t("email_address")}
          validationMessage={props.errors?.accountEmail?.message}
          inputType="email"
          id="accountEmail"
          placeholder={t("enter_email_address")}
          name="accountEmail"
          value={value}
          onChange={onChange}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("accountEmail", {
            required: `${t("validation_message.account_email_is_required")}`,
            pattern: {
              value: EMAIL_VALIDATION_PATTERN,
              message: `${t("validation_message.email_not_match_format")}`,
            },
          })}
        />
      </Stack>
    </>
  );
};

export default AccountEmail;
