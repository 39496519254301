import {
  makeStaticStyles,
  tokens,
  shorthands,
} from "@fluentui/react-components";

const staticStyle = makeStaticStyles({
  "nav.breadCrumb li:first-child a": {
    fontSize: "24px",
    fontWeight: tokens.fontWeightSemibold,
  },
  ".sidebar-content": {
    height: "0",
    ...shorthands.overflow("hidden"),
  },
  ".sidebar-menu.open .sidebar-content": {
    height: "auto",
  },
  ".sidebar-menu.open .sidebar-menu img": {
    display: "none",
  },
  ".sidebar-menu .sidebar-menu": {
    ...shorthands.padding("7px", 0, "7px", 0),
    ...shorthands.margin(0, "30px"),
  },

  ".sidebar-menu": {
    color: tokens.colorBrandBackground,
  },
  ".menu-image:hover": {
    color: "black",
  },
  ".menu-image svg, .sidebar-title svg, .side-bottom-link svg": {
    ...shorthands.margin(0, "10px", 0, 0),
  },
  ".sidebar-menu:hover": {
    color: tokens.colorBrandBackground,
  },
  ".sidebar-menu.open .sidebar-title, .sidebar-title, .side-bottom-link, .menu-image":
    {
      color: tokens.colorBrandBackground,
      textDecoration: "none",
    },
  ".sidebar-menu .sidebar-title": {
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  ".sidebar-title a": {
    display: "flex",
  },
  ".sidebar-menu a:hover, .static-menu a:hover": {
    textDecoration: "none",
  },
  ".sidebar-icon": {
    ...shorthands.margin(0, 0, 0, "19px"),
  },
  ".sidebar-menu.open .sidebar-icon": {
    "-webkit-transform": "rotateX(180deg)",
    transform: "rotateX(180deg)",
  },
  ".sidebar-close": {
    minWidth: "80px",
    width: "80px",
  },
  ".sidebar-close .menu-item-name": {
    display: "none",
  },
  ".contentSection .fui-Select": {
    minWidth: "100%",
  },
  ".sidebarLinks": {
    fontSize: "15px",
    fontWeight: tokens.fontWeightRegular,
    color: tokens.colorBrandBackground,
  },
  ".sidebarLinks:hover, .static-menu:hover, .menu-item-name": {
    textDecoration: "none",
    fontWeight: tokens.fontWeightRegular,
  },
  ".sidebar-content .menu-image": {
    marginBottom: 0,
  },
  ".sidebar-close .sidebar-bottom-arrow": {
    display: "none",
  },
  ".sidebar-bottom-arrow": {
    marginLeft: "10px !important",
    marginTop: "2px !important",
  },
  ".sidebar-dropdown.open .side-drop": {
    background: "#DCE4EA",
    borderRadius: "3px",
  },
  ".sidebar-content .menu-image button[aria-selected=true]::after": {
    backgroundColor: "unset",
  },
  ".sidebar-content .menu-image button::before": {
    content: "unset",
  },
  ".sidebar-content .menu-image button::after": {
    content: "unset",
  },
  ".sidebar-content .menu-image button[aria-selected=true] .menu-item-name": {
    color: tokens.colorBrandBackground,
  },
  ".sidebar-content .menu-image button[aria-selected=false] .menu-item-name": {
    color: tokens.colorBrandBackground,
  },

  ".side-bottom-link button[aria-selected=true] span": {
    fontWeight: "bold",
  },
  ".setting-popup[aria-selected=true] span": {
    fontWeight: "bold",
  },
  ".tab-open[aria-selected=true] span": {
    fontWeight: "bold",
  },
  ".aria-check[aria-checked=true] span": {
    fontWeight: "bold",
  },
});

export default staticStyle;
