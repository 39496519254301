import {
  makeStyles,
  shorthands,
  makeStaticStyles,
} from "@fluentui/react-components";

const style = makeStyles({
  themeFilter: {
    "@media screen and (max-width: 1280px)": {
      flexDirection: "column",
      ...shorthands.margin(0, 0, "15px", 0),
    },
  },
  themeSearch: {
    "@media screen and (max-width: 1280px)": {
      ...shorthands.margin(0, 0, "15px", 0),
    },
  },
});

const staticStyle = makeStaticStyles({
  ".color-option": {
    ...shorthands.border("none"),
  },
});

export default style;
export { staticStyle };
