import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setBilling } from "../reducer/billing";
import { defaultFromState } from "../../utils/defaultFromState";

export const billingApi = createApi({
  reducerPath: "billingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    billingList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        pagination = undefined,
        sortBy = undefined,
        sortType = undefined,
        createdAtAfter = undefined,
        createdAtBefore = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/client-invoices",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            pagination: pagination,
            sortBy,
            sortType,
            createdAtAfter,
            createdAtBefore,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              billing: {
                page,
                itemsPerPage,
                pagination,
                sortBy,
                sortType,
                createdAtAfter,
                createdAtBefore,
                client,
              },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              pagination,
              sortBy,
              sortType,
              createdAtAfter,
              createdAtBefore,
              client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setBilling({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setBilling({ errorMessage: error?.data?.error }));
      }
    ),
    getBilling: buildMutationHelper(
      builder,
      (id) => ({
        url: `/client-invoice/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setBilling({
            billingData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setBilling({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const { useBillingListMutation, useGetBillingMutation } = billingApi;
