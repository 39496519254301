import { createSlice } from "@reduxjs/toolkit";

const license = createSlice({
  name: "license",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    pagination: "",
    clientOrder: [],
    buyNow: [],
    purchaseBundle: false,
  },

  reducers: {
    setLicense(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setLicense } = license.actions;
export default license.reducer;
