import React, { useEffect } from "react";
import {
  Checkbox,
  Divider,
  mergeClasses,
  Text,
  Toast,
  Toaster,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import Layout from "../../styles/Layout.styles";

import {
  Breadcrumb,
  Form,
  FormButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "../../components";
import { useForm } from "react-hook-form";
import Module from "./Form/Module";
import ExternalSource from "./Form/ExternalSource";
import ApiKey from "./Form/ApiKey";
import Businesses from "./Form/Businesses";
import { useState } from "react";
import ExternalSourceDetails from "./ExternalSourceDetails";
import Style from "./ExternalSource.style";
import {
  useAddClientExternalSourcesMutation,
  useGetClientExternalSourcesListMutation,
} from "../../store/action/clientExternalSources";
import { setClientExternalSources } from "../../store/reducer/clientExternalSources";
import ClientExternalSourceRow from "./ClientExternalSourceRow";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useId } from "react";

const ExternalSourcesForm = (props) => {
  const layout = Layout();
  const style = Style();

  const [webhookSettings, setWebhookSettings] = useState(false);
  const [fetchEmployees, setFetchEmployees] = useState(false);
  const [isEditSourcesOpen, setIsEditSourcesOpen] = useState(false);
  const [openBusinessSelection, setOpenBusinessSelection] = useState(false);
  const [authTypeName, setAuthTypeName] = useState();
  const [externalSourceId, setExternalSourceId] = useState();
  const [externalSourcesSelectedOptions, setExternalSourcesSelectedOptions] =
    useState([]);
  const [externalSourcesList, setExternalSourcesList] = useState([]);
  const [moduleSelectedOptions, setModuleSelectedOptions] = useState([]);
  const [businessOptions, setBusinessOptions] = useState([]);
  const [externalDepartmentArray, setExternalDepartmentArray] = useState([]);
  const [editExternalSourceId, setEditExternalSourceId] = useState();
  const [errorHandle, setErrorHandle] = useState();
  const [isExternalSourcesListOpen, setExternalSourcesListOpen] =
    useState(false);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [
    addClientExternalSourcesApi,
    { isLoading: addClientExternalSourcesLoading },
  ] = useAddClientExternalSourcesMutation();
  const [clientExternalSourceListApi, { isLoading: clientListLoading }] =
    useGetClientExternalSourcesListMutation();
  const ExternalSourcesList = useSelector(
    (state) => state.clientExternalSources
  );

  useEffect(() => {
    if (!clientListLoading) {
      clientExternalSourceListApi();
    }
  }, [isExternalSourcesListOpen]);

  const breadcrumbData = [
    {
      title: "ExternalSources",
    },
  ];

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const onSubmit = (value) => {
    const data = {
      externalSource: `/external-source/${externalSourceId}`,
      externalApiKey: value.apiKey,
      externalDepartments: externalDepartmentArray,
      action: {
        webhook: webhookSettings,
        fetch_employee: fetchEmployees,
      },
    };
    if (!addClientExternalSourcesLoading) {
      addClientExternalSourcesApi({
        externalSourcesData: data,
      }).then((res) => {
        setOpenBusinessSelection(false);
        setModuleSelectedOptions([]);
        setExternalSourcesSelectedOptions([]);
        setAuthTypeName("");

        if (!clientListLoading) {
          clientExternalSourceListApi();
        }
      });
    }
  };

  const columns = [
    {
      columnKey: "module",
      label: "Module",
    },
    {
      columnKey: "External source",
      label: "External source",
    },
    {
      columnKey: "Action",
      label: "Action",
    },
  ];

  const commonProps = { register, errors, setValue };

  const notify = (message, valid) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`${message}`}</ToastTitle>
      </Toast>,
      { intent: valid ? "success" : "error" }
    );
  };

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Toaster position="top" timeout={3000} toasterId={toasterId} />

      <Stack
        className={mergeClasses(
          layout.flex_row,
          layout.flex_between,
          isEditSourcesOpen && "modalOpen"
        )}
      >
        <Stack
          className={mergeClasses(
            style.clientContent,
            "cardShadow",
            // "customizeContent",
            layout.w_100
          )}
        >
          <Stack className={mergeClasses(layout.alignCenter)}>
            <Stack className={mergeClasses(style.ExternalSourceContent)}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Module
                  {...commonProps}
                  setExternalSourcesList={setExternalSourcesList}
                  setModuleSelectedOptions={setModuleSelectedOptions}
                  moduleSelectedOptions={moduleSelectedOptions}
                />

                <ExternalSource
                  {...commonProps}
                  setAuthTypeName={setAuthTypeName}
                  externalSourcesList={externalSourcesList}
                  setExternalSourceId={setExternalSourceId}
                  setExternalSourcesSelectedOptions={
                    setExternalSourcesSelectedOptions
                  }
                  externalSourcesSelectedOptions={
                    externalSourcesSelectedOptions
                  }
                />

                {authTypeName === "username" && (
                  <ApiKey
                    {...commonProps}
                    setOpenBusinessSelection={setOpenBusinessSelection}
                    externalSourceId={externalSourceId}
                    setBusinessOptions={setBusinessOptions}
                    setExternalSourcesListOpen={setExternalSourcesListOpen}
                  />
                )}
                {openBusinessSelection && (
                  <>
                    <h3 className={layout.tokenColor}>Configure entities</h3>
                    <Divider className={layout.mb_1_0} />

                    <Businesses
                      {...commonProps}
                      businessOptions={businessOptions}
                      setExternalDepartmentArray={setExternalDepartmentArray}
                      setErrorHandle={setErrorHandle}
                      errorHandle={errorHandle}
                    />

                    <h3 className={layout.tokenColor}>Actions </h3>
                    <Divider className={layout.mb_1_0} />

                    <Checkbox
                      name="webhookSettings"
                      label="Webhook settings"
                      onChange={(e) => setWebhookSettings(e?.target?.checked)}
                      checked={webhookSettings}
                    />
                    <Checkbox
                      name="fetchEmployees"
                      label="Fetch employees"
                      onChange={(e) => setFetchEmployees(e?.target?.checked)}
                      checked={fetchEmployees}
                    />

                    <Stack className={style.formfooter}>
                      <FormButton
                        type="submit"
                        appearance="primary"
                        className={mergeClasses(
                          style.customBtn,
                          "custom-primary"
                        )}
                        loading={addClientExternalSourcesLoading}
                        disabled={
                          errorHandle?.length === 0 || errorHandle === undefined
                        }
                      >
                        Submit
                      </FormButton>
                    </Stack>
                  </>
                )}
              </Form>
            </Stack>
          </Stack>
          {ExternalSourcesList?.data?.length > 0 && (
            <>
              <h2
                className={mergeClasses(
                  style.ExternalSourceTitle,
                  layout.primary,
                  layout.mt_3
                )}
              >
                {t("externalSources.externalSources_list")}
              </h2>
              <Stack className={layout.overflow_auto}>
                <Table className={mergeClasses("tbl-layout-auto", layout.mt_2)}>
                  <TableHeader className={layout.headerBackground}>
                    <TableRow>
                      {columns?.map((column, key) => (
                        <TableHeaderCell
                          key={key}
                          onSortApi={clientExternalSourceListApi}
                          onSet={setClientExternalSources}
                          column={column}
                          sortBy={
                            column?.isSort ? ExternalSourcesList?.sortBy : false
                          }
                        >
                          <Text className={layout.headerLabel}>
                            {column.label}
                          </Text>
                        </TableHeaderCell>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody isLoading={clientListLoading}>
                    {ExternalSourcesList?.data?.map((item, key) => (
                      <ClientExternalSourceRow
                        item={item}
                        key={key}
                        setIsEditSourcesOpen={setIsEditSourcesOpen}
                        isEditSourcesOpen={isEditSourcesOpen}
                        setEditExternalSourceId={setEditExternalSourceId}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Stack>
            </>
          )}
        </Stack>
        {isEditSourcesOpen && (
          <Stack
            className={mergeClasses(
              style.modalBackground,
              isEditSourcesOpen && "detailsOpen"
            )}
          >
            <ExternalSourceDetails
              setIsEditSourcesOpen={setIsEditSourcesOpen}
              editExternalSourceId={editExternalSourceId}
              notify={notify}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default ExternalSourcesForm;
