import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setClientExternalSources } from "../reducer/clientExternalSources";
import { defaultFromState } from "../../utils/defaultFromState";

export const clientExternalSourcesApi = createApi({
  reducerPath: "clientExternalSourcesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    getClientExternalSourcesList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        pagination = undefined,
        client = localStorage?.loginClientId ?? undefined,
      } = {}) => {
        return {
          url: "/client-external-sources",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            pagination: pagination,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              externalSources: { page, itemsPerPage, pagination, client },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              pagination,
              client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },

      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setClientExternalSources({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setClientExternalSources({
              exportData,
              errorMessage: "",
            })
          );
        }
      },

      ({ error }, dispatch) => {
        dispatch(
          setClientExternalSources({ errorMessage: error?.data?.error })
        );
      }
    ),
    addExternalSourcesConnect: buildMutationHelper(
      builder,
      ({ externalSourcesData }) => ({
        url: `/external-source-connect`,
        method: "POST",
        body: externalSourcesData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setClientExternalSources({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(
          setClientExternalSources({ errorMessage: error?.data?.violations })
        );
      }
    ),
    addClientExternalSources: buildMutationHelper(
      builder,
      ({ externalSourcesData }) => ({
        url: `/client-external-source`,
        method: "POST",
        body: externalSourcesData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setClientExternalSources({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(
          setClientExternalSources({ errorMessage: error?.data?.violations })
        );
      }
    ),
    getClientExternalSources: buildMutationHelper(
      builder,
      (id) => ({
        url: `/client-external-source/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setClientExternalSources({
            externalSourcesData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(
          setClientExternalSources({ errorMessage: error?.data?.error })
        );
      }
    ),
    activeInActiveExternalSources: buildMutationHelper(
      builder,
      ({ externalSourcesData, id }) => ({
        url: `/client-external-source/${id}`,
        method: "PATCH",
        body: JSON.stringify(externalSourcesData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setClientExternalSources({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(
          setClientExternalSources({ errorMessage: error?.data?.error })
        );
      }
    ),
  }),
});
export const {
  useGetClientExternalSourcesListMutation,
  useAddExternalSourcesConnectMutation,
  useAddClientExternalSourcesMutation,
  useGetClientExternalSourcesMutation,
  useActiveInActiveExternalSourcesMutation,
} = clientExternalSourcesApi;
