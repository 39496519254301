import { mergeClasses } from "@fluentui/react-components";
import { t } from "i18next";
import Layout from "../../styles/Layout.styles";
import Style from "./style";
import { FieldDropDown } from "../../components";

const UserDropdown = (props) => {
  const {
    userDataList,
    selectedUserText,
    setSelectedUserText,
    selectedOptions,
    setSelectedOptions,
    setValue,
  } = props;
  const layout = Layout();
  const style = Style();

  const handleChange = (e, data) => {
    setSelectedUserText(data?.optionText);
    setSelectedOptions(data.selectedOptions);
    setValue("users", data?.optionText, { shouldValidate: true });
  };

  return (
    <>
      <FieldDropDown
        validationMessage={props.errors?.users?.message}
        multiselect={false}
        id="users"
        className={mergeClasses(layout.mr_1, style.userDropdown)}
        placeholder={t("licenses.select_user")}
        name="users"
        options={userDataList}
        selectedOptions={selectedOptions}
        onOptionSelect={handleChange}
        value={selectedUserText}
        inputClassName={mergeClasses(style.textboxInput, style.userDropdown)}
        validation={props.register("users", {
          required: `${t("validation_message.please_select_user")}`,
        })}
      />
    </>
  );
};

export default UserDropdown;
