import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  card: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    flexDirection: "column",
    justifyContent: "space-between",
    width: "620px",
    ...shorthands.margin("10px"),
    "@media screen and (max-width: 1025px)": {
      width: "100%",
    },
  },
  projectCard: {
    width: "620px",
    "@media screen and (max-width: 1025px)": {
      width: "100%",
    },
    fontSize: "18px",
    fontWeight: tokens.fontSizeBase600,
    color: tokens.colorBrandBackground2Pressed,
    ...shorthands.margin("10px"),
  },
  cardTitle: {
    height: "50px",
    paddingLeft: "15px",
    color: tokens.colorBrandBackground2Pressed,
    fontSize: "18px",
    fontWeight: tokens.fontSizeBase600,
    justifyContent: "center",
  },
  cardBody: {
    ...shorthands.padding("15px"),
  },
  textCenter: {
    textAlign: "center",
  },
});

export default style;
