import React, { useState } from "react";
import {
  mergeClasses,
  Input,
  DialogTrigger,
  Button,
} from "@fluentui/react-components";
import { staticStyle } from "../Style";
import { Stack } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Layout from "../../../../../styles/Layout.styles";
import CustomDialog from "../../../../../components/FieldComponents/CustomDialog";
import Form from "../../../../../components/FieldComponents/Form";
import FormButton from "../../../../../components/FieldComponents/FormButton";
import FieldsInput from "../../../../../components/FieldComponents/FieldsInput";
import {
  useAddThemeMutation,
  useEditThemeMutation,
  useGetThemeListMutation,
} from "../../../../../store/action/theme";
import { BASE_COLOR } from "../../../../../utils/constants";
import { trailWhiteSpace } from "../../../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../../../utils/errorHandler";

const ThemeManagement = (props) => {
  const { id, editData, isOpenDialog, setIsOpenDialog } = props;
  const [addThemeApi, { isLoading: isAddLoading }] = useAddThemeMutation();
  const [editThemeApi, { isLoading: isEditLoading }] = useEditThemeMutation();
  const [getThemeList] = useGetThemeListMutation();
  const [color, setColor] = useState(editData?.data?.themeCode || BASE_COLOR);
  const layout = Layout();
  const { t } = useTranslation();
  staticStyle();

  const {
    register,
    formState: { errors },
    reset,
    setError,
    handleSubmit,
  } = useForm();

  const onSubmit = (themeInfo) => {
    let trailData = trailWhiteSpace(themeInfo);
    const userThemedata = {
      name: trailData?.name,
      themeCode: color,
    };
    if (id) {
      if (!isEditLoading) {
        editThemeApi({ themeData: userThemedata, id: props?.id }).then(
          (res) => {
            let errorLists = res?.error?.data?.violations;
            if (errorLists) {
              formErrorHandler("", errorLists, setError);
            }
            if (!res?.error) {
              getThemeList();
              setIsOpenDialog(false);
            }
          }
        );
      }
    } else {
      if (!isAddLoading) {
        addThemeApi({ themeData: userThemedata }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res?.error) {
            getThemeList();
            setIsOpenDialog(false);
            reset({ name: "" });
          }
        });
      }
    }
  };
  if (id && !editData.data) {
    return null;
  }
  return (
    <>
      <CustomDialog
        dialogTitle={id ? t("theme_edit") : t("theme_add")}
        isEdit={id ? true : false}
        dialogTitleClass=""
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
        dialogContent={
          <Form className={layout.w_100} onSubmit={handleSubmit(onSubmit)}>
            <Stack className={mergeClasses(layout.w_100)}>
              <Stack className={mergeClasses(layout.textbox, layout.mb_15)}>
                <FieldsInput
                  label={t("theme_nm")}
                  fieldClassName="custom-label"
                  inputType="text"
                  validationMessage={errors?.name?.message}
                  placeholder={t("enter_theme_name")}
                  name="name"
                  id="colorInput"
                  inputDefaultValue={editData?.data?.name}
                  validation={register("name", {
                    required: `${t("validation_message.name_is_required")}`,
                  })}
                />
              </Stack>
            </Stack>
            <Stack>
              <FieldsInput
                label={t("theme_code")}
                validationMessage={errors?.themeCode?.message}
                id="themeCode"
                name="themeCode"
                value={color}
                inputClassName={mergeClasses(layout.mb_15)}
                validation={register("themeCode", {
                  required: `${t("validation_message.name_is_required")}`,
                })}
                contentAfter={
                  <Input
                    id="color-option"
                    type="color"
                    value={color}
                    className="color-option"
                    onChange={(e) => {
                      setColor(e.target.value);
                    }}
                  />
                }
              />
            </Stack>
            <Stack className={mergeClasses(layout.flex_row)}>
              <FormButton
                type="submit"
                size="medium"
                appearance="primary"
                loading={isAddLoading || isEditLoading}
                className={mergeClasses(
                  layout.btnCustom,
                  "custom-primary",
                  layout.mr_1,
                  layout.h_32
                )}
              >
                {id ? t("update") : t("add")}
              </FormButton>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => setIsOpenDialog(false)}
                  className={mergeClasses(
                    layout.btnCustom,
                    "custom-primary",
                    layout.h_32
                  )}
                >
                  {t("cancel")}
                </Button>
              </DialogTrigger>
            </Stack>
          </Form>
        }
        dialogContentClass=""
        dialogClose="Close"
        dialogCloseClass=""
        dialogSubmit="Do Something"
        dialogSubmitClass="Do Something"
        appearance="primary"
      />
    </>
  );
};

export default ThemeManagement;
