import { ReactComponent as CoinBlue } from "./images/blue/coin-blue.svg";
import { ReactComponent as VectorBlue } from "./images/blue/vector-blue.svg";
import { ReactComponent as CartBlue } from "./images/blue/cart-blue.svg";
import { ReactComponent as BoxAddBlue } from "./images/blue/box-add-blue.svg";
import { ReactComponent as PeopleBlue } from "./images/blue/people-blue.svg";
import { ReactComponent as JournalCheckBlue } from "./images/blue/journal-check-blue.svg";
import { ReactComponent as ProductionBlue } from "./images/blue/production-blue.svg";
import { ReactComponent as GraphUpArrowBlue } from "./images/blue/graph-up-arrow-blue.svg";
import { ReactComponent as AssetsBlue } from "./images/blue/assets-blue.svg";
import { ReactComponent as ResouceManagementBlue } from "./images/blue/resouce-management-blue.svg";
import { ReactComponent as AccountBlue } from "./images/blue/account-blue.svg";
import { ReactComponent as ClockBlue } from "./images/blue/clock-blue.svg";
import { ReactComponent as ListCheck } from "./images/blue/list-check-blue.svg";
import { ReactComponent as GearBlue } from "./images/blue/gear-blue.svg";
import { t } from "i18next";
import accessRole from "../Common/accessRole";

const SideMenuRoute = () => {
  let role = accessRole();
  const timeAndAttendanceChild = [
    {
      key: "who-is-working-today",
      name: `${t("sidebar_routes.who_is_working_today")}`,
      url: "/time-and-attandance/who-is-working-today",
      visible: role?.size && role?.has("project_tna_list") ? true : false,
    },
    {
      key: "detailed-timesheet",
      name: `${t("sidebar_routes.detaile_timesheet")}`,
      url: "/time-and-attandance/detailed-timesheet",
      visible: role?.size && role?.has("project_tna_list") ? true : false,
    },
    {
      key: "visitor",
      name: `${t("sidebar_routes.visitor")}`,
      url: "/time-and-attandance/visitor",
      visible: role?.size && role?.has("visitor_list") ? true : false,
    },
  ];
  return [
    {
      key: "home",
      name: `${t("sidebar_routes.opportunities")}`,
      url: "/opportunities",
      visible: false,
      image: <CoinBlue height={24} width={24} />,
    },
    {
      key: "estimation",
      name: `${t("sidebar_routes.estimation")}`,
      url: "/estimation",
      visible: false,
      image: <VectorBlue height={24} width={24} />,
    },
    {
      key: "procurement",
      name: `${t("sidebar_routes.procurement")}`,
      url: "/procurement",
      visible: false,
      image: <CartBlue height={24} width={24} />,
    },
    {
      key: "inventory",
      name: `${t("sidebar_routes.inventory")}`,
      url: "/inventory",
      visible: false,
      image: <BoxAddBlue height={24} width={24} />,
    },
    {
      key: "project_management",
      name: `${t("sidebar_routes.project_management")}`,
      url: "/project_management",
      visible: false,
      image: <PeopleBlue height={24} width={24} />,
    },
    {
      key: "hse",
      name: `${t("sidebar_routes.hse")}`,
      url: "/hse",
      visible: false,
      image: <JournalCheckBlue height={24} width={24} />,
    },
    {
      key: "production",
      name: `${t("sidebar_routes.production")}`,
      url: "/production",
      visible: false,
      image: <ProductionBlue height={24} width={24} />,
    },
    {
      key: "reporting",
      name: `${t("sidebar_routes.reporting")}`,
      url: "/reporting",
      visible: false,
      image: <GraphUpArrowBlue height={24} width={24} />,
    },
    {
      key: "assets",
      name: "Assets",
      url: "/assets",
      visible: false,
      image: <AssetsBlue height={24} width={24} />,
    },
    {
      key: "resource_management",
      name: `${t("sidebar_routes.resource_management")}`,
      url: "/resource_management",
      visible: false,
      image: <ResouceManagementBlue height={24} width={24} />,
    },
    {
      key: "accounts",
      name: `${t("sidebar_routes.accounts")}`,
      url: "/accounts",
      visible: false,
      image: <AccountBlue height={24} width={24} />,
    },
    {
      key: "time",
      name: `${t("sidebar_routes.time_attendance")}`,
      url: "/time-and-attendance",
      visible: role?.size && role?.has("project_tna_list") ? true : false,
      image: <ClockBlue height={24} width={24} />,
      // toggleMenu: true,
      // toggleSection: (
      //   <TimeAndAttendance
      //     children={timeAndAttendanceChild}
      //     image={<ClockBlue height={24} width={24} />}
      //     name={`${t("sidebar_routes.time_attendance")}`}
      //   />
      // ),
      children: timeAndAttendanceChild,
    },
  ];
};

const navLinkBottom = () => {
  let role = accessRole();
  return [
    {
      key: "client",
      name: `${t("sidebar_routes.client")}`,
      url: "/organization-list",
      visible: role?.size && role?.has("client_list") ? true : false,
      image: <PeopleBlue height={24} width={24} />,
    },
    {
      key: "projects",
      name: `${t("sidebar_routes.projects")}`,
      url: "/project-list",
      visible: role?.size && role?.has("project_list") ? true : false,
      image: <ListCheck height={24} width={24} />,
    },
    {
      key: "settings",
      name: `${t("sidebar_routes.settings")}`,
      url: "/settings",
      isDropdown: true,
      visible:
        role?.size &&
        (role?.has("system_user_list") ||
          role?.has("client_theme_list") ||
          role?.has("license_list") ||
          role?.has("billing_list") ||
          role?.has("question_bank_list") ||
          role?.has("system_end_user_list") ||
          role?.has("external_source")),

      image: <GearBlue height={24} width={24} />,
    },
  ];
};
const headerImage = {
  key: "header",
  name: "Home",
  url: "/home",
  image: "/images/sidebar/header.svg",
};

export default SideMenuRoute;
export { navLinkBottom, headerImage };
