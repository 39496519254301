import { createSlice } from "@reduxjs/toolkit";

const project = createSlice({
  name: "project",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    isActive: "true",
    trigger: false,
    pagination: "",
  },

  reducers: {
    setProject(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setProject } = project.actions;
export default project.reducer;
