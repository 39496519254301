import React, { useEffect, useState } from "react";
import { Text, mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import {
  Pagination,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Breadcrumb,
} from "../../../components";
import { useSelector } from "react-redux";
import Layout from "../../../styles/Layout.styles";
import { t } from "i18next";
import { useSystemEndUserListMutation } from "../../../store/action/systemEndUser";
import SystemEndUserFilter from "./SystemEndUserFilter";
import SystemEndUserRow from "./SystemEndUserRow";
import { setSystemEndUser } from "../../../store/reducer/systemEndUser";
import accessRole from "../../../components/Common/accessRole";
import { useClientLicenseListMutation } from "../../../store/action/license";

const SystemEndUserList = (props) => {
  const role = accessRole();
  const layout = Layout();
  const [getSystemEndUserListApi, { isLoading }] =
    useSystemEndUserListMutation();
  const systemEndUserList = useSelector((state) => state.systemEndUser);
  const [initialLoad, setInitialLoad] = useState(true);
  const [usedEndUser, setUsedEndUser] = useState();
  const [unusedEndUser, setUnusedEndUser] = useState();
  const [getClientLicensesList] = useClientLicenseListMutation();

  useEffect(() => {
    getClientLicensesList().then((res) => {
      const usedEndUserCount = res?.data?.data
        ?.filter((i) => i?.expired === false)
        ?.map((item) => {
          return item?.usedEndUser;
        });
      const unusedEndUserCount = res?.data?.data
        ?.filter((i) => i?.expired === false)
        ?.map((item) => {
          return item?.unusedEndUser;
        });

      setUsedEndUser(usedEndUserCount);
      setUnusedEndUser(unusedEndUserCount);
    });
    if (initialLoad) {
      if (!isLoading) {
        getSystemEndUserListApi();
        setInitialLoad(false);
      }
    }
  }, [
    initialLoad,
    getClientLicensesList,
    getSystemEndUserListApi,
    isLoading,
    systemEndUserList,
  ]);

  const columns = [
    { columnKey: "firstName", label: t("users.name"), isSort: true },
    { columnKey: "email", label: t("users.email"), isSort: true },
    { columnKey: "action", label: t("action") },
    {
      columnKey: "activeInactive",
      label: t("activeInactive"),
    },
  ];

  const breadcrumbData = [
    {
      title: t("system_end_user"),
      url: "/system-end-user-list",
    },
    {
      title: t("system_end_user_list"),
    },
  ];
  return (
    <>
      <Stack>
        <Breadcrumb data={breadcrumbData} />
        {/* <Stack
          className={mergeClasses(
            layout.d_flex,
            layout.flex_row,
            layout.alignCenter
          )}
        >
          {role?.has("end_user_license_count") && (
            <Stack className={mergeClasses(layout.size15, layout.flex_row)}>
              {t("enduser.available_end_user_licenses")} :{" "}
              <span className={mergeClasses(layout.fontWeight)}>
                {" "}
                &nbsp;
                {unusedEndUser?.reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )}{" "}
                &nbsp;
              </span>
              | {t("enduser.assigned_end_users")} :
              <span className={mergeClasses(layout.fontWeight)}>
                &nbsp;
                {usedEndUser?.reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )}{" "}
                &nbsp;
              </span>
            </Stack>
          )}
        </Stack> */}
      </Stack>

      <Stack className={mergeClasses(layout.projectContent, "cardShadow")}>
        <SystemEndUserFilter
          setInitialLoad={setInitialLoad}
          getSystemEndUserListApi={getSystemEndUserListApi}
          role={role}
        />
        <Stack className="contentData">
          <Stack className="overflow-y-auto">
            <Table className="tbl-layout-auto">
              <TableHeader className={layout.headerBackground}>
                <TableRow>
                  {columns.map((column, key) => (
                    <TableHeaderCell
                      key={key}
                      onSortApi={getSystemEndUserListApi}
                      onSet={setSystemEndUser}
                      column={column}
                      sortBy={
                        column?.isSort ? systemEndUserList?.sortBy : false
                      }
                      className={
                        role?.has("system_end_user_list") &&
                        role?.has("system_end_user_edit")
                          ? ""
                          : "hideAction"
                      }
                    >
                      <Text className={layout.headerLabel}>{column.label}</Text>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody isLoading={isLoading}>
                {systemEndUserList?.data?.map((item, key) => (
                  <SystemEndUserRow key={key} item={item} role={role} />
                ))}
              </TableBody>
            </Table>
          </Stack>

          <Pagination
            totalCount={systemEndUserList?.totalCount}
            itemsPerPage={systemEndUserList?.itemsPerPage}
            onSetListReducer={setSystemEndUser}
            onGetList={getSystemEndUserListApi}
            page={systemEndUserList?.page}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default SystemEndUserList;
