import { createSlice } from "@reduxjs/toolkit";

const timeAndAttendance = createSlice({
  name: "timeAndAttendance",
  initialState: {
    data: [],
    errorMessage: "",
    employeeDetails: {},
    updateLogs: false,
    exportData: [],
    headerData: [],
    pendingFilterAndPagination: {
      page: 0,
      itemsPerPage: 10,
    },
    approvedFilterAndPagination: {
      page: 0,
      itemsPerPage: 10,
    },
    rejectFilterAndPagination: {
      page: 0,
      itemsPerPage: 10,
    },
  },
  reducers: {
    setDetailedTimesheet(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setTimeAndAttendance(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setTimeAndAttendancePending(state, action) {
      return {
        ...state,
        pendingFilterAndPagination: {
          ...state.pendingFilterAndPagination,
          ...action.payload,
        },
      };
    },
    setTimeAndAttendanceReject(state, action) {
      return {
        ...state,
        rejectFilterAndPagination: {
          ...state.rejectFilterAndPagination,
          ...action.payload,
        },
      };
    },
    setTimeAndAttendanceApproved(state, action) {
      return {
        ...state,
        approvedFilterAndPagination: {
          ...state.approvedFilterAndPagination,
          ...action.payload,
        },
      };
    },
  },
});

export const {
  setDetailedTimesheet,
  setTimeAndAttendance,
  setTimeAndAttendanceApproved,
  setTimeAndAttendanceReject,
  setTimeAndAttendancePending,
} = timeAndAttendance.actions;
export default timeAndAttendance.reducer;
