import { makeStyles, shorthands } from "@fluentui/react-components";
import { tokens } from "@fluentui/react-theme";

const Sidebar = makeStyles({
  sidebarBtn: {
    backgroundImage: `url('/images/sidebar.png')`,
    backgroundRepeat: "no-repeat",
    height: "30px",
    width: "30px",
    minWidth: "30px",
    top: "113px",
    position: "absolute",
    "&.isOpen": {
      left: "235px",
      zIndex: "9999",
      "@media screen and (max-width: 600px)": {
        left: "235px",
      },
    },
    "&.isClose": {
      left: "75px",
      zIndex: "9999",
      "-webkit-transform": "rotateY(180deg)",
      transform: "rotateY(180deg)",
    },
    "@media screen and (max-width: 600px)": {
      top: "166px",
    },
    "@media screen and (max-width: 767px)": {
      "&.isClose": {
        left: "0",
      },
    },
  },
  sidebarUl: {
    ...shorthands.padding("0px"),
    ...shorthands.margin("0px"),
  },
  sidebarList: {
    listStyleType: "none",
    ...shorthands.padding("10px"),
  },
  sidebarDropdown: {
    fontSize: "15px",
    fontWeight: tokens.fontWeightRegular,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  sidebarLink: {
    fontSize: "15px",
    fontWeight: tokens.fontWeightRegular,
    display: "flex",
    ...shorthands.margin(0, 0, "10px", 0),
  },
  navbar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  sidebarImage: {
    ...shorthands.margin(0, "10px", 0, 0),
  },
  headerLogo: {
    ...shorthands.margin(0, "15px", 0, "10px"),
  },
  drawer: {
    width: "250px",
    minWidth: "250px",
  },
  drawerHeader: {
    ...shorthands.padding(0),
    ...shorthands.margin("24px", "20px", "24px", "20px"),
  },
  headerDrawer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sidebarBottomMenu: {
    "@media screen and (min-width: 768px)": {
      position: "absolute",
      bottom: "10px",
    },
  },
  headerNav: {
    "@media screen and (min-width: 768px)": {
      height: "calc( 100vh - 255px )",
      ...shorthands.overflow("hidden", "auto"),
    },
  },
  headerNavTwo: {
    "@media screen and (min-width: 768px)": {
      height: "calc( 100vh - 195px )",
      ...shorthands.overflow("hidden", "auto"),
    },
  },
  sidebarLinks: {
    fontSize: "15px",
    fontWeight: tokens.fontWeightRegular,
  },
});

export default Sidebar;
