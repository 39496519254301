import { createSlice } from "@reduxjs/toolkit";

const users = createSlice({
  name: "users",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    isActive: "true",
  },
  reducers: {
    setUsers(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setUsers } = users.actions;
export default users.reducer;
