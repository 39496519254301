import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  loginCard: {
    flexDirection: "row",
  },
  loginLeftTitle: {
    color: tokens.colorNeutralForegroundStaticInverted,
    fontSize: "60px",
    lineHeight: tokens.lineHeightHero900,
    display: "inline-block",
    textAlign: "center",
    ...shorthands.margin(0, 0, "46px", 0),
    "@media screen and (max-width: 660px)": {
      fontSize: "40px",
    },
  },
  loginLeftSubTitle: {
    color: tokens.colorNeutralForegroundStaticInverted,
    fontSize: "15px",
    lineHeight: "24px",
    textAlign: "center",
    display: "inline-block",
    ...shorthands.margin("0", "20px"),
    "@media screen and (min-width: 660px)": {
      maxWidth: "721px",
    },
  },
  loginLeft: {
    "@media screen and (max-width: 600px)": {
      ...shorthands.padding("40px"),
    },
  },
  loginRightTitle: {
    fontSize: "22px",
    fontWeight: tokens.fontWeightRegular,
    color: tokens.colorBrandBackgroundPressed,
    textAlign: "center",
    ...shorthands.margin(0, 0, "34px", 0),
  },
  loginRightContent: {
    "@media screen and (min-width: 660px)": {
      width: "446px",
      ...shorthands.margin("auto"),
    },
    "@media screen and (max-width: 559px)": {
      ...shorthands.margin("20px"),
    },
  },
  customCheckbox: {
    ...shorthands.margin("12px", 0, "25px", 0),
  },
  loginMain: {
    width: "100%",
    position: "relative",
    "@media screen and (min-width: 992px)": {
      width: "50%",
    },
    "@media screen and (min-width: 600px) and (max-width: 991px)": {
      minHeight: "500px",
    },
    ...shorthands.margin("0", "auto"),
  },
  loginLeftImage: {
    backgroundColor: tokens.colorBrandBackgroundPressed,
    backgroundImage: `url('/images/login.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    zIndex: 1,
  },
  loginPage: {
    display: "flex",
    "@media screen and (min-width: 992px)": {
      flexDirection: "row",
      height: "100vh",
    },
  },
  loginLeftSec: {
    fontWeight: tokens.fontWeightBold,
    ...shorthands.margin(0, 0, 0, "15px"),
  },
  mb_14: {
    ...shorthands.margin(0, 0, "14px", 0),
  },
  mb_27: {
    ...shorthands.margin(0, 0, "27px", 0),
  },
  mb_30: {
    ...shorthands.margin(0, 0, "30px", 0),
  },
  backToLogin: {
    "@media screen and (max-width: 992px)": {
      ...shorthands.margin("50px", "auto", "auto", "auto"),
    },
    "@media screen and (min-width: 992px)": {
      bottom: "60.5px",
      position: "absolute",
      left: "0",
      right: "0",
    },
  },
  figAlignDiv: {
    "@media screen and (min-width: 1920px)": {
      ...shorthands.margin("-132px", "", "auto", "5px"),
    },
  },
  backToLoginMain: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.margin("auto"),
  },
  backToLoginText: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightRegular,
    ...shorthands.margin(0, 0, 0, "15px"),
  },
  rememberMe: {
    ...shorthands.margin("", "", "", "-8px"),
  },
});

export default style;
