import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import {
  Button,
  mergeClasses,
  Text,
  Image,
  Spinner,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
} from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { PASSCODE_VALIDATION } from "../../../../utils/constants";
import { useState } from "react";
import Layout from "../../../../styles/Layout.styles";
import close from "../../../../images/close.svg";
import { useSystemEndUserResetPasscodeMutation } from "../../../../store/action/systemEndUser";

const SystemUserRegeneratePassCode = (props) => {
  const { systemUserPassCode, isEditDisable, setValue, id } = props;
  const style = Style();
  const { t } = useTranslation();
  const layout = Layout();
  const [rendomPassWord, setRendomPassWord] = useState("");
  const [endUserResetPasscode, { isLoading: isAddLoading }] =
    useSystemEndUserResetPasscodeMutation();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const rendomPasswordGenerate = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters?.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    setRendomPassWord(result);
    setValue("systemUserPassCode", result, { shouldValidate: true });
    return result;
  };

  const notify = (message, valid) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`${message}`}</ToastTitle>
      </Toast>,
      { intent: valid ? "success" : "error" }
    );
  };

  const [logDetailsId, setLogDetailsId] = useState(false);
  const [isEmployeeDetails, setIsEmployeeDetails] = useState(false);

  const reGeneratePassword = (length) => {
    let rendomPassword = rendomPasswordGenerate(length);

    let password = {
      passcode: rendomPassword,
    };

    endUserResetPasscode({ systemEndUserData: password, id: id }).then(
      (res) => {
        if (res?.error) {
          notify(res?.error?.data?.title, false);
        } else {
          setLogDetailsId(rendomPassword);
          setIsEmployeeDetails(true);
        }
      }
    );

    return rendomPassword;
  };
  return (
    <>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <Stack className={style.client_field}>
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.gap_15,
            layout.alignItemEnd
          )}
        >
          <FieldsInput
            label={t("passcode")}
            validationMessage={props.errors?.systemUserPassCode?.message}
            id="systemUserPassCode"
            value={rendomPassWord ? "******" : ""}
            placeholder={isEditDisable ? "******" : t("generate_passcode")}
            name="systemUserPassCode"
            className={mergeClasses(layout.w_100, "pointer-none")}
            disabled={isEditDisable}
            inputDefaultValue={systemUserPassCode}
            inputClassName={mergeClasses(
              style.textboxInput,
              layout.min_w_100,
              "pointer-none"
            )}
            validation={
              !isEditDisable
                ? props.register("systemUserPassCode", {
                    required: `${t("validation_message.passcode_is_required")}`,
                    pattern: {
                      value: PASSCODE_VALIDATION,
                      message: `${t(
                        "validation_message.passcode_value_enter_six_digit"
                      )}`,
                    },
                  })
                : ""
            }
          />

          <Stack>
            {isEditDisable ? (
              isAddLoading ? (
                <Stack className={layout.tableIcon}>
                  <Spinner className={mergeClasses(layout.m_auto)} />
                </Stack>
              ) : (
                <Button
                  onClick={() => reGeneratePassword(6)}
                  appearance="primary"
                  className={mergeClasses(layout.h_40, layout.textNowrap)}
                >
                  {t("re_generate")}
                </Button>
              )
            ) : (
              <Button
                onClick={() => rendomPasswordGenerate(6)}
                appearance="primary"
                className={layout.h_40}
                disabled={isEditDisable}
              >
                {t("generate_btn")}
              </Button>
            )}

            {props?.errors?.systemUserPassCode?.message && (
              <Text className={layout.mb_1_8}> </Text>
            )}
          </Stack>
        </Stack>

        {isEmployeeDetails && logDetailsId && (
          <Stack
            className={mergeClasses(
              layout.modalBackground,
              isEmployeeDetails && "detailsOpen"
            )}
          >
            <Stack
              className={mergeClasses(
                layout.cardPopup,
                "cardShadow",
                "sidebarDetails",
                layout.mt_0
              )}
            >
              <h2
                className={mergeClasses(
                  layout.primary,
                  layout.d_flex,
                  "fontWeightRegular",
                  layout.alignItemsStart
                )}
              >
                Save this Regenerate passcode, it's visible only once.
                <Button
                  appearance="subtle"
                  onClick={() => setIsEmployeeDetails(false)}
                  aria-label="close"
                  icon={<Image alt="" src={close} height={20} width={20} />}
                />
              </h2>

              <h1>{rendomPassWord}</h1>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default SystemUserRegeneratePassCode;
