import {
  Toast,
  ToastTitle,
  Toaster,
  mergeClasses,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { useStyle } from "../Style";
import { useTranslation } from "react-i18next";
import Layout from "../../../styles/Layout.styles";
import FieldMenuButton from "../../../components/FieldComponents/FieldMenuButton";
import {
  useProjectApproveMutation,
  useProjectRejectMutation,
} from "../../../store/action/time-and-attendance";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import TnAExport from "./TnAExport";
import { useGetPendingDataMutation } from "../../../store/action/time-and-attendance/time-and-attendancePending";
import { setTimeAndAttendancePending } from "../../../store/reducer/time-and-attendance/time-and-attendancePending";
import DateFilterMenu from "./DateFilterMenu";
import { Stack } from "@fluentui/react";

const TimeSheetActions = ({
  setProjectSelectedId,
  projectSelectedId,
  assignedProjectId,
  selectedValue,
  setIsEmployeeDetailsOpen,
}) => {
  const { t } = useTranslation();
  const style = useStyle();
  const layout = Layout();
  const [projectApproveData] = useProjectApproveMutation();
  const [projectRejectData] = useProjectRejectMutation();
  const [getPendingDataList] = useGetPendingDataMutation();
  const [open, setOpen] = useState(false);
  const timeAndAttendanceList = useSelector(
    (state) => state.timeAndAttendancePending
  );
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useDispatch();

  const getData = async () => {
    await getPendingDataList({
      projectId: assignedProjectId,
    });
  };

  const onClick = (e) => {
    if (projectSelectedId?.length !== 0 && assignedProjectId) {
      const tnaData = {
        approveProjectTnas: projectSelectedId,
      };

      projectApproveData({ tnaData: tnaData }).then((res) => {
        dispatch(
          setTimeAndAttendancePending({
            page:
              timeAndAttendanceList?.data?.length === 1 &&
              timeAndAttendanceList?.page > 0
                ? timeAndAttendanceList?.page - 1
                : timeAndAttendanceList?.page,
            itemsPerPage: timeAndAttendanceList?.itemsPerPage,
          })
        );
        getData().then((res) => {
          setProjectSelectedId([]);
          setOpen(false);
          setMenuOpen(false);
        });
      });
    }
  };

  const notify = () => {
    dispatchToast(
      <Toast>
        <ToastTitle>
          {projectSelectedId?.length === 0
            ? t("please_select_employee")
            : t("reject")}
        </ToastTitle>
      </Toast>,
      { intent: projectSelectedId?.length === 0 ? "error" : "success" }
    );
  };

  const handleSubmit = (value) => {
    notify();
    if (projectSelectedId?.length !== 0) {
      const tnaData = {
        rejectProjectTnas: projectSelectedId,
        rejectReasonMessage: value?.reason,
      };
      projectRejectData({ tnaData: tnaData }).then((res) => {
        dispatch(
          setTimeAndAttendancePending({
            page:
              timeAndAttendanceList?.data?.length === 1 &&
              timeAndAttendanceList?.page > 0
                ? timeAndAttendanceList?.page - 1
                : timeAndAttendanceList?.page,
            itemsPerPage: timeAndAttendanceList?.itemsPerPage,
          })
        );
        getData().then((res) => {
          setOpen(false);
          setProjectSelectedId([]);
          setMenuOpen(false);
        });
      });
    }
  };
  return (
    <div
      className={mergeClasses(
        style.btnWrapper,
        layout.alignTop,
        style.timesheetActions,
        "timesheetActions"
      )}
    >
      <DateFilterMenu
        assignedProjectId={assignedProjectId}
        selectedValue={selectedValue}
        setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
      />
      {assignedProjectId && (
        <TnAExport
          assignedProjectId={assignedProjectId}
          selectedValue={selectedValue}
        />
      )}

      <Toaster position="top" timeout={2000} toasterId={toasterId} />
      {selectedValue === "review" && (
        <FieldMenuButton
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          onApprove={onClick}
          label={t("action")}
          appearance="primary"
          className={mergeClasses(
            layout.smallBtn,
            layout.alignCenter,
            layout.mb_1,
            "custom-primary"
          )}
          onSubmit={handleSubmit}
          setOpen={setOpen}
          open={open}
          isToastMessage={projectSelectedId?.length !== 0}
          successMessage={t("approve")}
          rejectMessage={t("please_select_employee")}
        />
      )}
    </div>
  );
};

export default TimeSheetActions;
