import { Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Form, FormButton, Breadcrumb } from "../../../components";
import { useForm } from "react-hook-form";
import Layout from "../../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../Client/ClientManagement/AddClient.style";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import UserPhoneNumber from "./Form/UserPhoneNumber";
import UserSetPassword from "./Form/UserSetPassword";
import UserPasscodeGeneration from "./Form/UserPasscodeGeneration";
import UserCity from "./Form/UserCity";
import UserCountry from "./Form/UserCountry";
import UserAddress from "./Form/UserAddress";
import UserTimeZone from "./Form/UserTimeZone";
import UserState from "./Form/UserState";
import StaticStyle from "../../Client/ClientManagement/Style";
import UserFirstName from "./Form/UserFirstName";
import UserLastName from "./Form/UserLastName";
import UserRole from "./Form/UserRole";
import UserPhotoUpload from "./Form/UserPhotoUpload";
import UserEmailAddress from "./Form/UserEmailAddress";
import {
  useEditUsersMutation,
  useGetUsersMutation,
  useGetUsersPhotoMutation,
  useUploadAddUsersPhotoMutation,
  useUserAddMutation,
  useUsersListMutation,
} from "../../../store/action/users";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trailWhiteSpace } from "../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../utils/errorHandler";
import UserClient from "./Form/UserClient";
import accessRole from "../../../components/Common/accessRole";
import { setUsers } from "../../../store/reducer/users";

const UsersForm = (props) => {
  const { id } = props;

  const navigate = useNavigate();
  const layout = Layout();
  const style = Style();
  const [userEditData, setUserEditData] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [timeZoneSelectListData, setTimeZoneSelectListData] = useState();
  const [addUsersApi, { isLoading: isAddLoading }] = useUserAddMutation();
  const [getUserstApi, { isLoading: isGetLoading }] = useGetUsersMutation();
  const [editUsersApi, { isLoading: isEditLoading }] = useEditUsersMutation();
  const [addUploadUsersPhoto, { isLoading: isPhotoEditLoading }] =
    useUploadAddUsersPhotoMutation();
  const [getUsersPhotoApi] = useGetUsersPhotoMutation();
  //eslint-disable-next-line no-unused-vars
  const [getUsersListApi] = useUsersListMutation();
  const [isDeletePhoto, setIsDeletePhoto] = useState(false);
  const [getUsersPhoto, setGetUsersPhoto] = useState();
  const [isUsersPhoto, setIsUsersPhoto] = useState();
  const [isEditDisable, setIsEditDisable] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [userClient, setUserClient] = useState();

  const userForm = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  StaticStyle();

  const breadcrumbData = [
    {
      title: `${t("users_text")}`,
      url: "/users-list",
    },
    {
      title: id ? `${t("edit_users")}` : `${t("add_users")}`,
    },
  ];

  useEffect(() => {
    localStorage.setItem("edit_profile", false);
    if (initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getUserstApi(id).then((res) => {
            setUserEditData(res);
            setIsEditDisable(true);
          });
          getUsersPhotoApi(id).then((res) => {
            setGetUsersPhoto(res?.data?.imageUrl);
          });
        }
      }
      setInitialLoad(false);
    }
  }, [initialLoad, isGetLoading, getUserstApi, id, getUsersPhotoApi]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    resetField,
  } = useForm();
  const onSubmit = (value) => {
    let formData = trailWhiteSpace(value);

    const newUserData = {
      email: formData?.userEmailAddress,
      firstName: formData?.userFirstName,
      lastName: formData?.userLastName,
      phonePrefix: formData?.userPhonePrefix,
      phoneNumber: formData?.userPhoneNumber,
      roles:
        localStorage.edit_profile === "true" &&
        !userEditData?.data?.roles.includes("ROLE_CLIENT_ADMIN")
          ? userEditData?.data?.roles
          : userEditData?.data?.roles.includes("ROLE_TNA_LICENSED_USER")
          ? [...selectedOptions, "ROLE_TNA_LICENSED_USER"]
          : selectedOptions,
      password: formData?.userSetPassWord,
      passcode: formData?.userPassCode,
      address: formData?.userAddress,
      city: formData?.userCity,
      state: formData?.userState,
      country: formData?.userCountry,
      timezone: formData?.userTimeZone,
      client: accessRole()?.has("assign_organization")
        ? `/client/${userClient ? userClient : userEditData?.data?.client?.id}`
        : undefined,
      profileImageDeleted: isDeletePhoto,
    };
    if (id) {
      if (!isEditLoading) {
        editUsersApi({ usersData: newUserData, id: id }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("user", errorLists, setError, true);
          }

          let formData = new FormData();
          if (isUsersPhoto && !isPhotoEditLoading) {
            formData.append("fileUpload", isUsersPhoto);
            addUploadUsersPhoto({ formData, id }).then((resLogo) => {
              if (!errorLists) {
                if (!accessRole()?.has("system_user_list")) {
                  localStorage.setItem("edit_profile", false);
                  navigate("/project-list");
                } else {
                  localStorage.setItem("edit_profile", false);
                  navigate("/users-list");
                }
                localStorage.setItem("edit_profile", false);
              }
            });
          } else {
            if (!res.error) {
              if (!accessRole()?.has("system_user_list")) {
                localStorage.setItem("edit_profile", false);
                navigate("/project-list");
              } else {
                localStorage.setItem("edit_profile", false);
                navigate("/users-list");
              }
            }
          }
        });
      }
    } else {
      if (!isAddLoading) {
        addUsersApi({ usersData: newUserData }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("user", errorLists, setError, true);
          }

          const id = res?.data?.id;
          let formData = new FormData();

          if (isUsersPhoto && !isPhotoEditLoading) {
            formData.append("fileUpload", isUsersPhoto);
            addUploadUsersPhoto({ formData, id }).then((resLogo) => {
              if (!errorLists) {
                navigate("/users-list");
              }
            });
          } else {
            if (!res.error) {
              localStorage.setItem("edit_profile", false);
              navigate("/users-list");
              dispatch(setUsers({ page: 0, isActive: "true" }));
            }
          }
        });
      }
    }
  };

  if (id && !userEditData?.data) {
    return null;
  }

  const commonProps = { register, errors, setValue, resetField };

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack>
        <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <Stack
            className={mergeClasses(
              style.clientContent,
              "clientContent",
              layout.borderRadiusMedium
            )}
          >
            <UserFirstName
              {...commonProps}
              validationMessage={
                userForm?.errorMessage?.firstName &&
                userForm?.errorMessage?.firstName?.validationMessage
              }
              userFirstName={userEditData?.data?.firstName}
            />
            <UserLastName
              {...commonProps}
              userLastName={userEditData?.data?.lastName}
            />
            <UserPhoneNumber
              {...commonProps}
              userPhoneNumber={userEditData?.data?.phoneNumber}
              userPhonePrefix={userEditData?.data?.phonePrefix}
            />

            <UserEmailAddress
              {...commonProps}
              isEditDisable={isEditDisable}
              userEmailAddress={userEditData?.data?.email}
            />
            <UserPasscodeGeneration
              {...commonProps}
              isEditDisable={isEditDisable}
              userPassCode={userEditData?.data?.passcode}
            />
            <UserSetPassword
              {...commonProps}
              userSetPassword={userEditData?.data?.password}
              isEditDisable={isEditDisable}
            />
            {localStorage.edit_profile === "true" &&
            !userEditData?.data?.roles.includes("ROLE_CLIENT_ADMIN") ? (
              ""
            ) : (
              <UserRole
                {...commonProps}
                userRoles={userEditData?.data?.roles}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
                disableSingle={false}
              />
            )}

            {accessRole()?.has("assign_organization") && (
              <UserClient
                {...commonProps}
                userClientName={userEditData?.data?.client}
                setUserClient={setUserClient}
              />
            )}

            <UserAddress
              {...commonProps}
              userAddress={userEditData?.data?.address}
            />
            <UserCountry
              {...commonProps}
              userCountry={userEditData?.data?.country}
              setTimeZoneSelectListData={setTimeZoneSelectListData}
            />
            <UserState {...commonProps} userState={userEditData?.data?.state} />
            <UserCity {...commonProps} userCity={userEditData?.data?.city} />

            <UserTimeZone
              {...commonProps}
              userTimeZone={userEditData?.data?.timezone}
              timeZoneSelectListData={timeZoneSelectListData}
            />

            <UserPhotoUpload
              {...commonProps}
              validation={register("fileUpload")}
              setFileCallback={setIsUsersPhoto}
              userPhoto={getUsersPhoto}
              setIsDeletePhoto={setIsDeletePhoto}
            />

            <Stack className={style.formfooter}>
              <FormButton
                type="submit"
                appearance="primary"
                loading={isEditLoading || isAddLoading || isPhotoEditLoading}
                className={mergeClasses(style.customBtn, "custom-primary")}
              >
                {t("save")}
              </FormButton>
              <Link
                to="/users-list"
                appearance="primary"
                className={mergeClasses(style.customBtn)}
              >
                <FormButton className={layout.min150}>{t("cancel")}</FormButton>
              </Link>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </>
  );
};

export default UsersForm;
