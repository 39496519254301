import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import Layout from "../../../../styles/Layout.styles";
import FieldRadioButton from "../../../../components/FieldComponents/FieldRadioButton";

const QuestionType = (props) => {
  const style = Style();
  const layout = Layout();
  const { t } = useTranslation();

  // const onradioCheck = (data) => {    -- don't remove
  //   setRadioFieldCheck(data?.value);
  //   setValue("questionType", data?.value, { shouldValidate: true });
  // };

  const options = [{ value: "checkbox", label: "Checkbox" }];

  return (
    <>
      <Stack className={mergeClasses(layout.w_100, layout.mb_15)}>
        <FieldRadioButton
          label={t("type")}
          fieldClassName="custom-label"
          validationMessage={props.errors?.questionType?.message}
          id="questionType"
          name="questionType"
          radioValue={options}
          defaultValue={"checkbox"}
          inputClassName={mergeClasses(style.textboxInput, layout.w_100)}
          // onChange={(_, data) => onradioCheck(data)}   -- don't remove
          validation={
            // questionType !== "checkbox" ?    -- don't remove
            props.register("questionType")
          }
        />
      </Stack>
    </>
  );
};

export default QuestionType;
