export const defaultFromState = (stateParams, args) => {
  Object.keys(stateParams).forEach((key) => {
    if (typeof args?.params[key] !== "undefined") {
      stateParams[key] = args?.params[key];
    } else if (typeof stateParams[key] === "undefined") {
      delete stateParams[key];
    }
  });
  const {
    search: searchParam,
    pagination: paginationParam,
    sortBy: sortByParam,
    sortType: sortTypeParam,
    createdAtAfter,
    createdAtBefore,
    question: questionParam,
    projectId,
    locationId,
    projectLocationId,
    client,
    visitorClient,
    project,
    status,
    checkInDate,
    checkOutDate,
    withoutPage,
    module,
    ...finalParams
  } = stateParams;

  if (sortByParam && sortTypeParam) {
    finalParams[`order[${sortByParam}]`] = sortTypeParam;
  }
  if (searchParam) {
    finalParams["search"] = searchParam;
  }
  if (questionParam) {
    finalParams["question"] = questionParam;
  }

  if (paginationParam === false) {
    finalParams["pagination"] = paginationParam;
  }

  finalParams["page"] = withoutPage ? undefined : finalParams.page + 1;
  finalParams["projectTnaDetails.projectLocation.project"] = projectId;
  finalParams["projectLocation.project"] = projectLocationId;
  finalParams["projectLocation"] = locationId;
  finalParams["project"] = project;
  finalParams["checkinDate[after]"] = checkInDate;
  finalParams["checkoutDate[ before ]"] = checkOutDate;
  finalParams["createdAt[after]"] = createdAtAfter;
  finalParams["createdAt[before]"] = createdAtBefore;
  finalParams["status"] = status;
  finalParams["client"] = client;
  finalParams["module"] = module;
  finalParams["projectLocation.project.client"] = visitorClient;

  return { ...args, params: finalParams };
};

export const isActiveReload = (newDataArray, newData, data) => {
  newData.map((element) => {
    if (element.id === data.id) {
      newDataArray.push({ ...element, isActive: true });
    } else {
      newDataArray.push({ ...element, isActive: false });
    }
    return "";
  });
};
