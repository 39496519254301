import { useForm } from "react-hook-form";
import Layout from "../../styles/Layout.styles";
import { Stack } from "@fluentui/react";
import { Form, FormButton } from "../../components";
import {
  mergeClasses,
  Toast,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";
import Style from "../ExternalSources/ExternalSource.style";
import Business from "./Form/Business";
import Location from "./Form/Location";
import { useState } from "react";
import { useAddProjectDepartmentMutation } from "../../store/action/projectDepartment";
import { useEffect } from "react";
import { useGetProjectMutation } from "../../store/action/project";
import { t } from "i18next";

const BusinessLocation = (props) => {
  const { projectId } = props;

  const layout = Layout();
  const style = Style();
  const [viewData, setViewData] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState();
  const [selectedBusinessId, setSelectedBusinessId] = useState();
  const [locationDataList, setLocationDataList] = useState([]);
  const [businessDataList, setBusinessDataList] = useState([]);
  const [locationText, setLocationText] = useState();

  const [AddProjectDepartmentApi, { isLoading: appDepartmentLoading }] =
    useAddProjectDepartmentMutation();
  const [GetProjectApi, { isLoading: getProjectViewLoading }] =
    useGetProjectMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const commonProps = { register, errors, setValue };
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  useEffect(() => {
    if (projectId && !getProjectViewLoading) {
      GetProjectApi(projectId).then((res) => {
        if (res?.data?.projectDepartments?.length > 0) {
          setViewData(res?.data?.projectDepartments[0]);
          setSelectedLocationId(
            res?.data?.projectDepartments[0]?.externalLocationRef
          );
          setLocationText(
            res?.data?.projectDepartments[0]?.externalLocationName
          );
          setSelectedBusinessId(
            res?.data?.projectDepartments[0]?.clientDepartment?.id
          );
        } else {
          setViewData(null);
        }
      });
    }
  }, [GetProjectApi]);

  const notify = (message, valid) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`${message}`}</ToastTitle>
      </Toast>,
      { intent: valid ? "success" : "error" }
    );
  };

  const onSubmit = (value) => {
    const data = {
      project: `/project/${projectId}`,
      clientDepartment: `/client_departments/${selectedBusinessId}`,
      externalLocationRef: selectedLocationId,
      externalLocationName: value?.location,
    };
    if (!appDepartmentLoading) {
      AddProjectDepartmentApi({ projectDepartmentData: data }).then((res) => {
        if (!res?.error) {
          if (viewData === null) {
            notify(t("added"), true);
          } else {
            notify(t("update"), true);
          }
        } else {
          notify(t("not_update"), false);
        }
      });
    }
  };

  if (viewData === undefined) {
    return "";
  }

  return (
    <>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <Stack className={mergeClasses(layout.alignCenter)}>
        <Stack className={mergeClasses(style.ExternalSourceContent)}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Business
              {...commonProps}
              setLocationDataList={setLocationDataList}
              setSelectedBusinessId={setSelectedBusinessId}
              business={viewData}
              setBusinessDataList={setBusinessDataList}
              businessDataList={businessDataList}
              setLocationText={setLocationText}
            />
            <Location
              {...commonProps}
              locationDataList={locationDataList}
              setSelectedLocationId={setSelectedLocationId}
              location={viewData}
              setLocationDataList={setLocationDataList}
              selectedBusinessId={selectedBusinessId}
              setLocationText={setLocationText}
              locationText={locationText}
            />
            <Stack className={style.formfooter}>
              <FormButton
                type="submit"
                appearance="primary"
                className={mergeClasses(style.customBtn, "custom-primary")}
                loading={appDepartmentLoading}
              >
                Submit
              </FormButton>
            </Stack>
          </Form>
        </Stack>
      </Stack>
    </>
  );
};

export default BusinessLocation;
