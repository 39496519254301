import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { urlBuilder } from "../../utils/urlBuilder";
import { setSystemEndUser } from "../reducer/systemEndUser";
import { defaultFromState, isActiveReload } from "../../utils/defaultFromState";

export const systemEndUserApi = createApi({
  reducerPath: "systemEndUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    systemEndUserList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        search = undefined,
        sortBy = undefined,
        sortType = undefined,
        isActive = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/system-end-users",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            search: search,
            sortBy,
            sortType,
            isActive: isActive,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              systemEndUser: {
                page,
                itemsPerPage,
                search,
                sortBy,
                sortType,
                isActive,
                client,
              },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              search,
              sortBy,
              sortType,
              isActive,
              client,
            };

            return defaultFromState(stateParams, args);
          },
        };
      },
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setSystemEndUser({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    systemEndUserAdd: buildMutationHelper(
      builder,
      ({ systemEndUserData }) => ({
        url: `/system-end-user`,
        method: "POST",
        body: systemEndUserData,
        headers: getHeaders({ noAuth: false }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setSystemEndUser({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(
          setSystemEndUser({
            errorMessage: error?.data?.violations,
          })
        );
      }
    ),
    getSystemEndUser: buildMutationHelper(
      builder,
      (id) => ({
        url: `/system-end-user/${id}`,
        method: "GET",
        headers: getHeaders({ noAuth: false }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setSystemEndUser({
            userData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    editSystemEndUser: buildMutationHelper(
      builder,
      ({ systemEndUserData, id }) => ({
        url: `/system-end-user/${id}`,
        method: "PATCH",
        body: JSON.stringify(systemEndUserData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.totalItems;
        dispatch(
          setSystemEndUser({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.violations }));
      }
    ),
    getSystemEndUserPhoto: buildMutationHelper(
      builder,
      (id) => ({
        url: `/system-end-user-image/${id}`,
        method: "GET",
        headers: getHeaders({ noAuth: false }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setSystemEndUser({
            systemEndUserData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    uploadSystemEndUserPhoto: buildMutationHelper(
      builder,
      ({ formData, id }) => ({
        url: `/system-end-user-image/${id}`,
        method: "POST",
        body: formData,
        headers: getHeaders({
          noAuth: false,
          onlyFileUpload: true,
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;

        dispatch(setSystemEndUser({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    getSystemEndUserList: buildMutationHelper(
      builder,
      ({ projectId = undefined } = {}) => ({
        url: urlBuilder(`/project-client-system-end-users`, [
          { projectId: projectId },
        ]),
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const data = response?.data;
        dispatch(
          setSystemEndUser({
            data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    projectUserAssign: buildMutationHelper(
      builder,
      ({ projectAssignData }) => ({
        url: `/assign-users-project-teams`,
        method: "POST",
        body: projectAssignData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setSystemEndUser({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    getProjectClientSystemUser: buildMutationHelper(
      builder,
      ({
        projectId = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => ({
        url: urlBuilder(`/project-client-system-users`, [
          {
            projectId: projectId,
            client: client,
          },
        ]),
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const data = response?.data;
        dispatch(
          setSystemEndUser({
            data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    assignUsersProject: buildMutationHelper(
      builder,
      ({ projectAssignData }) => ({
        url: `/assign-users-project`,
        method: "POST",
        body: projectAssignData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setSystemEndUser({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    systemEndUserDelete: buildMutationHelper(
      builder,
      (id) => ({
        url: `/system-end-user/delete/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.error }));
      }
    ),
    systemEndUserActiveInactive: buildMutationHelper(
      builder,
      ({ systemEndUserData, id }) => ({
        url: `/system-end-user/${id}`,
        method: "PATCH",
        body: JSON.stringify(systemEndUserData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch, getState) => {
        const {
          endUser: { data: newData },
        } = getState();

        const { data } = response?.data;

        let newDataArray = [];
        isActiveReload(newDataArray, newData, data);

        dispatch(setSystemEndUser({ newDataArray, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(setSystemEndUser({ errorMessage: error?.data?.violations }));
      }
    ),
    systemEndUserResetPasscode: buildMutationHelper(
      builder,
      ({ systemEndUserData, id }) => ({
        url: `/system-end-user/reset-passcode/${id}`,
        method: "POST",
        body: systemEndUserData,
        headers: getHeaders({ noAuth: false }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(
          setSystemEndUser({
            data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(
          setSystemEndUser({
            errorMessage: error?.data?.violations,
          })
        );
      }
    ),
  }),
});
export const {
  useSystemEndUserListMutation,
  useSystemEndUserAddMutation,
  useGetSystemEndUserMutation,
  useEditSystemEndUserMutation,
  useGetSystemEndUserPhotoMutation,
  useUploadSystemEndUserPhotoMutation,
  useGetSystemEndUserListMutation,
  useProjectUserAssignMutation,
  useGetProjectClientSystemUserMutation,
  useAssignUsersProjectMutation,
  useSystemEndUserDeleteMutation,
  useSystemEndUserActiveInactiveMutation,
  useSystemEndUserResetPasscodeMutation,
} = systemEndUserApi;
