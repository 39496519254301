import { Button, Label, mergeClasses } from "@fluentui/react-components";
import Style from "../style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FieldDropDown } from "../../../../../components";
import Layout from "../../../../../styles/Layout.styles";
import { useGetQuestionBankMutation } from "../../../../../store/action/device";
import QuestionList from "./QuestionList";

const QuestionAsk = (props) => {
  const { visibleQuestions, setVisibleQuestions, setValue } = props;
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();
  const [getQuestionBank] = useGetQuestionBankMutation();
  const [questionDataList, setQuestionDataList] = useState([]);

  const [tagInputValue, setTagInputValue] = useState("");
  const [questionInputValue, setQuestionInputValue] = useState("");

  useEffect(() => {
    getQuestionBank().then((res) => {
      const listData = Object.entries(res?.data)?.map(([value, label]) => ({
        value: label?.id,
        label: label?.question,
      }));

      const result = listData.filter(
        (object1) =>
          !visibleQuestions.some((object2) => object1.value === object2.id)
      );
      setQuestionDataList(result);
      setValue("questionAsk", visibleQuestions?.question, {
        shouldValidate: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddQuestion = (e) => {
    e.preventDefault();

    const insertAt = 0;

    const addQuestion = [
      ...visibleQuestions.slice(0, insertAt),
      {
        question: tagInputValue?.trim(),
        id: questionInputValue,
      },
      ...visibleQuestions.slice(insertAt),
    ];

    if (tagInputValue?.trim()?.length !== 0) setVisibleQuestions(addQuestion);
    setTagInputValue("");

    const result = questionDataList.filter(
      (object1) => !addQuestion.some((object2) => object1.value === object2.id)
    );
    setQuestionDataList(result);
  };

  const handleChange = (e, data) => {
    setTagInputValue(data?.optionText);
    setQuestionInputValue(data?.optionValue);
    setValue("questionAsk", visibleQuestions?.question, {
      shouldValidate: true,
    });
  };

  return (
    <Stack>
      <Stack className={mergeClasses(layout.mb_2)}>
        <Label className={layout.mb_0_5}>{t("project.question_for_ask")}</Label>
        <Stack className={style.questionStyle}>
          <FieldDropDown
            multiselect={false}
            id="questionAsk"
            className={mergeClasses(layout.w_100, layout.mr_1)}
            placeholder={t("project.select_question")}
            name="questionAsk"
            options={questionDataList}
            value={tagInputValue}
            onOptionSelect={handleChange}
            inputClassName={mergeClasses(style.textboxInput, layout.w_100)}
            validation={props.register("questionAsk")}
          />
          <Button
            appearance="primary"
            className={mergeClasses(layout.smallBtn)}
            onClick={AddQuestion}
          >
            {t("add")}
          </Button>
        </Stack>
      </Stack>
      {visibleQuestions.length > 0 && (
        <Stack
          className={mergeClasses(style.customTags, "border4", layout.mb_2)}
        >
          {visibleQuestions?.map(({ question: name, id }, key) => (
            <QuestionList
              name={name}
              key={key}
              id={id}
              currentIndex={key}
              visibleQuestions={visibleQuestions}
              setVisibleQuestions={setVisibleQuestions}
              setQuestionDataList={setQuestionDataList}
              questionDataList={questionDataList}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default QuestionAsk;
