import { createSlice } from "@reduxjs/toolkit";

const clientExternalSources = createSlice({
  name: "clientExternalSources",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    pagination: "",
  },
  reducers: {
    setClientExternalSources(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setClientExternalSources } = clientExternalSources.actions;
export default clientExternalSources.reducer;
