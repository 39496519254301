import { t } from "i18next";
import accessRole from "../Common/accessRole";

const HeaderMenu = () => {
  let role = accessRole();

  return [
    {
      key: "summary",
      name: `${t("header.summary")}`,
      url: "/summary",
      visible: role?.size && role?.has("project_summary_view") ? true : false,
      // child: [
      //   {
      //     key: "licenses",
      //     name: `${t("header.licenses")}`,
      //     url: "/licenses",
      //     visible: true,
      //   },
      //   {
      //     key: "theme",
      //     name: `${t("header.theme")}`,
      //     url: "/theme",
      //     visible: true,
      //   },
      // ],
    },
    {
      key: "dashboard",
      name: `${t("header.dashboard")}`,
      url: "/dashboard",
      visible: role?.size && role?.has("dashboard_view") ? true : false,
    },
    {
      key: "reporting",
      name: `${t("header.reporting")}`,
      url: "/reporting",
      visible: role?.size && role?.has("reporting_view") ? true : false,
    },
  ];
};

export default HeaderMenu;
