import { createSlice } from "@reduxjs/toolkit";

const timeAndAttendanceReject = createSlice({
  name: "timeAndAttendanceReject",
  initialState: {
    data: [],
    errorMessage: "",
    exportData: [],
    page: 0,
    itemsPerPage: 10,
    projectId: "",
  },
  reducers: {
    setTimeAndAttendanceReject(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setTimeAndAttendanceReject } = timeAndAttendanceReject.actions;
export default timeAndAttendanceReject.reducer;
