import { t } from "i18next";
import accessRole from "../Common/accessRole";

const HeaderDropdownMenu = () => {
  let role = accessRole();

  return [
    {
      key: "users",
      name: `${t("header.users")}`,
      url: "/users-list",
      visible: role?.size && role?.has("system_user_list") ? true : false,
    },
    {
      key: "licenses",
      name: `${t("header.licenses")}`,
      url: "/licenses",
      visible: role?.size && role?.has("license_list") ? true : false,
    },
    {
      key: "theme",
      name: `${t("header.theme")}`,
      url: "/theme",
      visible: role?.size && role?.has("client_theme_list") ? true : false,
    },
    {
      key: "billing",
      name: `${t("header.billing")}`,
      url: "/billing",
      visible: role?.size && role?.has("billing_list") ? true : false,
    },
    {
      key: "policies",
      name: `${t("header.policies")}`,
      url: "/policies",
    },
    {
      key: "communications",
      name: `${t("header.communications")}`,
      url: "/communications",
    },
    {
      key: "questions",
      name: `${t("header.questions")}`,
      url: "/questions-list",
      visible: role?.size && role?.has("question_bank_list") ? true : false,
    },
    {
      key: "systemEndUser",
      name: `${t("header.systemEndUser")}`,
      url: "/system-end-user-list",
      visible: role?.size && role?.has("system_end_user_list") ? true : false,
    },
    {
      key: "externalSources",
      name: `${t("header.externalSources")}`,
      url: "/externalSources",
      visible: role?.size && role?.has("external_source") ? true : false,
    },
  ];
};

export default HeaderDropdownMenu;
