import { Stack, Text } from "@fluentui/react";
import Style from "./style";
import { useEffect, useState } from "react";
import Layout from "../../../styles/Layout.styles";
import {
  Divider,
  mergeClasses,
  Image,
  Button,
  Spinner,
} from "@fluentui/react-components";
import { statusType } from "../../../utils/projectStatus";
import { useTranslation } from "react-i18next";
import close from "../../../images/close.svg";
import { format } from "date-fns";
import { useGetProjectSummaryMutation } from "../../../store/action/projectSummary/projectSummaryLive";

const ProjectSummaryDetails = ({ projectSummaryId, setOpenSummaryDetails }) => {
  const style = Style();
  const layout = Layout();
  const { t } = useTranslation();

  const [GetProjectSummaryDetails, { isLoading }] =
    useGetProjectSummaryMutation();
  const [viewData, setViewData] = useState();

  useEffect(() => {
    if (projectSummaryId) {
      if (!isLoading) {
        GetProjectSummaryDetails(projectSummaryId).then((res) => {
          setViewData(res);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetProjectSummaryDetails, projectSummaryId]);
  return (
    <>
      <Stack
        className={mergeClasses(
          style.projectSummary,
          "cardShadow",
          "projectSummary",
          layout.mt_0
        )}
      >
        <h2 className={mergeClasses(style.projectSummaryTitle, layout.primary)}>
          {t("project_summary.project_details")}
          <Button
            appearance="subtle"
            onClick={() => setOpenSummaryDetails(false)}
            aria-label="close"
            icon={<Image alt="" src={close} height={20} width={20} />}
          />
        </h2>
        {isLoading ? (
          <Stack
            className={mergeClasses(layout.positionRelative, layout.w_100)}
          >
            <Spinner className={layout.m_auto} />
          </Stack>
        ) : (
          <>
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.project_id")}
              </Text>
              <Text
                truncate
                block
                className={mergeClasses(
                  style.summaryDetailText,
                  layout.textOverFlowHidden
                )}
              >{`${viewData?.data?.projectCode}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.project_name")}
              </Text>
              <Text
                className={style.summaryDetailText}
              >{`${viewData?.data?.title}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.commencement_date")}
              </Text>
              <Text className={style.summaryDetailText}>
                {viewData &&
                  format(
                    new Date(viewData?.data?.commencementDate),
                    "yyyy/MM/dd"
                  )}
              </Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.completion_date")}
              </Text>
              <Text className={style.summaryDetailText}>
                {viewData &&
                  format(
                    new Date(viewData?.data?.completionDate),
                    "yyyy/MM/dd"
                  )}
              </Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.total_team_member")}
              </Text>
              <Text
                className={style.summaryDetailText}
              >{`${viewData?.data?.totalMember}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.today_clockin")}
              </Text>
              <Text
                className={style.summaryDetailText}
              >{`${viewData?.data?.todayClockIn}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.address")}
              </Text>
              <Text className={style.summaryDetailText}>{`${
                viewData?.data?.address?.length > 0
                  ? viewData?.data?.address
                  : "-"
              }`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.builder")}
              </Text>
              <Text
                className={style.summaryDetailText}
              >{`${viewData?.data?.builder}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.management_team")}
              </Text>
              <Text className={style.summaryDetailText}>{`${
                viewData?.data?.projectManagementTeam?.length > 0
                  ? viewData?.data?.projectManagementTeam
                  : "-"
              }`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.design_team")}
              </Text>
              <Text className={style.summaryDetailText}>{`${
                viewData?.data?.designTeam?.length > 0
                  ? viewData?.data?.designTeam
                  : "-"
              }`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.client_contact")}
              </Text>
              <Text className={style.summaryDetailText}>
                ({`${viewData?.data?.clientContactPrefix}`}){" "}
                {`${viewData?.data?.clientContactNumber}`}
              </Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.site_contact")}
              </Text>
              <Text
                className={style.summaryDetailText}
              >{`${viewData?.data?.siteContact}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.site_phone_number")}
              </Text>
              <Text className={style.summaryDetailText}>
                ({`${viewData?.data?.sitePhonePrefix}`}){" "}
                {`${viewData?.data?.sitePhoneNumber}`}
              </Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.description")}
              </Text>
              <Text
                className={style.summaryDetailText}
              >{`${viewData?.data?.description}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.contract_value")}
              </Text>
              <Text className={style.summaryDetailText} $>
                ${`${viewData?.data?.contractValue}`}
              </Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.branch")}
              </Text>
              <Text
                className={style.summaryDetailText}
              >{`${viewData?.data?.branch}`}</Text>
            </Stack>
            <Divider className={style.detailsDivider} />
            <Stack
              className={mergeClasses(layout.padding1_5y, layout.flex_row)}
            >
              <Text className={style.summaryDetailLbl}>
                {t("project_summary.project_type")}
              </Text>
              <Text className={style.summaryDetailText}>
                {viewData?.data?.status && statusType(viewData?.data?.status)}
              </Text>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default ProjectSummaryDetails;
