import { eachDayOfInterval, startOfMonth, endOfMonth } from "date-fns";
export const getTimesheetHeader = (startDate, endDate) => {
  const now = new Date(startDate);
  const month = now.getMonth();
  const year = now.getFullYear();
  const originalArray = calendarDisplay(month, year);

  return originalArray.filter((d) => d);
  // Please do not remove below commented lines
  /*
    const numberOfParts = 6;
    const dividedArray = splitArrayIntoParts(originalArray, numberOfParts);
    return dividedArray.map(i => i.filter(f => f)).filter(j => j.length > 0);
  */
};

const calendarDisplay = (month, year) => {
  const days = getMonthDays(month + 1, year);
  const firstDay = new Date(year, month, 1);
  const startDay = firstDay.getDay();
  return setThePrintLoop(startDay, days);
};
const setThePrintLoop = (s, d) => {
  let dayArray = [];
  const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
  for (let i = 0; i < 42; i++) {
    if (i >= s && i - s < d) {
      const day = i - s + 1;
      dayArray.push({ monthDate: day, weekDay: days[i % 7], total: 0 });
    } else {
      dayArray.push("");
    }
  }
  return dayArray;
};
const getMonthDays = (month, year) => {
  let days;
  if (
    month === 1 ||
    month === 3 ||
    month === 5 ||
    month === 7 ||
    month === 8 ||
    month === 10 ||
    month === 12
  )
    days = 31;
  else if (month === 4 || month === 6 || month === 9 || month === 11) days = 30;
  else if (month === 2) {
    if (isLeapYear(year)) {
      days = 29;
    } else {
      days = 28;
    }
  }
  return days;
};
const isLeapYear = (year) => {
  if (year % 4 === 0) {
    return true;
  }

  return false;
};

export const getFirstAndLastDayOfMonth = (date) => {
  const now = date ? new Date(date) : new Date();
  const month = now.getMonth();
  const year = now.getFullYear();
  const firstDay = new Date(year, month, 1);
  const startDay = firstDay.getDate();
  const lastDay = new Date(year, month + 1, 0);
  const endDay = lastDay.getDate();

  return {
    startDay,
    endDay,
    month: month + 1,
    year,
  };
};

//eslint-disable-next-line no-unused-vars
const splitArrayIntoParts = (array, parts) => {
  const result = [];
  const length = array.length;
  const partSize = Math.ceil(length / parts);

  for (let i = 0; i < length; i += partSize) {
    const part = array.slice(i, i + partSize);
    result.push(part);
  }

  return result;
};

export function generateResult(startDate, endDate, singleDay = false) {
  const result = [];
  if (startDate.getTime() === endDate.getTime() && !singleDay) {
    // If startDate and endDate are the same, generate data for the whole month
    const start = startOfMonth(startDate);
    const end = endOfMonth(endDate);
    const dates = eachDayOfInterval({ start, end });
    dates.forEach((date) => {
      const dateObj = new Date(date);
      const monthDate = dateObj.getDate();
      const month = dateObj.getMonth();
      const weekDay = date
        .toLocaleDateString("en-US", { weekday: "short" })
        .toUpperCase();
      result.push({ monthDate, month, weekDay, total: 0 });
    });
  } else {
    // If custom dates are given, generate dates between start and end dates
    const dates = eachDayOfInterval({ start: startDate, end: endDate });

    dates.forEach((date) => {
      const dateObj = new Date(date);
      const monthDate = dateObj.getDate();
      const month = dateObj.getMonth();
      const weekDay = dateObj
        .toLocaleDateString("en-US", { weekday: "short" })
        .toUpperCase();
      result.push({ monthDate, month, weekDay, total: 0 });
    });
  }

  return result;
}
