import { createSlice } from "@reduxjs/toolkit";

const clientDepartment = createSlice({
  name: "clientDepartment",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    isActive: "true",
  },
  reducers: {
    setClientDepartment(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setClientDepartment } = clientDepartment.actions;
export default clientDepartment.reducer;
