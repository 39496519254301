import { configureStore } from "@reduxjs/toolkit";
import user from "./reducer/user";
import { userApi } from "./action/user";
import { projectApi } from "./action/project";
import { clientApi } from "./action/client";
import { themeApi } from "./action/theme";
import { client, project, theme } from "./reducer";
import common from "./reducer/common";
import { commonApi } from "./action/common";
import { usersApi } from "./action/users";
import users from "./reducer/users";
import { teamsApi } from "./action/teams";
import teams from "./reducer/teams";
import { locationApi } from "./action/location";
import location from "./reducer/location";
import { deviceApi } from "./action/device";
import device from "./reducer/device";
import { questionsApi } from "./action/question";
import questions from "./reducer/question";
import projectUser from "./reducer/projectUser";
import { projectUserApi } from "./action/projectUser";
import { systemEndUserApi } from "./action/systemEndUser";
import systemEndUser from "./reducer/systemEndUser";
import { timeAndAttendanceApi } from "./action/time-and-attendance";
import timeAndAttendance from "./reducer/time-and-attendance";
import { timeAndAttendanceApproveApi } from "./action/time-and-attendance/time-and-attendanceApprove";
import timeAndAttendanceApprove from "./reducer/time-and-attendance/time-and-attendanceApprove";
import timeAndAttendancePending from "./reducer/time-and-attendance/time-and-attendancePending";
import { timeAndAttendancePendingApi } from "./action/time-and-attendance/time-and-attendancePending";
import { timeAndAttendanceRejectApi } from "./action/time-and-attendance/time-and-attendanceReject";
import timeAndAttendanceReject from "./reducer/time-and-attendance/time-and-attendanceReject";
import { visitorApi } from "./action/visitor";
import visitor from "./reducer/visitor";
import { projectSummaryLiveApi } from "./action/projectSummary/projectSummaryLive";
import projectSummaryLive from "./reducer/projectSummary/projectSummaryLive";
import { projectSummaryAwaitApi } from "./action/projectSummary/projectSummaryAwait";
import projectSummaryAwait from "./reducer/projectSummary/projectSummaryAwait";
import projectSummaryComplete from "./reducer/projectSummary/projectSummaryComplete";
import { projectSummaryCompleteApi } from "./action/projectSummary/projectSummaryComplete";
import { licenseApi } from "./action/license";
import license from "./reducer/license";
import { bundleApi } from "./action/bundle";
import bundle from "./reducer/bundle";
import { billingApi } from "./action/billing";
import billing from "./reducer/billing";
import { modulesApi } from "./action/modules";
import modules from "./reducer/modules";
import externalSources from "./reducer/externalSources";
import { externalSourcesApi } from "./action/externalSources";
import { clientExternalSourcesApi } from "./action/clientExternalSources";
import clientExternalSources from "./reducer/clientExternalSources";
import { clientDepartmentApi } from "./action/clientDepartment";
import clientDepartment from "./reducer/clientDepartment";
import { projectDepartmentApi } from "./action/projectDepartment";
import projectDepartment from "./reducer/projectDepartment";

export const setupStore = () => {
  return configureStore({
    reducer: {
      [userApi.reducerPath]: userApi.reducer,
      [projectApi.reducerPath]: projectApi.reducer,
      [clientApi.reducerPath]: clientApi.reducer,
      [themeApi.reducerPath]: themeApi.reducer,
      [commonApi.reducerPath]: commonApi.reducer,
      [usersApi.reducerPath]: usersApi.reducer,
      [teamsApi.reducerPath]: teamsApi.reducer,
      [locationApi.reducerPath]: locationApi.reducer,
      [deviceApi.reducerPath]: deviceApi.reducer,
      [questionsApi.reducerPath]: questionsApi.reducer,
      [projectUserApi.reducerPath]: projectUserApi.reducer,
      [systemEndUserApi.reducerPath]: systemEndUserApi.reducer,
      [timeAndAttendanceApi.reducerPath]: timeAndAttendanceApi.reducer,
      [projectSummaryLiveApi.reducerPath]: projectSummaryLiveApi.reducer,
      [projectSummaryAwaitApi.reducerPath]: projectSummaryAwaitApi.reducer,
      [bundleApi.reducerPath]: bundleApi.reducer,
      [licenseApi.reducerPath]: licenseApi.reducer,
      [billingApi.reducerPath]: billingApi.reducer,
      [projectSummaryCompleteApi.reducerPath]:
        projectSummaryCompleteApi.reducer,
      [visitorApi.reducerPath]: visitorApi.reducer,
      [timeAndAttendanceApproveApi.reducerPath]:
        timeAndAttendanceApproveApi.reducer,
      [timeAndAttendancePendingApi.reducerPath]:
        timeAndAttendancePendingApi.reducer,
      [timeAndAttendanceRejectApi.reducerPath]:
        timeAndAttendanceRejectApi.reducer,
      [modulesApi.reducerPath]: modulesApi.reducer,
      [externalSourcesApi.reducerPath]: externalSourcesApi.reducer,
      [clientExternalSourcesApi.reducerPath]: clientExternalSourcesApi.reducer,
      [clientDepartmentApi.reducerPath]: clientDepartmentApi.reducer,
      [projectDepartmentApi.reducerPath]: projectDepartmentApi.reducer,

      user: user,
      theme: theme,
      project: project,
      client: client,
      timeAndAttendance: timeAndAttendance,
      common: common,
      users: users,
      teams: teams,
      location: location,
      device: device,
      questions: questions,
      projectUser: projectUser,
      systemEndUser: systemEndUser,
      projectSummaryLive: projectSummaryLive,
      projectSummaryAwait: projectSummaryAwait,
      projectSummaryComplete: projectSummaryComplete,
      visitor: visitor,
      license: license,
      bundle: bundle,
      billing: billing,
      timeAndAttendanceApprove: timeAndAttendanceApprove,
      timeAndAttendancePending: timeAndAttendancePending,
      timeAndAttendanceReject: timeAndAttendanceReject,
      modules: modules,
      externalSources: externalSources,
      clientExternalSources: clientExternalSources,
      clientDepartment: clientDepartment,
      projectDepartment: projectDepartment,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .concat(userApi.middleware)
        .concat(projectApi.middleware)
        .concat(clientApi.middleware)
        .concat(themeApi.middleware)
        .concat(commonApi.middleware)
        .concat(usersApi.middleware)
        .concat(teamsApi.middleware)
        .concat(locationApi.middleware)
        .concat(deviceApi.middleware)
        .concat(questionsApi.middleware)
        .concat(projectUserApi.middleware)
        .concat(systemEndUserApi.middleware)
        .concat(timeAndAttendanceApi.middleware)
        .concat(projectSummaryLiveApi.middleware)
        .concat(projectSummaryAwaitApi.middleware)
        .concat(projectSummaryCompleteApi.middleware)
        .concat(visitorApi.middleware)
        .concat(bundleApi.middleware)
        .concat(licenseApi.middleware)
        .concat(billingApi.middleware)
        .concat(timeAndAttendanceApproveApi.middleware)
        .concat(timeAndAttendancePendingApi.middleware)
        .concat(timeAndAttendanceRejectApi.middleware)
        .concat(modulesApi.middleware)
        .concat(externalSourcesApi.middleware)
        .concat(clientExternalSourcesApi.middleware)
        .concat(clientDepartmentApi.middleware)
        .concat(projectDepartmentApi.middleware);
    },
  });
};

export const AppStore = setupStore;
export const AppDispatch = AppStore["dispatch"];
