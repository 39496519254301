import { Stack } from "@fluentui/react";
import { Text, mergeClasses } from "@fluentui/react-components";
import Style from "./Style";
import { ReactComponent as Eye } from "../../../images/eye.svg";
import { ReactComponent as Pencil } from "../../../images/pencil.svg";
import { Link, useNavigate } from "react-router-dom";
import { TableCell, TableRow } from "../../../components/Table";
import Layout from "../../../styles/Layout.styles";
import {
  useDeleteClientMutation,
  useEditClientMutation,
  useGetClientListMutation,
} from "../../../store/action/client";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClient } from "../../../store/reducer/client";
import SwitchPopup from "../../../components/FieldComponents/SwitchPopup";
import { getAccessRoles } from "../../../utils/getAccessRoles";
import { useLoginUserMutation } from "../../../store/action/user";

const ClientListRow = ({ item, role }) => {
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [getDeleteClient, { isLoading: isDeleteLoading }] =
    useDeleteClientMutation();
  const [getClientListApi, { isLoading }] = useGetClientListMutation();
  const [clientActive] = useEditClientMutation();
  const dispatch = useDispatch();
  const clientList = useSelector((state) => state.client);
  const [getUserApi] = useLoginUserMutation();
  const selectedClientName = localStorage.getItem("selectedClientName");
  const activeClientName = localStorage.getItem("activeClientName");

  const handleSwitchChange = () => {
    const clientData = {
      isActive: item?.isActive ? false : true,
    };

    if (activeClientName === item?.companyName && item?.isActive) {
      getDeleteClient(item.id).then((res) => {
        dispatch(
          setClient({
            page:
              clientList?.data?.length === 1 && clientList?.page > 0
                ? clientList?.page - 1
                : clientList?.page,
          })
        );

        if (!isLoading) {
          getClientListApi();
          setOpen(false);
        }
      });
      setTimeout(function () {
        getUserApi().then((res) => {
          let person = res.data;

          const roleList = getAccessRoles(person);
          // localStorage.setItem("companyName", person?.client?.companyName);
          // localStorage.setItem("canCreateProject", person?.canCreateProject);
          // localStorage.setItem("activeUser", person?.fullName);
          // localStorage.setItem("activeUserId", person?.id);
          localStorage.setItem("accessRoles", roleList);

          localStorage.setItem("activeClientId", person?.activeClient?.id);

          localStorage.setItem(
            "activeClientName",
            person?.activeClient?.companyName
          );

          localStorage.setItem(
            "selectedClientName",
            person?.activeClient?.companyName
          );
          localStorage.setItem("selectedClientId", person?.activeClient?.id);
        });
      }, 250);
    } else if (selectedClientName === item?.companyName && item?.isActive) {
      getDeleteClient(item.id).then((res) => {
        dispatch(
          setClient({
            page:
              clientList?.data?.length === 1 && clientList?.page > 0
                ? clientList?.page - 1
                : clientList?.page,
          })
        );

        if (!isLoading) {
          getClientListApi();
          setOpen(false);
        }
      });

      getUserApi().then((res) => {
        let person = res.data;

        const roleList = getAccessRoles(person);
        localStorage.setItem("accessRoles", roleList);

        localStorage.setItem("activeClientId", person?.activeClient?.id);

        localStorage.setItem(
          "activeClientName",
          person?.activeClient?.companyName
        );

        localStorage.setItem(
          "selectedClientName",
          person?.activeClient?.companyName
        );
        localStorage.setItem("selectedClientId", person?.activeClient?.id);
      });
    } else {
      item?.isActive
        ? getDeleteClient(item.id).then((res) => {
            dispatch(
              setClient({
                page:
                  clientList?.data?.length === 1 && clientList?.page > 0
                    ? clientList?.page - 1
                    : clientList?.page,
              })
            );
            if (!isLoading) {
              getClientListApi();
              setOpen(false);
            }
          })
        : clientActive({ clientData: clientData, id: item.id }).then((res) => {
            if (!isLoading) {
              getClientListApi();
              setOpen(false);
            }
          });
    }
  };
  const style = Style();
  const layout = Layout();
  let disableLink = item.isActive === false;

  return (
    <>
      <TableRow key={item?.project?.label} className={style.customTblRow}>
        <TableCell tabIndex={item?.key}>{item?.companyName}</TableCell>
        <TableCell tabIndex={item?.key}>{item?.companyAddress}</TableCell>
        <TableCell tabIndex={item?.key}>
          <Text
            truncate
            block
            className={layout.textOverFlowHidden}
            title={item?.email}
          >
            {item?.email}
          </Text>
        </TableCell>
        <TableCell tabIndex={item?.key}>
          {item?.contactPerson?.firstName}
        </TableCell>
        <TableCell tabIndex={item?.key}>
          {item?.accountPerson?.firstName}
        </TableCell>
        <TableCell tabIndex={item?.key}>
          <Stack className={layout.tableIcons}>
            {role?.has("client_view") && (
              <Link
                to={`/organization-list/${item.id}`}
                className={mergeClasses(
                  layout.tableIcon,
                  layout.d_flex,
                  layout.alignCenter
                )}
              >
                <Eye height={18} width={18} />
              </Link>
            )}

            {role?.has("client_edit") && (
              <Stack
                className={mergeClasses(
                  layout.tableIcons,
                  disableLink ? "disableIcons" : ""
                )}
              >
                <Link
                  to={`/organization-list/${item.id}/edit`}
                  className={mergeClasses(
                    layout.tableIcon,
                    layout.d_flex,
                    layout.alignCenter
                  )}
                >
                  <Pencil height={18} width={18} />
                </Link>
              </Stack>
            )}
          </Stack>
        </TableCell>
        <TableCell tabIndex={item?.key}>
          {role?.has("client_edit") ? (
            <Stack className={layout.tableIcons}>
              <SwitchPopup
                open={item?.isActive && open}
                onOpenChange={(_, data) => setOpen(data.open)}
                handleSwitchChange={() => {
                  item?.isActive === false && handleSwitchChange();
                }}
                checked={item?.isActive ? true : false}
                handleInactiveSwitch={handleSwitchChange}
                handleCancel={() => setOpen(false)}
                isLoading={isLoading}
              />
            </Stack>
          ) : item?.isActive ? (
            "Active"
          ) : (
            "Inactive"
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
export default ClientListRow;
