export const trailWhiteSpace = (formFields) => {
  const trailData = {};
  for (const key in formFields) {
    if (typeof formFields[key] === "string") {
      trailData[key] = formFields[key]?.trim();
    } else {
      trailData[key] = formFields[key];
    }
  }

  return trailData;
};
