import { Stack } from "@fluentui/react";
import {
  Button,
  Divider,
  Text,
  mergeClasses,
} from "@fluentui/react-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../../../styles/Layout.styles";
import {
  useDeleteLocationMutation,
  useLocationListMutation,
} from "../../../../store/action/location";
import { ReactComponent as LocationIcon } from "../../../../images/location.svg";
import AddDevice from "../AddDevice/AddDevice";
import Style from "./Style";
import { useTranslation } from "react-i18next";
import { DeletePopup } from "../../../../components";
import DeviceDataList from "./DeviceDataList";

const LocationCard = ({ item, projectId, locationId, role }) => {
  const layout = Layout();
  const style = Style();
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [getDeleteLocation] = useDeleteLocationMutation();
  const [getLocationListApi] = useLocationListMutation();
  const locationList = useSelector((state) => state.location);

  const getLocationDataListApi = async (page, itemsPerPage) => {
    await getLocationListApi({
      page: page,
      itemsPerPage: itemsPerPage,
      project: projectId,
    });
  };

  const handleDelete = (id) => {
    getDeleteLocation(id).then((res) => {
      getLocationDataListApi(
        locationList?.data?.length === 1 && locationList?.page > 0
          ? locationList?.page - 1
          : locationList?.page,
        locationList?.itemsPerPage
      ).then((res) => {
        setOpen(false);
      });
    });
  };

  return (
    <>
      <Text
        key={item?.name}
        className={mergeClasses(layout.border, style.locationBlock)}
      >
        <Text
          className={mergeClasses(
            layout.d_flex,
            layout.flex_between,
            layout.customHeader,
            style.header
          )}
        >
          <Text className={mergeClasses(layout.d_flex, layout.wrap)}>
            <Text
              className={mergeClasses(
                layout.d_flex,
                style.primaryColor,
                layout.mr_1
              )}
            >
              <LocationIcon width={18} />
            </Text>
            <Text
              className={mergeClasses(layout.fontWeightSemibold, layout.mr_1)}
            >
              {item?.name}
            </Text>
            <Text
              className={mergeClasses(layout.fontWeightSemibold, layout.mr_1)}
            >
              ({item?.shortCode})
            </Text>
            <Text className={layout.mr_1}>{item?.address}</Text>
          </Text>
          <Text className={mergeClasses(layout.d_flex)}>
            {role?.has("project_location_edit") && (
              <DeletePopup
                className={layout.tableIcon}
                open={open}
                onOpenChange={(_, data) => setOpen(data?.open)}
                onClick={() => setOpen(true)}
                handleDelete={() => handleDelete(item?.id)}
                handleCancel={() => setOpen(false)}
              />
            )}
          </Text>
        </Text>
        <Text
          as="div"
          className={mergeClasses(
            layout.d_flex,
            layout.flex_between,
            layout.padding1_5x,
            style.marginx1
          )}
        >
          <Stack>&nbsp;</Stack>
          <Text as="div">
            {role?.has("device_create") && (
              <Button
                appearance="primary"
                onClick={() => setIsOpenDialog(true)}
              >
                {t("project.pl_add_devices")}
              </Button>
            )}
            {isOpenDialog === true && (
              <AddDevice
                isOpenDialog={isOpenDialog}
                setIsOpenDialog={setIsOpenDialog}
                projectLocationId={item?.id}
                projectId={projectId}
              />
            )}
          </Text>
        </Text>
        <Divider />
        {item?.projectLocationDevices?.map((item, key) => {
          return (
            <DeviceDataList
              key={key}
              item={item}
              role={role}
              projectId={projectId}
              locationId={locationId}
            />
          );
        })}
      </Text>
    </>
  );
};
export default LocationCard;
