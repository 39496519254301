import {
  makeStyles,
  shorthands,
  tokens,
  makeStaticStyles,
} from "@fluentui/react-components";

export const useStyle = makeStyles({
  timeAndAttendanceWrapper: {
    ...shorthands.padding("20px"),
    ...shorthands.margin("26px", 0, 0, 0),
    ...shorthands.borderRadius("4px"),
    boxShadow:
      "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",
  },
  filterAction: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 660px)": {
      ...shorthands.gap("10px"),
      width: "100%",
      justifyContent: "space-between",
    },
  },
  filterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    "@media (max-width: 1365px)": {
      flexDirection: "column",
    },
    "@media (max-width: 660px)": {
      width: "100%",
    },
  },
  tnaFilterWrapper: {
    ...shorthands.gap("10px"),
  },
  detailedFilter: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "@media (max-width: 660px)": {
      flexDirection: "column",
      ...shorthands.gap("10px"),
    },
  },
  tabPadding: {
    ...shorthands.padding(0),
    ...shorthands.margin(0, "10px", 0, 0),
    paddingBottom: "10px",
    color: tokens.colorBrandBackground2Pressed,
  },

  tableWrapper: {
    width: "100%",
  },

  timesheetWrapper: {
    ...shorthands.overflow("scroll", "hidden"),
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    "@media (max-width: 767px)": {
      justifyContent: "space-between",
    },
  },
  detailedTimesheetFilter: {
    ...shorthands.margin(0),
  },
  stackMainClass: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 992px)": {
      flexDirection: "column",
      ...shorthands.gap("20px"),
      alignItems: "flex-start",
    },
  },
  headerCellWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  teamsHeader: {
    width: "165px",
    "@media (min-width: 992px)": {
      textAlign: "left",
      position: "sticky",
      left: 0,
      zIndex: 1,
      backgroundColor: tokens.colorNeutralBackground3,
      marginLeft: "-1px",
    },
  },
  loggedHeader: {
    width: "60px",
  },
  dayHeader: {
    width: "35px",
    justifyContent: "center",
  },
  customTable: {
    tableLayout: "auto",
  },
  teamCell: {
    ...shorthands.padding(0),
  },
  teamCelldiv: {
    height: "100%",
    justifyContent: "center",
    paddingLeft: "12px",
    color: "white",
    fontWeight: tokens.fontWeightBold,
  },
  modalBackground: {
    "@media (max-width: 1440px)": {
      backdropFilter: "blur(10px)",
      position: "absolute",
      ...shorthands.overflow("auto"),
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 9999,
    },
  },
  timesheetActions: {
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
});

export const staticStyle = makeStaticStyles({
  ".timesheet-details .fui-TableCell": {
    textAlign: "center",
  },
  ".fui-TableCell:first-child": {
    textAlign: "left",
  },
  ".min30": {
    width: "30px",
  },
  ".workingTodayBreadcrumb .fui-Breadcrumb__list": {
    flexFlow: "wrap",
  },
  ".react-datepicker-popper": {
    zIndex: 999,
  },
  ".timesheet-details .react-datepicker-wrapper input": {
    width: "231px",
  },
});
