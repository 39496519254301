import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const SystemUserFirstName = (props) => {
  const { systemUserFirstName, validationMessage } = props;
  const style = Style();
  const { t } = useTranslation();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("first_name")}
          validationMessage={
            validationMessage
              ? validationMessage
              : props.errors?.systemUserFirstName?.message
          }
          id="systemUserFirstName"
          placeholder={t("enter_first_name")}
          maxLength="30"
          name="systemUserFirstName"
          inputDefaultValue={systemUserFirstName}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("systemUserFirstName", {
            required: `${t("validation_message.first_name_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default SystemUserFirstName;
