import * as React from "react";
import { breadCrumbStyle, useStaticStyles } from "./Style";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  mergeClasses,
} from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BreadcrumbList = ({ data, className }) => {
  const style = breadCrumbStyle();
  useStaticStyles();
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb
        aria-label={t("bread_crumb.small_breadcrumb_example_with_buttons")}
        size="large"
        className={mergeClasses(style.breadCrumb, "breadCrumb", className)}
      >
        {data?.map((item, key) => (
          <React.Fragment key={key}>
            {key > 0 ? <BreadcrumbDivider /> : ""}
            <BreadcrumbItem>
              <Link to={item?.url}>{item?.title}</Link>
            </BreadcrumbItem>
          </React.Fragment>
        ))}
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbList;
