import React, { useEffect, useState } from "react";
import Style from "../../styles/Unauthentication.styles";
import { Stack } from "@fluentui/react";
import { mergeClasses, Text } from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import { useLoginMutation } from "../../store/action/user";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import UnAuthWrapper from "../../components/UnAuthWrapper";
import Form from "../../components/FieldComponents/Form";
import { useSelector } from "react-redux";
import FormButton from "../../components/FieldComponents/FormButton";
import FieldsInput from "../../components/FieldComponents/FieldsInput";
import { useTranslation } from "react-i18next";
import { BASE_COLOR } from "../../utils/constants";
import { Eye24Filled, EyeOff24Filled } from "@fluentui/react-icons";
import { useLoginUserMutation } from "../../store/action/user";
import { getAccessRoles } from "../../utils/getAccessRoles";
import CustomFieldsInput from "../../components/FieldComponents/CustomFieldsInput";

const Login = (props) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  const themeColor = useSelector((state) => state.user);
  if (themeColor?.userTheme) {
    props.setActiveTheme(BASE_COLOR);
  }
  const [showPassword, setShowPassword] = useState(false);

  const layout = Layout();
  const style = Style();
  const [loginUserApi, { isLoading }] = useLoginMutation();
  const [getUserApi, { isGetLoading }] = useLoginUserMutation();
  const navigate = useNavigate();
  const loginUserData = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [valuePassword, setValuePassword] = useState();
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    errors?.password?.message && setErrorMessage(false);
  }, [errors?.password?.message]);

  const onSubmit = async (userData) => {
    if (!isLoading) {
      await loginUserApi(userData).then(({ data: { token } = {} }) => {
        if (token) {
          if (!isGetLoading) {
            getUserApi()
              .then((res) => {
                let person = res.data;

                //build roles array and set and dispatch
                const roleList = getAccessRoles(person);

                localStorage.setItem(
                  "canBeImportEmployee",
                  person?.canBeImportEmployee
                );

                localStorage.setItem(
                  "companyName",
                  person?.client?.companyName
                );
                localStorage.setItem("loginClientId", person?.client?.id);

                //----------

                localStorage.setItem("activeUser", person?.fullName);
                localStorage.setItem("activeUserId", person?.id);
                localStorage.setItem(
                  "activeUserImage",
                  person?.profileImageUrl
                );
                //----------
                localStorage.setItem(
                  "activeClientId",
                  person?.activeClient?.id
                );

                localStorage.setItem(
                  "activeClientName",
                  person?.activeClient?.companyName
                );
                //----------
                localStorage.setItem(
                  "canCreateProject",
                  person?.canCreateProject
                );
                localStorage.setItem("accessRoles", roleList);

                // if (
                //   Array.isArray(person?.clients) &&
                //   person?.clients?.length > 0
                // ) {
                //   localStorage.setItem(
                //     "selectedClientName",
                //     person?.clients[0]?.companyName
                //   );
                //   localStorage.setItem(
                //     "selectedClientId",
                //     person?.clients[0]?.id
                //   );
                // }
                navigate("/project-list");
              })
              .catch((e) => {});
          }
        } else {
          setValuePassword("");
          setErrorMessage(true);
        }
      });
    }
  };
  const handleChange = (e) => {
    setValuePassword(e?.target?.value);
    setErrorMessage(false);
    setValue("password", e?.target?.value, { shouldValidate: "true" });
  };
  if (localStorage?.activeUser) {
    navigate("/project-list");
  }

  return (
    <>
      <UnAuthWrapper>
        <Stack className={style.loginMain}>
          <Stack className={style.loginRightContent}>
            <Text className={mergeClasses(style.loginRightTitle)}>
              {t("login_welcome_back")}
            </Text>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Stack className={mergeClasses(style.mb_14)}>
                <Stack className={mergeClasses(layout.textbox)}>
                  <FieldsInput
                    label={t("forgot_password.email_address")}
                    validationMessage={errors?.email?.message}
                    fieldClassName="custom-label"
                    inputType="email"
                    placeholder={t("login.enter_email_address")}
                    name=""
                    inputClassName={layout.textboxInput}
                    inputId={"email"}
                    validation={register("email", {
                      required: t("login.email_is_required"),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("login.email_not_match"),
                      },
                    })}
                  />
                </Stack>
              </Stack>
              <Stack className={layout.mb_3_4}>
                <Stack
                  className={mergeClasses(
                    layout.textbox,
                    layout.positionRelative
                  )}
                >
                  <Link
                    className={mergeClasses(
                      layout.textboxLabel,
                      layout.forgetLink
                    )}
                    to="/forgot"
                  >
                    {t("login.forget_password")}
                  </Link>
                  <CustomFieldsInput
                    label={t("password")}
                    validationMessage={errors?.password?.message}
                    fieldClassName="custom-label"
                    inputType={showPassword ? "text" : "password"}
                    placeholder={t("enter_password")}
                    name="password"
                    inputClassName={mergeClasses(
                      layout.textboxInput,
                      layout.flex_row,
                      layout.alignCenter
                    )}
                    inputId={"password"}
                    validation={register("password", {
                      required: t("login.password_is_required"),
                    })}
                    value={valuePassword}
                    onChange={handleChange}
                    contentAfter={
                      <Link onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <Eye24Filled aria-label="eye open" />
                        ) : (
                          <EyeOff24Filled aria-label="eye close" />
                        )}
                      </Link>
                    }
                  />
                  {errorMessage && (
                    <p className={layout.colorEbc2f32}>
                      {loginUserData.errorMessage}
                    </p>
                  )}
                </Stack>
              </Stack>

              <FormButton
                type="submit"
                appearance="primary"
                loading={isLoading}
                className={mergeClasses(
                  layout.btnCustom,
                  "custom-primary",
                  layout.w_100
                )}
              >
                {!isLoading && `${t("sign_in")}`}
              </FormButton>
            </Form>
          </Stack>
        </Stack>
      </UnAuthWrapper>
    </>
  );
};

export default Login;
