import React, { useState } from "react";
import Style from "../../styles/Unauthentication.styles";
import { Stack } from "@fluentui/react";
import { mergeClasses, Text, useId, Button } from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import { Image } from "@fluentui/react-components";
import forgot_arrow from "../../images/forgot-arrow.svg";
import { useForgotPasswordUserMutation } from "../../store/action/user";
import { useForm } from "react-hook-form";
import UnAuthWrapper from "../../components/UnAuthWrapper";
import Form from "../../components/FieldComponents/Form";
import FieldsInput from "../../components/FieldComponents/FieldsInput";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const layout = Layout();
  const style = Style();
  const inputId = useId("input-with-placeholder");
  const [successMsg, setSuccessMsg] = useState();
  const { t } = useTranslation();
  const [forgotPasswordUserApi, { isLoading }] =
    useForgotPasswordUserMutation();

  const onSubmit = (data) => {
    if (!isLoading) {
      forgotPasswordUserApi(data).then((res) => {
        setSuccessMsg(res?.data?.success);
      });
    }
  };

  return (
    <>
      <UnAuthWrapper>
        <Stack className={style.loginMain}>
          <Stack className={style.loginRightContent}>
            <Stack className={style.figAlignDiv}>
              <Text className={style.loginRightTitle}>
                {t("forgot_password.forgot_password_title")}
              </Text>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Stack className={style.mb_30}>
                  <Stack className={mergeClasses(layout.textbox)}>
                    <FieldsInput
                      label={t("forgot_password.email_address")}
                      validationMessage={errors?.email?.message}
                      fieldClassName="custom-label"
                      inputType="email"
                      placeholder={t("forgot_password.enter_email_address")}
                      name="email"
                      htmlFor={inputId}
                      inputClassName={layout.textboxInput}
                      inputId={inputId}
                      validation={register("email", {
                        required: `${t(
                          "validation_message.email_is_required"
                        )}`,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: `${t(
                            "validation_message.email_not_match_format"
                          )}`,
                        },
                      })}
                    />
                  </Stack>
                </Stack>
                <Button
                  appearance="primary"
                  type="submit"
                  disabled={isLoading}
                  className={mergeClasses(
                    layout.btnCustom,
                    "custom-primary",
                    layout.w_100
                  )}
                >
                  {isLoading
                    ? t("forgot_password.loading")
                    : t("forgot_password.forget_password")}
                </Button>
              </Form>

              {successMsg ? (
                <p className={layout.greenColor}>
                  {t("forgot_password.success_message")}
                </p>
              ) : (
                ""
              )}

              <Stack className={style.backToLogin}>
                <Stack className={style.backToLoginMain}>
                  <Image alt="" src={forgot_arrow} height={15} width={15} />
                  <Link className={style.backToLoginText} to="/login">
                    {t("forgot_password.back_to_login")}
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </UnAuthWrapper>
    </>
  );
};

export default ForgotPassword;
