import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getHeaders } from "../../utils/requestHeaders";
import { API_HOST } from "../../utils/constants";
import { buildMutationHelper } from "../../utils/buildMutationHelper";
import { setTeams } from "../reducer/teams";
import { defaultFromState } from "../../utils/defaultFromState";

export const teamsApi = createApi({
  reducerPath: "teamsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
  }),

  endpoints: (builder) => ({
    teamsList: buildMutationHelper(
      builder,
      ({
        page = undefined,
        itemsPerPage = undefined,
        search = undefined,
        project = undefined,
        pagination = undefined,
        sortBy = undefined,
        sortType = undefined,
        client = localStorage?.selectedClientId ?? undefined,
      } = {}) => {
        return {
          url: "/project-teams",
          params: {
            page: page,
            itemsPerPage: itemsPerPage,
            search: search,
            project: project,
            pagination: pagination,
            sortBy,
            sortType,
            client: client,
          },
          method: "GET",
          headers: getHeaders(),
          defaultFromState: (getState, args) => {
            const {
              teams: {
                page,
                itemsPerPage,
                search,
                pagination,
                sortBy,
                sortType,
                client,
              },
            } = getState();

            let stateParams = {
              page,
              itemsPerPage,
              search,
              pagination,
              sortBy,
              sortType,
              client,
            };
            let stateParamsProject = {
              page,
              itemsPerPage,
              search,
              pagination,
              project,
              sortBy,
              sortType,
              client,
            };
            return defaultFromState(
              project ? stateParamsProject : stateParams,
              args
            );
          },
        };
      },
      (response, dispatch) => {
        if (
          new URL(response?.meta?.request?.url).searchParams.get(
            "pagination"
          ) === null
        ) {
          const { data } = response?.data;
          const totalCount = response?.data?.totalItems;
          dispatch(
            setTeams({
              data,
              totalCount,
              errorMessage: "",
            })
          );
        } else {
          const exportData = response?.data;
          dispatch(
            setTeams({
              exportData,
              errorMessage: "",
            })
          );
        }
      },
      ({ error }, dispatch) => {
        dispatch(setTeams({ errorMessage: error?.data?.error }));
      }
    ),
    addTeams: buildMutationHelper(
      builder,
      ({ teamsData }) => ({
        url: `/project-team`,
        method: "POST",
        body: teamsData,
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        const totalCount = response?.data?.total;
        dispatch(
          setTeams({
            data,
            totalCount,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTeams({ errorMessage: error?.data?.detail }));
      }
    ),

    getTeams: buildMutationHelper(
      builder,
      (id) => ({
        url: `project-team/${id}`,
        method: "GET",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const data = response?.data;
        dispatch(
          setTeams({
            userData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTeams({ errorMessage: error?.data?.error }));
      }
    ),
    editTeams: buildMutationHelper(
      builder,
      ({ teamsData, id }) => ({
        url: `/project-team/${id}`,
        method: "PATCH",
        body: JSON.stringify(teamsData),
        headers: getHeaders({
          customHeaders: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }),
      }),
      (response, dispatch) => {
        const { data } = response?.data;
        dispatch(setTeams({ data, errorMessage: "" }));
      },
      ({ error }, dispatch) => {
        dispatch(
          setTeams({ errorMessage: error?.data?.violations[0]?.message })
        );
      }
    ),
    deleteTeams: buildMutationHelper(
      builder,
      (id) => ({
        url: `/project-team/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
      (response, dispatch) => {
        const data = response?.data;
        dispatch(
          setTeams({
            teamsData: data,
            errorMessage: "",
          })
        );
      },
      ({ error }, dispatch) => {
        dispatch(setTeams({ errorMessage: error?.data?.error }));
      }
    ),
  }),
});
export const {
  useTeamsListMutation,
  useAddTeamsMutation,
  useEditTeamsMutation,
  useGetTeamsMutation,
  useDeleteTeamsMutation,
} = teamsApi;
