import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useProjectUserListMutation } from "../../store/action/projectUser";
import { useSelector } from "react-redux";
import ProjectUserRow from "./ProjectUserRow";
import {
  MessageBar,
  MessageBarBody,
  Spinner,
  mergeClasses,
} from "@fluentui/react-components";
import Layout from "../../styles/Layout.styles";
import Style from "./style";
import {
  useGetSystemEndUserListMutation,
  useProjectUserAssignMutation,
} from "../../store/action/systemEndUser";
import { useGetProjectMutation } from "../../store/action/project";
import ToasterComponent from "../../components/Toaster/ToasterComponent";
import { t } from "i18next";

const ProjectUserList = ({ projectId }) => {
  const [getUseProjectUserList, { isLoading }] = useProjectUserListMutation();
  const [getSystemEndUserList] = useGetSystemEndUserListMutation();
  const [projectTeamsUsersList, { isLoading: isUserLoading }] =
    useProjectUserAssignMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [options, setOptions] = useState([]);
  const [projectTeamsUsers, setProjectTeamsUsers] = useState([]);
  const projectEndUserList = useSelector((state) => state.projectUser);
  const [GetProjectApi, { isLoading: isProjectLoading }] =
    useGetProjectMutation();
  const [projectName, setProjectName] = useState(true);
  const [projectInitial, setProjectInitial] = useState(true);
  const [isToastMessage, setIsToastMessage] = useState(true);
  const layout = Layout();
  const style = Style();

  useEffect(() => {
    if (projectInitial) {
      if (!isProjectLoading) {
        GetProjectApi(projectId).then((res) => {
          setProjectName(res?.data);
          setProjectInitial(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectLoading]);

  useEffect(() => {
    if (initialLoad) {
      if (!isLoading) {
        getUseProjectUserList(projectId).then((res) => {
          let teamUserArray = [];
          res?.data?.forEach((item) => {
            teamUserArray.push({
              teamId: item?.id,
              userIds: item?.systemEndUsers?.map((i) => i?.id),
            });
          });
          setProjectTeamsUsers(teamUserArray);
        });
        getSystemEndUserList({ projectId: projectId }).then((res) => {
          const data = res?.data?.map?.((item, label) => ({
            children: item?.fullName,
            value: item?.id,
          }));
          setOptions(data);
        });
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, isLoading]);

  const onHandleProjectTeam = () => {
    setIsToastMessage(true);
    if (!isUserLoading) {
      const projectAssignData = {
        projectTeamsUsers,
      };

      projectTeamsUsersList({ projectAssignData: projectAssignData });
    }
  };
  return (
    <>
      <Stack>
        <MessageBar
          intent="transparent"
          className={mergeClasses("border-none", layout.mt_0, layout.pt_0)}
        >
          <MessageBarBody className={layout.py_1}>
            <h2 className={mergeClasses(style.projectHeader, layout.primary)}>
              {projectName?.title}
            </h2>
          </MessageBarBody>
        </MessageBar>

        <Stack
          className={mergeClasses(
            style.mainCard,
            style.settingDiv,
            "setting-options"
          )}
        >
          {isLoading ? (
            <Stack
              className={mergeClasses(layout.positionRelative, layout.w_100)}
            >
              <Spinner className={layout.m_auto} />
            </Stack>
          ) : (
            <>
              {projectEndUserList?.data?.map((item, key) => (
                <ProjectUserRow
                  key={key}
                  item={item}
                  options={options}
                  projectTeamsUsers={projectTeamsUsers}
                  setProjectTeamsUsers={setProjectTeamsUsers}
                />
              ))}
            </>
          )}
        </Stack>
        <Stack className={layout.mt_1}>
          <ToasterComponent
            type="submit"
            appearance="primary"
            className={mergeClasses(
              style.customBtn,
              "custom-primary",
              layout.m_auto
            )}
            onClick={onHandleProjectTeam}
            loading={isUserLoading}
            buttonLabel="Assign Project"
            isToastMessage={isToastMessage}
            successMessage={
              projectTeamsUsers?.flatMap((item) => {
                return item?.userIds;
              })?.length === 0
                ? t("user_not_assigned")
                : t("users_assign")
            }
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectUserList;
