import { createSlice } from "@reduxjs/toolkit";

const billing = createSlice({
  name: "billing",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    pagination: "",
  },

  reducers: {
    setBilling(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setBilling } = billing.actions;
export default billing.reducer;
