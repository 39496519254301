import React, { useState } from "react";
import ClientForm from "./ClientForm";

const AddClient = () => {
  const [userInput, setUserInput] = useState({ editData: {}, clientLogo: "" });

  return (
    <ClientForm
      editData={userInput.editData}
      setUserInput={setUserInput}
      userInput={userInput}
    />
  );
};

export default AddClient;
