import { Stack } from "@fluentui/react";
import { Breadcrumb } from "../../../components";
import { useState } from "react";
import TabListComponent from "../../../components/TabList/TabListComponent";
import { t } from "i18next";
import Layout from "../../../styles/Layout.styles";
import { mergeClasses } from "@fluentui/react-components";
import { staticStyle, useStyle } from "../../TimesheetAndAttandance/Style";
import Invoices from "./Invoices/Invoices";
import DateFilterInvoice from "./Invoices/DateFilterInvoice";
import { useBillingListMutation } from "../../../store/action/billing";

const BillingTabs = () => {
  const [selectedValue, setSelectedValue] = useState("invoices");
  const layout = Layout();
  const style = useStyle({ color: "red" });
  const [getBillingDataList, { isLoading }] = useBillingListMutation();
  staticStyle();

  const breadcrumbData = [
    {
      title: `${t("bills.billing")}`,
    },
    {
      title: `${t("bills.billsPayments")}`,
    },
  ];

  const tabListOption = [
    {
      id: "invoices",
      label: "Invoices",
      component: (
        <Invoices
          getBillingDataList={getBillingDataList}
          isLoading={isLoading}
        />
      ),
    },
  ];

  const onTabSelect = (_, data) => {
    setSelectedValue(data?.value);
  };

  return (
    <>
      <Stack className={mergeClasses(layout.flex_row, layout.flex_between)}>
        <Stack className={mergeClasses(layout.w_100, "customizeContent")}>
          <Stack
            className={mergeClasses(
              layout.alignCenter,
              layout.w_100,
              style.stackMainClass
            )}
          >
            <Breadcrumb
              data={breadcrumbData}
              className="workingTodayBreadcrumb"
            />
          </Stack>
          <Stack
            className={mergeClasses(style.timeAndAttendanceWrapper, style.root)}
          >
            <TabListComponent
              selectedValue={selectedValue}
              onTabSelect={onTabSelect}
              tabListOption={tabListOption}
              isFilter={
                <DateFilterInvoice
                  getBillingDataList={getBillingDataList}
                  isLoading={isLoading}
                />
              }
              // stackClass={style.filterWrapper}
              // tabListOptionClass={style.tableWrapper}
              // tabClass={mergeClasses(style.tabPadding, "custom-tabs")}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default BillingTabs;
