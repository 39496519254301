import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { t } from "i18next";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceClientContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
});

const InvoiceNo = ({ invoice, date }) => (
  <Fragment>
    <View style={styles.invoiceClientContainer}>
      <Text style={styles.label}>
        {invoice?.clientOrder?.client?.companyName}
      </Text>
    </View>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>{t("invoice.invoice_no")}</Text>
      <Text>{invoice?.invoiceNumber}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>{t("invoice.date")}</Text>
      <Text>{date}</Text>
    </View>
  </Fragment>
);

export default InvoiceNo;
