import { Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Form, FormButton, CustomDialog } from "../../../components";
import { useForm } from "react-hook-form";
import Layout from "../../../styles/Layout.styles";
import {
  Button,
  DialogTrigger,
  mergeClasses,
} from "@fluentui/react-components";
import Style from "../../Client/ClientManagement/AddClient.style";
import { useTranslation } from "react-i18next";
import StaticStyle from "../../Client/ClientManagement/Style";
import TeamsColorCode from "./Form/TeamsColorCode";
import TeamsName from "./Form/TeamsName";
import TeamsDescription from "./Form/TeamsDescription";
import {
  useAddTeamsMutation,
  useEditTeamsMutation,
  useTeamsListMutation,
} from "../../../store/action/teams";
import { useSelector } from "react-redux";
import { BASE_COLOR } from "../../../utils/constants";
import { trailWhiteSpace } from "../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../utils/errorHandler";

const TeamsForm = (props) => {
  const { teamId, isOpenDialog, setIsOpenDialog, projectId, userEditData } =
    props;
  const layout = Layout();
  const style = Style();
  const [addTeamsApi, { isLoading: isAddLoading }] = useAddTeamsMutation();
  const [editTeamsApi, { isLoading: isEditLoading }] = useEditTeamsMutation();
  const [getTeamsListApi] = useTeamsListMutation();
  const teamsList = useSelector((state) => state.teams);
  const [color, setColor] = useState(
    userEditData?.data?.colorCode || BASE_COLOR
  );

  const { t } = useTranslation();
  StaticStyle();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm();

  if (teamId && !userEditData?.data) {
    return null;
  }

  const onGetTeams = async (page, itemsPerPage) => {
    await getTeamsListApi({
      page: page,
      itemsPerPage: itemsPerPage,
      project: projectId,
    });
  };

  const getProjectId = /project/ + projectId;

  const commonProps = { register, errors };

  const onSubmit = (value) => {
    let trailData = trailWhiteSpace(value);
    const newTeamsData = {
      name: trailData?.name,
      project: getProjectId,
      colorCode: color,
      description: trailData?.description,
    };

    if (teamId) {
      if (!isEditLoading) {
        editTeamsApi({ teamsData: newTeamsData, id: teamId }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res?.error) {
            setIsOpenDialog(false);

            onGetTeams(teamsList?.page, teamsList?.itemsPerPage);
          }
        });
      }
    } else {
      if (!isAddLoading) {
        addTeamsApi({ teamsData: newTeamsData }).then((res) => {
          let errorLists = res?.error?.data?.violations;

          if (errorLists) {
            formErrorHandler("", errorLists, setError);
          }
          if (!res?.error) {
            setIsOpenDialog(false);
            onGetTeams(teamsList?.page, teamsList?.itemsPerPage);
          }
        });
      }
    }
  };

  return (
    <>
      <CustomDialog
        dialogTitle={teamId ? t("teams.edit_team") : t("teams.add_team")}
        isEdit={teamId ? true : false}
        dialogTitleClass=""
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
        dialogContent={
          <Form className={layout.w_100} onSubmit={handleSubmit(onSubmit)}>
            <Stack className={mergeClasses(style.mb_14, layout.w_100)}>
              <Stack className={mergeClasses(layout.textbox, layout.mb_15)}>
                <TeamsName {...commonProps} name={userEditData?.data?.name} />
                <TeamsColorCode
                  {...commonProps}
                  color={color}
                  setColor={setColor}
                  teamsColorCode={userEditData?.data?.colorCode}
                />
                <TeamsDescription
                  {...commonProps}
                  description={userEditData?.data?.description}
                />
              </Stack>
            </Stack>

            <Stack className={mergeClasses(layout.flex_row)}>
              <FormButton
                type="submit"
                size="medium"
                appearance="primary"
                className={mergeClasses(
                  "custom-primary",
                  layout.smallBtn,
                  layout.mr_1
                )}
              >
                {teamId ? t("update") : t("add")}
              </FormButton>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => setIsOpenDialog(false)}
                  className={mergeClasses("custom-primary", layout.smallBtn)}
                  loading={isEditLoading || isAddLoading}
                >
                  Cancel
                </Button>
              </DialogTrigger>
            </Stack>
          </Form>
        }
        dialogContentClass=""
        dialogClose="Close"
        dialogCloseClass=""
        dialogSubmit="Do Something"
        dialogSubmitClass="Do Something"
        appearance="primary"
      />
    </>
  );
};

export default TeamsForm;
