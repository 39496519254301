import Layout from "../../../styles/Layout.styles";
import { TableCell, TableRow } from "../../../components/Table";
import { Toaster, mergeClasses, useId } from "@fluentui/react-components";
import { Text, Stack } from "@fluentui/react";
import { timeFormatted } from "../../../utils/convert-date-time";

const LogTimeRow = (props) => {
  const { item, projectTnaDetails } = props;
  const layout = Layout();
  const toasterId = useId("toaster");

  return (
    <TableRow className={mergeClasses()}>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />

      <TableCell tabIndex={item?.key} role="gridcell">
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.alignCenter,
            layout.tableIcons,
            item?.isDeleted ? "disableIcons" : ""
          )}
        >
          <Text>
            {timeFormatted(item?.checkIn, projectTnaDetails?.timezone)}
          </Text>
        </Stack>
      </TableCell>
      <TableCell tabIndex={item?.key} role="gridcell">
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.alignCenter,
            layout.tableIcons,
            item?.isDeleted ? "disableIcons" : ""
          )}
        >
          <Text>
            {timeFormatted(item?.checkOut, projectTnaDetails?.timezone)}
          </Text>
        </Stack>
      </TableCell>
      <TableCell tabIndex={item?.key} role="gridcell">
        <Stack
          className={mergeClasses(
            layout.flex_row,
            layout.alignCenter,
            layout.tableIcons,
            item?.isDeleted ? "disableIcons" : ""
          )}
        >
          <Text>
            {item?.isEdited === true && "*"}
            {item?.logType === "app"
              ? "App"
              : item?.logType === "manual"
              ? "Manual"
              : "App-Manual"}
          </Text>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default LogTimeRow;
