import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const style = makeStyles({
  prodBtn: {
    minWidth: "51px",
  },
  headerLabel: {
    ...shorthands.padding("", "7px"),
  },
  projectContent: {
    ...shorthands.padding("20px"),
    ...shorthands.margin("30px", 0, 0, 0),
  },
  wordBreak: {
    wordWrap: "break-word",
  },
  employeeDetails: {
    minWidth: "400px",
    maxWidth: "400px",
    ...shorthands.padding("20px"),
    ...shorthands.margin("30px", 0, 0, "30px"),
    backgroundColor: "white",
    ...shorthands.overflow("auto"),
    "@media (max-width: 1366px)": {
      minWidth: "300px",
      position: "absolute",
      ...shorthands.overflow("auto"),
      top: "10px",
      left: "10px",
      right: "10px",
      maxWidth: "500px",
      ...shorthands.margin("20px", "auto"),
      zIndex: 9999,
    },
  },
  summaryBackground: {
    "@media (max-width: 1440px)": {
      backdropFilter: "blur(10px)",
      position: "absolute",
      ...shorthands.overflow("auto"),
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 9999,
    },
  },
  detailLbl: {
    minWidth: "45%",
    fontWeight: tokens.fontWeightSemibold,
    marginRight: "10px",
    color: "#475569",
    display: "inline-flex",
  },
  detailText: {
    minWidth: "50%",
    color: "#616161",
    display: "inline-flex",
    wordBreak: "break-word",
  },
  detailTitle: {
    marginTop: 0,
    display: "flex",
    flexDirection: "flex-between",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: tokens.fontWeightSemibold,
    fontSize: "18px",
  },
  detailsDivider: {
    maxHeight: "2px",
  },
  textAlign: {
    textAlign: "center",
    marginTop: "15px",
  },
});

export default style;
