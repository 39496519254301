import { t } from "i18next";

export const formErrorHandler = (
  preFix = "",
  errorList,
  setError,
  customField = false
) => {
  let buildErrorObj = errorHandler(preFix, errorList);
  appendErrorForm(buildErrorObj, setError, customField);
};

export const errorHandler = (preFix = "", errorList) => {
  const trailData = {};

  for (const key in errorList) {
    let keyName = errorList[key]?.propertyPath;

    let capitalizedString =
      preFix !== ""
        ? keyName.charAt(0).toUpperCase() + keyName.slice(1)
        : keyName;

    let message = errorList[key]?.message;

    //eslint-disable-next-line no-unused-vars
    let propertyPath = errorList[key]?.propertyPath;

    if (message.includes("already_exists")) {
      let valMessage = existPropertyTrans(message);
      trailData[preFix + capitalizedString] = {
        validationMessage: valMessage,
      };
    } else {
      trailData[preFix + capitalizedString] = {
        validationMessage: `${errorList[
          key
        ]?.propertyPath.toLowerCase()}_required`,
      };
    }
  }

  return trailData;
};

const existPropertyTrans = (message) => {
  let newKey = message;

  switch (message) {
    case message.includes("email_already_used"):
      newKey = "email_already_used";
      break;

    case message.includes("already_exists"):
      newKey = "email_already_used";
      break;

    case message.includes("location_name_already_used"):
      newKey = "location_name_already_used";
      break;

    case message.includes("already_used"):
      newKey = "email_already_used";
      break;

    default:
      newKey = message;
      break;
  }
  return newKey;
};

const fieldCustomName = {
  "contactPerson.firstName": "contactFirstName",
  "contactPerson.lastName": "contactLastName",
  "contactPerson.department": "contactDepartment",
  "accountPerson.firstName": "accountFirstName",
  "accountPerson.lastName": "accountLastName",
  "accountPerson.department": "accountDepartment",
  userEmail: "userEmailAddress",
};
// eslint-disable-next-line no-unused-vars
const checkFieldCustomName = () => "key" in fieldCustomName;

export const appendErrorForm = (buildErrorObj, setError, customField) => {
  for (const key in buildErrorObj) {
    if (customField) {
      setError(key in fieldCustomName ? fieldCustomName[key] : key, {
        type: "custom",
        message: t(`${buildErrorObj[key]?.validationMessage}`),
      });
    } else {
      setError(key, {
        type: "custom",
        message: t(`${buildErrorObj[key]?.validationMessage}`),
      });
    }
  }
};
