import { Text, mergeClasses } from "@fluentui/react-components";
import { TableCell, TableRow } from "../../../../components/Table";
import Layout from "../../../../styles/Layout.styles";
import {
  timeFormatted,
  dateFormatted,
} from "../../../../utils/convert-date-time";

const RejectRow = (props) => {
  const {
    employeeSelectedId,
    isEmployeeDetailsOpen,
    item,
    setEmployeeSelectedId,
    setIsEmployeeDetailsOpen,
  } = props;
  const layout = Layout();

  const onEmployeeDetails = () => {
    setIsEmployeeDetailsOpen(true);
    setEmployeeSelectedId(item.id);
  };

  const highLightRow = isEmployeeDetailsOpen && employeeSelectedId === item?.id;

  return (
    <>
      <TableRow
        key={item?.project?.label}
        className={mergeClasses(highLightRow ? "highLightRow" : "")}
      >
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          <Text
            truncate
            block
            className={mergeClasses(
              layout.textOverFlowHidden,
              layout.cursor_pointer,
              highLightRow ? "highLightRow" : ""
            )}
            title={item?.projectCode}
            onClick={onEmployeeDetails}
          >
            {item?.employeeName}
          </Text>
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.checkinDate &&
            dateFormatted(item?.checkinDate, item?.timezone)}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.clockCount}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {timeFormatted(item?.checkinDate, item?.timezone)}
        </TableCell>
        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {timeFormatted(item?.checkoutDate, item?.timezone)}
        </TableCell>

        <TableCell
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.totalHours?.toFixed(2) ?? "-"}
        </TableCell>
        <TableCell
          truncate
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.rejectedByName}
        </TableCell>
        <TableCell
          truncate
          tabIndex={item?.key}
          className={layout.customTblCell}
          role="gridcell"
        >
          {item?.rejectReason}
        </TableCell>
      </TableRow>
    </>
  );
};
export default RejectRow;
