import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { t } from "i18next";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 20,
    marginRight: 15,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
  },
  address: {
    display: "flex",
    flexDirection: "row",
  },
});

const BillTo = ({ invoice }) => (
  <>
    <View style={styles.address}>
      <View style={styles.headerContainer}>
        <Text style={styles.billTo}>{t("invoice.address")}</Text>
        <Text>{invoice?.clientOrder?.client?.companyName}</Text>
        <Text>{invoice?.clientOrder?.client?.companyAddress}</Text>
      </View>
      <View style={styles.headerContainer}>
        <Text style={styles.billTo}>{t("invoice.bill_to")}</Text>
        <Text>{invoice?.clientOrder?.client?.companyName}</Text>
        <Text>{invoice?.clientOrder?.client?.companyBillingAddress}</Text>
      </View>
    </View>
  </>
);

export default BillTo;
