import Style from "../../../../../Client/ClientManagement/AddClient.style";
import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import FieldsInput from "../../../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Layout from "../../../../../../styles/Layout.styles";

const LocationName = (props) => {
  const { locationName } = props;
  const style = Style();
  const layout = Layout;
  const { t } = useTranslation();
  return (
    <>
      <Stack className={mergeClasses(layout.textbox, layout.mb_1)}>
        <FieldsInput
          label={t("project.pl_location_name")}
          fieldClassName="custom-label"
          validationMessage={props?.errors?.locationName?.message}
          placeholder={t("project.pl_enter_location_name")}
          name="locationName"
          id="locationName"
          inputClassName={mergeClasses(style.textboxInput)}
          inputDefaultValue={locationName}
          validation={props.register("locationName", {
            required: `${t("validation_message.location_name_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default LocationName;
