import { Form, FormButton } from "../../components";
import ApiKey from "./Form/ApiKey";
import {
  Button,
  Checkbox,
  Divider,
  Image,
  mergeClasses,
  Spinner,
} from "@fluentui/react-components";
import Businesses from "./Form/Businesses";
import { useForm } from "react-hook-form";
import Layout from "../../styles/Layout.styles";
import { Stack } from "@fluentui/react";
import Style from "./ExternalSource.style";
import {
  useActiveInActiveExternalSourcesMutation,
  useGetClientExternalSourcesListMutation,
  useGetClientExternalSourcesMutation,
} from "../../store/action/clientExternalSources";
import { useEffect } from "react";
import { useState } from "react";
import { useGetExternalSourcesBusinessListMutation } from "../../store/action/externalSources";
import { t } from "i18next";
import close from "../../images/close.svg";

const ExternalSourceDetails = (props) => {
  const { setIsEditSourcesOpen, editExternalSourceId, notify } = props;
  const layout = Layout();
  const style = Style();
  const [selectedOptionId, setSelectedOptionId] = useState([]);

  const [editApiKey, setEditApiKey] = useState();
  const [webhookSettings, setWebhookSettings] = useState();
  const [fetchEmployees, setFetchEmployees] = useState();
  const [externalDepartmentArray, setExternalDepartmentArray] = useState([]);
  const [editBusinessOptions, setEditBusinessOptions] = useState([]);
  const [editBusinessDataList, { isLoading: editBusinessLoading }] =
    useGetExternalSourcesBusinessListMutation();
  const [GetClientExternalSource, { isLoading }] =
    useGetClientExternalSourcesMutation();
  const [clientExternalSourceListApi] =
    useGetClientExternalSourcesListMutation();
  const [updateExternalSource, { isLoading: updateExternalSourceLoading }] =
    useActiveInActiveExternalSourcesMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const commonProps = { register, errors, setValue };

  useEffect(() => {
    if (editExternalSourceId && !editBusinessLoading) {
      editBusinessDataList(editExternalSourceId).then((res) => {
        const data = res?.data?.map?.((item, label) => ({
          children: item?.name,
          value: item?.id,
          contactName: item?.contactName,
        }));
        setEditBusinessOptions(data);
      });

      GetClientExternalSource(editExternalSourceId).then((res) => {
        res?.data?.clientExternalConnections?.map((item, key) => {
          item?.clientDepartments?.map(
            (option, key) =>
              option?.isActive &&
              selectedOptionId.push(option?.externalSourceRef)
          );
          const data = item?.clientDepartments
            .filter((item) => item.isActive)
            .map((item) => ({
              id: item?.externalSourceRef,
              name: item?.name,
              contactName: item?.contactName,
            }));

          setExternalDepartmentArray(data);
          setWebhookSettings(item?.action?.webhook);
          setFetchEmployees(item?.action?.fetch_employee);
          setEditApiKey(item?.externalApiKey);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetClientExternalSource, editBusinessDataList, editExternalSourceId]);

  const onSubmit = (value) => {
    const externalSourcesData = {
      isActive: true,
      externalDepartments: externalDepartmentArray,
      action: {
        webhook: webhookSettings,
        fetch_employee: fetchEmployees,
      },
    };

    if (!updateExternalSourceLoading) {
      updateExternalSource({
        externalSourcesData: externalSourcesData,
        id: editExternalSourceId,
      }).then((res) => {
        if (!isLoading) {
          clientExternalSourceListApi();
          notify(t("update"), true);
          setIsEditSourcesOpen(false);
        } else {
          notify(t("not updated"), false);
        }
      });
    }
  };

  if (editBusinessOptions?.length === 0) {
    return "";
  }
  return (
    <>
      <Stack
        className={mergeClasses(
          style.clientContent,
          style.externalSourceDetails,
          "cardShadow"
        )}
      >
        <h2 className={mergeClasses(style.ExternalSourceTitle, layout.primary)}>
          {t("externalSources.externalSources_details")}
          <Button
            appearance="subtle"
            onClick={() => setIsEditSourcesOpen(false)}
            aria-label="close"
            icon={<Image alt="" src={close} height={20} width={20} />}
          />
        </h2>
        {isLoading || editBusinessLoading ? (
          <Stack className={mergeClasses(layout.w_100, style.client_field)}>
            <Spinner className={layout.m_auto} />
          </Stack>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ApiKey
              {...commonProps}
              editExternalSourceId={editExternalSourceId}
              editApiKey={editApiKey}
            />

            <h3 className={layout.tokenColor}>Configure entities</h3>
            <Divider className={layout.mb_1_0} />

            <Businesses
              {...commonProps}
              businessOptions={editBusinessOptions}
              editExternalSourceId={editExternalSourceId}
              setSelectedOptionId={setSelectedOptionId}
              selectedOptionId={selectedOptionId}
              setExternalDepartmentArray={setExternalDepartmentArray}
            />

            <h3 className={layout.tokenColor}>Actions </h3>
            <Divider className={layout.mb_1_0} />
            <Checkbox
              name="webhookSettings"
              label="Webhook settings"
              onChange={(e) => setWebhookSettings(e?.target?.checked)}
              checked={webhookSettings}
            />
            <Checkbox
              name="fetchEmployees"
              label="Fetch employees"
              onChange={(e) => setFetchEmployees(e?.target?.checked)}
              checked={fetchEmployees}
            />

            <Stack className={style.formfooter}>
              <FormButton
                type="submit"
                appearance="primary"
                className={mergeClasses(style.customBtn, "custom-primary")}
                loading={updateExternalSourceLoading}
                disabled={selectedOptionId?.length === 0}
              >
                Save
              </FormButton>
              <FormButton
                onClick={() => setIsEditSourcesOpen(false)}
                className={mergeClasses(style.customBtn, "custom-primary")}
              >
                Cancel
              </FormButton>
            </Stack>
          </Form>
        )}
      </Stack>
    </>
  );
};

export default ExternalSourceDetails;
