import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const style = makeStyles({
  prodBtn: {
    minWidth: "51px",
    ...shorthands.margin(0, "20px", 0, 0),
  },
  headerLabel: {
    ...shorthands.padding("", "7px"),
  },
  projectContent: {
    ...shorthands.padding("20px"),
    ...shorthands.margin("30px", 0, 0, 0),
  },
  wordBreak: {
    wordWrap: "break-word",
  },
  headerSearch: {
    flexDirection: "row-reverse",
    "@media screen and (min-width: 600px) and (max-width: 1024px)": {
      maxWidth: "250px",
    },
    "@media screen and (min-width: 1025px)": {
      ...shorthands.margin("", "28px", "", ""),
    },
    minWidth: "250px",
  },
  userMainDetails: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width: 1280px)": {
      flexDirection: "column",
    },
  },
  detailsText: {
    display: "flex",
    "@media (max-width: 991px)": {
      flexDirection: "column",
    },
  },
  detailsLeft: {
    textAlign: "center",
    marginBottom: "14px",
    fontWeight: tokens.fontWeightSemibold,
  },
  userViewLeft: {
    ...shorthands.border("1px", "solid", "#E2E8F0"),
    minWidth: "400px",
    maxWidth: "400px",
    marginRight: "20px",
    wordBreak: "break-word",
    borderRadiusMedium: tokens.borderRadiusMedium,
    "@media (max-width: 1280px)": {
      width: "100%",
      minWidth: "400px",
      maxWidth: "none",
    },
    "@media (max-width: 600px)": {
      minWidth: "auto",
    },
  },
  userViewRight: {
    width: "calc( 100% - 400px )",
    height: "100%",
    "@media (min-width: 1280px)": {
      flexDirection: "column",
    },
    wordBreak: "break-word",
    borderRadiusMedium: tokens.borderRadiusMedium,
    ...shorthands.border("1px", "solid", "#E2E8F0"),
    "@media (max-width: 1280px)": {
      width: "100%",
    },
  },
  userTitle: {
    fontSize: "24px",
    color: tokens.colorBrandBackgroundPressed,
    marginTop: "15px",
    marginBottom: "10px",
  },
  textElement: {
    width: "100%",
    fontWeight: tokens.fontWeightSemibold,
    maxWidth: "200px",
    wordBreak: "break-word",
    color: "#475569",
  },
  textDetails: {
    width: "100%",
    maxWidth: "200px",
    wordBreak: "break-word",
    color: tokens.colorNeutralForeground3,
  },
});

export default style;
