import * as React from "react";
import {
  Button,
  Image,
  Input,
  mergeClasses,
  Text,
  Toast,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import trash from "../../images/trash.svg";
import { useState, useRef, useEffect } from "react";
import Layout from "../../styles/Layout.styles";
import imageTimeStamp from "../../utils/imageTimeStamp";

const CsvUpload = ({
  accept,
  label,
  setFileCallback,
  photo,
  setIsDeleteLogo,
  placeholder,
  className,
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(photo);
  const [selectedFileImg, setSelectedFileImg] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [fileUploadSize, setFileUploadSize] = useState();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [isUploaded, setIsUploaded] = useState(false);

  const inputRef = useRef();
  const layout = Layout();

  const notify = (message, valid) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`${message}`}</ToastTitle>
      </Toast>,
      { intent: valid ? "success" : "error" }
    );
  };

  const handleFileUpload = (e) => {
    setFileUploadSize(e?.target?.size <= 2 * 1024 * 1024);
    if (
      (e?.target?.files[0]?.type === "image/jpeg" ||
        e?.target?.files[0]?.type === "image/jpg" ||
        e?.target?.files[0]?.type === "image/png" ||
        e?.target?.files[0]?.type === "image/gif") &&
      e?.target?.files[0]?.size <= 2 * 1024 * 1024
    ) {
      setIsUploaded(false);
      setFileCallback(e.target.files[0]);
      setSelectedFile(URL.createObjectURL(e.target.files[0]));

      setIsDeleteLogo(false);

      notify(t("image_upload"), true);
      setSelectedFileImg(true);
    } else {
      notify(t("upload_image_format"), false);
      setSelectedFileImg(false);
    }
  };

  useEffect(() => {
    setIsUploaded(true);
  }, []);

  const fileDelete = (e) => {
    setSelectedFile(null);
    setSelectedFileImg(false);
    setIsDeleteLogo(true);
  };

  const handleClick = () => {
    inputRef?.current?.click();
    return false;
  };

  return (
    <div className={mergeClasses("custom-label", layout.m_auto, className)}>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <Input
        type="file"
        className={mergeClasses(layout.w_100, "customFile")}
        onChange={handleFileUpload}
        ref={inputRef}
        accept={accept}
        contentAfter={
          <>
            <Text className="customFilePlaceholder"> {placeholder}</Text>
            <Button appearance="primary" type="button" onClick={handleClick}>
              {t("upload")}
            </Button>
          </>
        }
      />
      <br />
      {selectedFileImg || selectedFile ? (
        <>
          <Image
            shape="circular"
            height={48}
            width={48}
            className={layout.mt_1}
            src={isUploaded ? imageTimeStamp(selectedFile) : selectedFile}
          />
          <Image
            alt=""
            style={{
              marginLeft: "-10px",
              marginBottom: "20px",
              borderRadius: "16px",
              boxShadow0px: "0px 4px 0px rgba(0, 0, 0, 0.25)",
              height: "20px",
              backgroundColor: "white",
              cursor: "pointer",
            }}
            src={trash}
            height={18}
            width={18}
            onClick={fileDelete}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CsvUpload;
