import React from "react";
import { useParams } from "react-router-dom";
import ViewSystemUserFrom from "./ViewSystemUserFrom";

const ViewSystemUser = () => {
  const { id } = useParams();
  return <ViewSystemUserFrom id={id} />;
};

export default ViewSystemUser;
