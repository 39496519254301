import { mergeClasses, tokens, Tooltip } from "@fluentui/react-components";
import React from "react";
import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuPopover,
  MenuItem,
  Divider,
} from "@fluentui/react-components";
import { headerStyle } from "./Style";
import Layout from "../../styles/Layout.styles";
import { Link, NavLink, useLocation } from "react-router-dom";
import HeaderDropdownMenu from "./HeaderDropdownMenu";
import { Stack } from "@fluentui/react";
import { ReactComponent as GearBlue } from "./../Sidebar/images/blue/gear-blue.svg";

const BottomSettingDropdown = (props) => {
  const { sidebarOpen, sideBarTabName } = props;
  const layout = Layout();

  const style = headerStyle();

  let crossAxis = sidebarOpen ? 20 : 50;
  let mainAxis = sidebarOpen ? 20 : -25;

  const location = useLocation();

  const activeSettingBold = HeaderDropdownMenu().find((menu, key) => {
    return location.pathname.includes(menu?.url);
  });

  return (
    <Menu positioning={{ position: "after", offset: { crossAxis, mainAxis } }}>
      <MenuTrigger disableButtonEnhancement className={layout.w_100}>
        <Button
          appearance="transparent"
          className={mergeClasses(
            layout.sidebarLink,
            "side-bottom-link",
            layout.p_0,

            layout.justifyContentStart
          )}
        >
          <Stack
            className={mergeClasses(
              layout.d_flex,
              layout.flex_row,
              "side-bottom-link"
            )}
          >
            {!sidebarOpen ? (
              <Tooltip withArrow content={"Settings"}>
                <GearBlue
                  height={24}
                  width={24}
                  style={{ borderLeft: activeSettingBold && "3px solid " }}
                  className={
                    (activeSettingBold && layout.fontWeight, layout.pl_8)
                  }
                />
              </Tooltip>
            ) : (
              <>
                <GearBlue
                  height={24}
                  width={24}
                  style={{ borderLeft: activeSettingBold && "3px solid " }}
                  className={
                    (activeSettingBold && layout.fontWeight, layout.pl_10)
                  }
                />
                <span className={activeSettingBold && layout.fontWeight}>
                  {sideBarTabName?.name}
                </span>
              </>
            )}
          </Stack>
        </Button>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {HeaderDropdownMenu().map((menu, key) => {
            let visibleMenu = HeaderDropdownMenu().filter(menuVisible);

            function menuVisible(menu) {
              return menu.visible === true;
            }

            return menu?.child?.length && menu?.visible ? (
              <Menu key={key}>
                <MenuPopover>
                  <MenuList>
                    {menu?.child?.map(
                      (subMenu, subMenuKey) =>
                        subMenu?.visible && (
                          <MenuItem
                            value={subMenu.key}
                            className="headerRightMenuItem"
                            key={subMenuKey}
                          >
                            <Link
                              key={subMenu.key}
                              className={mergeClasses(
                                style.headerLink,
                                layout.w_100,
                                layout.mb_0,
                                "headerLinkResp"
                              )}
                              to={subMenu.url || "#"}
                            >
                              {subMenu.name}
                            </Link>
                          </MenuItem>
                        )
                    )}
                  </MenuList>
                </MenuPopover>
              </Menu>
            ) : (
              menu?.visible && (
                <React.Fragment key={menu.key}>
                  <Stack className={layout.m_0_5}>
                    <NavLink
                      key={menu.key}
                      style={({ isActive }) => {
                        return isActive
                          ? {
                              fontWeight: "bold",
                              color: tokens.colorBrandBackground,
                            }
                          : {};
                      }}
                      to={menu.url}
                    >
                      {menu.name}
                    </NavLink>
                  </Stack>
                  {visibleMenu?.length === 0 ? "" : <Divider />}
                </React.Fragment>
              )
            );
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
export default BottomSettingDropdown;
