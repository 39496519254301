import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { useTranslation } from "react-i18next";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddProject.style";
import { Stack } from "@fluentui/react";

const SiteContact = (props) => {
  const { siteContact } = props;
  const { t } = useTranslation();
  const style = Style();
  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("project.site_contact")}
          validationMessage={props.errors?.siteContact?.message}
          id="siteContact"
          placeholder={t("project.enter_site_contact")}
          name="siteContact"
          inputDefaultValue={siteContact}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("siteContact", {
            required: `${t("validation_message.site_contact_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default SiteContact;
