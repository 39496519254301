import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const style = makeStyles({
  customTable: {
    borderRadiusMedium: tokens.borderRadiusMedium,
    ...shorthands.border("1px", "solid", "#E2E8F0"),
    "@media (max-width: 1024px)": {
      ...shorthands.overflow("auto", "auto"),
    },
  },
  customHeader: {
    ...shorthands.borderTop("1px", "solid", "#E2E8F0"),
    ...shorthands.borderRight("1px", "solid", "#E2E8F0"),
    borderRadiusMedium: tokens.borderRadiusMedium,
  },
  customTblCell: {
    ...shorthands.padding("", "12px"),
    borderRadiusMedium: tokens.borderRadiusMedium,
    ...shorthands.borderRight("1px", "solid", "#E2E8F0"),
  },
  columnHeader: {
    ...shorthands.padding("", "12px"),
    ...shorthands.borderRight("1px", "solid", "#E2E8F0"),
  },
  spinnerTableRow: {
    position: "relative",
    height: "55px",
  },
  spinnerTableCol: {
    position: "absolute",
    ...shorthands.padding(0),
    width: "100%",
  },
});

export default style;
