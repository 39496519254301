import { Stack } from "@fluentui/react";
import { Breadcrumb } from "../../../components";
import { useStyle, staticStyle } from "../Style";
import { useEffect, useState } from "react";
import { mergeClasses } from "@fluentui/react-components";
import TabListComponent from "../../../components/TabList/TabListComponent";
import { useGetAssignedProjectsMutation } from "../../../store/action/project";
import TimeSheetAndAttendanceReject from "./reject";
import TimeSheetAndAttendancePending from "./pending/pending";
import AssignedProject from "./AssignedProject";
import TimeSheetAndAttendanceApproved from "./approved/approved";
import Layout from "../../../styles/Layout.styles";
import TimeSheetActions from "./TimeSheetActions";
import EmployeeDetails from "./Sidebar/EmployeeDetails";
import { setTimeAndAttendance } from "../../../store/reducer/time-and-attendance";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

const WhoIsWorkingToday = () => {
  const style = useStyle({ color: "red" });
  staticStyle();
  const [selectedValue, setSelectedValue] = useState("review");
  const [GetAssignedProject] = useGetAssignedProjectsMutation();
  const [options, setOptions] = useState([]);
  const [assignedProjectId, setAssignedProjectId] = useState();
  const [projectSelectedId, setProjectSelectedId] = useState([]);
  const [employeeSelectedId, setEmployeeSelectedId] = useState();
  const [isEmployeeDetailsOpen, setIsEmployeeDetailsOpen] = useState(false);
  const timeAndAttendanceList = useSelector(
    (state) => state.timeAndAttendancePending
  );
  const [checked, setChecked] = useState(
    timeAndAttendanceList?.data?.length !== 0 &&
      timeAndAttendanceList?.data?.length !==
        [...new Set(projectSelectedId)]?.length
  );
  const dispatch = useDispatch();

  const layout = Layout();
  useEffect(() => {
    GetAssignedProject().then((res) => {
      const data = res?.data?.map?.((item, label) => ({
        children: item?.title,
        value: item?.id,
      }));
      setOptions(data);
      dispatch(
        setTimeAndAttendance({
          page: 0,
          projectId: res?.data[0]?.id,
        })
      );
      setAssignedProjectId(res?.data[0]?.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabListOption = [
    {
      id: "review",
      label: t("pending_for_review"),
      component: (
        <TimeSheetAndAttendancePending
          employeeSelectedId={employeeSelectedId}
          assignedProjectId={assignedProjectId}
          setEmployeeSelectedId={setEmployeeSelectedId}
          setProjectSelectedId={setProjectSelectedId}
          projectSelectedId={projectSelectedId}
          setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
          setChecked={setChecked}
          checked={checked}
          isEmployeeDetailsOpen={isEmployeeDetailsOpen}
        />
      ),
    },
    {
      id: "approved",
      label: t("approved"),
      component: (
        <TimeSheetAndAttendanceApproved
          assignedProjectId={assignedProjectId}
          employeeSelectedId={employeeSelectedId}
          setEmployeeSelectedId={setEmployeeSelectedId}
          setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
          isEmployeeDetailsOpen={isEmployeeDetailsOpen}
        />
      ),
    },
    {
      id: "reject",
      label: t("reject"),
      component: (
        <TimeSheetAndAttendanceReject
          employeeSelectedId={employeeSelectedId}
          assignedProjectId={assignedProjectId}
          setEmployeeSelectedId={setEmployeeSelectedId}
          setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
          isEmployeeDetailsOpen={isEmployeeDetailsOpen}
        />
      ),
    },
  ];

  const onTabSelect = (_, data) => {
    setSelectedValue(data?.value);
    setIsEmployeeDetailsOpen(false);
    setProjectSelectedId([]);
    setChecked(false);
  };

  const breadcrumbData = [
    {
      title: t("time_and_attendance"),
    },
    {
      title: t("who_is_working_today"),
    },
  ];

  return (
    <>
      <Stack className={mergeClasses(layout.flex_row, layout.flex_between)}>
        <Stack
          className={mergeClasses(
            layout.w_100,
            "customizeContent review-timelog",
            isEmployeeDetailsOpen && "modalOpen"
          )}
        >
          <Stack
            className={mergeClasses(
              layout.alignCenter,
              layout.w_100,
              style.stackMainClass
            )}
          >
            <Breadcrumb
              data={breadcrumbData}
              className="workingTodayBreadcrumb"
            />
            <AssignedProject
              options={options}
              assignedProjectId={assignedProjectId}
              setAssignedProjectId={setAssignedProjectId}
              setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
              setSelectedValue={setSelectedValue}
              setChecked={setChecked}
            />
          </Stack>
          <Stack
            className={mergeClasses(style.timeAndAttendanceWrapper, style.root)}
          >
            <TabListComponent
              selectedValue={selectedValue}
              onTabSelect={onTabSelect}
              tabListOption={tabListOption}
              stackClass={mergeClasses(
                style.filterWrapper,
                "timeAndAttendanceWrap"
              )}
              tabListOptionClass={style.tableWrapper}
              tabClass={mergeClasses(
                style.tabPadding,
                layout.primary,
                "custom-tabs"
              )}
              isFilter={
                <TimeSheetActions
                  setProjectSelectedId={setProjectSelectedId}
                  projectSelectedId={projectSelectedId}
                  assignedProjectId={assignedProjectId}
                  selectedValue={selectedValue}
                  setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
                />
              }
            />
          </Stack>
        </Stack>

        {isEmployeeDetailsOpen && (
          <Stack
            className={mergeClasses(
              style.modalBackground,
              isEmployeeDetailsOpen && "detailsOpen"
            )}
          >
            <EmployeeDetails
              employeeSelectedId={employeeSelectedId}
              setIsEmployeeDetailsOpen={setIsEmployeeDetailsOpen}
              assignedProjectId={assignedProjectId}
              selectedValue={selectedValue}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default WhoIsWorkingToday;
