import * as React from "react";

import { Field, Textarea, mergeClasses } from "@fluentui/react-components";

const FieldsTextarea = (props) => {
  const {
    label,
    fieldProps,
    validationMessage,
    validationMessageIcon = "",
    size,
    noRequired,
    placeholder,
    value,
    name,
    inputClassName,
    inputDefaultValue,
    validation,
    id,
    disabled,
    maxLength,
    className,
  } = props;
  return (
    <>
      <Field
        label={label}
        validationMessage={validationMessage}
        className={mergeClasses("custom-label", className)}
        validationMessageIcon={validationMessageIcon}
        size={size}
        required={noRequired ? undefined : true}
        {...fieldProps}
      >
        <Textarea
          id={id}
          size={size}
          className={inputClassName}
          name={name}
          value={value}
          maxLength={maxLength}
          defaultValue={inputDefaultValue}
          disabled={disabled}
          placeholder={placeholder}
          {...validation}
        />
      </Field>
    </>
  );
};

export default FieldsTextarea;
