import React, { useEffect, useState } from "react";
import { useGetClientLogoMutation } from "../../../store/action/client";
import { useGetClientMutation } from "../../../store/action/client";
import ClientForm from "./ClientForm";

const ClientFormWrapper = (props) => {
  const { id } = props;
  const [userInput, setUserInput] = useState({
    initialLoad: false,
    editData: {},
    clientLogo: "",
  });
  const [getClientApi, { isLoading: isGetLoading }] = useGetClientMutation();
  const [getClientLogoApi] = useGetClientLogoMutation();

  useEffect(() => {
    if (!userInput.initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getInitialDataFromApi();
        }
      }
      setUserInput({ ...userInput, initialLoad: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialDataFromApi = async () => {
    const { data: { imageUrl = "" } = {} } = await getClientLogoApi(props?.id);
    await getClientApi(props?.id).then((res) => {
      setUserInput({ ...userInput, editData: res, clientLogo: imageUrl });
    });
  };
  if (id && !userInput?.editData?.data) {
    return null;
  }

  return (
    <ClientForm
      editData={userInput.editData}
      setUserInput={setUserInput}
      userInput={userInput}
      id={id}
    />
  );
};

export default ClientFormWrapper;
