import * as React from "react";
import { Field, Image, Input, mergeClasses } from "@fluentui/react-components";
import Datetime from "react-datetime";
import { Controller } from "react-hook-form";
import clockIcon from "../../images/clock-icon.svg";
import { Stack } from "@fluentui/react";
import { useState } from "react";
import Layout from "../../styles/Layout.styles";

const FieldTimePicker = ({
  label,
  name,
  noRequired,
  control,
  value,
  onChange,
  errorField,
  className,
  errorMsgClass,
  requiredMsg,
}) => {
  const layout = Layout();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setTimeout(function () {
      setIsOpen(true);
    }, 250);
  };
  const handleClose = () => {
    setTimeout(function () {
      setIsOpen(false);
    }, 250);
  };
  const onFocus = () => {
    setIsOpen(true);
  };

  const renderInput = (props, openCalendar, closeCalendar) => {
    const onClick = () => {
      if (isOpen) {
        closeCalendar();
      } else {
        openCalendar();
      }
    };

    return (
      <Input
        {...props}
        key={name}
        placeholder="HH:mm"
        className={mergeClasses(className, errorField && "errorBorder")}
        onClick={onClick}
        onBlurCapture={onFocus}
        contentAfter={
          <Image onClick={onClick} className="clockIcon" src={clockIcon} />
        }
        readOnly
      />
    );
  };

  return (
    <Field
      label={label}
      className={mergeClasses("custom-label")}
      required={noRequired ? undefined : true}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Stack className="dateWithIcon">
              <Datetime
                key={name}
                timeFormat="HH:mm"
                value={value}
                dateFormat={false}
                input={true}
                closeOnClickOutside={true}
                onOpen={handleOpen}
                onClose={handleClose}
                renderInput={renderInput}
                onChange={onChange}
              />{" "}
            </Stack>
            {errorField && (
              <p
                className={mergeClasses(
                  layout.colorEbc2f32,
                  layout.mt_0,
                  layout.fontSize12,
                  errorMsgClass
                )}
              >
                {errorField.message}
              </p>
            )}
          </>
        )}
        rules={{ required: requiredMsg }}
      />
    </Field>
  );
};

export default FieldTimePicker;
