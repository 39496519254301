import {
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
} from "@fluentui/react-components";
import * as React from "react";
import { FormButton } from "../FieldComponents";

const ToasterComponent = ({
  buttonLabel,
  successMessage,
  appearance,
  type,
  className,
  onClick,
  isToastMessage,
  rejectMessage,
  loading,
}) => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const notify = () => {
    dispatchToast(
      <Toast>
        <ToastTitle>
          {isToastMessage ? successMessage : rejectMessage}
        </ToastTitle>
      </Toast>,
      { isToastMessage, intent: isToastMessage ? "success" : "error" }
    );
  };

  return (
    <>
      <Toaster position="top" timeout={3000} toasterId={toasterId} />
      <FormButton
        type={type}
        appearance={appearance}
        className={className}
        loading={loading}
        onClick={() => {
          notify();
          onClick();
        }}
      >
        {buttonLabel}
      </FormButton>
    </>
  );
};

export default ToasterComponent;
