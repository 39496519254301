import { API_HOST } from "./constants";

const removeTrailingSlash = (str) => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

export const urlBuilder = (endPoint, appParams) => {
  let params = new URL(API_HOST);
  params.pathname = removeTrailingSlash(params.pathname) + endPoint;
  appParams.forEach((i) => {
    if (typeof Object.values(i)[0] !== "undefined") {
      params.searchParams.append(Object.keys(i)[0], Object.values(i)[0]);
    }
  });

  return params.toString();
};
