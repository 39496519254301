import React from "react";
import { Routes, Route } from "react-router-dom";
import routing from "../Routing";

const Content = () => {
  return (
    <Routes>
      {routing.map((route, key) => (
        <Route key={key} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default Content;
