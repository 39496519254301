import * as React from "react";
import { Stack } from "@fluentui/react";
import Style from "./addSystemUser";
import { useTranslation } from "react-i18next";
import FileUpload from "../../../../components/FieldComponents/FileUpload";

const SystemUserPhotoUpload = (props) => {
  const { setFileCallback, userPhoto, setIsDeletePhoto } = props;
  const { t } = useTranslation();
  const style = Style();

  return (
    <Stack className={style.client_field}>
      <FileUpload
        name={"fileUpload"}
        accept="image/png, image/jpeg, image/jpg,image/gif"
        label={t("photo_label")}
        placeholder={t("upload_photo")}
        setFileCallback={setFileCallback}
        photo={userPhoto}
        setIsDeleteLogo={setIsDeletePhoto}
      />
    </Stack>
  );
};

export default SystemUserPhotoUpload;
