import { createSlice } from "@reduxjs/toolkit";

const theme = createSlice({
  name: "theme",
  initialState: {
    data: [],
    errorMessage: "",
    page: 0,
    itemsPerPage: 10,
    themeCode: "",
    search: "",
  },
  reducers: {
    setTheme(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setTheme } = theme.actions;
export default theme.reducer;
