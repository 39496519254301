import { mergeClasses } from "@fluentui/react-components";
import Style from "../../AddClient.style";
import { Stack } from "@fluentui/react";
import FieldTimeZoneSelect from "../../../../../components/FieldComponents/FieldTimeZoneSelect";
import { t } from "i18next";

const ContactTimeZone = (props) => {
  const { contactTimeZone, timeZoneSelectContactData } = props;
  const style = Style();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldTimeZoneSelect
          label={t("time_zone")}
          validationMessage={props.errors?.contactTimeZone?.message}
          id="contactTimeZone"
          placeholder={t("enter_time_zone")}
          name="contactTimeZone"
          timeZoneSelectListData={timeZoneSelectContactData}
          inputDefaultValue={contactTimeZone}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={props.register("contactTimeZone", {
            required: `${t("validation_message.timezone_is_required")}`,
          })}
        />
      </Stack>
    </>
  );
};

export default ContactTimeZone;
