import FieldsInput from "../../../../components/FieldComponents/FieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../AddClient.style";
import { Stack } from "@fluentui/react";
import { EMAIL_VALIDATION_PATTERN } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";

const Email = (props) => {
  const { email, isDisable } = props;
  const { t } = useTranslation();
  const style = Style();

  return (
    <>
      <Stack className={style.client_field}>
        <FieldsInput
          label={t("email_address")}
          required
          validationMessage={props.errors?.email?.message}
          inputType="email"
          id="email"
          placeholder={t("enter_email_address")}
          name="email"
          disabled={isDisable}
          inputDefaultValue={email}
          inputClassName={mergeClasses(style.textboxInput)}
          validation={
            !isDisable
              ? props.register("email", {
                  required: `${t(
                    "validation_message.account_email_is_required"
                  )}`,
                  pattern: {
                    value: EMAIL_VALIDATION_PATTERN,
                    message: `${t(
                      "validation_message.email_not_match_format"
                    )}`,
                  },
                })
              : ""
          }
        />
      </Stack>
    </>
  );
};

export default Email;
