import React from "react";
import { mergeClasses } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import Breadcrumb from "../../../../components/Breadcrumb";
import Layout from "../../../../styles/Layout.styles";
import Style from "./Style";
import LocationFilter from "./LocationFilter";
import Location from "./Location";
import { useEffect } from "react";
import { useState } from "react";
import { useLocationListMutation } from "../../../../store/action/location";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import accessRole from "../../../../components/Common/accessRole";
import TabListComponent from "../../../../components/TabList/TabListComponent";
import BusinessLocation from "../../../BusinessLocation/BusinessLocation";

const LocationWrapper = () => {
  const { id } = useParams();
  const role = accessRole();

  const layout = Layout();
  const style = Style();
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedValue, setSelectedValue] = useState("location");
  const [getLocationListApi] = useLocationListMutation();
  const locationList = useSelector((state) => state.location);

  const breadcrumbData = [
    {
      title: `${t("project.project")}`,
      url: "/project-list",
    },
    {
      title: `${t("location")}`,
    },
  ];

  useEffect(() => {
    if (initialLoad) {
      if (id) {
        getLocationDataListApi(locationList?.page, locationList?.itemsPerPage);
        setInitialLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, getLocationListApi, id, locationList]);

  const getLocationDataListApi = async (page, itemsPerPage) => {
    await getLocationListApi({
      page: page,
      itemsPerPage: itemsPerPage,
      project: id,
    });
  };

  const tabListOption = [
    {
      id: "location",
      label: `${t("location")}`,
      component: <Location projectId={id} role={role} />,
    },
    {
      id: "businessLocation",
      label: `${t("business_location")}`,
      component: <BusinessLocation projectId={id} />,
    },
  ];

  const onTabSelect = (_, data) => {
    setSelectedValue(data?.value);
  };

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack
        className={mergeClasses(
          style.locationContent,
          layout.projectContent,
          layout.w_100,
          "cardShadow"
        )}
      >
        <TabListComponent
          selectedValue={selectedValue}
          stackClass={style.contentTabExport}
          onTabSelect={onTabSelect}
          tabListOption={tabListOption}
          isFilter={
            selectedValue === "location" && (
              <LocationFilter projectId={id} role={role} />
            )
          }
        />
      </Stack>
    </>
  );
};

export default LocationWrapper;
