import CustomFieldsInput from "../../../../../components/FieldComponents/CustomFieldsInput";
import { mergeClasses } from "@fluentui/react-components";
import Style from "../../AddClient.style";
import { Stack } from "@fluentui/react";
import { NUMBER_VALIDATION_16SIZE_PATTERN } from "../../../../../utils/constants";
import { useTranslation } from "react-i18next";
import Layout from "../../../../../styles/Layout.styles";
import FieldSelectPhoneCode from "../../../../../components/FieldComponents/FieldSelectPhoneCode";
import { blockInvalidCharPhone } from "../../../../../utils/blockInvalidCharPhone";

const AccountPhoneNumber = (props) => {
  const {
    accountPhonePrefix,
    selectPhonePrefixValue,
    selectChange,
    value,
    onChange,
  } = props;
  const style = Style();
  const { t } = useTranslation();
  const layout = Layout();
  return (
    <>
      <Stack className={style.client_field}>
        <Stack className={layout.flex_row}>
          <FieldSelectPhoneCode
            name={accountPhonePrefix}
            value={selectPhonePrefixValue}
            onChange={selectChange}
            inputDefaultValue={
              selectPhonePrefixValue ? selectPhonePrefixValue : "+61"
            }
            className={mergeClasses(
              layout.clientContactPrefix,
              "clientContactPrefix",
              props?.errors?.accountPhoneNumber ? "mb-1_5" : ""
            )}
            validation={props.register("accountPhonePrefix")}
          />

          <CustomFieldsInput
            label={t("phone_number")}
            validationMessage={props.errors?.accountPhoneNumber?.message}
            id="accountPhoneNumber"
            placeholder={t("enter_phone_number")}
            // inputType="number"
            // onKeyDown={blockInvalidCharPhone}
            name="accountPhoneNumber"
            value={value}
            onChange={onChange}
            className="phoneNumberInput"
            inputClassName={mergeClasses(style.textboxInput)}
            validation={props.register("accountPhoneNumber", {
              required: `${t("validation_message.phone_number_is_required")}`,
              pattern: {
                value: NUMBER_VALIDATION_16SIZE_PATTERN,
                message: `${t("validation_message.number_not_match16_format")}`,
              },
            })}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default AccountPhoneNumber;
